<template>
    <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
        <ul class="errors-list">
            <li v-for="error in errors">
                {{ error }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ['errors'],
	mounted() {
		//console.log(JSON.stringify(this.errors));
	},
}
</script>

