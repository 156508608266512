var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 400 400" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 400 400",
        "xml:space": "preserve"
      }
    },
    [
      _c("title", [_vm._v("11")]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M335.7,123.1h-135l54.6-50.9c2.4-2.3,2.6-6.1,0.3-8.5c-2.3-2.4-6.1-2.6-8.5-0.3L186,120.5L152.6,82c-2.2-2.5-6-2.8-8.5-0.6\r\n\t\tc-2.5,2.2-2.8,6-0.6,8.5l28.8,33.2H64.3c-3.3,0-6,2.7-6,6v185.8c0,3.3,2.7,6,6,6h17.4v11.3c0,3.3,2.7,6,6,6s6-2.7,6-6v-11.3h214.3\r\n\t\tv11.3c0,3.3,2.7,6,6,6s6-2.7,6-6v-11.3h15.8c3.3,0,6-2.7,6-6V129.1C341.7,125.8,339.1,123.1,335.7,123.1z M329.7,308.9H70.3V135.1\r\n\t\th259.5V308.9z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M92,160.2v127.1c0,3.3,2.7,6,6,6h170.4c3.3,0,6-2.7,6-6V160.2c0-3.3-2.7-6-6-6H98C94.7,154.2,92,156.9,92,160.2z\r\n\t\t M104,166.2h158.4v115.1H104V166.2z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M155.4,258.9h-29v-26.8c0-3.3-2.7-6-6-6s-6,2.7-6,6v32.8c0,3.3,2.7,6,6,6h35c3.3,0,6-2.7,6-6S158.7,258.9,155.4,258.9z"
          }
        }),
        _vm._v(" "),
        _c("circle", { attrs: { cx: "305.7", cy: "177", r: "8.4" } }),
        _vm._v(" "),
        _c("circle", { attrs: { cx: "305.7", cy: "208.7", r: "8.4" } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }