<template>
    <div class="row list-item-wrapper mb-2 flex-center" v-bind:class="{ 'bg-light':(transaction.pending==1)}">
        <div class="col-2">
            <span class="dateContain">
                <span class="dateMonth ml-1">
                    {{ transaction.day_of_week }}
                </span>
                <span class="dateDay ml-1">
                    {{ transaction.date_day }}
                </span>
            </span>
        </div>
        <div class="col-4">
            <span v-if="transaction.entity" data-toggle="tooltip" :title="transaction.entity.name">{{ transaction.entity.name_short }}</span>
        </div>
        <div class="col-3">
            <p v-if="transaction.subcategories.length===0">Needs Category</p>
            <p v-else-if="typeof(transaction.subcategories)!=='undefined' && transaction.subcategories.length>1"  v-for="(subcategory, index) in transaction.subcategories">
            {{ subcategory.name }} ({{ formatMoney(subcategory.pivot.amount) }})
            </p>
            <p v-else-if="firstSubcategory">{{ firstSubcategory.name }} <span v-if="isPivotDifferentFromTotal(firstSubcategory)">({{ formatMoney(firstSubcategory.pivot.amount) }})</span></p>
            <p v-else-if="transaction.subcategories.length===0">Needs Category</p>
        </div>
        <div class="col-2 text-right">
            <span v-if="transaction.debit_amount">
                ${{ transaction.absolute_value_amount ? + transaction.absolute_value_amount : '0.00' }}
            </span>
            <span v-else>
                ${{ transaction.absolute_value_amount ? '-' + transaction.absolute_value_amount : '0.00' }}
            </span>
            <span v-if="transaction.pending==1">
                <p><small><i>Pending</i></small></p>
            </span>
        </div>
		<div class="col-1 icons-col">
			<a @click="$emit('edit',  transaction)" v-on:click.stop class="circle-btn">
               <span class="pencilIcon"></span>
            </a>
		</div>
    </div>
</template>
<script>
    export default {
		props:["transaction","categories"],
		ready: function() {
			this.$nextTick(function () {
            	$('[data-toggle="tooltip"]').tooltip()
	        });
		},
		methods:{
			update(selected){
				this.$emit('update', selected, this.transaction);
			},
            formatMoney(amount) {
                return accounting.formatMoney(amount);
            },
            isPivotDifferentFromTotal(firstSubcategory) {
                if(typeof(firstSubcategory.pivot)!=='undefined') {
                    if(Number(firstSubcategory.pivot.amount)!==Number(this.transaction.amount)) {
                        return true;
                    }
                }
                return false;
            }
		},
        computed: {
            firstSubcategory() {

                if (this.transaction.subcategories && this.transaction.subcategories.length>0) {
                    return this.transaction.subcategories[0];
                }
                return false;
            },
        }
	}
</script>
<!--<div class="col-md-2">
<a @click="$emit('edit', transaction)"><i class="fa fa-pencil"></i>Edit</a>
</div>-->
<!--
-<button class="btn btn-link" href="#" v-on:click="$emit('remove', transaction)">X</button>
-->
