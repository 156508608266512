<template>
    <div>
        <budgets-search-add></budgets-search-add>
        <div class="container h-100">
            <div class="row no-gutters h-100">
                <div class="col-lg-4 order-lg-last col-12">
                    <budgetProgress></budgetProgress>
                </div>
                <div class="col-lg-8 order-lg-first col-12">
                    <h3 class="mb-4">{{ month }} Budget</h3>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="combined-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false" @click='setActiveBudgetsTab("all")'>Combined ({{ (budgets) ? budgets.length : 0}})</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="bills-tab" data-toggle="tab" href="#bills" role="tab" aria-controls="bills" aria-selected="true" @click='setActiveBudgetsTab("bills")'>Bills ({{(due_in_full_budgets) ? due_in_full_budgets.length : 0}})</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="budgets-tab" data-toggle="tab" href="#budgets" role="tab" aria-controls="budgets" aria-selected="false" @click='setActiveBudgetsTab("budgets")'>Expenses ({{ (spread_out_budgets) ? spread_out_budgets.length : 0}})</a>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="combined-tab">
                            <budgets_list v-if='activeTab=="all"'></budgets_list>
                            <bills_list v-if='activeTab=="all"'></bills_list>
                        </div>
                        <div class="tab-pane fade" id="bills" role="tabpanel" aria-labelledby="bills-tab">
                            <budgets_list v-if='activeTab=="bills"'></budgets_list>
                        </div>
                        <div class="tab-pane fade" id="budgets" role="tabpanel" aria-labelledby="budgets-tab">
                            <bills_list v-if='activeTab=="budgets"'></bills_list>
                        </div>
                    </div>
                    <budgetTransactionsModal v-if="showBudgetTransactionsModal"></budgetTransactionsModal>
                    <budgetSummaryModal v-if="showBudgetSummaryModal"></budgetSummaryModal>
                    <suggestedBudgets v-if="showSuggestedBudgetsModal"></suggestedBudgets>
                    <component :is='current_input'></component>
                </div>
            </div>
        </div>
        <datfootertho></datfootertho>
    </div>
</template>
<script>
    export default {
		methods: {
			setActiveBudgetsTab(tab) {
				this.$store.commit('setActiveBudgetsTab', tab);
			},
			toggle () {
				this.$store.commit('toggleBudgetMode');
			},
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
            monthStringFromDate(date) {
                var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                if(typeof(date)==='object') {
                    return  monthNames[date.getMonth()];
                }
            },
		},
		mounted: function() {

            if (this.$router.currentRoute.params.month) {
                var month = this.$router.currentRoute.params.month;
            }
            if (this.$router.currentRoute.params.year) {
                var year = this.$router.currentRoute.params.year;
            }
            if (month + year) {
                var passedInDate = new Date(year, month-1);
                this.$store.commit('setCurrentDate', new Date(passedInDate));
                if (!this.$store.state.budgets) {
                    this.$store.dispatch('get_budgets').then(() => {
                        if(!this.$store.state.this_months_transactions) {
                            this.$store.dispatch('get_all_accounts_transactions_for_this_month').then(() => {

                            });
                        }
                    })
                }

            } else if (!this.$store.state.current_date) {
                return this.$store.dispatch('get_current_date').then(() => {
                    if (!this.$store.state.budgets) {
                        this.$store.dispatch('get_budgets').then(() => {
                            if(!this.$store.state.this_months_transactions) {
                                this.$store.dispatch('get_all_accounts_transactions_for_this_month').then(() => {

                                });
                            }
                        })
                    }
                });
            }
		},
		computed: {
			activeTab() {
				return this.$store.state.active_budgets_tab;
			},
            budgets() {
                return this.$store.getters.budgets;
            },
            due_in_full_budgets () {
                if(this.$store.getters.budgets && this.$store.getters.budgets.length) {
                    var dueInFullBudgets = this.$store.getters.budgets.filter(function(budget){return budget.due_in_full;});
                    return dueInFullBudgets;
                }
            },
            spread_out_budgets () {
                if(this.$store.getters.budgets && this.$store.getters.budgets.length) {
                    return this.$store.getters.budgets.filter(function(budget){return budget.due_in_full==false;});
                }
            },
            body_view() {
                if(this.$store.state.budgets.length===0) {
                    return 'message-to-user';
                } else {
                    return null;
                }
            },
            current_input() {
                return this.$store.state.current_input;
            },
			showBudgetSummaryModal () {
				return this.$store.state.showBudgetSummaryModal;
			},
			showBudgetTransactionsModal () {
				return this.$store.state.showBudgetTransactionsModal;
			},
            showSuggestedBudgetsModal () {
				return this.$store.state.showSuggestedBudgetsModal;
            },
			new_budget() {
				return this.$store.state.new_budget;
			},
			unbudgeted_expense_categories() {
				return this.$store.state.unbudgeted_expense_categories;
			},
			month() {
                if(this.$store.state.current_date){
                    return this.monthStringFromDate(this.$store.state.current_date);
                }
			},
            year() {
                if(this.$store.state.current_date){
                    return this.$store.state.current_date.getFullYear();
                }
            },
			remaining () {
				return this.round(this.$store.getters.total_budgeted_remaining,2);
			},
//			account () {
//				return this.$store.state.account;
//			},
			budgets (){
				return this.$store.getters.budgets;
			},
			total_income (){
				return this.$store.getters.total_income;
			},
			monthFirst () {
				return this.$store.state.monthFirst;
			},
			monthLast () {
				return this.$store.state.monthLast;
			},
			percent: function () {
				return 'left: ' + this.round(((this.$store.state.today / this.$store.state.last)*100),0) + '%';
			}
		},
	}
</script>
