var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("3")]),
      _c("path", {
        attrs: {
          d:
            "M66.79,240.29a18.27,18.27,0,0,0,18.26,18.25H247.27a18.26,18.26,0,0,0,12-4.51L374,153.65a6,6,0,0,0-3.95-10.52H338.26a6.07,6.07,0,0,0-2.38.49L244.41,183.1a8.26,8.26,0,0,1-8.91-1.54l-26.14-24.34a6,6,0,0,0-4.09-1.61H157.85v-11h20.53a6,6,0,0,0,0-12h-84a6,6,0,1,0,0,12h19.24v11H20a6,6,0,0,0-6,6V181.1c0,7.86,0,28.74,20.46,28.74H66.79Zm58.87-95.71h20.19v11H125.66Zm-46.87,23H202.91l24.42,22.74a20.27,20.27,0,0,0,21.84,3.77l90.33-39h14.61L251.39,245a6.23,6.23,0,0,1-4.12,1.54H85.05a6.26,6.26,0,0,1-6.26-6.25ZM34.46,197.84c-4.95,0-8.46-1.2-8.46-16.74V167.61H66.79v30.23Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M383.19,228.65l-18.29-37a6,6,0,0,0-10.76,0l-18.29,37a27.07,27.07,0,0,0,.95,25.74,26.21,26.21,0,0,0,21.28,13c.47,0,1,0,1.44,0s1,0,1.45,0a26.19,26.19,0,0,0,21.27-13A27,27,0,0,0,383.19,228.65Zm-11.3,19.66a14,14,0,0,1-11.53,7.09,15.69,15.69,0,0,1-1.68,0,14,14,0,0,1-11.53-7.09,15.08,15.08,0,0,1-.54-14.35l12.91-26.12L372.43,234A15,15,0,0,1,371.89,248.31Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M95.94,240.37h31.5a5,5,0,1,0,0-10H97.25V206.64a5,5,0,1,0-10,0v25A8.73,8.73,0,0,0,95.94,240.37Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }