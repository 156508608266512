<template>
	<div class="row list-item-wrapper mb-2 mt-2" @click="showBudgetSummary(budget)">
        <div class="col-4">
            <icon-base icon-name="write" height="30" width="30" iconColor="#FEB800" class="ml-2 mr-4">
                <component v-bind:is='getIconName(budget.subcategory.slug)' slot="icon"></component>
            </icon-base>
            <span>{{ budget.display_name }}</span>
        </div>
        <div class="col-2">
            <i class="fa fa-balance-scale" aria-hidden="true" v-if="budget_amount==0 && budget.spent > 0" @click='balanceBudget(budget, $event)'></i>
        </div>
        <div class="col-2">
            <span>{{ formatMoney(budget_amount) }}</span>
        </div>
        <div class="col-2">
            <span>{{ formatMoney(budget.spent) }}</span>
        </div>
        <div class="col-1 icons-col">
            <a @click="$emit('edit', budget)" v-on:click.stop class="circle-btn">
                <span class="pencilIcon"></span>
            </a>
        </div>
    </div>
    <!--
   -    <div class="editable budget-category boxshadow-1 p-3 mb-4">
   -        <div class="row" v-if=" !budget.is_due_on_recurring_date">
   -            <div class="col">
   -                <div class="row">
   -                    <div class="col mb-2">
   -                        <span class="category-title">{{ budget.subcategory.name }}</span>
   -                        <a @click="$emit('edit', budget)" class="hide edit-icon"><i class="fa fa-pencil"></i></a>
   -                    </div>
   -                </div>
   -                <div class="row" v-if='budget_amount > 0'>
   -                    <div class="col">
   -                        <div class="progress">
   -                            <div class="progress-bar" v-bind:class="{ 'bg-success':(remaining>0 && !budget.is_due_on_recurring_date), 'bg-warning' : (remaining>0) && (budget.is_recurring && budget.is_due_on_recurring_date && shown_month_is_current_or_past) && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month < current_day_of_month), 'bg-danger': (shown_month_is_current_or_past) && (remaining<0), '': (!shown_month_is_current_or_past) || remaining==0 }" role="progressbar"  aria-valuemin="0" aria-valuemax="100" :style="width">
   -
   -                                <div class="percentage-indicator">
   -                                    <span class="value">
   -                                        <i class="fa fa-check d-none" aria-hidden="true" v-if="percentSpent==100"></i>
   -                                        {{percentSpent}}%
   -                                    </span>
   -
   -                                </div>
   -                            </div>
   -                        </div>
   -                    </div>
   -                </div>
   -                <div class="row">
   -                    <div class="col-4">
   -                        <p class="amount-cont">
   -                        <span class="title" v-if="remaining>=0">Remaining</span>
   -                        <span v-else-if="remaining<0 && budget_amount==0">Needs budgeted</span>
   -                        <span v-else-if="remaining<0 && budget_amount>0">Over budget</span>
   -                        <span v-else-if="remaining==0">Met budget</span>
   -                        <span class="amount"> {{ formatMoney(remaining) }}</span>
   -                        </p>
   -                    </div>
   -                    <div class="col-4">
   -                        <p class="amount-cont">
   -                        <span class="title">budgeted</span>
   -                        <span class="amount"> {{ formatMoney(budget_amount) }}</span>
   -                        </p>
   -                    </div>
   -                    <div class="col-4">
   -                        <p class="amount-cont">
   -                        <span class="title">spent</span>
   -                        <span class="amount">{{ formatMoney(budget.spent) }}</span>
   -                        </p>
   -                    </div>
   -                    <div class="col-12 text-center">
   -                        <a href="#" class="btn btn-primary mt-5" @click="showBudgetTransactions(budget)">View transactions</a>
   -                    </div>
   -                </div>
   -            </div>
   -        </div>
   -    </div>
   -->
</template>
<script>
export default {
	props:["budget","index"],
	ready: function() {
		this.$nextTick(function () {
			$('[data-toggle="tooltip"]').tooltip()
		});
	},
	filters: {
		date: function (value) {
			var d = new Date();
			d.setDate(value);
			return format(d, 'MM/DD/YYYY')
		}
	},
	computed: {
		daysPassedDue: function() {
			var result = differenceInDays(
				new Date(),
				new Date(this.budget.recurrence_date_this_month)
			);
			return result;
		},
		budget_paid: function() {
			if(typeof(this.budget.months) !=='undefined') {
				return this.budget.months[0].has_been_paid;
			} else if(typeof(this.budget.most_recent_budget_month) !=='undefined') {
				return this.budget.most_recent_budget_month.has_been_paid;
			}
			return 0;
		},
		budget_amount: function() {
			if(typeof(this.budget.months) !=='undefined') {
				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
				var month = format(d, 'M');
				var year = format(d, 'YYYY');
				var currentBudgetMonth;
					currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));

				if(!currentBudgetMonth) {
					var currentDate = new Date(this.$store.state.current_date);
					var today = new Date();

					var monthShown = currentDate.getMonth();
					var yearShown = currentDate.getYear();
					var actualCurrentMonth = today.getMonth();
					var actualCurrentYear = today.getYear();
                    console.log(actualCurrentYear);
                    console.log(yearShown);

					if((monthShown > actualCurrentMonth && yearShown == actualCurrentYear) || yearShown > actualCurrentYear) {
						// https://date-fns.org/docs/format
                        if ( yearShown == actualCurrentYear) {
                            console.log('year shown is current year');
                            var month = format(today, 'M');
                            var year = format(today, 'YYYY');
                            currentBudgetMonth = this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));

                            if(currentBudgetMonth) {
                                return currentBudgetMonth.amount;
                            }
                        }
                        console.log('future month not current year');
                        // if future month, just show last budget_months budget amount
                        if (this.budget.months.length) {
                            console.log('has budget months');
                            console.log(this.budget.months[this.budget.months.length - 1].amount);
							// latest budget month
							return this.budget.months[this.budget.months.length - 1].amount;
						}
					}
				}
			}

            if (typeof(currentBudgetMonth) !=='undefined') {
                return currentBudgetMonth.amount;
            }
            return this.budget.amount;
		},
		current_shown_day_of_month_formatted: function() {
			var ds = new Date(this.$store.state.current_date);
			return format(ds, 'MM/DD/YYYY');
		},
		current_day_of_month_formatted: function () {
			var d = new Date();
			// https://date-fns.org/docs/format
			return format(d, 'MM/DD/YYYY');
			//return format(d, 'DD')
		},
		current_day_of_month: function () {
			var d = new Date();
			// https://date-fns.org/docs/format
			return format(d, 'MMM Do');
			//return format(d, 'DD')
		},

		// @TODO: this is not working for past years
		shown_month_is_current_or_past: function () {
			if(this.budget.recurrence_date_this_month) {

				// Due date
				var d = new Date(this.budget.recurrence_date_this_month);
				var dueDateMonth = d.getMonth();
				var dueDateYear = d.getYear();

				// Current date
				var ds = new Date(this.$store.state.current_date);
				var monthShown = ds.getMonth();
				var yearShown = ds.getYear();

				if(monthShown <= dueDateMonth && yearShown <= dueDateYear) {
					return true;
				}
				return false;

			} else if (!this.budget.recurrence_date_this_month) {

				var d = new Date();
				var actualDateMonth = d.getMonth();
				var actualDateYear = d.getYear();

				var ds = new Date(this.$store.state.current_date);
				var monthShown = ds.getMonth();
				var yearShown = ds.getYear();

				if(monthShown <= actualDateMonth && yearShown <= actualDateYear) {
					return true;
				}

			}
		},
		width: function () {
			return 'width: ' + this.percentSpent + '%';
		},
		widthLabel: function () {

			//return (Number(this.budget.amount) > 0) ? ( '$' + Number(this.budget.spent) + ' of $' + Number(this.budget.amount)) : Number(0);
			return '$' + this.round(Number(this.budget.spent),2);
		},
		remaining: function() {
			return accounting.formatNumber(Number(this.budget_amount) - Number(this.budget.spent),2);
		},
		percentSpent: function() {
			return typeof(this.budget.spent)=='number' && this.budget_amount > 0 ? this.round((Number(this.budget.spent) / Number(this.budget_amount)) * 100, 0) : 100;
		},
		shownMonthIsCurrentMonth() {
			var today = new Date();
			var month = today.getMonth();
			var year = today.getYear();

			// Current selected date
			var ds = new Date(this.$store.state.current_date);
			var monthShown = ds.getMonth();
			var yearShown = ds.getYear();

			if(monthShown == month && yearShown == year) {
				return true;
			}
			return false;
		},
		percentDaysPassed () {

			var today = new Date();
			var month = today.getMonth();
			var year = today.getYear();

			// Current selected date
			var ds = new Date(this.$store.state.current_date);
			var monthShown = ds.getMonth();
			var yearShown = ds.getYear();

			if(monthShown == month && yearShown == year) {
				var d = new Date(2008, monthShown + 1, 0);
				return this.round(((this.$store.state.current_date.getDate() / d.getDate())*100),0);
			}

			/*
				if(this.budget.recurrence_date_this_month) {

					var d = new Date(this.budget.recurrence_date_this_month);
					var dueDateMonth = d.getMonth();
					var dueDateYear = d.getYear();

					if(monthShown == dueDateMonth && yearShown == dueDateYear) {

						var d = new Date(2008, monthShown + 1, 0);
						return this.round(((this.$store.state.current_date.getDate() / d.getDate())*100),0);

					}
				}
			 */

		},
		percent() {
			return 'left: ' + this.percentDaysPassed + '%';
		},
		amount () {
			return this.round(Number(this.budget.amount), 2);
		},
	},
	data() {
		return {
			widthTween: 0,
		}
	},
	methods: {
        getIconName(slug) {
            return 'icon-' + slug;
        },
		formatMoney(amount) {
			return accounting.formatMoney(amount);
		},
		balanceBudget: function(budget, event) {
            event.stopPropagation();
			if (budget.months) {
				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
				var month = format(d, 'M');
				var year = format(d, 'YYYY');
				var currentBudgetMonth;
				currentBudgetMonth= budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
				if(currentBudgetMonth){
					currentBudgetMonth.amount = accounting.toFixed(budget.spent, 2);
				}
			} else {
				var months = [];
				months.push({amount: accounting.toFixed(budget.spent, 2)});
				budget.push(months);
			}
			this.$store.dispatch('update_budget', {
				budget: budget
			})


		},

		showBudgetTransactions: function(budget) {
			// console.log(JSON.stringify(this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)));
			this.$store.commit('setToggleShowBudgetTransactionsModal', {setting: true, budget: budget, budget_transactions: this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)})
		},
		showBudgetSummary: function(budget) {
			// console.log(JSON.stringify(this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)));
			this.$store.commit('setToggleShowBudgetSummaryModal', {setting: true, budget: budget, budget_transactions: this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)})
		},
		round (value, decimals) {
			return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
		},
	},
	watch: {
		widthLabel: function(newValue, oldValue) {

			var vm = this
			var animationFrame
			function animate (time) {
				TWEEN.update(time)
				animationFrame = requestAnimationFrame(animate)
			}

			new TWEEN.Tween({ tweeningNumber: oldValue })
				.easing(TWEEN.Easing.Quadratic.Out)
				.to({ tweeningNumber: newValue }, 1200)
				.onUpdate(function () {
					vm.widthTween = this.tweeningNumber.toFixed(0)
				})
				.onComplete(function () {
					cancelAnimationFrame(animationFrame)
				})
				.start()
			animationFrame = requestAnimationFrame(animate)
		}
	},
}
</script>


<!--
	-
	-    <div class="row">
	-
	-
	-        <div class="col">
	-
	-            <span class="badge" v-bind:class="{ 'badge-success':(remaining>0 && !budget.is_due_on_recurring_date), 'badge-warning' : (remaining>0) && (budget.is_recurring && budget.is_due_on_recurring_date && shown_month_is_current_or_past) && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month < current_day_of_month), 'badge-danger': (shown_month_is_current_or_past) && (remaining<0), 'badge-primary': (!shown_month_is_current_or_past) || remaining==0 }">
	-                <span v-if="!shown_month_is_current_or_past && budget.is_recurring">Not due yet</span>
	-                <span v-else-if="budget.amount==0 && remaining<=0"> [>${{ remaining }}<] Needs budgeted</span>
	-                <span v-else-if="budget.amount>0 && remaining<0"> {{ budget.subcategory.name }} [>${{ remaining }}--> <!-- Over budget<] </span>
	-                <span v-else-if="(remaining>0) && budget.is_due_on_recurring_date && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month < current_day_of_month)">${{ remaining }} due soon</span>
	-                <span v-else-if="remaining==0 && !budget.is_due_on_recurring_date"><i class="fa fa-check" aria-hidden="true"></i> Hit budget</span>
	-                <span v-else-if="remaining==0 && budget.is_due_on_recurring_date"> {{ budget.subcategory.name }} [>Paid in full<]</span>
	-                <span v-else-if="budget.is_due_on_recurring_date && remaining>0 && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month > current_day_of_month)">${{ remaining }} past due</span>
	-                <span v-else-if="(remaining>0 && budget.is_due_on_recurring_date &&  budget.recurrence_date_this_month && (budget.recurrence_date_this_month == current_day_of_month))">${{ remaining }} due today</span>
	-                <span v-else-if="remaining>0"> {{ budget.subcategory.name }} [> ${{ remaining }} left<]</span>
	-                <span v-else-if="remaining==0 && budget.is_due_on_recurring_date">${{ remaining }} Paid in full</span>
	-                <span v-else-if="remaining==0 && !budget.is_due_on_recurring_date">${{ remaining }} Hit budget</span>
	-
	-            </span>
	-        </div>
	-        <div class="col">
	-            <div class="progress">
	-                <div class="progress-bar" v-bind:class="{ 'bg-info': remaining==0, 'bg-success': remaining>0 && percentSpent<=percentDaysPassed, 'bg-danger': remaining<0, 'bg-warning': (percentSpent>percentDaysPassed && remaining>0)  }" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="width">
	-                                {{ widthLabel }}
	-                </div>
	-            </div>
	-            <div class="progress-p" v-if='!budget.is_due_on_recurring_date && shownMonthIsCurrentMonth'>
	-            	<div class="progress-pace progress-pace-day" role="progressbar" :style="percent" data-toggle="tooltip" v-bind:data-before-content="current_day_of_month"></div>
	-            </div>
	-            <span class="small text-muted"><a href="#" @click="showBudgetTransactions(budget)">Total Spent</a></span>
	-        </div>
	-    </div>
	-->

	<!--, 'progress-bar-onpoint': remaining==0-->
	<!--
	 -<i class="fa fa-check" aria-hidden="true" v-if="remaining==0"></i>
	-->
	<!--<span class="label" v-bind:class="{ 'label-success': remaining>0, 'label-danger': remaining<0, 'label-onpoint': remaining==0 }">-->

	<!--	<p>$ {{ remaining }} <span v-if="remaining>0">available</span><span v-else="remaining<0">over budget</span></p>-->
	<!--</span>-->
	<!--<div class="row">
	 <div class="col-md-12">
	 <a @click="$emit('edit', budget)">Edit <i class="fa fa-pencil"></i></a>
	 </div>
	 </div>-->
	 <!--<div class="col-md-2">
	  <h1>${{ remaining }}</h1>
	  <h1 class="small">Left</h1>
	  </div>-->
	  <!--<div class="col-md-3">
	   <span class="editable">
	   <div class="col-md-8">
	   $ {{ budget.amount }}
	   </div>
	   <div class="col-md-4">
	   </div>
	   </span>
	   </div>-->
	   <!--<div class="col-md-1">
		$ {{ budget.spent }}
		</div>
		<div class="col-md-1">
		<span class="label" v-bind:class="{ 'label-success': remaining>0, 'label-danger': remaining<0, 'label-onpoint': remaining==0 }">
		$ {{ remaining }}
		</span>
		</div>-->







		<!--<div class="row editable pv20">
	  <div class="col-md-3">
	  <span class="medium" @click="showBudgetTransactions(budget)">
	  {{ budget.subcategory.name }}
	  </span>
	  </div>
	  <div class="col-md-2">
	  <span class="medium">
	  ${{ budget.amount }}
	  </span>
	  </div>
	  <div class="col-md-3">
	  </div>
	  <div class="col-md-2">
	  <span class="label medium" v-bind:class="{ 'label-success': remaining>0, 'label-danger': remaining<0, 'label-onpoint': remaining==0 }">
	  ${{ remaining }}
	  </span>
	  </div>
	  <div class="col-md-2">
	  <a @click="$emit('edit', budget)">Edit</a>
	  </div>
	  </div>-->


	  <!--<span class="small" v-if="!shown_month_is_current_or_past"></span>
	   <span class="small" v-else-if="budget.recurs_this_month && budget.is_due_on_recurring_date && budget.recurrence_date_this_month > current_day_of_month">Due on: {{ budget.recurrence_date_this_month }}</span>
	   <span class="small" v-else-if="budget.recurs_this_month && budget.is_due_on_recurring_date && budget.recurrence_date_this_month < current_day_of_month && remaining>0">Past Due!</span>
	   <span class="small" v-else-if="budget.recurs_this_month && budget.is_due_on_recurring_date && budget.recurrence_date_this_month == current_day_of_month">Due today.</span>-->
	   <!--
		-<div class="col">
		-    <span>
		-        {{ budget.subcategory.name }}
		-    <a @click="$emit('edit', budget)" class="hide text-primary"><i class="fa fa-pencil"></i></a>
		-    </span>
		-</div>
	   -->
	   <!--
		-            <span class="badge" v-bind:class="{ 'badge-success':(remaining>0 && !budget.is_due_on_recurring_date), 'badge-warning' : (remaining>0) && (budget.is_recurring && budget.is_due_on_recurring_date && shown_month_is_current_or_past) && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month < current_day_of_month), 'badge-danger': (shown_month_is_current_or_past) && (remaining<0), 'badge-primary': (!shown_month_is_current_or_past) || remaining==0 }">
		-                <span v-if="!shown_month_is_current_or_past && budget.is_recurring">Not due yet</span>
		-                <span v-else-if="budget.amount==0 && remaining<=0"> ${{ remaining }} Needs budgeted</span>
		-                <span v-else-if="budget.amount>0 && remaining<0"> ${{ remaining }} Over budget</span>
		-                <span v-else-if="(remaining>0) && budget.is_due_on_recurring_date && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month < current_day_of_month)">${{ remaining }} due soon</span>
		-                <span v-else-if="remaining==0 && !budget.is_due_on_recurring_date"><i class="fa fa-check" aria-hidden="true"></i> Hit budget</span>
		-                <span v-else-if="remaining==0 && budget.is_due_on_recurring_date"><i class="fa fa-check" aria-hidden="true"></i> Paid in full</span>
		-                <span v-else-if="budget.is_due_on_recurring_date && remaining>0 && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month > current_day_of_month)">${{ remaining }} past due</span>
		-                <span v-else-if="(remaining>0 && budget.is_due_on_recurring_date &&  budget.recurrence_date_this_month && (budget.recurrence_date_this_month == current_day_of_month))">${{ remaining }} due today</span>
		-                <span v-else-if="remaining>0">${{ remaining }} left</span>
		-                <span v-else-if="remaining==0 && budget.is_due_on_recurring_date">${{ remaining }} Paid in full</span>
		-                <span v-else-if="remaining==0 && !budget.is_due_on_recurring_date">${{ remaining }} Hit budget</span>
		-
		-            </span>
	   -->
	   <!--
		'badge-info': (shown_month_is_current_or_past) && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month >= current_day_of_month),
	   -->
