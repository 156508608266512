var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    { staticClass: "form-control ui dropdown transparent-dropdown" },
    _vm._l(_vm.accounts, function(daccount, index) {
      return _vm.accounts.length > 0
        ? _c("option", { domProps: { value: daccount.id } }, [
            _vm._v(_vm._s(daccount.name))
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }