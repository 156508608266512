var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("15")]),
      _c("path", {
        attrs: {
          d:
            "M311.32,82.06H88.68a5,5,0,0,0-5,5V312.9a5,5,0,0,0,5,5h43.66a5,5,0,0,0,5-5V278.8H260.52v34.1a5,5,0,0,0,5,5h45.76a5,5,0,0,0,5-5V87.1A5,5,0,0,0,311.32,82.06ZM93.72,180.34V103.4l2.78,3.14c6.11,6.9,14,10.67,22.29,10.67h.64c8.63-.21,16.73-4.47,22.79-12l2.31-2.88,3.73,4.21a34.54,34.54,0,0,0,2.89,2.91C148.2,120.78,134.7,162.81,93.72,180.34Zm40.65-81.45c-4.17,5.18-9.56,8.11-15.18,8.24h-.39c-5.37,0-10.59-2.58-14.75-7.28l-6.84-7.72H139.8Zm25.72,16.24A27.61,27.61,0,0,0,167,117,111.21,111.21,0,0,1,143,151.66,130.59,130.59,0,0,0,160.09,115.13Zm10.85-8c-5.51.16-10.88-2.45-15.14-7.27l-4.87-5.5a5,5,0,0,0,.92-2.22h39.71l-5.44,6.75C182,104.07,176.56,107,170.94,107.13Zm88.62-15h42.3l-5.43,6.75c-4.18,5.18-9.57,8.11-15.18,8.24s-10.88-2.45-15.14-7.27l-6.6-7.45A2.56,2.56,0,0,0,259.56,92.14Zm-17.48,6.75c-4.17,5.18-9.56,8.11-15.18,8.24-.17,0-.34,0-.52,0a5,5,0,0,0-2.77-.24,20.73,20.73,0,0,1-11.85-7l-6.84-7.72h42.59ZM238.8,114.3a130.15,130.15,0,0,0,17.3,37.36,111.66,111.66,0,0,1-24.09-35A27.9,27.9,0,0,0,238.8,114.3Zm8.77-6.43c.82-.83,1.6-1.72,2.36-2.66l3.55-4.41,5.08,5.74c6.11,6.9,14,10.67,22.29,10.67h.64c8.63-.21,16.72-4.47,22.79-12l2-2.49v78C262.34,162.48,249.53,116.16,247.57,107.87ZM93.72,210a224.34,224.34,0,0,1,10.33,97.84H93.72Zm20.47,97.84a233.2,233.2,0,0,0-8.52-94c8.87,13,18.43,32.72,21.63,60.27v33.77Zm22.57-39.14c-6.52-46.19-29.4-70.91-38.75-79.26a99.78,99.78,0,0,0,16.29-8.87c.16,0,.32,0,.47,0a5.06,5.06,0,0,0,1.82-.34c36.59-14.11,54.12-45.26,61.68-64.2A31.78,31.78,0,0,0,194,105.21l4.32-5.36,5.92,6.69a30.9,30.9,0,0,0,16.86,10.12c7.69,19,25.23,49.66,61.46,63.63a5,5,0,0,0,1.81.34c.16,0,.32,0,.47,0A100.44,100.44,0,0,0,301,189.43c-9.68,8.36-33.31,33.08-39.88,79.29Zm133.84,5.37c3.33-28.67,13.86-48.9,23.32-61.8a233.08,233.08,0,0,0-9,95.57H270.6Zm24.47,33.77A223.91,223.91,0,0,1,306.28,207.5V307.86Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }