<template>
	<div class="row list-item-wrapper mb-2" @click="showBudgetSummary(budget)">
        <div class="col-3">
            <icon-base icon-name="write" height="30" width="30" iconColor="#FEB800" class="ml-2 mr-4">
                <component v-bind:is='getIconName(budget.subcategory.slug)' slot="icon"></component>
            </icon-base>
            <span v-if="budget.website"><a  v-bind:href="budget.website" target="_blank" class="ml-2">{{ budget.display_name }}</a></span>
            <span v-else>{{ budget.display_name }}</span>
        </div>
		<div class="col-3">
			<span>{{ recurrence_date_this_month_short }}</span>
		</div>
		<div class="col-2">
			<span>{{ formatMoney(budget_amount) }}</span>
		</div>
		<div class="col-3">
        <div v-if="shown_month_is_current_or_past && this_month_transactions!==0" >
			<span class="pill" v-bind:class="getBudgetStatus.icon">
                 <span>{{ getBudgetStatus.label }}</span>
			</span>
            </div>
            <div class="" v-else-if='!shown_month_is_current_or_past'>N/A</div>
            <div class="text-line-sm" v-else></div>
		</div>
		<div class="col-1 icons-col">
			<a @click="$emit('edit', budget)" v-on:click.stop class="circle-btn">
               <span class="pencilIcon"></span>
            </a>
		</div>
	</div>
</template>
<script>
export default {
	mounted() {
        // Logs the budget object
        //console.log('==> this.budget', this.$store.getters.dueInFullBudgetStatus(this.budget));
	},
	props:['budget','index'],
	methods: {
        getIconName(slug) {
            return 'icon-' + slug;
        },
		pluralize(word, count) {
			return pluralize(word, count);
        },
		formatMoney(amount) {
            return accounting.formatMoney(amount, '$', 0 ,"", "");
		},
		showBudgetSummary: function(budget) {
			// console.log(JSON.stringify(this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)));
			this.$store.commit('setToggleShowBudgetSummaryModal', {setting: true, budget: budget, budget_transactions: this.$store.getters.budgetTransactionsBySubcategoryId(budget.subcategory.id)})
		},
	},
	computed: {
        getBudgetStatus: function () {
            return this.$store.getters.dueInFullBudgetStatus(this.budget);
        },
        this_month_transactions: function() {
            return this.$store.getters.this_month_transactions;
        },
        recurrence_date_this_month_short: function () {
            var today = new Date(this.$store.state.current_date);
            var month = format(today, 'MM');
            var year = format(today, 'YYYY');
            var recurrenceDate;
            if(!this.budget.recurrences || !this.budget.recurrences.length) {
                return 'N/A';
            }
            recurrenceDate = this.budget.recurrences[0].repeat_start;
            var d = new Date(recurrenceDate);
            var day = format(d, 'DD');
            // https://date-fns.org/docs/format
            var date = new Date(month + '/' + day + '/' + year);
            return d = format(date, 'MMM Do');
        },
        recurrence_date_this_month: function () {
            if(this.budget.recurrence_date_this_month) {
                //return this.budget.recurrence_date_this_month;
                var today = new Date(this.$store.state.current_date);
				var d = new Date(this.budget.recurrence_date_this_month);
				var day = format(d, 'DD');
                var month = format(today, 'MM');
				var year = format(today, 'YYYY');
				// https://date-fns.org/docs/format
                var date = new Date(month + '/' + day + '/' + year);
			    return format(date, 'MM/DD/YYYY');
            } else {
				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
			    return format(d, 'MM/DD/YYYY');
            }
        },
		budget_amount: function() {
			if(typeof(this.budget.months) !=='undefined') {
				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
				var month = format(d, 'M');
				var year = format(d, 'YYYY');
				var currentBudgetMonth;
					currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));

				if(!currentBudgetMonth) {
					var currentDate = new Date(this.$store.state.current_date);
					var today = new Date();

					var monthShown = currentDate.getMonth();
					var yearShown = currentDate.getYear();
					var actualCurrentMonth = today.getMonth();
					var actualCurrentYear = today.getYear();
                    console.log(actualCurrentYear);
                    console.log(yearShown);

					if((monthShown > actualCurrentMonth && yearShown == actualCurrentYear) || yearShown > actualCurrentYear) {
						// https://date-fns.org/docs/format
                        if ( yearShown == actualCurrentYear) {
                            console.log('year shown is current year');
                            var month = format(today, 'M');
                            var year = format(today, 'YYYY');
                            currentBudgetMonth = this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));

                            if(currentBudgetMonth) {
                                return currentBudgetMonth.amount;
                            }
                        }
                        console.log('future month not current year');
                        // if future month, just show last budget_months budget amount
                        if (this.budget.months.length) {
                            console.log('has budget months');
                            console.log(this.budget.months[this.budget.months.length - 1].amount);
							// latest budget month
							return this.budget.months[this.budget.months.length - 1].amount;
						}
					}
				}
			}

            if (typeof(currentBudgetMonth) !=='undefined') {
                return currentBudgetMonth.amount;
            }
            return this.budget.amount;
		},
		remaining: function() {
            var remaining = this.budget_amount - this.$store.getters.get_total_spent_in_budget(this.budget);
            return remaining;
//			return accounting.formatNumber(Number(this.budget_amount) - Number(this.budget.spent),2);
		},
		current_day_of_month_formatted: function () {
            return this.$store.getters.currentDayOfMonthFormatted;
		},
		current_day_of_month: function () {
			var d = new Date();
			// https://date-fns.org/docs/format
			return format(d, 'MMM Do');
			//return format(d, 'DD')
		},
		shown_month_is_current_or_past: function () {

            // Current date
            var ds = new Date(this.$store.state.current_date);
            var monthShown = ds.getMonth();
            var yearShown = ds.getYear();

			if(this.budget.recurrence_date_this_month) {

				// Due date
				var d = new Date(this.budget.recurrence_date_this_month);
				var dueDateMonth = d.getMonth();
				var dueDateYear = d.getYear();

				if(monthShown <= dueDateMonth && yearShown <= dueDateYear) {
					return true;
				}
				return false;

			} else if (!this.budget.recurrence_date_this_month) {

                if(typeof(this.budget.months) !=='undefined') {
                    var ds = new Date(this.$store.state.current_date);
                    var date = format(ds, 'MM/01/YYYY');
                    var currentBudget = this.budget.months.find(budgetMonth => String(budgetMonth.date) === String(date));
                    if(currentBudget){
                        var budgetDueDateThisMonth = new Date(currentBudget.date);
                        var dueDateThisMonth = budgetDueDateThisMonth.getMonth();
                        var dueDateThisYear = budgetDueDateThisMonth.getYear();

                        if(monthShown <= dueDateThisMonth && yearShown <= dueDateThisYear) {
                            return true;
                        }
                    }
                    return false;
                }
			}
		},
		daysPastDue: function() {
			var result = differenceInDays(
				new Date(),
				new Date(this.budget.recurrence_date_this_month)
			);
			return result;
		},
		daysUntilDue: function() {

            var recurrenceDate = new Date(this.budget.recurrence_date_this_month);
			var today =	new Date();

			var result = differenceInDays(
				recurrenceDate,
				today
			);

            // this library will say 0 days diff if less than 24 hours, but we want to say do in one day if tomorrow.
            if(Number(result)==0 && recurrenceDate.getDay() !== today.getDay()){
                return 1;
            }

			return result;
		},
	}
}
</script>
