var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-8")]),
      _c("path", {
        attrs: {
          d:
            "M324.39,76.93H239.58V45.7a6,6,0,0,0-6-6h-75a6,6,0,0,0-6,6V76.93h-87a6,6,0,0,0-6,6v56.81a6,6,0,0,0,6,6H87.38V355a6,6,0,0,0,6,6H296.46a6,6,0,0,0,6-6V145.74h21.93a6,6,0,0,0,6-6V82.93A6,6,0,0,0,324.39,76.93ZM164.59,51.7h63V76.93h-63ZM290.46,349H99.38V145.74H290.46Zm27.93-215.23H71.56V88.93H318.39Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M144.61,315.79a6,6,0,0,0,6-6V184.5a6,6,0,0,0-12,0V309.79A6,6,0,0,0,144.61,315.79Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M194.92,315.79a6,6,0,0,0,6-6V184.5a6,6,0,0,0-12,0V309.79A6,6,0,0,0,194.92,315.79Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M245.22,315.79a6,6,0,0,0,6-6V184.5a6,6,0,0,0-12,0V309.79A6,6,0,0,0,245.22,315.79Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }