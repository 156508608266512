var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 400 400" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 400 400",
        "xml:space": "preserve"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M350.8,134.8c-0.8-0.5-1.3-1.3-1.4-2.2c-2.9-22.5-15.8-42.6-35-54.6c-16.5-10-35.1-12.1-52.6-5.9c-0.2,0.1-0.4,0-0.5-0.2c-11.8-12.4-44.5-40.9-94.2-36.4C108.5,41,67,92.7,66.1,126.5c-19.7,4.3-36.7,16.8-46.7,34.3C7,182.2,5.4,209.2,15,236.8c8.4,24.6,27.5,40.3,55.1,45.5c10,1.8,20.3,2.3,30.4,1.4c10.9,54.6,64,90.1,118.6,79.2c38-7.6,68.2-36.2,77.9-73.7c4,0.7,8.1,1,12.1,1c34.6,0,63.1-24.9,75.2-53.7C396.9,206.7,395.7,162.1,350.8,134.8z M199.4,349.4c-47.2,0-85.4-38.2-85.4-85.4c0-47.2,38.2-85.4,85.4-85.4c47.2,0,85.4,38.2,85.4,85.4v0C284.7,311.2,246.5,349.4,199.4,349.4z M370.1,230.6c-11,26.2-38.3,48.6-70.4,43.4c0.3-3.3,0.5-6.7,0.5-10c0-55.7-45.2-100.8-100.8-100.8c-55.7,0-100.8,45.1-100.8,100.8c0,1.5,0,2.9,0.1,4.4c-16.2,1.4-56.2,0.7-69.2-36.7c-8-23.3-6.9-45.7,3.2-63.3c8.2-14.4,22.3-24.4,38.6-27.4c6.1-1.2,10.5-6.7,10.3-13c-0.4-13.3,9-31.5,23.7-46.4c17.6-17.8,40-28.7,63.2-30.9c42.9-3.9,71.3,20.8,81.5,31.6c4.3,4.6,11,6.2,16.9,4.1c13-4.7,27-3,39.4,4.5c15.3,9.6,25.5,25.5,27.8,43.5c0.7,5.5,3.9,10.5,8.7,13.4C379.2,170.2,380.8,205.1,370.1,230.6L370.1,230.6z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M187.8,64.4c-24.8-3.9-53.7,9.3-68.4,23.6c-3.1,3-3.1,7.9-0.1,10.9c3,3.1,7.9,3.1,10.9,0.1c10.3-10,34.2-22.7,55.3-19.4c4.2,0.6,8.1-2.4,8.7-6.6C194.7,68.9,191.9,65.1,187.8,64.4L187.8,64.4z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M174.9,215.1c-4.3,0-7.7,3.5-7.7,7.7V304c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7v-81.2C182.6,218.5,179.2,215.1,174.9,215.1z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M223.7,215.1c-4.3,0-7.7,3.5-7.7,7.7V304c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7v-81.2C231.4,218.5,227.9,215.1,223.7,215.1z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }