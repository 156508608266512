<template>
	<div>
		<button class="btn btn-primary" @click="connectBank" >Connect Bank</button>
        <connect-bank-account-modal v-if="showConnectBankAccountModal" :banks="banks"
	></connect-bank-account-modal>
	</div>
</template>
<script>

    export default {
		props: ['banks'],
		methods: {
			connectBank(){
				this.$store.commit('setToggleConnectBankAccountModal', true);
			}
		},
		computed: {

			showConnectBankAccountModal() {
				return this.$store.state.showConnectBankAccountModal;
			},
		}
	}
</script>
