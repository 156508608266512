<template>
	<div class="shadow-b secondary-menu-bar">
		<div class="container">
			<div class="row">
				<div class="col-xl-4 col-lg-5 order-lg-last text-center text-lg-right month-switch">
					<a href="#" class="btn btn-link btn-link-light" @click="loadCurrentMonth">Today</a>
					<a href="#" class="btn btn-link btn-link-light" @click="loadPreviousMonth"><i class="fa fa-angle-left"></i></a>
					<a href="#" class="btn btn-link btn-link-light" @click="loadNextMonth" :disabled='next_is_future'><i class="fa fa-angle-right"></i></a>
					<span class="btn btn-link btn-link-light month">{{ month }} {{ year }}</span>
				</div>
				<div class="col-xl-8 col-lg-7 order-lg-first mt-4 mt-lg-0">
                    <accounts-selector></accounts-selector>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            category_filter: 'all',
            search: '',
        }
    },
    mounted: function() {

        if(this.$store.state.entities.length==0) {
            this.$store.dispatch('get_entities');
        }

        if(this.$store.state.income_categories.length==0) {
            this.$store.dispatch('get_income_categories');
        }

        if(this.$store.state.expense_categories.length==0) {
            this.$store.dispatch('get_expense_categories');
        }

    },
    methods: {
        loadCurrentMonth() {
            var current = new Date();
            var current_year = this.$store.state.current_date.setYear(current.getFullYear());
            var current_month = this.$store.state.current_date.setMonth(current.getMonth());
            this.$store.commit('setCurrentDate', new Date(current_month));
            this.$store.dispatch('get_budgets').then(() => {
                this.$store.dispatch('get_month_transactions', {account: this.account})
            });
        },
        loadPreviousMonth() {
            var previous_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()-1);
            this.$store.commit('setCurrentDate', new Date(previous_month));
            this.$store.dispatch('get_budgets');
            this.$store.dispatch('get_month_transactions', {account: this.account});
        },
        loadNextMonth() {
            if(!this.next_is_future) {
                var next_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()+1);
                this.$store.commit('setCurrentDate', new Date(next_month));
                this.$store.dispatch('get_budgets');
                this.$store.dispatch('get_month_transactions', {account: this.account});
            }
        },
        monthStringFromDate(date) {
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            if(typeof(date)==='object') {
                return  monthNames[date.getMonth()];
            }
        },
		toggle () {
			this.$store.commit('toggleTransactionMode');
		},
        searchGoals() {
            this.$store.commit('searchTransactions', this.search)
        },
        addMonths(date, months) {
            var d = date.getDate();
            date.setMonth(date.getMonth() + +months);
            if (date.getDate() != d) {
                date.setDate(0);
            }
            return date;
        }
    },
    computed: {
        next_is_future() {


            if(this.$store.state.current_date){

		        var set = new Date(JSON.parse(JSON.stringify(this.$store.state.current_date)));
                var current = new Date();
                var next = this.addMonths(set, 1);

                if( next  >= current) {
                    return true;
                }
            }
            return false;
        },
        account() {
            return this.$store.state.account;
        },
        accounts() {
            return this.$store.state.accounts;
        },
        month() {
            if(this.$store.state.current_date){
                return this.monthStringFromDate(this.$store.state.current_date);
            }
        },
        year() {
            if(this.$store.state.current_date){
                return this.$store.state.current_date.getFullYear();
            }
        },
		new_transaction() {
			return this.$store.state.new_transaction;
		},
    },
    watch: {

        category_filter: function(newValue, oldValue) {
            this.$store.commit('filterTransactions', newValue)
        }

    }
}
</script>
                <!--
                   -<div class="col">
                   -    <div class="input-group">
                   -        <input class="form-control py-2 border-right-0 border" type="search" placeholder="Search Transactions" v-model="search" v-on:keyup="searchGoals" :disabled="accounts.length==0">
                   -        <div class="input-group-append">
                   -            <div class="input-group-text bg-white" id="btnGroupAddon2"><i class="fa fa-search"></i></div>
                   -        </div>
                   -    </div>
                   -</div>
                   -<div class="col">
                   -    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" :disabled="accounts.length==0">Filter</button>
                   -    <button class="btn btn-primary" @click='toggle' :disabled="accounts.length==0">Add Transaction</button>
                   -</div>
                   -<div class="col">
                   -    <a href="#" class="btn btn-light" @click="loadCurrentMonth">Today</a>
                   -    <a href="#" class="btn btn-default" @click="loadPreviousMonth"><i class="fa fa-angle-left"></i></a>
                   -    <a href="#" class="btn btn-default" @click="loadNextMonth"><i class="fa fa-angle-right"></i></a>
                   -    {{ month }} {{ year }}
                   -</div>
                   -->
        <!--
           -<div class="collapse" id="collapseExample">
           -    <div class="bg-light py-2">
           -        <div class="container">
           -            <div class="row">
           -                <div class="col-6">
           -                    <label>Filter transactions by:</label>
           -                    <div class="form-check form-check-inline">
           -                        <input class="form-check-input" type="radio" v-model="category_filter" id="categoryFilter1" value="all">
           -                        <label class="form-check-label" for="categoryFilter1">All</label>
           -                    </div>
           -                    <div class="form-check form-check-inline">
           -                        <input class="form-check-input" type="radio" v-model="category_filter" id="categoryFilter2" value="categorized">
           -                        <label class="form-check-label" for="categoryFilter2">Categorized</label>
           -                    </div>
           -                    <div class="form-check form-check-inline">
           -                        <input class="form-check-input" type="radio" v-model="category_filter" id="categoryFilter3" value="uncategorized">
           -                        <label class="form-check-label" for="categoryFilter3">Uncategorized</label>
           -                    </div>
           -                </div>
           -            </div>
           -        </div>
           -    </div>
           -->
