var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toast",
      attrs: {
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true",
        "data-delay": _vm.toast.delay,
        id: _vm.index
      }
    },
    [
      _c("div", { staticClass: "toast-body" }, [
        _vm._m(0),
        _vm._v("\n        " + _vm._s(_vm.toast.message) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "ml-2 mb-1 close",
        attrs: {
          type: "button",
          "data-dismiss": "toast",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }