var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        staticClass: "form-control no-border",
        attrs: {
          type: "text",
          step: "any",
          placeholder: "0.00",
          "aria-label": "",
          "aria-describedby": "basic-addon1"
        },
        domProps: { value: _vm.amount },
        on: {
          input: function($event) {
            return _vm.updateValue($event.target.value)
          },
          focus: _vm.focusIn,
          blur: _vm.focusOut
        }
      }),
      _vm._v(" "),
      _c(
        "icon-base",
        {
          staticClass: "icon-right",
          attrs: {
            "icon-name": "cash",
            width: "29",
            height: "19",
            "view-box": "0 0 29 19"
          }
        },
        [_c("icon-cash", { attrs: { slot: "icon" }, slot: "icon" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }