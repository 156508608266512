var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "editable single-transation container-fluid" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2" }, [
                _c("span", { staticClass: "dateContain" }, [
                  _c("span", { staticClass: "dateMonth ml-1" }, [
                    _c("div", { staticClass: "text-line" })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "dateDay ml-1" }, [
                    _c("div", { staticClass: "text-line" })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "text-line" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "text-line" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-2 text-right" }, [
                _c("div", { staticClass: "text-line" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1" })
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }