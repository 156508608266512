var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 9")]),
      _c("path", {
        attrs: {
          d:
            "M30,367A11,11,0,0,0,41,377.92H267.66A10.94,10.94,0,0,0,278.59,367V337.25a10.94,10.94,0,0,0-10.93-10.93h-5.48V195.51h29.36a9.57,9.57,0,0,1,9.57,9.56v112.7a34.43,34.43,0,0,0,68.86,0V133.42a26.11,26.11,0,0,0-10.66-21L313,78.57a6,6,0,1,0-7.09,9.69l28.9,21.13A27.32,27.32,0,1,0,358,158.7V317.77a22.43,22.43,0,0,1-44.86,0V205.07a21.59,21.59,0,0,0-21.57-21.56H262.18V45.09a23,23,0,0,0-23-23H72.67a23,23,0,0,0-23,23V326.32H41A11,11,0,0,0,30,337.25ZM344.5,150.26a15.32,15.32,0,0,1,0-30.64,15.1,15.1,0,0,1,5.95,1.2l1.78,1.3a14,14,0,0,1,5.74,11.3v8.83A15.34,15.34,0,0,1,344.5,150.26ZM61.66,45.09a11,11,0,0,1,11-11h166.5a11,11,0,0,1,11,11V326.32H61.66ZM42,338.32H266.59v27.6H42Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M211.84,58.29H98.47A11.26,11.26,0,0,0,87.23,69.54v80.21A11.25,11.25,0,0,0,98.47,161H211.84a11.26,11.26,0,0,0,11.25-11.24V69.54A11.27,11.27,0,0,0,211.84,58.29Zm-.75,90.7H99.23V70.29H211.09Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }