<template>
    <div>
		<input type="text" step="any" class="form-control no-border" v-bind:value="amount" v-on:input="updateValue($event.target.value)" placeholder="0.00" aria-label="" aria-describedby="basic-addon1" v-on:focus="focusIn" v-on:blur="focusOut">
        <icon-base icon-name="cash" width='29' height='19' view-box='0 0 29 19' class="icon-right"><icon-cash slot="icon"></icon-cash></icon-base>
    </div>
</template>

<script>
export default {
    props: ['amount','balance','icon'],
	mounted() {
		this.$emit('passValue', accounting.formatMoney(this.amount));
	},
    methods: {
        updateValue: function (amount) {
            this.$emit('passValue', amount);
        },
		focusIn: function() {
            this.$emit('passValue', accounting.unformat(this.amount));
		},
		focusOut: function() {
            this.$emit('passValue', accounting.formatMoney(this.amount));
		}

    },
    computed: {
        formattedAmount() {
            return accounting.formatMoney(this.amount);
        }
    }
}
</script>
