var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("12")]),
      _c("path", {
        attrs: {
          d:
            "M352.57,264H316.64c0-.26,0-.51,0-.76a42.63,42.63,0,0,0-85.26,0c0,.25,0,.5,0,.76H211.68a6.38,6.38,0,0,1-6.37-6.38v-7.08h16.18a5.17,5.17,0,1,0,0-10.34H205.31V164.53a6.33,6.33,0,0,1,1.41-4c1.08-1.33,2.44-3.08,4-5.11,11.2-14.46,37.38-48.22,60.19-51H351.7a5.17,5.17,0,1,0,0-10.33H270.63a5.12,5.12,0,0,0-.56,0c-27.24,3-54.44,38.07-67.51,54.94-1.53,2-2.86,3.68-3.87,4.92A16.74,16.74,0,0,0,195,164.53V212H186.6c-1.83-11.31-7.34-21.13-16.08-27.6a36.07,36.07,0,0,0-13.29-6.19c-1.06-20.64-10.37-36.6-26.09-44.3-16.62-8.14-37.7-5.5-52.45,6.57C63.38,153,57.67,173,62.81,195.55a26.22,26.22,0,0,0-14.75,11.17c-8,12.08-7.65,28.94.8,39.23,3.73,4.53,11,10.28,24.11,10.28a50.88,50.88,0,0,0,6.82-.49,27,27,0,0,0,1.48,14.75c5.14,13.76,18.66,24.08,32.89,25.09.72.06,1.48.09,2.29.09,7.38,0,18.47-2.51,29.41-14.42a21.5,21.5,0,0,0,9.77,11.92,21.22,21.22,0,0,0,10.3,2.58A25.6,25.6,0,0,0,180.52,291c9.82-6.88,14.36-19.34,12.15-33.33a5.17,5.17,0,1,0-10.21,1.61c2.28,14.41-4.75,21.06-7.87,23.26-4.5,3.14-10,3.76-14,1.56s-6.34-7.37-6.25-14.38c.78-1.28,1.56-2.61,2.33-4a5.17,5.17,0,0,0-9.08-5c-9.21,16.89-20.51,25.35-32.73,24.49-11.52-.82-20.76-9.87-23.94-18.39-1.23-3.3-2.6-9.34,1.31-14.09q3.41-1.09,7.13-2.62a5.17,5.17,0,0,0-3.93-9.56c-18.14,7.45-31.86,7-38.63-1.23-5.59-6.8-5.66-18.64-.16-27,2.49-3.76,8.16-9.63,18.18-6.68a5.17,5.17,0,0,0,2.92-9.92,30.93,30.93,0,0,0-4.5-1c-6.67-26.35,4.6-40.36,12-46.39,11.67-9.56,28.29-11.68,41.36-5.29,12,5.9,19.08,17.9,20.23,34.15a42,42,0,0,0-21,7.41,5.17,5.17,0,0,0,5.8,8.56,31.32,31.32,0,0,1,16.56-5.71A5.15,5.15,0,0,0,152,189.4h.14a5.12,5.12,0,0,0,3.09-1.05,25.91,25.91,0,0,1,9.13,4.35c11.61,8.59,15.61,25.47,10.19,43a5.18,5.18,0,0,0,3.41,6.47,5.41,5.41,0,0,0,1.53.23,5.18,5.18,0,0,0,4.94-3.64,62.69,62.69,0,0,0,2.8-16.42H195v35.32a16.73,16.73,0,0,0,16.71,16.71h21.2a42.62,42.62,0,0,0,82.3,0h37.39a5.17,5.17,0,0,0,0-10.33ZM274,295.55a32.29,32.29,0,1,1,32.29-32.29A32.33,32.33,0,0,1,274,295.55Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274.49,129.1h78.07a5.17,5.17,0,1,0,0-10.34H272.35a5.16,5.16,0,0,0-3.65,1.52L233,156a14.46,14.46,0,0,0,10.22,24.68H350.84a5.17,5.17,0,1,0,0-10.34H243.22a4.12,4.12,0,0,1-2.91-7Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M323.84,240.22a5.17,5.17,0,1,0,0,10.34h21.35a5.17,5.17,0,1,0,0-10.34Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274,242.93a20.33,20.33,0,1,0,20.33,20.33A20.35,20.35,0,0,0,274,242.93Zm0,30.32a10,10,0,1,1,10-10A10,10,0,0,1,274,273.25Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }