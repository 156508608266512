var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 8")]),
      _c("path", {
        attrs: {
          d:
            "M386.78,189.93a6,6,0,0,0-5.07,6.8c0,.24,3.31,24-7.25,36.21a19.8,19.8,0,0,1-11.26,6.48c2.05-9.52,12.14-64.36-16.54-106.48-16.21-23.8-41.7-38.88-75.81-44.89A47.19,47.19,0,1,0,184,87H156.31C130.72,54.77,84.36,37,82.36,36.28a6,6,0,0,0-8.12,5.62v75.42c-5.93,5.47-22.19,22.12-29.9,46.5-15.42.74-38.56,9.29-38.56,53.86v2.66c0,14.78,2.25,26,6.87,34.19,5.65,10.07,14.73,15.69,27,16.71a126.71,126.71,0,0,0,16.4,27.94A130.88,130.88,0,0,0,119.48,344v21.69a12.45,12.45,0,0,0,12.44,12.44h41.21a12.44,12.44,0,0,0,12.43-12.44V350h45.09v15.6a12.45,12.45,0,0,0,12.44,12.44H286.3a12.45,12.45,0,0,0,12.44-12.44V342.92a112,112,0,0,0,28.93-20.71C341.16,309,357.32,286.36,362,251.76c8.9-1,16.15-4.69,21.61-11,14.15-16.38,10.2-44.58,10-45.77A6,6,0,0,0,386.78,189.93Zm-159.14-156a35.19,35.19,0,1,1-35.19,35.19A35.23,35.23,0,0,1,227.64,33.92ZM351.23,238a5.84,5.84,0,0,0-.16,1.1c-1.5,30-12.08,55-31.43,74.21-14.55,14.45-28.9,20.09-29,20.14a6,6,0,0,0-3.87,5.61v26.61a.44.44,0,0,1-.44.44H243.09a.44.44,0,0,1-.44-.44V344a6,6,0,0,0-6-6H179.56a6,6,0,0,0-6,6v21.6a.44.44,0,0,1-.43.44H131.92a.44.44,0,0,1-.44-.44V339.53a6,6,0,0,0-4.32-5.76,117.44,117.44,0,0,1-61.58-41.91,111.16,111.16,0,0,1-15.47-26.8,8.84,8.84,0,0,0-7.87-5.68c-11.39-.46-24.46-5.31-24.46-39v-2.66c0-41.91,21.77-41.91,28.92-41.91a8.9,8.9,0,0,0,8.5-6.42c7.85-27.09,28.65-44.53,28.85-44.7A6,6,0,0,0,86.24,120V51c14.54,6.62,45.11,22.5,62.3,45.58A6,6,0,0,0,153.35,99h37.79a47.13,47.13,0,0,0,72.9.13c33.17,4.86,57.62,18.49,72.66,40.53C364.57,180.48,351.37,237.4,351.23,238Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M93.93,143.73a20,20,0,1,0,20,20A20.05,20.05,0,0,0,93.93,143.73Zm0,28.05a8,8,0,1,1,8-8A8,8,0,0,1,93.93,171.78Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M327.76,220.72a6,6,0,0,0-5.79,6.2c.06,2.08,1.08,51.14-45.78,65a6,6,0,0,0,1.69,11.75,6.23,6.23,0,0,0,1.7-.24c23.16-6.83,39.93-22.34,48.48-44.85a89.93,89.93,0,0,0,5.9-32A6,6,0,0,0,327.76,220.72Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }