var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.connectBank } },
        [_vm._v("Connect Bank")]
      ),
      _vm._v(" "),
      _vm.showConnectBankAccountModal
        ? _c("connect-bank-account-modal", { attrs: { banks: _vm.banks } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }