<template>
	<div>
		<button type="submit" class="btn btn-danger" disabled>
			<i class="fa fa-spinner fa-spin fa-fw"></i> Please wait
			<span class="sr-only">Loading...</span>
		</button>
	</div>
</template>
<script>
    export default {
		methods: {
		}
	}
</script>
