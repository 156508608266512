<template>
	<div class="editing">
		<div class="row">	
			<div class="col-md-4">
				<dropdown @update="update" :expense_categories="expense_categories"></dropdown>
			</div>
			<div class="col-md-4">
				<div class="input-group">
					<span class="input-group-btn">
						<button class="btn btn-default" type="button" @click="subtractFromValue"><i class="fa fa-angle-down"></i></button>
					</span>
					<input type="text" class="form-control" v-model.number="expense.credit_amount" placeholder="0.00">
					<span class="input-group-btn">
						<button class="btn btn-default" type="button" @click="addToValue"><i class="fa fa-angle-up"></i></button>
					</span>
				</div>
			</div>
			<div class="col-md-4">
				<select v-model="expense.repeat_day" class="form-control">
					<option>Select day of month</option>
					<option v-for="n in 31" :value="n">{{n}}</option>
				</select>
			</div>
		</div>

		<div class="row errors" v-if="errors.length > 0">
			<div class="col-md-12">
				<ul class="errors-list">
					<li v-for="error in errors">
					{{ error.message }}
					</li>
				</ul>
			</div>
		</div>

		<div class="row">
			<div class="col-md-offset-3 col-md-6">
				<div class="text-right">
					<button class="btn btn-link mt10" @click="cancel">Cancel</button>
					<button class="btn btn-primary" @click="addNew">Add New</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				expense: {
					credit_amount: 0,
					repeat_day: 1
				}
			}
		},
		computed: {

			expense_categories () {
				return this.$store.state.expense_categories;
			},
			account () {
				return this.$store.state.account;
			},

			expenses (){
				return this.$store.state.expenses;
			},

            errors() {
				return this.$store.state.new_expense_errors;
            },

		},
		methods: {
			update(selected){
				this.expense.transaction_subcategory_id = selected;
			},
			subtractFromValue(index) {
				if (this.expense.credit_amount - 10 > 0) {
					this.expense.credit_amount -= 10;
				}
			},	
			addToValue(index) {
				this.expense.credit_amount += 10;
			},
			cancel() {


this.$emit('toggle');
				//this.$store.commit('toggleExpenseMode');

			},
			addNew() {
				this.$store.dispatch('add_new_expense', {expense: this.expense});
			},
		}
	}
</script>
