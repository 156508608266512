var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal inside-close-btn is-active" }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "div",
            { staticClass: "px-3 py-3" },
            [
              _c("h4", { staticClass: "mb-5 mt-2" }, [
                _vm._v(
                  _vm._s(_vm.budget_transactions_budget.subcategory_name) +
                    " Transactions"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("p", { staticClass: "strong green" }, [_vm._v("Place")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("p", { staticClass: "strong green" }, [_vm._v("Amount")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("p", { staticClass: "strong" }, [
                    _vm._v("Bank / Credit Card")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("p", { staticClass: "strong green" }, [_vm._v("Date")])
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.budget_transactions, function(transaction) {
                return _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-3" }, [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(_vm._s(transaction.entity.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(
                        _vm._s(
                          _vm.sumCategorySpending(transaction.subcategories)
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(_vm._s(_vm.transaction_account_name(transaction)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(_vm._s(transaction.date_short))
                    ])
                  ])
                ])
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "total border-top mt-3 pt-4 pb-4 text-center green"
                },
                [
                  _c("span", [_vm._v("Total - ")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatMoney(_vm.transactions_total)))
                  ])
                ]
              )
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "modal-close", on: { click: _vm.skip } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }