var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container account-info-inner boxshadow-1" },
    [
      _c("div", { staticClass: "row mb-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("h3", [_vm._v("Profile")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "loginEmail" } }, [
              _vm._v("Login email")
            ]),
            _vm._v(
              "\n                " + _vm._s(_vm.user.email) + "\n            "
            )
          ]),
          _vm._v(" "),
          _vm.user.edit == true
            ? _c("div", { staticClass: "form-group" }, [
                _c("p", [_vm._v("Test")]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 form-group" }, [
                    _c("label", [_vm._v("Current Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.old_password,
                          expression: "user.old_password"
                        }
                      ],
                      staticClass: "form-control no-border",
                      attrs: { id: "old_password", type: "password" },
                      domProps: { value: _vm.user.old_password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.user,
                            "old_password",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 form-group" }, [
                    _c("label", [_vm._v("New Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.password,
                          expression: "user.password"
                        }
                      ],
                      staticClass: "form-control no-border",
                      attrs: { id: "password", type: "password" },
                      domProps: { value: _vm.user.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "password", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 form-group" }, [
                    _c("label", [_vm._v("Confirm New Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.password_confirmation,
                          expression: "user.password_confirmation"
                        }
                      ],
                      staticClass: "form-control no-border",
                      attrs: { id: "password_confirmation", type: "password" },
                      domProps: { value: _vm.user.password_confirmation },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.user,
                            "password_confirmation",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mt10",
                    on: { click: _vm.savePassword }
                  },
                  [_vm._v("Save")]
                )
              ])
            : _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v("Password")
                ]),
                _vm._v("\n                ******\n                "),
                _c(
                  "a",
                  {
                    staticClass: "circle-btn",
                    on: { click: _vm.editPassword }
                  },
                  [_c("span", { staticClass: "pencilIcon" })]
                )
              ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "a",
            { attrs: { href: "#" }, on: { click: _vm.deleteUserAccount } },
            [_vm._v("Delete Account")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.user.stripe_id && _vm.subscription
        ? _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("h4", { staticClass: "h-green" }, [
                _c("i", {
                  staticClass: "fa fa-check-circle h-green",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(" " + _vm._s(_vm.subscriptionPlanName))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "card-compact" }, [
                _vm._v("\n            Your plan will automatically renew on "),
                _c("strong", [_vm._v(_vm._s(_vm.subscription.next_bill_date))]),
                _vm._v(" and you'll be charged "),
                _c("strong", [_vm._v("$" + _vm._s(_vm.subscription.amount))]),
                _vm._v(".\n            ")
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: { name: "plan" } }
                },
                [_vm._v("Manage Plan")]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "col-6" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("p", { staticClass: "card-compact" }, [
                _vm._v(
                  "\n            You are on the Basic Budgeter plan. Upgrade to Budget Boss to get automatic transaction imports from banks and credit cards.\n            "
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: { name: "change" } }
                },
                [_vm._v("Manage Plan")]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", [_vm._v("Account Overview")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "h-green" }, [
      _c("i", {
        staticClass: "fa fa-check-circle h-green",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" Basic Budgeter")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }