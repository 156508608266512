<template>	
	<div class="ui transparent-dropdown no-border form-control dropdown button" v-bind:class="{ disabled: disabled }">
		<span class="text">Choose Category</span>
		<i class="dropdown icon"></i>
		<div class="menu">
			<div class="item" v-for="category in expense_categories">
				<span class="text">{{ category.name }}</span>
				<div class="menu">
					<div class="item" v-for="subcategory in category.subcategories" :data-value="subcategory.id">{{ subcategory.name }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
		props: {
			expense_categories: Array,
			disabled: false,
			current: null
		},
		computed: {
			/*
			 *transaction_categories () {
			 *    return this.expense_categories;
			 *    return this.$store.state.transaction_categories;
			 *}
			 */
		},
		created() {
			//console.log(this.subcategory_index);
		},
		mounted: function () {
			var that = this;
			$(this.$el).dropdown({
			     onChange: function(selected) {
			         that.onSelect(selected);
			     }
			});
			if (this.current) {
				$(this.$el).dropdown('set selected', this.current);
			}

		},
		methods: {
			onSelect(selected) {
				if($(this.$el).dropdown('get value')=='new') {
					// show create new category modal
				}
				this.$emit('update', selected);
			}
		},
	}
// <div class="ui floating labeled icon dropdown button">
// <button v-on:click="counter -= 1">-</button><slot></slot><input type="text" v-model="counter"><button v-on:click="counter += 1">+</button>
</script>
