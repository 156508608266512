var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 10")]),
      _c("path", {
        attrs: {
          d:
            "M203.57,28.11a6,6,0,0,0-7.45,0c-9,7.09-88.24,71.63-115,154.73-15.67,48.6-8.15,102.48,19.63,140.63,23.35,32.06,58.37,49.72,98.62,49.72h1.35c40.24,0,75.26-17.66,98.61-49.72,27.78-38.14,35.3-92,19.63-140.63h0C292.21,100,212.56,35.22,203.57,28.11Zm86,288.3c-21,28.87-52.6,44.78-88.91,44.78h-1.35c-36.31,0-67.89-15.91-88.92-44.79C84.85,281.3,78,231.54,92.5,186.53c23.32-72.31,90.6-131.95,107.36-146,18,14.87,84.24,73.41,107.64,146C322,231.54,315.15,281.31,289.59,316.41Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274.41,225.65a6,6,0,0,0-5.29,6.62c.2,1.84,4.53,45.31-31.82,67.46A6,6,0,1,0,243.54,310c43-26.19,37.75-76.91,37.5-79.05A6,6,0,0,0,274.41,225.65Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }