var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-progress" }, [
    _c("h5", { staticClass: "title1" }, [
      _vm._v(
        _vm._s(
          _vm.account && _vm.account.type ? _vm.account.type.name + "" : null
        )
      )
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "title2" }, [_vm._v("Monthly overview")]),
    _vm._v(" "),
    _vm.account &&
    typeof _vm.account.type !== "undefined" &&
    _vm.account.type &&
    _vm.account.type.name == "Checking"
      ? _c("ul", { staticClass: "stats-group" }, [
          this.budgets && this.budgets.length !== 0
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tAvailable to Spend\n                "),
                _c("span", { staticClass: "white" }, [
                  _vm._v(_vm._s(_vm.ok_to_spend))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.account &&
          typeof _vm.account.type !== "undefined" &&
          _vm.account.type
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tCurrent Balance\n\t\t\t\t"),
                this.accounts.length !== 0
                  ? _c("span", { staticClass: "white" }, [
                      _vm._v(_vm._s(_vm.current_balance))
                    ])
                  : _c("div", { staticClass: "text-line-sm" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.account &&
          typeof _vm.account.type !== "undefined" &&
          _vm.account.type
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tAvailable Balance\n\t\t\t\t"),
                this.accounts.length !== 0
                  ? _c("span", { staticClass: "white" }, [
                      _vm._v(_vm._s(_vm.available_balance))
                    ])
                  : _c("div", { staticClass: "text-line-sm" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.account.subtype == "credit card"
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tLimit\n\t\t\t\t"),
                this.accounts.length !== 0
                  ? _c("span", { staticClass: "white" }, [
                      _vm._v(_vm._s(_vm.limit))
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.account &&
    typeof _vm.account.type !== "undefined" &&
    _vm.account.type &&
    _vm.account.type.name == "Credit card"
      ? _c("ul", { staticClass: "stats-group" }, [
          this.budgets && this.budgets.length !== 0
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tBalance\n                "),
                _c("span", { staticClass: "white" }, [
                  _vm._v(_vm._s(_vm.current_balance))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.account &&
          typeof _vm.account.type !== "undefined" &&
          _vm.account.type
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tAvailable Balance\n\t\t\t\t"),
                this.accounts.length !== 0
                  ? _c("span", { staticClass: "white" }, [
                      _vm._v(_vm._s(_vm.available_balance))
                    ])
                  : _c("div", { staticClass: "text-line-sm" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.account.subtype == "credit card"
            ? _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
                _vm._v("\n\t\t\t\tLimit\n\t\t\t\t"),
                this.accounts.length !== 0
                  ? _c("span", { staticClass: "white" }, [
                      _vm._v(_vm._s(_vm.limit))
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }