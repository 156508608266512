<template>
    <div class="modal is-active new-transation-modal">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <div class="px-3 py-3">
                    <h4 class="mb-4 mt-2 text-center">*New Budget</h4>
                    <div class="form-group top-radio-btns">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio"  value="0" v-model="budget.due_in_full" name="options" id="option2" autocomplete="off">
                            <label class="custom-control-label price-value two" for="option2" v-on:click="setType(0)">Standard (Spread out over month)</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" value="1" v-model="budget.due_in_full" name="options" id="option3" autocomplete="off">
                            <label class="custom-control-label price-value two" for="option3" v-on:click="setType(1)">Bill (Due in full on due date)</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Amount</label>
                            <currency-input :amount='budget.credit_amount'
                             @subtractFromAmountValue='subtractFromAmountValue'
                             @addToAmountValue='addToAmountValue'
                             @passValue='passValue'
                             ></currency-input>
                        </div>
                        <div class="col-sm-6">
                            <label>Starting</label>
                            <date @setDate="setStartDate" :date='first'></date>
                            <!--
                                -<div class="form-group">
                                -    <label>Budget Type</label>
                                -    <select class="form-control ui dropdown transparent-dropdown" v-model='budget.is_due_on_recurring_date'>
                                -        <option value="0">Standard (Spread out over month)</option>
                                -        <option value="1">Bill (Due in full on due date)</option>
                                -    </select>
                                -</div>
                            -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 form-group" @click='handleNoType'>
                            <label>Category</label>
                            <div class="clearfix"></div>
                            <div class="clearfix"></div>
                            <semantic-select :items="expense_categories" @update='setCategory'></semantic-select>
                        </div>
                        <div class="col-sm-6 form-group" @click='handleNoType'>
                            <label>Sub-category</label>
                            <div class="clearfix"></div>
                            <semantic-select :items="expense_subcategories" @update='setSubcategory'></semantic-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Does this budget repeat?</label>
                                <div class="custom-control custom-toggle-checkbox">
                                    <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
                                                                                        v-bind:true-value="1"
                                                                                        v-bind:false-value="0"
                                                                                        id="defaultCheck">
                                    <div class="toggle-labels">
                                        <label class="unchecked-label" for="defaultCheck">No</label>
                                        <label class="checked-label" for="defaultCheck">Yes</label>
                                    </div>
                                </div>

                                <!--
                                   -<div class="custom-control custom-checkbox">
                                   -    <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
                                   -                                                        v-bind:true-value="1"
                                   -                                                        v-bind:false-value="0"
                                   -                                                        id="defaultCheck"
                                   -                                                        >
                                   -                                                        <label class="custom-control-label" for="defaultCheck">
                                   -                                                            Yes
                                   -                                                        </label>
                                   -</div>
                                   -->
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" v-if='budget.is_recurring==1'>
                        <!--
                           -<div class="col-sm-4">
                           -    <div class="form-group">
                           -        <label>Starting</label>
                           -        <date @setDate="setStartDate" :date='first'></date>
                           -    </div>
                           -</div>
                           -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Every</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_interval" value="1">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-model="budget.repeat_frequency">
                                    <option value="month" selected="selected">{{ this.formatPlural(budget.repeat_interval, 'Month') }}</option>
                                    <option value="year">{{ this.formatPlural(budget.repeat_interval, 'Year') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-if="budget.repeat_frequency=='month'">
                                    <option value="month">On day {{ start_day }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Ending</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-model='budget.ending'>
                                    <option value="never">No end date</option>
                                    <option value="end_after">End after</option>
                                    <option value="end_by">End by</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_after'">
                                <label>Number of occurences</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_num_occurrences" @change="setEndDateToNull">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_by'">
                                <label>End Date</label>
                                <date @setDate="setEndDate" :date='today'></date>
                            </div>
                        </div>
                    </div>
                </div>
                <errors :errors='errors'></errors>
                <div class="form-row mt-4 justify-content-center">
                    <div class="form-group">
                        <button class="btn btn-outline-primary btn-wide" @click="cancel">Cancel</button>
                        <button class="btn btn-primary btn-wide ml-4" @click="addNew">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
<!--
   -    <div class="modal is-active">
   -        <div class="modal-background"></div>
   -        <div class="modal-content">
   -            <div class="box">
   -                <div class="px-3 py-3">
   -                    <h4 class="mb-5 mt-3">New Budget</h4>
   -                    <div class="contaienr-fluid">
   -                        <div class="row">
   -                            <div class="col-sm-6">
   -                                <div class="form-group">
   -                                    <label>Budget Method</label>
   -                                    <select class="form-control ui dropdown transparent-dropdown" v-model='budget.is_due_on_recurring_date'>
   -                                        <option value="0">Spread out over month</option>
   -                                        <option value="1">Due in full on due date</option>
   -                                    </select>
   -                                </div>
   -                            </div>
   -                            <div class="col-sm-6 mt-4 mt-md-0">
   -                                <div class="form-group">
   -                                    <label>Budget Category</label>
   -                                    <dropdown @update="update" :expense_categories="unbudgeted_expense_categories"></dropdown>
   -                                </div>
   -                            </div>
   -                            <div class="col-sm-6 mt-4 mt-md-0">
   -                                <div class="form-group">
   -                                    <label>Amount</label>
   -                                    <currency-input :amount='budget.credit_amount'
   -                                     @subtractFromAmountValue='subtractFromAmountValue'
   -                                     @addToAmountValue='addToAmountValue'
   -                                     @passValue='passValue'
   -                                     ></currency-input>
   -                                </div>
   -                            </div>
   -                            <div class="col-sm-6 pt-4">
   -                                <div class="form-group">
   -                                    <label>Does this budget repeat?</label>
   -                                    <div class="custom-control custom-checkbox">
   -                                        <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
   -                                                                                            v-bind:true-value="1"
   -                                                                                            v-bind:false-value="0"
   -                                                                                            id="defaultCheck"
   -                                                                                            >
   -                                                                                            <label class="custom-control-label" for="defaultCheck">
   -                                                                                                Yes
   -                                                                                            </label>
   -                                    </div>
   -                                </div>
   -                            </div>
   -                        </div>
   -
   -                    <div class="clearfix"></div>
   -                    <errors :errors="errors"></errors>
   -                    <div class="form-row">
   -                        <div class="form-group col">
   -                            <div class="text-right">
   -                                <button class="btn btn-link mt10" @click="cancel">Cancel</button>
   -                                <button class="btn btn-primary" @click="addNew">Save</button>
   -                            </div>
   -                        </div>
   -                    </div>
   -                </div>
   -            </div>
   -        </div>
   -    </div>
   -->
	<!--old-->
										<!--<option value="day">{{ this.formatPlural(budget.repeat_interval, 'Day') }}</option>
			  <option value="week">{{ this.formatPlural(budget.repeat_interval, 'Week') }}</option>-->
										<!--<option>Daily</option>
			  <option>Weekly on (day of week)</option>-->
</template>
<script>

export default {
	mounted() {
		this.$store.commit('clearNewBudgetErrors');
        if(this.$store.state.transaction_categories.length==0) {
            this.$store.dispatch('get_transactions_categories');
        } else {
            //console.log('==> this.$store.state.transaction_categories', JSON.stringify(this.$store.state.transaction_categories));
        }
	},
	props: {
		expense: Array,
	},
	data() {
		return {
			start_day: new Date().getDate(),
			budget: {
				is_recurring: false,
				repeat_frequency: 'month',
				repeat_interval: 1,
				day_of_month: "",
				//repeat_weekdays: [],
				credit_amount: 0,
//				account_id: this.$store.state.account.id,
				start_date: null,
				end_date: null,
				repeat_num_occurrences: null,
				ending: 'never',
				due_in_full: 0
			},
            selected: [],
            options: [
                {text: 'S', value: 'sunday'},
                {text: 'M', value: 'monday'},
                {text: 'T', value: 'tuesday'},
                {text: 'W', value: 'wednesday'},
                {text: 'T', value: 'thursday'},
                {text: 'F', value: 'friday'},
                {text: 'S', value: 'saturday'}
            ],
                expense_subcategories: [],
				showChooseType: false
        }
    },
	computed: {
        expense_categories () {
            return this.$store.state.expense_categories;
        },
		today() {
			return new Date();
			//var lastDay = new Date(y, m + 1, 0);
		},
		first() {
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			return new Date(y, m, 1);
			//var lastDay = new Date(y, m + 1, 0);
		},
		dayOfWeek() {
			return this.first.getDay();
		},
		count() {
			if(this.budget.frequency=='year') {
				return 50;
			} else if(this.budget.frequency=='month') {
				return 12;
			} else if(this.budget.frequency=='week') {
				return 52;
			} else if(this.budget.frequency=='weekday') {
				return 7;
			}
		},
		errors() {
			return this.$store.state.new_budget_errors;
		},
		account () {
			return this.$store.state.account;
		},

		budgets (){
			return this.$store.state.budgets;
		},
		unbudgeted_expense_categories (){
			return this.$store.getters.unbudgeted_expense_categories;
		},

	},
	methods: {
        setType(type) {
            this.budget.due_in_full = type;
        },
        handleNoType() {
            if(!this.type) {
                this.showChooseType=true;
            }
        },
		setDateToDay(val) {
			$('.datepicker').datepicker();
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var day = new Date(y, m, val);
			$('.datepicker').datepicker('setDate',day);

		},
		formatPlural(count, noun, suffix = 's') {
			return noun += (Number(count) !== 1) ? suffix : '';
		},
		setStartDate(date) {
            //**Monthly recurring rules ** By default, if your start date is on the 29th, 30th, or 31st, Recurr will skip following months that don't have at least that many days. (e.g. Jan 31 + 1 month = March)
			this.budget.start_date = date;
			var date = new Date(date);
			this.start_day = date.getDate();
		},
		setEndDate(date) {
			this.budget.end_date = date;
            this.budget.repeat_num_occurrences = null;
		},

        setEndDateToNull() {
			this.budget.end_date = null;
        },

		setYearlyStartDate(date) {
			this.budget.yearly_start_date = date;
		},
        subtractFromAmountValue(index) {
            if(accounting.unformat(this.budget.credit_amount)-10>0) {
                return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) - 10;
            }
        },
        addToAmountValue(index) {
            return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) + 10;
        },
        passValue(value) {
            return this.budget.credit_amount = (value);
        },
        cancel() {
			this.$store.commit('setCurrentInput', {input: null});
        },
		addNew() {
			this.$store.commit('clearNewBudgetErrors');
            this.budget.credit_amount = accounting.unformat(this.budget.credit_amount);
			this.$store.dispatch('add_new_budget', {budget: this.budget});
		},
        setCategory(value) {

            // var m = this.$store.state.unbudgeted_expense_categories.find(category => Number(category.id) === Number(value));
		    var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(value));
            if(m) {
                this.expense_subcategories = m.subcategories;
            }

        },
        setSubcategory(selected) {

			//console.log(JSON.stringify(selected));
			var that = this;
			var i;

			var once_monthly;
			var numberCategories = this.$store.state.unbudgeted_expense_categories.length;
			for (i = 0; i < numberCategories; i++) {

				selected = Number(selected);
				const test = that.$store.state.unbudgeted_expense_categories[i].subcategories.find(subcategory => subcategory.id === selected);

				if(test) {
					//console.log(JSON.stringify(test));
				}

				if(test && test.once_monthly == 1) {
					once_monthly = 1;
					break;
				} else {
					once_monthly = 0;
					break;
				}
			}

			that.budget.once_monthly = once_monthly;
			this.budget.transaction_subcategory_id = selected;
        },
	}
}
</script>
<!--	<div class="row">
	<div class="col-md-4">
	<h4>Pattern</h4>
	<label>Repeats</label>
	<div class="radio">
	<label><input type="radio" v-model="budget.frequency" value="month">Monthly</label>
	</div>
	<div class="radio">
	<label><input type="radio" v-model="budget.frequency" value="year">Yearly</label>
	</div>
	</div>
	<div class="col-md-8">
	<h4>Range</h4>
	<div class="row">
	<div class="col-md-8">-->
		<!--<div v-if='budget.frequency=="week"'>
	  <label>Repeat every: </label>
	  <select v-model="budget.frequency_interval_week" class="form-control">
	  <option value="">Select option</option>
	  <option v-for="n in count" :value="n">{{ n }} </option>
	  </select>
	  </div>
	  <select v-model="budget.frequency_interval_month" class="form-control" v-if='budget.frequency=="month"'>
	  <option value="">Select option</option>
	  <option v-for="n in count" :value="n">{{ n }} </option>
	  </select>
	  <div v-if='budget.frequency=="year"'>
	  <select v-model="budget.frequency_interval_year" class="form-control">
	  <option value="">Select option</option>
	  <option v-for="n in count" :value="n">{{ n }} </option>
	  </select>
	  </div>-->
	  <!--
	   <div v-if='budget.frequency=="day"'>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="sunday"
	   > Sunday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="monday"
	   > Monday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="tuesday"
	   > Tuesday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="wednesday"
	   > Wednesday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="thursday"
	   > Thursday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
	   v-model='budget.repeat_weekdays'
	   value="friday"
	   > Friday
	   </label>
	   </div>
	   <div class="checkbox">
	   <label>
	   <input type="checkbox"
		   v-model='budget.repeat_weekdays'
		   value="saturday"
	 > Saturday
	 </label>
	 </div>
	 </div>
	 </div>
	  -->
	  <!--<div class="col-md-4" v-if='budget.frequency!=="day"'>
	   <label>&nbsp;</label>
	   <p>{{ budget.frequency }}(s)</p>
	   </div>-->
	   <!--	</div>
		<div class="row" v-if='budget.frequency=="year"'>
		<div class="col-md-6">
		<label>On the date of:</label>
		<date @setDate="setYearlyStartDate"></date>
		</div>
		</div>
		<div class="row" v-if='budget.frequency=="month"'>
		<div class="col-md-6">
		<label>On the day of:</label>
		<select class="form-control" v-model="budget.day_of_month" @change="setDateToDay(budget.day_of_month)">
		<option value="">Select option</option>
		<option v-for="n in 31" :value="n">{{ n }} </option>
		</select>
		</div>
		</div>
		<div  v-if='budget.frequency=="month" || budget.frequency=="year"'>
		<div class="row">
		<div class="col-md-6">
		<label>Starting</label>
		<div>
		<date @setDate="setDate"></date>
		</div>
		</div>
		</div>-->

		<!--

			<div class="form-row" v-if="budget.repeat_frequency=='week'">
				<div class="col">
					<label>On the following day(s):</label>
					<div class="clearfix"></div>
					<checkbox v-model="budget.repeat_weekdays" :options="options"></checkbox>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="sunday"
	 > Sunday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="monday"
	 > Monday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="tuesday"
	 > Tuesday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="wednesday"
	 > Wednesday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="thursday"
	 > Thursday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="friday"
	 > Friday
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input type="checkbox"
			  v-model='budget.repeat_weekdays'
			  value="saturday"
	 > Saturday
						</label>
					</div>
				</div>
			</div>
		-->
            <!--
			   -<div class="form-row">
			   -    <div class="form-group col">
			   -        <label>Budgeting Method</label>
			   -        <div class="form-check">
			   -            <input class="form-check-input" type="checkbox" v-model="budget.is_due_on_recurring_date"
			   -                                          v-bind:true-value="1"
			   -                                          v-bind:false-value="0"
			   -                                          id="method"
			   -   >
			   -           <label class="form-check-label" for="method">
			   -               Is due in full on due date
			   -           </label>
			   -        </div>
			   -    </div>
			   -</div>
               -->
