<template>
	<div>
		<transaction v-for="(transaction, index) in this_month_transactions"
						   :is="transaction.mode"
						   :transaction="transaction"
						   :categories="transaction_categories"
						   :index="index"
                           :key="transaction.id"
						   @edit="edit"
						   @save="save(transaction, index)"
						   @remove="remove(transaction, index)"
						   @update="update"
		 >
		</transaction>
	</div>
</template>
<script>

    export default {
        mounted: function() {
		//	this.$store.commit('setSortMonthTransactions', {order: 'desc', column: 'date_short'});
        },
		computed: {
			account () {
				return this.$store.state.account;
			},
			transaction_categories () {
				return this.$store.state.transaction_categories;
			},
            showAddNewEntityModal() {
				return this.$store.state.showAddNewEntityModal;
            },
			this_month_transactions () {


				this.$store.getters.this_month_transactions.sort(function (a, b) {
					if(a.date_short < b.date_short) return 1;
					if(a.date_short > b.date_short) return -1;
					return 0;
				});

				return this.$store.getters.this_month_transactions;

				//	return this.$store.state.this_month_transactions;

				//Last used
				// return _.orderBy(this.$store.getters.this_month_transactions, ['date_short'], ['asc']);

			},
		},
		methods:{
			update(selected, transaction) {


               // this.transaction.subcategories.push({id:null,amount: null, category_id: null, transaction_id: this.transaction.id});

				this.$store.state.transaction_categories.forEach(function(category) {
					var m = category.subcategories.find(subcategory => subcategory.id === Number(selected));
					if(m) {
						transaction.subcategory = m;
					}
				});
				transaction.transaction_subcategory_id = selected;

			},
			edit(transaction) {
                // console.log(JSON.stringify(transaction));
				transaction.mode = "transaction-edit"
			},
			remove(transaction, index) {

				var that = this;
				this.$store.dispatch('delete_transaction', {
					transaction: transaction,
					index: index
				});

			}
		}
	}
</script>
