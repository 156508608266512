var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "29",
        height: "19",
        viewBox: "0 0 29 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M25.0455 0H3.95455C1.77051 0 0 1.77051 0 3.95455V14.5C0 16.684 1.77051 18.4545 3.95455 18.4545H25.0455C27.2295 18.4545 29 16.684 29 14.5V3.95455C29 1.77051 27.2295 0 25.0455 0ZM5.27273 9.22727C5.27273 8.49926 5.8629 7.90909 6.59091 7.90909C7.31892 7.90909 7.90909 8.49926 7.90909 9.22727C7.90909 9.95528 7.31892 10.5455 6.59091 10.5455C5.8629 10.5455 5.27273 9.95528 5.27273 9.22727ZM21.0909 9.22727C21.0909 8.49926 21.6811 7.90909 22.4091 7.90909C23.1371 7.90909 23.7273 8.49926 23.7273 9.22727C23.7273 9.95528 23.1371 10.5455 22.4091 10.5455C21.6811 10.5455 21.0909 9.95528 21.0909 9.22727ZM25.0455 15.8182C25.7735 15.8182 26.3636 15.228 26.3636 14.5V3.95455C26.3636 3.22653 25.7735 2.63636 25.0455 2.63636H3.95455C3.22653 2.63636 2.63636 3.22653 2.63636 3.95455V14.5C2.63636 15.228 3.22653 15.8182 3.95455 15.8182H25.0455ZM14.5 5.27273C12.316 5.27273 10.5455 7.04324 10.5455 9.22727C10.5455 11.4113 12.316 13.1818 14.5 13.1818C16.684 13.1818 18.4545 11.4113 18.4545 9.22727C18.4545 7.04324 16.684 5.27273 14.5 5.27273ZM13.1818 9.22727C13.1818 9.95528 13.772 10.5455 14.5 10.5455C15.228 10.5455 15.8182 9.95528 15.8182 9.22727C15.8182 8.49926 15.228 7.90909 14.5 7.90909C13.772 7.90909 13.1818 8.49926 13.1818 9.22727Z",
          fill: "#00B19E"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }