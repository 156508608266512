<template>
<div class="editing">
	<div class="row">
		<div class="col-md-4">
			<dropdown @update="update" :expense_categories="expense_categories"></dropdown>
		</div>
		<div class="col-md-4">
			<div class="input-group">
				<span class="input-group-btn">
					<button class="btn btn-default" type="button" @click="subtractFromValue"><i class="fa fa-angle-down"></i></button>
				</span>
				<input type="text" class="form-control" v-model.number="expense.credit_amount" placeholder="0.00">
				<span class="input-group-btn">
					<button class="btn btn-default" type="button" @click="addToValue"><i class="fa fa-angle-up"></i></button>
				</span>
			</div>
		</div>
		<div class="col-md-4">
			<select v-model="expense.repeat_day" class="form-control">
				<option>Select day of month</option>
				<option v-for="n in 31" :value="n">{{n}}</option>
			</select>
		</div>
	</div>
	<div class="row">
		<div class="col-md-3">
			<button class="btn btn-danger mt10" @click="$emit('remove', expense)">Remove</button>
		</div>
		<div class="col-md-offset-4 col-md-4">
			<div class="text-right">
				<button class="btn btn-link mt10" @click="cancel">Cancel</button>
				<button class="btn btn-primary mt10" @click="updateExpense">Save</button>
			</div>
		</div>
	</div>
</div>
</template>
<script>
	export default {
		props:["expense"],
		computed: {
			expense_categories () {
				return this.$store.state.expense_categories;
			},
			account () {
				return this.$store.state.account;
			},
		},
		mounted: function() {
			this.original = this.expense.credit_amount;
			$('.ui.dropdown').dropdown('set selected', this.expense.transaction_subcategory_id);
		},
		methods: {
			update(selected){
				this.expense.transaction_subcategory_id = selected;
			},
			cancel () {
				this.expense.credit_amount = this.original;
				this.expense.mode = 'expense';
			//	this.$emit('toggle');	
			},
			subtractFromValue(index) {
				if (this.expense.credit_amount - 10 > 0) {
					this.expense.credit_amount -= 10;
				}
			},	
			addToValue(index) {
				this.expense.credit_amount += 10;
			},
			updateExpense() {

				var that = this;	
				axios.patch('/api/accounts/' + that.account.id + '/transactions/recurring/' + this.expense.id, this.expense)
				.then(function (response) {
 					//that.$store.state.expenses.push(response.data);
					that.expense.mode = 'expense';
				})
				.catch(function (error) {
					console.log(error);
				});
				this.$emit('toggle');	
			},
		}
	}
</script>
