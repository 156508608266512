<template>
<div class="editing">
	<div class="row">
		<div class="col-md-4">
			<dropdown @update="update" :expense_categories="income_categories"></dropdown>
		</div>
		<div class="col-md-4">
			<div class="input-group">
				<span class="input-group-btn">
					<button class="btn btn-default" type="button" @click="$emit('subtractFromValue')"><i class="fa fa-angle-down"></i></button>
				</span>
				<input type="text" class="form-control" v-model.number="paycheck.debit_amount" placeholder="0.00">
				<span class="input-group-btn">
					<button class="btn btn-default" type="button" @click="$emit('addToValue')"><i class="fa fa-angle-up"></i></button>
				</span>
			</div>
		</div>
		<div class="col-md-4">
			<select v-model="paycheck.repeat_day" class="form-control">
				<option>Select day of month</option>
				<option v-for="n in 31" :value="n">{{n}}</option>
			</select>
		</div>
		<div class="col-md-4">
		</div>
	</div>
	<div class="row">
		<div class="col-md-3">
			<button class="btn btn-danger mt10" @click="$emit('remove', paycheck)">Remove</button>
		</div>
		<div class="col-md-4">
			<div class="text-right">
				<button class="btn btn-link mt10" @click="cancel">Cancel</button>
				<button class="btn btn-primary mt10" @click="$emit('update', paycheck)">Save</button>
			</div>
		</div>
	</div>
</div>
</template>
<script>
	export default {
		props:["paycheck"],
		mounted: function() {
			this.original = this.paycheck.debit_amount;
			$('.ui.dropdown').dropdown('set selected', this.paycheck.transaction_subcategory_id);
		},
		methods: {
			update(selected){
				this.paycheck.transaction_subcategory_id = selected;
			},
			cancel () {
				this.paycheck.debit_amount = this.original;
				this.paycheck.mode = 'income';
			},
		},
		computed: {
			income_categories () {
				return this.$store.state.income_categories;
			},
		}
	}
</script>
