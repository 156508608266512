var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "20",
        viewBox: "0 0 18 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M7.53258 19.7778C7.3378 19.922 7.10089 20 6.85741 20C6.69737 19.9958 6.54008 19.9579 6.39605 19.8889C6.01401 19.6988 5.77417 19.3113 5.77715 18.8889V11.8889L0.150759 1.65556C-0.0502529 1.31178 -0.0502529 0.888226 0.150759 0.544447C0.35338 0.207003 0.721199 7.0839e-05 1.1185 2.41854e-06H16.8724C17.2657 -0.000806179 17.6308 0.201189 17.8356 0.532774C18.0403 0.864359 18.0548 1.27741 17.8739 1.62222L12.4725 11.8667V15.5C12.4752 15.8478 12.3128 16.1767 12.0337 16.3889L7.53258 19.7778ZM7.9531 16L10.2045 14.351L10.227 11.1178C10.2263 10.9454 10.2688 10.7754 10.3508 10.622L15 2H3L7.84052 10.622C7.91529 10.7698 7.9538 10.932 7.9531 11.0962V16Z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }