var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container account-info-inner boxshadow-1" },
    [
      _vm.subscription
        ? _c("div", { staticClass: "row mb-5" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("h4", { staticClass: "h-green" }, [
                  _c("i", {
                    staticClass: "fa fa-check-circle h-green",
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v(" " + _vm._s(_vm.subscriptionPlanName))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-compact" }, [
                  _vm._v(
                    "\n            Your plan will automatically renew on "
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.subscription.next_bill_date))
                  ]),
                  _vm._v(" and you'll be charged "),
                  _c("strong", [_vm._v("$" + _vm._s(_vm.subscription.amount))]),
                  _vm._v(".\n            ")
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { to: { name: "change" } }
                  },
                  [_vm._v("Change or Cancel")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", [_vm._v("Subscription & payment")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }