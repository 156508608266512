var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-3")]),
      _c("path", {
        attrs: {
          d:
            "M382.89,125.33a15,15,0,0,1-15-15,6,6,0,0,0-6-6H294.29a6,6,0,0,0-6,6c0,8.69-8.3,11-13.2,11s-13.2-2.32-13.2-11a6,6,0,0,0-6-6H38.13a6,6,0,0,0-6,6,15,15,0,0,1-15,15,6,6,0,0,0-6,6V268.67a6,6,0,0,0,6,6,15,15,0,0,1,15,15,6,6,0,0,0,6,6H255.69a6,6,0,0,0,6-6,9.48,9.48,0,0,1,4.86-8.82,14.87,14.87,0,0,1,14,0,9.53,9.53,0,0,1,4.86,8.83,6,6,0,0,0,6,6h70.43a6,6,0,0,0,6-6,15,15,0,0,1,15-15,6,6,0,0,0,6-6V131.33A6,6,0,0,0,382.89,125.33Zm-6,138a27.13,27.13,0,0,0-20.35,20.35H296.69a21.25,21.25,0,0,0-10.11-13.22,25.22,25.22,0,0,0-8.44-3v-5a6,6,0,0,0-12,0v5.62a24.47,24.47,0,0,0-5.59,2.33,21.25,21.25,0,0,0-10.11,13.22h-207a27.13,27.13,0,0,0-20.35-20.35V136.66a27.13,27.13,0,0,0,20.35-20.35H250.62c2.06,8,8.23,13.28,15.52,15.61v1.21a6,6,0,0,0,12,0c9.68-1,18.82-6.71,21.41-16.84h57a27.13,27.13,0,0,0,20.35,20.35Z"
        }
      }),
      _c("path", {
        attrs: { d: "M53.69,228.32h84.09a6,6,0,1,0,0-12H53.69a6,6,0,0,0,0,12Z" }
      }),
      _c("path", {
        attrs: { d: "M211.7,249.77h-158a6,6,0,0,0,0,12h158a6,6,0,0,0,0-12Z" }
      }),
      _c("path", {
        attrs: {
          d:
            "M272.14,200.09a6,6,0,0,0-6,6v11.68a6,6,0,0,0,12,0V206.09A6,6,0,0,0,272.14,200.09Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M272.14,228.31a6,6,0,0,0-6,6V246a6,6,0,1,0,12,0V234.31A6,6,0,0,0,272.14,228.31Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M272.14,143.66a6,6,0,0,0-6,6v11.68a6,6,0,1,0,12,0V149.66A6,6,0,0,0,272.14,143.66Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M272.14,171.88a6,6,0,0,0-6,6v11.67a6,6,0,0,0,12,0V177.88A6,6,0,0,0,272.14,171.88Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M300.87,197.69h38.9a6,6,0,0,0,6-6V152.8a6,6,0,0,0-6-6h-38.9a6,6,0,0,0-6,6v38.89A6,6,0,0,0,300.87,197.69Zm6-38.89h26.9v26.89h-26.9Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M299.88,238.42h22.17a6,6,0,0,0,0-12H299.88a6,6,0,0,0,0,12Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M341.67,249.77H299.88a6,6,0,0,0,0,12h41.79a6,6,0,1,0,0-12Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M69.14,207.45c9.21,0,16.72-6.33,17.12-14.24a4.92,4.92,0,0,0,0-.67V150.48l26-5.63v22.27a19.36,19.36,0,0,0-5.16-.69c-9.46,0-17.16,6.71-17.16,14.95s7.7,14.91,17.16,14.91c9.2,0,16.72-6.34,17.12-14.25a4.87,4.87,0,0,0,0-.66v-44h0c0-.14,0-.27,0-.4a1.7,1.7,0,0,1,0-.22c0-.08,0-.16,0-.23l-.06-.39v0l0-.13c0-.14-.07-.29-.12-.43s0-.13-.07-.19-.08-.24-.13-.36a2,2,0,0,0-.13-.25c0-.09-.08-.18-.13-.27s-.11-.18-.17-.27-.09-.15-.13-.22l-.2-.27c0-.06-.1-.13-.16-.19a2.29,2.29,0,0,0-.21-.23l-.18-.2-.21-.19-.23-.2-.19-.14-.29-.2-.16-.1a3.79,3.79,0,0,0-.34-.2l-.14-.07-.39-.17-.15-.06a3,3,0,0,0-.39-.13l-.2-.06-.36-.08-.29-.05-.29,0-.36,0h-.57l-.28,0-.19,0-.42.07h0L79,139.78a6,6,0,0,0-4.73,5.86v32.64a19.36,19.36,0,0,0-5.16-.69c-9.46,0-17.16,6.71-17.16,14.95S59.68,207.45,69.14,207.45Zm38-23.16c-3.1,0-5.16-1.75-5.16-2.91s2-2.95,5.16-2.95,5.16,1.75,5.16,2.95S110.19,184.29,107.09,184.29Zm-37.95,5.3c3.15,0,5.16,1.75,5.16,2.95s-2.06,2.91-5.16,2.91S64,193.7,64,192.54,66,189.59,69.14,189.59Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }