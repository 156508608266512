<template>
    <div class="h-100">
        <div class="main-wrap login">
            <form class="form-signin" method="POST" action="/forgot-password">
                <div class="text-center mb-4">
                    <router-link :to="{ name: 'home' }" class="nav-link font-weight-bold small">
                        <img src="/images/logo.png" class="mb-4" alt="Budget It Up Logo">
                    </router-link>
                    </a>
                </div>
                <div class="form-label-group">
                    <input type="email" v-model="user.email" value="" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
                    <label for="inputEmail">Email address</label>
                    <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                    </div>
                </div>
                <errors :errors="errors"></errors>
                <success-message :success_messages="success_messages"></success-message>
                <a href="#" class="btn btn-lg btn-outline-primary btn-block mt-5" @click="sendReset($event)">Send Password Reset Link</a>
                <p class="mt-5 mb-3 text-muted text-center">
                <a class="underline-link" href="/signup">Don't have an account?</a>
                </p>
                <p class="mt-5 mb-3 text-muted text-center">
                <a class="underline-link" href="/login">Remember your password?</a>
                </p>
            </form>
        </div>
    </div>
</template>

<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	mounted: function() {
		document.getElementById('app').className='h-100';
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
		document.getElementById('app').className='';
	},
	data() {
		return {
			user: {
				email: null,
			},
		}
	},
	computed: {
		errors() {
			return this.$store.state.reset_password_errors;
		},
		success_messages() {
			return this.$store.state.success_messages;
		},
	},
	methods: {
		sendReset(event) {
			event.preventDefault();
			this.$store.commit('clearSuccessMessages');
			this.$store.commit('clearResetPasswordErrors');
			if(this.validate()) {
				this.$store.dispatch('reset_password_email', {userEmail: this.user})
			}
		},
		validate() {
			if(this.user.email) {
				return true;
			} else if (!this.user.email) {
				this.$store.commit('addResetPasswordErrors', 'Please enter your email.');
			}
		}
	}
}
</script>
