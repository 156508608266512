<template>
    <div>
        <div class="purchase-plan-header">
            <div class="container text-center">
                <h1 class="thick">Get Budget Boss</h1>
                <ul class="list-inline sell-points">
                    <li class="list-inline-item"><i class="fa fa-check text-light" aria-hidden="true"></i>Automatic transaction imports</li>
                    <li class="list-inline-item"><i class="fa fa-check text-light" aria-hidden="true"></i>1 bank or credit card connection</li>
                </ul>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-6">
                    <p class="mt-3">
                    Your plan
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="card bg-light p-4 border rounded">
                        <div class="card-content">
                            <h5 class="strong">Budget Boss</h5>
                            <h6 class="card-subheading">
                                <strong>$5.99</strong> / month
                            </h6>
                            <div class="card-terms">
                                Cancel anytime.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="card mt-4 mb-5 p-4 rounded boxshadow-1">
                        <form class="validate" role="form" method="POST" action="/purchase/plan" id="planSubscriptionPaymentForm" novalidate>

                            <div id="credit_card_billing">

                                <div class="form-group" id="group-name">
                                    <div class="form-row">
                                        <div class="col-12 col-md-6 form-label-group" id="first_name_container">
                                            <input id="first_name" type="text" class="form-control" v-model="subscription.first_name" placeholder="First name" value="">
                                            <label for="first_name">First name *</label>
                                        </div>
                                        <div class="col-12 col-md-6 form-label-group" id="last_name_container">
                                            <input id="last_name" type="text" class="form-control" v-model="subscription.last_name" placeholder="Last name" value="">
                                            <label for="last_name">Last name *</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-label-group" id="card_number_container">
                                    <input type="text" class="form-control" v-model="subscription.card_number" id="card_number" placeholder="Credit card number" />
                                    <label for="card_number">Card Number *</label>
                                </div>
                                <div class="form-label-group">
                                    <div class="form-row">
                                        <div class="col-6 form-label-group" id="card_csc_container">
                                            <input type="text" class="form-control" v-model="subscription.card_csc" id="card_csc" placeholder="CSC" >
                                            <label for="card_csc">CSC *</label>
                                        </div>
                                        <div class="col-6 form-label-group" id="card_expiration_container">
                                            <input type="text" class="form-control" v-model="subscription.card_expiration" id="card_expiration" placeholder="MM / YY">
                                            <label for="card_expiration">Expiration *</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-label-group" id="postal_container">
                                    <input type="text" class="form-control" v-model="subscription.postal" placeholder="Postal code" id="postal" value="">
                                    <label for="postal">Postal Code *</label>
                                </div>
                            </div>
                            <input type="hidden" value="standard" name="encryption" checked>
                            <div class="form-group mt-5 text-center text-md-left">

                                <strong>By purchasing you authorize Budgetitup to automatically charge you $5.99 + any applicable tax each month until you cancel.</strong> If the price changes, we’ll notify you beforehand. You can check your renewal date or cancel anytime via your Account page. No partial refunds.

                                </p>
                            </div>
                            <div class="payment-errors"></div>
                            <input type="hidden" v-model="subscription.timezone" value="" id="timezone">
                            <div class="form-group text-center text-md-left">
                                <errors :errors="errors"></errors>
                                <a href="#" class="btn btn-primary" id="subscribe" @click="payForPlan()">
                                    Start Budget Boss
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    data: function() {
        return {
            subscription: {
                name: 'budget_master'
            },
        }
    },
    methods: {
        payForPlan: function() {
            var reg = new Register();
            if(reg.paymentIsValid()) {

                var $form = $(this);
                $form.find('#subscribe').prop('disabled', true);

                //Stripe.setPublishableKey("pk_test_xomr2vyW3UvXABhMkmu5si1S");
                Stripe.setPublishableKey("pk_live_VZwyQo5D3EXwOWtK6c5xi5VO");
                Stripe.card.createToken({
                    number: $('#card_number').val(),
                    cvc: $('#card_csc').val(),
                    exp_month: $.payment.cardExpiryVal($('#card_expiration').val()).month,
                    exp_year: $.payment.cardExpiryVal($('#card_expiration').val()).year,
                    address_zip: $('#postal').val()
                }, this.stripeResponseHandler);
                event.preventDefault();
            }
        },
        round (value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },
		stripeResponseHandler(status, response) {

			var $form = $('#planSubscriptionPaymentForm');
			if (response.error) {

				event.preventDefault();
				event.stopPropagation();
				$form.find('.payment-errors').text(response.error.message);
				$form.find('.payment-errors').addClass('alert alert-danger');
				$form.find('#subscribe').prop('disabled', false);
				$('#subscribe').button('reset');
			} else {

				event.preventDefault();
				event.stopPropagation();
                this.subscription.stripe_token = response.id;

                if(this.validatePayment()) {
                    this.$store.dispatch('payForPlan', {paymentData: this.subscription})
				}

			}
		},
		validatePayment() {

			var validator = new FormValidator();
			var firstNameInput = document.getElementById('first_name');
			var firstNameContainer = document.getElementById('first_name_container');
			var firstNameValue = firstNameInput.value;

			var validFirstName = validator.validate(firstNameInput, firstNameContainer, validator.validateName, firstNameValue, 'First name invalid.','first name');

			var lastNameInput = document.getElementById('last_name');
			var lastNameContainer = document.getElementById('last_name_container');
			var lastNameValue = lastNameInput.value;

			var validLastName = validator.validate(lastNameInput, lastNameContainer, validator.validateName, lastNameValue, 'Last name invalid.','last name');

			var cardNumberInput = document.getElementById('card_number');
			var cardNumberContainer = document.getElementById('card_number_container');
			var cardNumberValue = cardNumberInput.value;

			var validCardNumber = validator.validate(cardNumberInput, cardNumberContainer, validator.validateCardNumber, cardNumberValue, 'Credit card number invalid.','credit card number');

			var cardSecurityCodeInput = document.getElementById('card_csc');
			var cardSecurityCodeContainer = document.getElementById('card_csc_container');
			var cardSecurityCodeValue = cardSecurityCodeInput.value;

			var validCardSecurityCode = validator.validate(cardSecurityCodeInput, cardSecurityCodeContainer, validator.validateCardSecurityCode, cardSecurityCodeValue, 'Credit card number invalid.','card security code');

			var cardExpirationInput = document.getElementById('card_expiration');
			var cardExpirationContainer = document.getElementById('card_expiration_container');
			var cardExpirationValue = cardExpirationInput.value;

			var validCardExpiry = validator.validate(cardExpirationInput, cardExpirationContainer, validator.validateCardExpiry, cardExpirationValue, 'Credit card expiration invalid.','card expiration');

			var postalInput = document.getElementById('postal');
			var postalContainer = document.getElementById('postal_container');
			var postalValue = postalInput.value;

			var validPostal = validator.validate(postalInput, postalContainer, validator.validatePostal, postalValue, 'Zipcode is invalid.','zipcode');

			if(validFirstName && validLastName && validCardNumber && validCardSecurityCode && validCardExpiry && validPostal) {
				return true;
			} else {
				return false;
			}

		}
    },
    mounted: function() {

        $( document ).ready(function() {
            $('#card_number').payment('formatCardNumber');
            $('#card_expiration').payment('formatCardExpiry');
            $('#card_csc').payment('formatCardCVC');
        });

        var stripe = document.createElement('script')
        stripe.setAttribute('src', 'https://js.stripe.com/v2/')
        document.head.appendChild(stripe)

        document.body.className = 'pt-0';

        // Set the user timezone
        var userTimezone = -(new Date().getTimezoneOffset() / 60);
        this.subscription.timezone = userTimezone;

        var form = document.getElementById('planSubscriptionPaymentForm');

        form.addEventListener('submit', function(event) {

        }, false);
    },
    computed: {
        user: function() {
            return this.$store.state.user;
        },
            errors() {
                return this.$store.state.register_errors;
            },
    },
}
</script>

