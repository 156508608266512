<template>
	<transition
	name="fade"
	>
	<div class="modal is-active">
  		<div class="modal-background"></div>
 		<div class="modal-content">
				<div class="box">
					<h1>Your Monthly Expenses</h1>
					<h5>Enter in your fixed monthly expenses such as utilities and rent/mortgage.</h5>
				<div class="row">
					<div class="col-md-12">
						<div class="text-left">
							<component :is="new_expense.mode" @toggle="toggle"></component>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<p class="strong">Category</p>
					</div>
					<div class="col-md-4">
						<p class="strong">Amount</p>
					</div>
					<div class="col-md-4">
						<p class="strong">Repeat day of month</p>
					</div>
				</div>
				<expense v-for="(expense, index) in expenses"
		   			    v-bind:expense="expense"
						@update="update"
						@edit="edit"
						@remove="remove(expense,index)"
						@subtractFromValue="subtractFromValue(index)"
						@addToValue="addToValue(index)"
						:is="expense.mode"
                        :key="expense.id"
		        ></expense>
				<div class="form-group">
					<div class="text-right">
						<button class="btn btn-default" v-on:click="skip">Close</button>
						<!--<button class="btn btn-primary" v-on:click="save(paychecks)">Save</button>-->
					</div>
				</div>
		</div>
  		<button class="modal-close" @click="skip"></button>
	</div>
	</div>
	</transition>
</template>

<script>
    export default {
		props:["expenses", "expense_categories"],
		computed: {
			account () {
				return this.$store.state.account;
			},
			new_expense() {
				return this.$store.state.new_expense;
			}
		},
		methods: {

			edit(expense) {
				expense.mode = "edit-expense"
			},
			toggle () {
				this.$store.commit('toggleExpenseMode');

			},
			update(selected){
				this.$emit('update', selected, this.transaction);
			},
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			add() {
				this.expenses.push({
						credit_amount : null,
						repeat_day : null
					});
			//	this.counter = this.expenses.length;
			},
			subtract(expense, index) {
				this.expenses.splice(index, 1);
			//	this.counter = this.expenses.length;
			},
			skip() {
				//this.$store.dispatch('hide_modal');
				this.$store.state.showRecurringExpensesModal = false;
			},
			save(expenses) {

				var that = this;
				axios.post('/api/accounts/' + that.$store.state.account.id + '/transactions/recurring?type=expenses', {'expenses':expenses})
				.then(function (response) {
					that.$store.state.showRecurringExpensesModal = false;
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			remove(expense, index) {

				this.$store.dispatch('delete_expense', {
					expense: expense,
					index: index
				})
			},
		}
	}
</script>
