<template>
	<div>
        <div class="budgets-list">
            <div class="container-fluid" v-if='spread_out_budgets && spread_out_budgets.length'>
                <div class="row table-header">
                    <div class="col-4">
                        Category
                        <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                            <icon-sort slot="icon"></icon-sort>
                        </icon-base>
                    </div>
                    <div class="col-2">
                        &nbsp;
                    </div>
                    <div class="col-2">
                        Amount
                        <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                            <icon-sort slot="icon"></icon-sort>
                        </icon-base>
                    </div>
                    <div class="col-2">
                        Spent
                        <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                            <icon-sort slot="icon"></icon-sort>
                        </icon-base>
                        <a href="" class="switch-icon"><i class="fas fa-exchange-alt"></i></a>
                    </div>
                    <div class="col-1"></div>
                </div>
                <span  v-if='budgets && budgets.length>0'>
                <budget v-for="(budget, index) in spread_out_budgets"
                        :is="budget.mode"
                        :budget="budget"
                        :index="index"
                        :percent="percent"
                        :key="budget.id"
                        @edit="edit(budget, index)"
                        @remove="remove(budget, index)"
                        @update="update(budget, index)"
                        @subtractFromValue="subtractFromValue(index)"
                        @addToValue="addToValue(index)"
                        @balanceBudget="balanceBudget(budget.id)"
                        >
                </budget>
                </span>
                <loading-budget v-else></loading-budget>
            </div>
            <div class="container-fluid" v-else>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <button class="btn-svg" @click="set_current_input('add-new-budget')" :disabled="current_input==='add-new-budget' || !current_month_is_shown_month">
                            <icon-base icon-name="graph" height="200" width="200" view-box="0 0 200 200" icon-class="mt-5"><icon-budget slot="icon"></icon-budget></icon-base>
                        </button>
                    </div>
                </div>
                <h3 class="onerem text-center">You have no expenses for {{ month }} {{ year }}.</h3>
                <p class="text-center">Create a new expense using the button above.</p>
            </div>
        </div>
	</div>
</template>
<script>
    export default {
		computed: {
			activeTab() {
				return this.$store.state.active_budgets_tab;
			},
            month() {
                if(this.$store.state.current_date){
                    return this.monthStringFromDate(this.$store.state.current_date);
                }
            },
            year() {
                if(this.$store.state.current_date){
                    return this.$store.state.current_date.getFullYear();
                }
            },
			account () {
				return this.$store.state.account;
			},
            spread_out_budgets () {
                if(this.$store.state.budgets && this.$store.state.budgets.length) {
                    return this.$store.state.budgets.filter(function(budget){return budget.due_in_full==false;});
                }
				//return this.$store.getters.budgets.filter(function(budget){return budget.is_due_on_recurring_date==false;});
            },
			budgets (){
                if(this.$store.state.budgets && this.$store.state.budgets.length) {
                    this.$store.getters.budgets.sort(function (a, b) {
                        if(a.subcategory.name < b.subcategory.name) return -1;
                        if(a.subcategory.name > b.subcategory.name) return 1;
                        return 0;
                    });
                    return this.$store.getters.budgets;
                }
			},
			percent () {
				return 'left: ' + this.round(((this.$store.state.today / this.$store.state.last)*100),0) + '%';
			},
            current_month_is_shown_month() {

                // Current date
                var d = new Date();
                var dateMonth = d.getMonth();
                var dateYear = d.getYear();

                // Shown date
                var ds = new Date(this.$store.state.current_date);
                var monthShown = ds.getMonth();
                var yearShown = ds.getYear();

                if(monthShown === dateMonth && yearShown === dateYear) {
                    return true;
                }

                return false;

            },
            current_input() {
                return this.$store.state.current_input;
            },
		},
		methods: {
            set_current_input(input) {
                this.$store.commit('setCurrentInput', {input: input});
            },
            monthStringFromDate(date) {
                var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                if(typeof(date)==='object') {
                    return  monthNames[date.getMonth()];
                }
            },
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			update(budget, index){
				this.$store.dispatch('update_budget', {
					budget: budget,
                    key: index
				})
			},
			edit(budget, index) {
				this.$store.commit('setCurrentInput', {input: 'budget-edit', data: budget});
				//budget.originalMode = budget.mode;
				//budget.mode = "budget-edit"
			},
			remove(budget, index) {
				var that = this;
				return axios.delete('/budgets/' + budget.id)
					.then(function (response) {
						that.budgets.splice(index, 1);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		}
	}
</script>
