var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "273.77", y1: "199.79", x2: "221.59", y2: "139.62" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "283.15", y1: "86.22", x2: "335.34", y2: "146.39" }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: {
          x: "218.42",
          y: "38.97",
          width: "38.28",
          height: "113.77",
          rx: "12",
          ry: "12",
          transform: "translate(154.31 -146.41) rotate(49.06)"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: {
          x: "300.21",
          y: "133.26",
          width: "38.28",
          height: "113.77",
          rx: "12",
          ry: "12",
          transform: "translate(253.74 -175.69) rotate(49.06)"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M240.19,161.08l-200.1,140a24,24,0,0,0-6,33.35L36,337.11a24,24,0,0,0,35.27,4.69L259.24,183"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "231.89", y: "321.48", width: "138.27", height: "28.47" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M251.19,317v-3.16c0-8.55,6.33-15.48,14.14-15.48h71.74c7.81,0,14.14,6.93,14.14,15.48V317"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }