<template>
<transition
name="fade"
>
<div>
	<h3 class="text-center">{{ message }}</h3>
	<div class="text-center">
		<i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
		<span class="sr-only">Loading...</span>
	</div>
</div>
</transition>

</template>
<script>
export default {
	computed: {
		message() {
			return this.$store.state.bank_connect_message;
		}
	}
}
</script>
