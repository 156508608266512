<template>
    <div class="h-100">
		<add-new-goal-modal  v-if="showAddNewGoalModal"></add-new-goal-modal>
        <goal-search-add></goal-search-add>
		<div class="container h-100">
			<div class="row no-gutters h-100">
				<div class="col-lg-4 order-lg-last col-12">
					<goal-progress></goal-progress>
				</div>
				<div class="col-lg-8 order-lg-first col-12 goal-categories-cont">
                    <component :is='body_view' :page="'goals'"></component>
                    <goal v-for="(goal, index) in goals"
                          :is="goal.mode"
                          :goal="goal"
                          :goals="goals"
                          :index="index"
                          :key="goal.id"
                          @edit="edit"
                          @save="save"
                          @remove="remove(goal, index)"
                          @update="update"

                          >
                    </goal>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    export default {
        mounted: function() {

            this.$store.dispatch('get_current_date').then(() => {
                this.$store.dispatch('get_goals').then(() => {
                    if(this.$store.state.goals.length===0) {
                        this.$store.state.body_view = 'message-to-user';
                    } else {
                        this.$store.state.body_view = null;
                    }
               });
            });
            this.$store.dispatch('get_accounts');
        },
		computed: {
            body_view() {
                if(this.$store.state.goals.length===0) {
                    return 'message-to-user';
                } else {
                    return null;
                }
            },
			showAddNewGoalModal() {
				return this.$store.state.showAddNewGoalModal;
			},
			goals() {
				return this.$store.getters.goals;
			},
			accounts() {
				return this.$store.state.accounts;
			},
		},
		methods: {
			save() {

			},
			edit(goal) {
				goal.mode = "goal-edit"
			},
			update() {

			},
			remove(goal, index) {
                if (!confirm('Are you sure?')) { return false }
				this.$store.dispatch('delete_goal', {
					goal: goal,
					index: index
				});
			},
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			addNew() {
				this.$store.commit('toggleAddNewGoalModal', true)
			},
		}
	}
</script>
