<template>
	<div>
		<button class="btn btn-primary" @click="addNew" >Add New</button>
	</div>
</template>
<script>

    export default {
		methods: {
			addNew(){
				this.$emit('toggle');	
			}
		}
	}
</script>
