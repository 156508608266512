<template>
	<transition
		name="fade"
		>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <div class="px-3 py-3">
                        <h4 class="mb-4 mt-2">Edit Account</h4>
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6">    
                                <label>Account Name</label>
                                <input type="text" class="form-control no-border" id="name" v-model='account.name'>
                            </div>
                            <div class="form-group col-12 col-md-6">    
                                <label>Account Type</label>
								<semantic-select :items="account_types" :defaultItem='defaultItem' :preSelect="account.account_type_id" @update='setAccountType'></semantic-select>
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6">    
                                <label>Account Balance</label>
                                <currency-input :amount='account.most_recent_balance.current_balance'
                                 @subtractFromAmountValue='subtractFromAmountValue'
                                 @addToAmountValue='addToAmountValue'
                                 @passValue='passValue'
                                 ></currency-input>
                            </div>
                            <div class="form-group col-12 col-md-6">    
                                <label>Date</label>
                                <date @setDate="setDate" :date='account.most_recent_balance.date'></date>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <errors :errors='errors'></errors>
                        <div class="form-row mt-4">
                            <div class="form-group order-md-last col-sm-8">
                                <div class="text-right text-sm-right text-center">
                                    <button class="btn btn-outline-primary btn-box-shadow btn-wide" @click="cancel">Cancel</button>
                                    <button type="button" class="btn btn-primary btn-box-shadow btn-wide ml-4" @click='close'>Save</button>
                                </div>
                            </div>
                            <div class="form-group order-md-first col-sm-4 text-sm-left text-center">
                                <button class="btn btn-danger btn-wide btn-box-shadow mt10" @click="remove">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</transition>
</template>
<script>
    export default {
        mounted() {
			this.original_recent_balance = Object.assign({}, this.account.most_recent_balance);
        },
		data: function () {
			return {
				defaultItem: 'account type'
			}
		},
		computed: {
			account_types() {
				return this.$store.state.account_types;
			},
			account () {
				return this.$store.state.account;
			},
			errors() {
				return this.$store.state.update_account_errors;
			},
		},
		methods: {
			setAccountType(id) {
				this.account.account_type_id = id;
			},
			remove() {
				this.$store.dispatch('delete_account', {account_id: this.account.id});
            },
            cancel() {

        		this.account.most_recent_balance = Object.assign(this.account.most_recent_balance, this.original_recent_balance);

				this.$store.commit('setCurrentInput', {input: null}); 
            },
			close() {
				this.account.most_recent_balance.available_balance = Math.abs(this.account.most_recent_balance.current_balance);
				this.$store.dispatch('update_account', {account: this.account});
			},
			setAmount(amount) {
				this.account.balance = {debit_amount: amount};
			},
			setDate(date) {
				this.account.most_recent_balance.date = date;
			},
            subtractFromAmountValue(index) {
                if(accounting.unformat(this.account.most_recent_balance.current_balance)-10>0) {
                    return this.account.most_recent_balance.current_balance = accounting.unformat(this.account.most_recent_balance.current_balance) - 10;
                }
            },	
            addToAmountValue(index) {
                return this.account.most_recent_balance.current_balance = accounting.unformat(this.account.most_recent_balance.current_balance) + 10;
            },
            passValue(value) {
                return this.account.most_recent_balance.current_balance = (value);
            },
		}
	}
</script>
