<template>
	<transition
		name="fade"
		>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<div class="bg-light px-3 py-3">
						<h4>New Payee</h4>
						<div class="form-row">
							<div class="col form-group">
								<label for="exampleInputEntity1">Payee*</label>
								<input type="email" v-model="entity.name" class="form-control" id="exampleInputEntity1" placeholder="Enter entity name">
							</div>
						</div>
						<div class="form-row">
							<div class="col form-group">
								<label for="exampleInputAddress1">Address Line 1</label>
								<input type="text" v-model="entity.address_line1" class="form-control" id="exampleInputAddress1" placeholder="Enter address line 1">
							</div>
						</div>
						<div class="form-row">
							<div class="col form-group">
								<label for="exampleInputCity1">Address Line 2</label>
								<input type="text" v-model="entity.address_line2" class="form-control" id="exampleInputCity1" placeholder="Enter address line 2">
							</div>
						</div>
						<div class="form-row">
                            <div class="col-sm-4 form-group">
								<label for="cvc">City</label>
								<input type="text" v-model="entity.city" class="form-control" id="exampleInputCity1" placeholder="Enter city">
							</div>
                            <div class="col-sm-4 form-group">
								<label for="cvc">State</label>
								<select class="form-control" name="state" v-model="entity.state">
									<option :value="null">Select a state</option>
									<option v-for="state in states" :data-value="state.abbreviation">{{ state.name }}</option>
								</select>
							</div>
                            <div class="col-sm-4 form-group">
								<label for="cvc">Zipcode</label>
								<input type="text" class="form-control" id="exampleInputCity1" placeholder="Enter zipcode">
							</div>
						</div>
						<errors :errors='errors'></errors>
						<div class="form-row">
							<div class="col form-group">
								<div class="text-right">
									<button class="btn btn-link mt10" @click="set_current_input('add-new-transaction')">Cancel</button>
									<button class="btn btn-primary" @click="save">Add New</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    export default {
		data() {
			return {
				entity: {
					name: null,
					address1: null,
					address2: null,
					city: null,
					state: null,
					zipcode: null
				},
                states:
            [
                {
                    name: "Alabama",
                    abbreviation: "AL"
                },
                {
                    name: "Alaska",
                    abbreviation: "AK"
                },
                {
                    name: "American Samoa",
                    abbreviation: "AS"
                },
                {
                    name: "Arizona",
                    abbreviation: "AZ"
                },
                {
                    name: "Arkansas",
                    abbreviation: "AR"
                },
                {
                    name: "California",
                    abbreviation: "CA"
                },
                {
                    name: "Colorado",
                    abbreviation: "CO"
                },
                {
                    name: "Connecticut",
                    abbreviation: "CT"
                },
                {
                    name: "Delaware",
                    abbreviation: "DE"
                },
                {
                    name: "District Of Columbia",
                    abbreviation: "DC"
                },
                {
                    name: "Florida",
                    abbreviation: "FL"
                },
                {
                    name: "Georgia",
                    abbreviation: "GA"
                },
                {
                    name: "Guam",
                    abbreviation: "GU"
                },
                {
                    name: "Hawaii",
                    abbreviation: "HI"
                },
                {
                    name: "Idaho",
                    abbreviation: "ID"
                },
                {
                    name: "Illinois",
                    abbreviation: "IL"
                },
                {
                    name: "Indiana",
                    abbreviation: "IN"
                },
                {
                    name: "Iowa",
                    abbreviation: "IA"
                },
                {
                    name: "Kansas",
                    abbreviation: "KS"
                },
                {
                    name: "Kentucky",
                    abbreviation: "KY"
                },
                {
                    name: "Louisiana",
                    abbreviation: "LA"
                },
                {
                    name: "Maine",
                    abbreviation: "ME"
                },
                {
                    name: "Marshall Islands",
                    abbreviation: "MH"
                },
                {
                    name: "Maryland",
                    abbreviation: "MD"
                },
                {
                    name: "Massachusetts",
                    abbreviation: "MA"
                },
                {
                    name: "Michigan",
                    abbreviation: "MI"
                },
                {
                    name: "Minnesota",
                    abbreviation: "MN"
                },
                {
                    name: "Mississippi",
                    abbreviation: "MS"
                },
                {
                    name: "Missouri",
                    abbreviation: "MO"
                },
                {
                    name: "Montana",
                    abbreviation: "MT"
                },
                {
                    name: "Nebraska",
                    abbreviation: "NE"
                },
                {
                    name: "Nevada",
                    abbreviation: "NV"
                },
                {
                    name: "New Hampshire",
                    abbreviation: "NH"
                },
                {
                    name: "New Jersey",
                    abbreviation: "NJ"
                },
                {
                    name: "New Mexico",
                    abbreviation: "NM"
                },
                {
                    name: "New York",
                    abbreviation: "NY"
                },
                {
                    name: "North Carolina",
                    abbreviation: "NC"
                },
                {
                    name: "North Dakota",
                    abbreviation: "ND"
                },
                {
                    name: "Ohio",
                    abbreviation: "OH"
                },
                {
                    name: "Oklahoma",
                    abbreviation: "OK"
                },
                {
                    name: "Oregon",
                    abbreviation: "OR"
                },
                {
                    name: "Palau",
                    abbreviation: "PW"
                },
                {
                    name: "Pennsylvania",
                    abbreviation: "PA"
                },
                {
                    name: "Puerto Rico",
                    abbreviation: "PR"
                },
                {
                    name: "Rhode Island",
                    abbreviation: "RI"
                },
                {
                    name: "South Carolina",
                    abbreviation: "SC"
                },
                {
                    name: "South Dakota",
                    abbreviation: "SD"
                },
                {
                    name: "Tennessee",
                    abbreviation: "TN"
                },
                {
                    name: "Texas",
                    abbreviation: "TX"
                },
                {
                    name: "Utah",
                    abbreviation: "UT"
                },
                {
                    name: "Vermont",
                    abbreviation: "VT"
                },
                {
                    name: "Virgin Islands",
                    abbreviation: "VI"
                },
                {
                    name: "Virginia",
                    abbreviation: "VA"
                },
                {
                    name: "Washington",
                    abbreviation: "WA"
                },
                {
                    name: "West Virginia",
                    abbreviation: "WV"
                },
                {
                    name: "Wisconsin",
                    abbreviation: "WI"
                },
                {
                    name: "Wyoming",
                    abbreviation: "WY"
                }
            ]
			}
		},
        computed: {
            errors() {
                return this.$store.state.new_entity_errors;
            },

		},
		methods: {

            set_current_input(input) {
                this.$store.commit('setCurrentInput', input); 
            },
			skip() {
				this.$store.commit('setToggleAddNewEntityModal', false);
			},
			save() {
				this.$store.dispatch('add_new_entity', {
					entity: this.entity
				});
			},
			remove(expense, index) {
				this.$store.dispatch('delete_expense', {
					expense: expense,
					index: index
				});
			},
		} 
	}
</script>
