var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("accounts-menu-bar"),
      _vm._v(" "),
      _c("budgeted-to-go"),
      _vm._v(" "),
      _c(_vm.current_input, { tag: "component" }),
      _vm._v(" "),
      _vm.showTransactionsGraphModal ? _c("graph") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 order-lg-last col-12" },
            [_c("account-progress")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-8 order-lg-first col-12" },
            [
              _c("h3", { staticClass: "mb-4" }, [
                _vm._v(
                  "Transactions (" +
                    _vm._s(_vm.this_month_transactions.length) +
                    ")"
                )
              ]),
              _vm._v(" "),
              _vm.accounts.length !== 0
                ? _c("transactions-menu-bar")
                : _vm._e(),
              _vm._v(" "),
              _vm.this_month_transactions_count !== 0
                ? _c("transactions-filters")
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "month-transations" }, [
                _c("div", { staticClass: "container-fluid" }, [
                  _c("div", { staticClass: "row table-header" }, [
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ml-1",
                            on: {
                              click: function($event) {
                                return _vm.sort(_vm.sortDate, "date_short")
                              }
                            }
                          },
                          [_vm._v("Date")]
                        ),
                        _vm._v(" "),
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              "icon-name": "write",
                              height: "20",
                              width: "20",
                              iconClass: "ml-2 mt-1"
                            }
                          },
                          [
                            _c("icon-sort", {
                              attrs: { slot: "icon" },
                              slot: "icon"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sort(
                                  _vm.sortPayee,
                                  "entity.name_short"
                                )
                              }
                            }
                          },
                          [_vm._v("Payee")]
                        ),
                        _vm._v(" "),
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              "icon-name": "write",
                              height: "20",
                              width: "20",
                              iconClass: "ml-2 mt-1"
                            }
                          },
                          [
                            _c("icon-sort", {
                              attrs: { slot: "icon" },
                              slot: "icon"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sort(
                                  _vm.sortPayee,
                                  "subcategories[0].name"
                                )
                              }
                            }
                          },
                          [_vm._v("Category")]
                        ),
                        _vm._v(" "),
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              "icon-name": "write",
                              height: "20",
                              width: "20",
                              iconClass: "ml-2 mt-1"
                            }
                          },
                          [
                            _c("icon-sort", {
                              attrs: { slot: "icon" },
                              slot: "icon"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-2 text-right" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sort(
                                  _vm.sortAmount,
                                  "absolute_value_amount"
                                )
                              }
                            }
                          },
                          [_vm._v("Amount")]
                        ),
                        _vm._v(" "),
                        _c(
                          "icon-base",
                          {
                            attrs: {
                              "icon-name": "write",
                              height: "20",
                              width: "20",
                              iconClass: "ml-2 mt-1"
                            }
                          },
                          [
                            _c("icon-sort", {
                              attrs: { slot: "icon" },
                              slot: "icon"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1" }, [
                      _vm._v(
                        "\n                                 \n                            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                this.loadingTransactions == false &&
                _vm.this_month_transactions.length > 0
                  ? _c(
                      "span",
                      _vm._l(_vm.this_month_transactions, function(
                        transaction,
                        index
                      ) {
                        return _c(transaction.mode, {
                          key: transaction.id,
                          tag: "transaction",
                          attrs: {
                            transaction: transaction,
                            categories: _vm.transaction_categories,
                            index: index
                          },
                          on: {
                            edit: _vm.edit_transaction,
                            save: function($event) {
                              return _vm.save(transaction, index)
                            },
                            remove: function($event) {
                              return _vm.remove_transaction(transaction, index)
                            },
                            update: _vm.update_transaction
                          }
                        })
                      }),
                      1
                    )
                  : this.loadingTransactions
                  ? _c("span", [_c("loading-transaction")], 1)
                  : this.loadingTransactions == false &&
                    _vm.this_month_transactions.length == 0
                  ? _c("span", [_c("no-transactions")], 1)
                  : _vm._e()
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }