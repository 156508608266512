var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal inside-close-btn is-active" }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "px-3 py-3" }, [
            _c("h4", { staticClass: "mb-2 mt-2" }, [
              _vm._v(_vm._s(_vm.budget_transactions_budget.subcategory_name))
            ]),
            _vm._v(" "),
            _c("h5", { staticClass: "mb-2 mt-2" }, [
              _vm._v("Spending Summary for " + _vm._s(_vm.month))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-5 mt-5" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("p", { staticClass: "modalTitle" }, [_vm._v("Budgeted")]),
                _vm._v(" "),
                _c("p", { staticClass: "modalAmount" }, [
                  _vm._v(_vm._s(_vm.formatMoney(_vm.current_month_amount)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("p", { staticClass: "modalTitle" }, [_vm._v("Spent")]),
                _vm._v(" "),
                _c("p", { staticClass: "modalAmount" }, [
                  _vm._v(_vm._s(_vm.formatMoney(_vm.transactions_total)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("p", { staticClass: "modalTitle" }, [_vm._v("Status")]),
                _vm._v(" "),
                _c("p", { staticClass: "modalAmount" }, [
                  _vm._v(_vm._s(_vm.status.label))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row table-header mt-2 mb-3" }, [
              _c("div", { staticClass: "col-3" }, [
                _vm._v(
                  "\n                                Place\n                            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _vm._v(
                  "\n                                Amount\n                            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _vm._v(
                  "\n                                Bank / Credit Card\n                            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _vm._v(
                  "\n                                Date\n                            "
                )
              ])
            ]),
            _vm._v(" "),
            _vm.budget_transactions.length > 0
              ? _c(
                  "div",
                  { staticClass: "month-transations" },
                  _vm._l(_vm.budget_transactions, function(transaction) {
                    return _c(
                      "div",
                      { staticClass: "row list-item-wrapper mb-2 flex-center" },
                      [
                        _c("div", { staticClass: "col-3" }, [
                          _c("p", { staticClass: "strong" }, [
                            _vm._v(_vm._s(transaction.entity.name))
                          ]),
                          _vm._v(
                            _vm._s(transaction.id) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [
                          _c("p", { staticClass: "strong" }, [
                            _vm._v(
                              _vm._s(
                                _vm.sumCategorySpending(
                                  transaction.subcategories
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [
                          _c("p", { staticClass: "strong" }, [
                            _vm._v(_vm._s(transaction.account.name))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [
                          _c("p", { staticClass: "strong" }, [
                            _vm._v(_vm._s(transaction.date_short))
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm.budget_transactions.length == 0
              ? _c("span", [_c("no-transactions")], 1)
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "modal-close", on: { click: _vm.skip } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }