<template>
    <div class="container account-info-inner boxshadow-1">
        <div class="row mb-5">
            <div class="col-12">
                <h4>Account Overview</h4>
            </div>
            <div class="col-6">
                <h3>Profile</h3>
                <div class="form-group">
                    <label for="loginEmail">Login email</label>
                    {{ user.email }}
                </div>
                <div class="form-group" v-if="user.edit==true">
                   <p>Test</p>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Current Password</label>
                            <input id="old_password" type="password" class="form-control no-border"
                                                                     v-model="user.old_password">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>New Password</label>
                            <input id="password" type="password" class="form-control no-border"
                                                                 v-model="user.password">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Confirm New Password</label>
                            <input id="password_confirmation" type="password" class="form-control no-border"
                                                                              v-model="user.password_confirmation">
                        </div>
                    </div>
                    <button class="btn btn-primary mt10" @click="savePassword">Save</button>
                </div>

                <div class="form-group" v-else>
                    <label for="password">Password</label>
                    ******
                    <a @click="editPassword" class="circle-btn">
                        <span class="pencilIcon"></span>
                    </a>
                </div>
            </div>
                <div class="form-group">
                    <a href="#" @click='deleteUserAccount'>Delete Account</a>
                </div>
            </div>
            <div class="col-6" v-if="user.stripe_id && subscription">
                <h4 class="h-green"><i class="fa fa-check-circle h-green" aria-hidden="true"></i> {{ subscriptionPlanName }}</h4>
                <p class="card-compact">
                Your plan will automatically renew on <strong>{{ subscription.next_bill_date }}</strong> and you'll be charged <strong>${{ subscription.amount }}</strong>.
                </p>
                <router-link class="btn btn-primary" :to="{ name: 'plan' }">Manage Plan</router-link>
            </div>

            <div class="col-6" v-else>
                <h4 class="h-green"><i class="fa fa-check-circle h-green" aria-hidden="true"></i> Basic Budgeter</h4>
                <p class="card-compact">
                You are on the Basic Budgeter plan. Upgrade to Budget Boss to get automatic transaction imports from banks and credit cards.
                </p>
                <router-link class="btn btn-primary" :to="{ name: 'change' }">Manage Plan</router-link>
            </div>
            <!-- TODO add income support -->
            <!-- <div class="col-6">
                <div class="form-group">
                    <div class="form-group">
                        <label>Include income on budgets?</label>
                        <div class="custom-control custom-toggle-checkbox">
                            <input class="custom-control-input" type="checkbox" @click="updateIncome" v-model="showIncome"
                                                                                v-bind:true-value="true"
                                                                                v-bind:false-value="false"
                                                                                id="defaultCheck">
                            <div class="toggle-labels">
                                <label class="unchecked-label" for="defaultCheck">No</label>
                                <label class="checked-label" for="defaultCheck">Yes</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->


                    <!--
                        -<input id="subscriptionPlan" type="text" class="form-control no-border">
                    -->
                <!--
                   -</div>
                   -<div class="form-group">
                   -    <label for="billingCicle">Billing Cycle</label>
                   -    Monthly
                   -->
                    <!--
                        -<input id="billingCicle" type="text" class="form-control no-border">
                    -->
                <!--
                   -</div>
                   -<div class="form-group">
                   -    <label for="billingAmount">Billing Amount</label>
                   -    ${{ subscriptionAmount }}
                   -->
                    <!--
                        -<input id="billingAmount" type="text" class="form-control no-border">
                    -->
                <!--
                   -</div>
                   -->
                <!--
                   -<div class="form-group">
                   -    <label for="subscriptionStartDate">Subscription Start Date</label>
                   -    {{ subscriptionStartDate }}
                   -->
                    <!--
                        -<input id="subscriptionStartDate" type="text" class="form-control no-border">
                    -->
                <!--
                   -</div>
                   -->
                <!--
                   -<div class="form-group">
                   -    <label for="connectedAccountsLimits">Connected Accounts Limits</label>
                   -    1
                   -->
                    <!--
                        -<input id="connectedAccountsLimits" type="text" class="form-control no-border">
                    -->
                <!--
                   -</div>
                   -->
        </div>
    </div>
</template>
<script>

    export default {
        data: function() {
            return {
                subscription: null,
                showIncome: null
            }
        },
		methods: {
            savePassword () {
                this.user.edit = false;
                console.log(JSON.stringify(this.user));
            },
            editPassword() {
                this.user.edit = true;
                console.log(JSON.stringify(this.user));
            },
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
            deleteUserAccount() {
                this.$store.dispatch('delete_user_account');
            },
            updateIncome() {
                alert('wire it up');
                this.$store.dispatch('setShowIncome', {
                    showIncome: this.showIncome
                });
            }
		},
		mounted: function() {
            var that = this;
            this.$store.dispatch('get_subscriptions').then(() => {
                that.subscription = this.$store.state.subscriptions;
            });
		},
		computed: {
            user: function() {
                return this.$store.state.user;
            },
			userName: function () {
				return 'test';
			},
			userEmail: function () {
				return 'test';
			},
			subscriptionPlanName: function () {
				return 'Budget Boss';
			},
			subscriptionAmount: function () {
				return 9.99;
			},
			subscriptionStartDate: function () {
				return 'test';
			},
			subscriptionNumberOfAvailableConnectedBanks: function () {
				return 'test';
			},
		},
	}
</script>
