var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-progress" }, [
    _c("h5", { staticClass: "title1" }, [_vm._v("Overview")]),
    _vm._v(" "),
    _c("h5", { staticClass: "title2" }, [
      _vm.activeTab == "budgets"
        ? _c("span", [_vm._v("On-going Expenses")])
        : _vm.activeTab == "bills"
        ? _c("span", [_vm._v("Bills")])
        : _vm.activeTab == "all"
        ? _c("span", [_vm._v("Combined")])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.activeTab == "budgets"
      ? _c("ul", { staticClass: "stats-group" }, [
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tPlanned\n\t\t\t\t"),
            _c("span", { staticClass: "white" }, [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_ongoing_budgeted)))
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tSpent\n\t\t\t\t"),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_ongoing_spent)))
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tRemaining\n\t\t\t\t"),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_ongoing_remaining)))
            ])
          ])
        ])
      : _vm.activeTab == "bills"
      ? _c("ul", { staticClass: "stats-group" }, [
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tTotal\n\t\t\t\t"),
            _c("span", { staticClass: "white" }, [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_bills_budgeted)))
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tPaid\n\t\t\t\t"),
            _c("span", [_vm._v(_vm._s(_vm.formatMoney(_vm.total_bills_spent)))])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tUnpaid\n\t\t\t\t"),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_bills_remaining)))
            ])
          ])
        ])
      : _vm.activeTab == "all"
      ? _c("ul", { staticClass: "stats-group" }, [
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tTotal\n\t\t\t\t"),
            _c("span", { staticClass: "white" }, [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_combined)))
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tSpent\n\t\t\t\t"),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_combined_spent)))
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-group-item mt-4 mb-4" }, [
            _vm._v("\n\t\t\t\tRemaining\n\t\t\t\t"),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMoney(_vm.total_combined_remaining)))
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }