require('./bootstrap');
require('./dropdown');
require('./search');
require('./transition');
require('./chartist.min');
var _ = require('./lodash');
require('./jquery.circliful');
require('./jquery.payment.min.js');
require('./bootstrap-datepicker.js');
window.format = require('date-fns/format');
window.addMonths = require('date-fns/add_months')
window.differenceInDays = require('date-fns/difference_in_days')
window.subDays = require('date-fns/sub_days')
window.isAfter = require('date-fns/is_after')
window.getLastDayOfMonth = require('date-fns/last_day_of_month')
// Marketing Page Views
Vue.component('app', require('./components/App.vue').default);
Vue.component('navbar', require('./components/Navbar.vue').default);
Vue.component('toast', require('./components/Toast.vue').default);
Vue.component('datfootertho', require('./components/Footer.vue').default);

// In App views
Vue.component('graph', require('./components/Graph.vue').default);
Vue.component('bill-budget', require('./components/Bill.vue').default);
Vue.component('semantic-select', require('./components/SemanticSelect.vue').default);
Vue.component('reset-password', require('./components/ResetPassword.vue').default);
Vue.component('change-password', require('./components/ChangePassword.vue').default);
Vue.component('full-page-loading-screen', require('./components/FullPageLoadingScreen.vue').default);
Vue.component('budgeted-to-go', require('./components/BudgetedToGo.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('mfa-modal', require('./components/MFAForm.vue').default);
Vue.component('split-transaction-item', require('./components/SplitTransactionItem.vue').default);
Vue.component('currency-input', require('./components/CurrencyInput.vue').default);
Vue.component('message-to-user', require('./components/MessageToUser.vue').default);
Vue.component('plaid-error-card', require('./components/PlaidError.vue').default);
Vue.component('loading', require('./components/LoadingData.vue').default);
Vue.component('split-transaction', require('./components/SplitTransaction.vue').default);
Vue.component('google-ad', require('./components/GoogleAd.vue').default);
Vue.component('accounts-menu-bar', require('./components/AccountsMenuBar.vue').default);
Vue.component('transactions-menu-bar', require('./components/TransactionsMenuBar.vue').default);
Vue.component('transactions-filters', require('./components/TransactionsFilters.vue').default);
Vue.component('errors', require('./components/Errors.vue').default);
// No goals until all existing bugs and features work solid.
/* Vue.component('allocation', require('./components/GoalAllocation.vue').default);
 * Vue.component('goal-search-add', require('./components/GoalSearchAddNew.vue').default);
 * Vue.component('success-message', require('./components/SuccessMessage.vue').default);
 * Vue.component('goal-step-one', require('./components/NewGoalStepOne.vue').default);
 * Vue.component('goal-step-two', require('./components/NewGoalStepTwo.vue').default);
 * Vue.component('goal-step-three', require('./components/NewGoalStepThree.vue').default);
 * Vue.component('goal-step-four', require('./components/NewGoalStepFour.vue').default);
 * Vue.component('goal-step-five', require('./components/NewGoalStepFive.vue').default);
 * Vue.component('goal-confirm', require('./components/NewGoalConfirm.vue').default);
 * Vue.component('add-new-allocation', require('./components/AddNewGoalAllocation.vue').default);
 * Vue.component('goal', require('./components/Goal.vue').default);
 * Vue.component('goals', require('./components/Goals.vue').default);
 * Vue.component('add-new-goal-modal', require('./components/AddNewGoalModal.vue').default);
 * Vue.component('goal-edit', require('./components/EditGoalModal.vue').default); */
Vue.component('accounts', require('./components/Accounts.vue').default);
Vue.component('accounts-selector', require('./components/AccountsSelector.vue').default);
Vue.component('accountProgress', require('./components/AccountProgress.vue').default);
Vue.component('goalProgress', require('./components/GoalProgress.vue').default);
Vue.component('safe-to-spend', require('./components/SafeToSpend.vue').default);
Vue.component('remove-button-loading', require('./components/RemoveButtonLoading.vue').default);
Vue.component('remove-connected-bank-button', require('./components/RemoveConnectedBankButton.vue').default);
Vue.component('bank-login-form', require('./components/BankLoginForm.vue').default);
Vue.component('link-token-login-form', require('./components/LinkFormWithLinkToken.vue').default);
Vue.component('logging-in', require('./components/LoggingIn.vue').default);
Vue.component('edit-connected-bank', require('./components/ConnectedBankEdit.vue').default);
Vue.component('connected-bank', require('./components/ConnectedBank.vue').default);
Vue.component('connected-bank-accounts-list', require('./components/ConnectedBankAccountsList.vue').default);
Vue.component('connect-bank-account-button', require('./components/ConnectBankAccountButton.vue').default);
Vue.component('connect-bank-account-modal', require('./components/ConnectBankAccount.vue').default);
Vue.component('plaid-link-token-modal', require('./components/PlaidLinkTokenFormModal.vue').default);
Vue.component('uncategorizedTransactions', require('./components/UncategorizedTransactions.vue').default);
Vue.component('transaction', require('./components/Transaction.vue').default);
Vue.component('transaction-edit', require('./components/TransactionEdit.vue').default);
Vue.component('loading-transaction', require('./components/LoadingTransaction.vue').default);
Vue.component('dropdown', require('./components/Dropdown.vue').default);
Vue.component('select-dropdown', require('./components/SelectDropdown.vue').default);
Vue.component('entitySelect', require('./components/EntitySelect.vue').default);
Vue.component('budgets-search-add', require('./components/BudgetsSearchAdd.vue').default);
Vue.component('budgetProgress', require('./components/BudgetProgress.vue').default);
Vue.component('budgets', require('./components/Budgets.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('expensesmodal', require('./components/ExpensesModal.vue').default);
Vue.component('budgets_list', require('./components/BillsList.vue').default);
Vue.component('bills_list', require('./components/BudgetsList.vue').default);
Vue.component('budget', require('./components/Budget.vue').default);
Vue.component('loading-budget', require('./components/LoadingBudget.vue').default);
Vue.component('formAddExpense', require('./components/FormAddExpense.vue').default);
Vue.component('budget-edit', require('./components/BudgetEdit.vue').default);
Vue.component('income-expenses', require('./components/IncomeAndExpenses.vue').default);
Vue.component('addNewBudgetButton', require('./components/AddNewBudgetButton.vue').default);
Vue.component('addNewBudget', require('./components/AddNewBudget.vue').default);
Vue.component('income', require('./components/Income.vue').default);
Vue.component('edit-income', require('./components/EditIncome.vue').default);
Vue.component('addNewIncomeButton', require('./components/AddNewIncomeButton.vue').default);
Vue.component('addNewIncome', require('./components/FormAddIncome.vue').default);
Vue.component('addNewExpenseButton', require('./components/AddNewExpenseButton.vue').default);
Vue.component('addNewExpense', require('./components/FormAddExpense.vue').default);
Vue.component('expense', require('./components/Expense.vue').default);
Vue.component('edit-expense', require('./components/EditExpense.vue').default);
Vue.component('budgetTransactionsModal', require('./components/BudgetTransactionsModal.vue').default);
Vue.component('budgetSummaryModal', require('./components/BudgetSummaryModal.vue').default);
Vue.component('thisMonthTransactionsList', require('./components/ThisMonthTransactionsList.vue').default);
Vue.component('addNewTransactionButton', require('./components/AddNewTransactionButton.vue').default);
Vue.component('addNewTransaction', require('./components/AddNewTransaction.vue').default);
Vue.component('date', require('./components/Date.vue').default);
Vue.component('add-new-entity', require('./components/AddNewEntityModal.vue').default);
Vue.component('add-new-account', require('./components/AddNewAccount.vue').default);
Vue.component('edit-account', require('./components/EditAccount.vue').default);
Vue.component('no-transactions', require('./components/NoTransactions.vue').default);
Vue.component('suggestedBudgets', require('./components/SuggestedBudgetsModal.vue').default);

// icons
Vue.component('icon-base', require('./components/IconBase.vue').default);
Vue.component('icon-accounting-and-bookkeeping', require('./components/icons/IconAccountingAndBookkeeping.vue').default);
Vue.component('icon-acupuncture', require('./components/icons/IconAcupuncture.vue').default);
Vue.component('icon-adult-education', require('./components/icons/IconAdultEducation.vue').default);
Vue.component('icon-adult-entertainment', require('./components/icons/IconAdultEntertainment.vue').default);
Vue.component('icon-advertising', require('./components/icons/IconAdvertising.vue').default);
Vue.component('icon-airfare', require('./components/icons/IconAirfare.vue').default);
Vue.component('icon-alcoholic-drinks', require('./components/icons/IconAlcoholicDrinks.vue').default);
Vue.component('icon-aquarium', require('./components/icons/IconAquarium.vue').default);
Vue.component('icon-arcades-and-amusement-parks', require('./components/icons/IconArcadesAndAmusementParks.vue').default);
Vue.component('icon-art-dealers-and-galleries', require('./components/icons/IconArtDealersAndGalleries.vue').default);
Vue.component('icon-audio-streaming-subscription', require('./components/icons/IconAudioStreamingSubscription.vue').default);
Vue.component('icon-auto-insurance', require('./components/icons/IconAutoInsurance.vue').default);
Vue.component('icon-auto-loan', require('./components/icons/IconAutoLoan.vue').default);
Vue.component('icon-auto-oil-and-lube', require('./components/icons/IconAutoOilAndLube.vue').default);
Vue.component('icon-auto-smog-check', require('./components/icons/IconAutoSmogCheck.vue').default);
Vue.component('icon-auto-tires', require('./components/icons/IconAutoTires.vue').default);
Vue.component('icon-bank-fees', require('./components/icons/IconBankFees.vue').default);
Vue.component('icon-billiards-and-pool', require('./components/icons/IconBilliardsAndPool.vue').default);
Vue.component('icon-books', require('./components/icons/IconBooks.vue').default);
Vue.component('icon-bowling', require('./components/icons/IconBowling.vue').default);
Vue.component('icon-business-hotellodging', require('./components/icons/IconBusinessHotellodging.vue').default);
 Vue.component('icon-business-legal', require('./components/icons/IconBusinessLegal.vue').default);
Vue.component('icon-cable-internet', require('./components/icons/IconCableInternet.vue').default);
Vue.component('icon-cable', require('./components/icons/IconCable.vue').default);
Vue.component('icon-car-wash-and-detail', require('./components/icons/IconCarWashAndDetail.vue').default);
Vue.component('icon-cash-advance', require('./components/icons/IconCashAdvance.vue').default);
Vue.component('icon-cash-deposit-gift', require('./components/icons/IconCashDepositGift.vue').default);
Vue.component('icon-cell-phone', require('./components/icons/IconCellPhone.vue').default);
Vue.component('icon-charity', require('./components/icons/IconCharity.vue').default);
Vue.component('icon-check-deposit-gift', require('./components/icons/IconCheckDepositGift.vue').default);
Vue.component('icon-childcare', require('./components/icons/IconChildcare.vue').default);
Vue.component('icon-circuses-and-carnivals', require('./components/icons/IconCircusesAndCarnivals.vue').default);
Vue.component('icon-clothing', require('./components/icons/IconClothing.vue').default);
Vue.component('icon-coffee-shops', require('./components/icons/IconCoffeeShops.vue').default);
Vue.component('icon-coffee', require('./components/icons/IconCoffee.vue').default);
Vue.component('icon-college-tuition', require('./components/icons/IconCollegeTuition.vue').default);
Vue.component('icon-comedy-shows', require('./components/icons/IconComedyShows.vue').default);
Vue.component('icon-concerts', require('./components/icons/IconConcerts.vue').default);
Vue.component('icon-credit-card-payment', require('./components/icons/IconCreditCardPayment.vue').default);
Vue.component('icon-credit-card', require('./components/icons/IconCreditCard.vue').default);
Vue.component('icon-culinary-lessons-and-schools', require('./components/icons/IconCulinaryLessonsAndSchools.vue').default);
Vue.component('icon-dance-schools', require('./components/icons/IconDanceSchools.vue').default);
Vue.component('icon-dining', require('./components/icons/IconDining.vue').default);
Vue.component('icon-electric', require('./components/icons/IconElectric.vue').default);
Vue.component('icon-federal-taxes', require('./components/icons/IconFederalTaxes.vue').default);
Vue.component('icon-garbage', require('./components/icons/IconGarbage.vue').default);
Vue.component('icon-gas', require('./components/icons/IconGas.vue').default);
Vue.component('icon-groceries', require('./components/icons/IconGroceries.vue').default);
Vue.component('icon-gym', require('./components/icons/IconGym.vue').default);
Vue.component('icon-health-insurance', require('./components/icons/IconHealthInsurance.vue').default);
Vue.component('icon-healthcare', require('./components/icons/IconHealthcare.vue').default);
Vue.component('icon-insurance', require('./components/icons/IconInsurance.vue').default);
Vue.component('icon-internet', require('./components/icons/IconInternet.vue').default);
Vue.component('icon-paycheck', require('./components/icons/IconPaycheck.vue').default);
Vue.component('icon-public-transportation', require('./components/icons/IconPublicTransportation.vue').default);
Vue.component('icon-rent-mortgage', require('./components/icons/IconRentMortgage.vue').default);
Vue.component('icon-restaurants', require('./components/icons/IconRestaurants.vue').default);
Vue.component('icon-savings', require('./components/icons/IconSavings.vue').default);
Vue.component('icon-shopping', require('./components/icons/IconShopping.vue').default);
Vue.component('icon-software-subscriptions', require('./components/icons/IconSoftwareSubscriptions.vue').default);
Vue.component('icon-student-loans', require('./components/icons/IconStudentLoans.vue').default);
Vue.component('icon-transfer-to-savings', require('./components/icons/IconTransferToSavings.vue').default);
Vue.component('icon-trash', require('./components/icons/IconTrash.vue').default);
Vue.component('icon-water', require('./components/icons/IconWater.vue').default);

Vue.component('icon-edit', require('./components/icons/IconPencil.vue').default);
Vue.component('icon-sort', require('./components/icons/IconSort.vue').default);
Vue.component('icon-filter', require('./components/icons/IconFilter.vue').default);
Vue.component('icon-graph', require('./components/icons/IconGraph.vue').default);
Vue.component('icon-budget', require('./components/icons/IconBudget.vue').default);
Vue.component('icon-cash', require('./components/icons/IconCash.vue').default);
Vue.component('icon-calendar', require('./components/icons/IconCalendar.vue').default);

const storedToken = localStorage.getItem('token');
if(storedToken!=='null') {
	axios.interceptors.request.use (
		function (config) {
			if (storedToken) config.headers.Authorization = `Bearer ${storedToken}`;
			return config;
		},
		function (error) {
			return Promise.reject (error);
		}
	);
}

const store = new Vuex.Store({
  	state: {
        loginRedirectTo: 'budgets',
		isLoggedIn: !!localStorage.getItem("token"),
		isLoggingIn: false,
		loadingTransactions: true,
		authToken: null,
		csrfToken: null,
        institutions: [],
        user: JSON.parse(localStorage.getItem("user")),
        notifications: [],
        loading_message: null,
        body_view: null,
        current_input: null,
        current_data: null,
		last: null,
		goals: null,
		search_goals: null,
		first: null,
		today: null,
		month: null,
		states: [],
		toasts: [],	
		active_budgets_tab: 'all',
		search_budgets: null,
        bank_data_imports: null,
		account: [],
		accounts: [],
		budgets: null,
		suggested_budgets: null,
		account_types: [],
		expenses: [],
		entities: [],
		connected_banks: [],
		paychecks: [],
		total_spent: 0,
		monthLast: null,
		monthFirst: null,
		total_budgeted: 0,
		total_goals: 0,
		new_budget:{
					mode: ''
				},
		new_transaction:{
					mode: ''
				},
		new_expense: {
			mode: 'addNewExpenseButton'
		},
		income_categories: [],
		success_messages: [],
		new_account_errors: [],
        new_transaction_errors: [],
		update_account_errors: [],
		update_transaction_errors: [],
		new_budget_errors: [],
		update_budget_errors: [],
		new_goal_errors: [],
		login_errors: [],
		reset_password_errors: [],
		payForPlanErrors: [],
		register_errors: [],
		new_income_errors: [],
		new_expense_errors: [],
		new_entity_errors: [],
		mfa_errors: [],
		bank_connect_message: null,
		connect_bank_errors: [],
		connect_bank_account_modal_message: 'Login to your bank using the form below.',
		bank_login_mode: 'bank-login-form',
		plaid_link_token_login_mode: 'link-token-login-form',
        link_token: null,
        new_allocation: {
            mode: ''
        },
		expense_categories: [],
		budget_transactions: [],
		budget_transactions_budget: [],
		new_goal_allocation_errors: [],
		transaction_categories: [],
		showRecurringIncomeModal: false,
		total_budgeted_remaining: 0,
		showRecurringExpensesModal: false,
		showBudgetTransactionsModal: false,
        showBudgetSummaryModal: false,
        showSuggestedBudgetsModal: false,
		showTransactionsGraphModal: false,
        showSplitTransaction: false,
		uncategorized_transactions: [],
		unbudgeted_expense_categories: [],
		month_transactions: [],
		this_month_transactions: [],
        this_month_transactions_filtered: null,
		month_transactions: [],
		showAddNewEntityModal: false,
		showAddNewAccountModal: false,
		showEditAccount: false,
		showConnectBankAccountModal: false,
		showPlaidLinkTokenModal: false,
		showMFAModal: false,
		mfaResponseModel: null,
		showBudgetedToGo: false,
		plaidTokenId: null,
		remove_bank_button_mode: 'remove-connected-bank-button',
		showAddNewGoalModal: false,
		current_date: null,
		current_month: null,
        plaid_error: null,
        transaction_category_status_filter: null,
        transaction_type_filter: null,
		full_page_loading_message: null,
		full_page_loader_percent: null,
        subscriptions: null
  	},
	actions: {

		login: function ({dispatch, commit, state}, {userCredentials, key}) {

			var that = this;	
			commit('clearLoginErrors');

			return axios.post('/login', userCredentials)
				.then(function (response) {
					commit('setAuthUser',  response.data.user);
                    console.log(JSON.stringify(response.data.user));
					commit('setAuthToken',  response.data.api_token);
					localStorage.setItem('token', response.data.api_token);
					localStorage.setItem('user', JSON.stringify(response.data.user));
					store.dispatch('prefix_axios_requests').then(() => {
					store.dispatch('get_notifications').then(() => {
						if(response.data.user.is_budget_boss===true) {
							commit('setIsLoggingIn', true);
							commit('setFullPageLoadingScreenData', {message: 'Connecting to bank'});
							store.dispatch('import_transactions', {
								user_id: response.data.user.id,
								fromLoginScreen: true 
							});

							/*
							 *store.dispatch('import_accounts', {
							 *    user_id: response.data.user.id,
							 *    fromLoginScreen: true
							 *});
							 */
						} else {
							return router.push(store.state.loginRedirectTo);
						}
					});
					});
				})
				.catch(function (error) {
					dispatch('handleErrors', {errors: error, errorsVar: 'login_errors'});
				});
		},

		reset_password_email: function ({commit, state}, {userEmail, key}) {

			var that = this;	
			commit('clearResetPasswordErrors');
			return axios.post('/forgot-password', userEmail)
				.then(function (response) {
					commit('addSuccessMessages', { 'message': 
					'Reset password email sent successfully.'
					});
				})
				.catch(function (error) {

					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key];
								commit('clearSuccessMessages');
								commit('addResetPasswordErrors', errorMessage);
							}
						}
					} else if (error.request) {
						// @todo log this to Sentry or similar service
						console.log(error.request);
					} else {
						// @todo log this to Sentry or similar service
						console.log('error');
					}
				});
		},

		reset_password: function ({commit, state}, {userCredentials}) {

			var that = this;
			commit('clearResetPasswordErrors');

			return axios.post('/reset-password', userCredentials)
				.then(function (response) {

                    return router.push('/login');
/*                     commit('setAuthUser',  response.data.user);
 *                     commit('setAuthToken',  response.data.api_token);
 *                     localStorage.setItem('token', response.data.api_token);
 *                     localStorage.setItem('user', JSON.stringify(response.data.user));
 *
 *                     store.dispatch('prefix_axios_requests').then(() => {
 *                         if(typeof(response.data.user.is_budget_boss) !== 'undefined' && response.data.user.is_budget_boss===true) {
 *                             commit('setIsLoggingIn', true);
 *                             commit('setFullPageLoadingScreenData', {message: 'Connecting to bank'});
 *                             store.dispatch('import_transactions', {
 *                                 user_id: response.data.user.id,
 *                                 fromLoginScreen: true
 *                             });
 *                         } else {
 *                             return router.push('/budgets');
 *                         }
 *                     }); */

				})
				.catch(function (error) {
					if (error.response) {
                        commit('clearSuccessMessages');
                        commit('addResetPasswordErrors', error.response.data);
					} else if (error.request) {
						// @todo log this to Sentry or similar service
						console.log(error.request);
					} else {
						// @todo log this to Sentry or similar service
						console.log('error');
					}
				});
		},
		register: function ({commit, state}, {registerCredentials}) {

			var that = this;	
			commit('clearRegisterErrors');

			return axios.post('/register', registerCredentials)
				.then(function (response) {
					commit('setAuthUser',  response.data.user);
					commit('setAuthToken',  response.data.api_token);
					localStorage.setItem('token', response.data.api_token);
					localStorage.setItem('user', JSON.stringify(response.data.user));

					store.dispatch('prefix_axios_requests').then(() => {
						if(response.data.user.is_budget_boss===true) {
							return router.push("connected-banks");
						} else {
							return router.push(store.state.loginRedirectTo);
						}
					});


				})
				.catch(function (error) {
					//commit('setIsLoggingIn', false);
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key];
                                //commit('addRegisterErrors', { 'message': errorMessage[0]});
                                store.dispatch('handleErrors', {errors: error, errorsVar: 'register_errors'});

							}
						}
					} else if (error.request) {
						// @todo log this to Sentry or similar service
						console.log(error.request);
					} else {
						// @todo log this to Sentry or similar service
						console.log('Error', error.message);
					}
				});
		},
		payForPlan: function ({commit, state}, {paymentData}) {

			var that = this;	
			commit('clearPayForPlanErrors');

			return axios.post('/subscriptions/plan', paymentData)
				.then(function (response) {
					commit('setAuthUser',  response.data.user);
					localStorage.setItem('user', JSON.stringify(response.data.user));

					store.dispatch('prefix_axios_requests').then(() => {
						if(response.data.user.is_budget_boss===true) {
							return router.push("/connected-banks");
						} else {
							//return router.push("accounts");
						}
					});


				})
				.catch(function (error) {
					//commit('setIsLoggingIn', false);
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key];
								console.log('==> errorMessage', JSON.stringify(errorMessage[0]));
								commit('addPayForPlanErrors', { 'message': errorMessage[0]});
							}
						}
					} else if (error.request) {
						// @todo log this to Sentry or similar service
						console.log(error.request);
					} else {
						// @todo log this to Sentry or similar service
						console.log('Error', error.message);
					}
				});
		},
		prefix_axios_requests: function ({commit, state}) {

			var token = state.authToken;
			axios.interceptors.request.use (
				function (config) {
					if (token) config.headers.Authorization = `Bearer ${token}`;
					return config;
				}, 
				function (error) {
						return Promise.reject (error);
				}
			);

			axios.interceptors.response.use((response) => {
				return Promise.resolve(response)
			}, function (error) {
				if(typeof error.response !== 'undefined'){
					if(error.response.status === 401) {
						store.dispatch('logout');
					}
				}
				return Promise.reject(error);
			});


		},
		logout: function ({commit, state}) {
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			localStorage.removeItem("toasts");
			localStorage.removeItem("notifications");
			commit('setAuthUser',  null);
			commit('setAuthToken',  null);
			return router.push("login");
		},
		set_notification_user: function ({commit, state}, {notificationId}) {
			return axios.post('/notifications/' + notificationId)
				.then(function (response) {
					store.dispatch('get_notifications').then(() => {

                    });
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		handleErrors: function ({commit, state}, {errors, errorsVar}) {

			errors = errors.response;
			if (errors.data) {

                if(errors.data.status){
                    return commit('addErrors', {'errorsVar': errorsVar, 'error': errors.data.status});
                }
                if(errors.data.message){
                    return commit('addErrors', {'errorsVar': errorsVar, 'error': errors.data.message});
                }

				var key, count = 0, errorMessage;
				for(key in errors.data) {
					if(errors.data.hasOwnProperty(key)) {
						errorMessage = errors.data[key][0];
						commit('addErrors', {'errorsVar': errorsVar, 'error': errorMessage});
					}
				}
			} else if (errors.request) {
				console.log(errors.request);
			} else {
				console.log('Error', errors.message);
			}
		},

		exchangePlaidToken: function ({commit, state}, {token, metadata}) {

			var that = this;	
			//commit('clearLoginErrors');

			return axios.post('/plaid/token/exchange', {'token': token, 'metadata': metadata})
				.then(function (response) {
                    console.log('==> response.data', JSON.stringify(response.data));
                    that.$store.commit('clearConnectBankAccountErrors');
                    that.$store.commit('setToggleConnectBankAccountModal', false);
					//commit('setPlaidToken',  response.data.api_token);

				})
				.catch(function (error) {

                    console.log('==> response.data', JSON.stringify(error));
                    if (error.status===422) {
				        return commit('setToggleConnectBankAccountModal', true);
                    }
				});
		},

		import_transactions: function ({commit, state}, {user_id, fromLoginScreen = false}) {

			if(fromLoginScreen===true) {
				commit('setFullPageLoadingScreenData', {message: 'Connecting to accounts.'});
                commit('setFullPageLoaderPercent', 0);

                // Enable pusher logging - don't include this in production
                //Pusher.logToConsole = true;
                var pusher = new Pusher('370c84563161a8977aa1', {
                    cluster: 'us3',
                    forceTLS: true
                });
                var channel = pusher.subscribe('user-' + user_id + '-channel');
                channel.bind('categorizeTransactionsProgress', function(data) {
                    commit('setFullPageLoadingScreenData', {message: 'Categorizing your ' + data.accountName + ' transactions. ' });
                    commit('setFullPageLoaderPercent', data.progress);
                });
			} else {
				commit('setBankLoginMode', {mode: 'logging-in', message: 'Importing Transactions'});
			}

			var that = this;
			return axios.post('/transactions/import', {'user_id': user_id})
			.then(function (response) {

				if(fromLoginScreen===false) {
					commit('setBankLoginMode', {mode: 'bank-login-form'});
					commit('setToggleConnectBankAccountModal', false);
				} else {
					commit('setFullPageLoaderPercent', 100);
			        commit('setIsLoggingIn', false);
                    var uncategorizedTransactions = response.data.uncategorizedTransactions;
                    console.log('==> uncategorizedTransactions', JSON.stringify(uncategorizedTransactions));

				var numberOfTransactions = 0;
                    numberOfTransactions = uncategorizedTransactions.reduce(function(acc, accountTransactions) {
                        return acc + Number(accountTransactions['transactions'].length);
                    }, 0);
                
                    if(numberOfTransactions) {
                        commit('appendToast', {title: 'Attention', message: 'You have ' + numberOfTransactions + ' uncategorized transactions, do you want to categorize them now?', when: 'just now', delay: '50000'});
                    }
					return router.push(store.state.loginRedirectTo);
				}

			})
			.catch(function (error) {

				if(!fromLoginScreen) {
					commit('setBankLoginMode', {mode: 'bank-login-form'});
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key][0];
								commit('addConnectBankErrors', { 'message': errorMessage});
							}
						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				} else {

                    if(error.response && error.response.status===403) {
                        //store.dispatch('handleErrors', {errors: error, errorsVar: 'login_errors'});
                        store.dispatch('handleErrors', {errors: error, errorsVar: 'login_errors'});
                        var institutionId = error.response.data.institutionId;
                        console.log(institutionId);
                        store.dispatch('get_link_token', {institutionId: institutionId}).then(() => {
                        });

						//return commit('setToggleConnectBankAccountModal', true);

						//return commit('setToggleConnectBankAccountModal', true);
                    } else if (error.response && error.response.status===401) {
						store.dispatch('logout');
                        store.dispatch('handleErrors', {errors: error, errorsVar: 'login_errors'});
			            commit('setIsLoggingIn', false);

                    }
                }
			});
		},
        get_link_token: function({commit, state}, {institutionId}) {
            console.log(institutionId);
			var that = this;	
			return axios.post('/plaid/getLinkToken', {institutionId})
				.then(function (response) {
                    commit('setLinkToken',  response.data.link_token);
                    commit('setTogglePlaidLinkTokenModal', true);
				})
				.catch(function (error) {
					commit('setIsLoggingIn', false);
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key];
								console.log('==> errorMessage', JSON.stringify(errorMessage));
							}
						}
					} else if (error.request) {
						// @todo log this to Sentry or similar service
						console.log(error.request);
					} else {
						// @todo log this to Sentry or similar service
						console.log('Error', error.message);
					}
				});
		},
		connect_bank_account: function ({commit, state}, {bank_connection, key}) {

			commit('setBankLoginMode', {mode: 'logging-in', message: 'Connecting to bank, please wait...'});
			return axios.post('/banks/connect', bank_connection)
				.then(function (response) {

					console.log(JSON.stringify(response));
					console.log(JSON.stringify(response.data));
					console.log(JSON.stringify(response.data.mfa_response));
				//	console.log(JSON.stringify(response.data.token_id));
					commit('setMfaResponseModel', response.data.mfa_response);
					commit('setPlaidTokenId', response.data.token_id);
					/*
					 *console.log('==> response', JSON.stringify(response.status));
					 *console.log('==> response', JSON.stringify(response));
					 */

					if(response.status===201) {
						commit('setToggleMFAModal', true);
						return commit('setToggleConnectBankAccountModal', false);
					}
					commit('setBankLoginMode', {mode: 'logging-in', message: 'Importing Accounts'});
                    store.dispatch('get_plaid_errors').then(() => {
                        store.dispatch('import_accounts', {
                            user_id: state.user.id,
							fromLoginScreen: false
                        });
                    });


				})
				.catch(function (error) {

					console.log('==> error', JSON.stringify(error));

					commit('setBankLoginMode', {mode: 'bank-login-form'});

                    if(error.response.status===422) {

                        if (error.response) {
                            var key, count = 0, errorMessage;
                            for(key in error.response.data) {
                                if(error.response.data.hasOwnProperty(key)) {
                                    errorMessage = error.response.data[key][0];
                                    console.log('==> errorMessage', JSON.stringify(errorMessage));
                                    commit('addConnectBankErrors', { 'message': errorMessage});
                                }
                            }
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    }
				});
		},



		getBankDataImports: function ({commit, state}) {

			var that = this;	
			//commit('clearLoginErrors');

			return axios.post('/bank/data/imports')
				.then(function (response) {
                    console.log('==> response.data', JSON.stringify(response.data));
					commit('setBankDataImports',  response.data);

				})
				.catch(function (error) {

				});
		},

		get_subscriptions: function ({commit, state}) {
			return axios.get('/subscriptions')
			.then(function (response) {

                return commit('addSubscription', { 'subscription': response.data});
			})
			.catch(function (error) {
                return Promise.reject(error);
			});
		},
        // @todo add confirmation screen, Are you sure?
		switch_to_basic: function ({commit, state}) {
			return axios.post('/subscriptions/plan/basic')
			.then(function (response) {

                commit('addSubscription', { 'subscription': response.data});
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		sum_total_budgeted_spent: function ({commit, state}) {

            var that = this;
            state.budgets.forEach(function(val, key) {
                val['spent'] = 0;
            });

			if(	typeof(state.accounts)!=='undefined' && state.accounts.length) {
				state.accounts.forEach(function(account, key) {
					if(typeof(account.transactions) !== 'undefined' && account.transactions.length) {
						account.transactions.forEach(function(transaction, key) {


							transaction.subcategories.forEach(function(subcategory, key1) {
								var m;
								m = state.budgets.findIndex(budget => Number(budget.transaction_subcategory_id) === Number(subcategory.id));
								if(transaction.type=="expense"){
									// findIndex returns -1 for false
									if(m>-1 && subcategory.pivot.amount) {
										commit('increaseBudgetSpentAmount', {
											amount: subcategory.pivot.amount,
											key: m 
										});
									}
								}

							});
						});
					}
				});
			}

        },

		add_new_goal: function ({commit, state}, {goal, key}) {

			var that = this;	
			commit('clearNewGoalErrors');
			return axios.post('/goals', goal)
				.then(function (response) {
					state.goals.push(response.data);
				    commit('toggleAddNewGoalModal', false)

				})
				.catch(function (error) {
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key][0];
								commit('addNewGoalErrors', { 'message': errorMessage});
							}
						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		},
		add_new_goal_allocation: function ({commit, state}, {goal, goal_allocation, goal_index}) {

			var that = this;	
			return axios.post('/goals/' + goal.id + '/allocations', goal_allocation)
				.then(function (response) {
					state.goals[goal_index].allocations.push(response.data);
				    //commit('toggleAddNewGoalModal', false)

				})
				.catch(function (error) {
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key][0];
								commit('addNewGoalAllocationErrors', { 'message': errorMessage});
							}
						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		},

		update_goal: function ({commit, state}, {goal, index}) {
			return axios.patch('/goals/' + goal.id, goal)
			.then(function (response) {
                //console.log('==> response', JSON.stringify(response));
                goal.mode = 'goal';
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		delete_goal: function ({commit, state}, {goal,index}) {

			var that = this;
			//commit('setRemoveConnectedBankButtonMode', 'remove-button-loading');

			return axios.delete('/goals/' + goal.id)
			.then(function (response) {
				state.goals.splice(index, 1);
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		delete_goal_allocation: function ({commit, state}, {goal, goal_allocation, index, goal_allocation_index}) {

			var that = this;
			//commit('setRemoveConnectedBankButtonMode', 'remove-button-loading');

			return axios.delete('/goals/' + goal.id + '/allocations/' + goal_allocation.id)
			.then(function (response) {
				state.goals[index].allocations.splice(goal_allocation_index, 1);
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		delete_user_account: function ({commit, state}) {

			var that = this;
			return axios.delete('/users/' + state.user.id)
			.then(function (response) {
				store.dispatch('logout');
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		update_account_balance_and_transactions: function ({commit, state}, {account}) {

			var that = this;
			return axios.post('/accounts/update', {'account': account})
			.then(function (response) {

				//state.connected_banks.push(response.data);
				console.log(JSON.stringify(response.data));

				/*
				 *commit('setBankLoginMode', {mode: 'logging-in', message: 'Importing Transactions'});
				 *store.dispatch('import_transactions', {
				 *    plaid_id: response.data.id
				 *});
				 */
			})
			.catch(function (error) {

				/*
				 *commit('setBankLoginMode', {mode: 'bank-login-form'});
				 *if (error.response) {
				 *    var key, count = 0, errorMessage;
				 *    for(key in error.response.data) {
				 *        if(error.response.data.hasOwnProperty(key)) {
				 *            errorMessage = error.response.data[key][0];
				 *            commit('addConnectBankErrors', { 'message': errorMessage});
				 *        }
				 *    }
				 *} else if (error.request) {
				 *    console.log(error.request);
				 *} else {
				 *    console.log('Error', error.message);
				 *}
				 */
			});

		},
		delete_connected_bank: function ({commit, state}, {connected_bank,index}) {

			var that = this;
			commit('setRemoveConnectedBankButtonMode', 'remove-button-loading');
			return axios.delete('/connected-banks/' + connected_bank.id)
			.then(function (response) {
				connected_bank.mode = 'connected-bank';
				state.connected_banks.splice(index, 1);
				commit('setRemoveConnectedBankButtonMode', 'remove-bank-button-mode');
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		add_new_expense: function ({commit, state}, {expense, key}) {

			commit('clearNewExpenseErrors');
			var that = this;	
			return axios.post('/accounts/' + state.account.id + '/transactions/recurring', expense)
			.then(function (response) {
				state.expenses.push(response.data);
			})
			.catch(function (error) {
				if (error.response) {
					var key, count = 0, errorMessage;
					for(key in error.response.data) {
						if(error.response.data.hasOwnProperty(key)) {
							errorMessage = error.response.data[key][0];
							commit('addNewExpenseErrors', { 'message': errorMessage});
							commit('toggleExpenseMode', {expense});
						}
					}
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
		},

		add_new_budget: function ({dispatch, commit, state}, {budget, key}) {

			var that = this;
			return axios.post('/budgets', budget)
				.then(function (response) {
					//state.budgets.push(response.data);
                    dispatch('get_budgets').then(() => {
                        if(!state.this_months_transactions) {
                            dispatch('get_all_accounts_transactions_for_this_month').then(() => {

                            });
                        }
                    })

			        commit('setCurrentInput', {input: null}); 
					dispatch('sum_total_budgeted_spent');
					//commit('toggleBudgetMode', {budget});
				})
				.catch(function (error) {
					dispatch('handleErrors', {errors: error, errorsVar: 'new_budget_errors'});
				});
		},

		add_new_income: function ({commit, state}, {income, key}) {

				commit('clearNewIncomeErrors');
				var that = this;	
				return axios.post('/accounts/' + state.account.id + '/transactions/recurring', income)
				.then(function (response) {
 					state.paychecks.push(response.data);
				})
				.catch(function (error) {
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key][0];
								commit('addNewIncomeErrors', { 'message': errorMessage});
							}
						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		},

		add_new_account: function ({dispatch, commit, state}, {account, key}) {

			if (account) {
				return axios.post('/accounts', account)
				.then(response => { 
					console.log(response)
					 commit('addAccount', response.data);

                     // not sure if this should be changed to get the account from the collection
                    commit('setAccount', response.data);
			        commit('setCurrentInput', {input: null}); 
                    //commit('setToggleAddNewAccountModal', false);
				})
					.catch(error => {
						console.log(error.response)
						dispatch('handleErrors', {errors: error, errorsVar: 'new_account_errors'});
					});
/*
 *                .catch(function (error) { 
 *return 					console.log(error.response);
 *                    if (error.response) {
 *                        console.log('==> error', er);
 *                        dispatch('handleErrors', {errors: error, errorsVar: 'new_account_errors'});
 *                    }
 *                });
 */
			}
		},

		hide_modal: function ({ commit, state }) {
			state.showRecurringIncomeModal = false;
		},

		categorize_transaction: function ({commit, state}, {transaction, key}) {

			return axios.patch('/accounts/' + state.account.id + '/transactions/' + transaction.id, transaction)
			.then(function (response) {
				transaction = response.data;
				commit('removeUncategorizedTransaction', {transaction: transaction, key: key});
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		get_current_date: function ({commit, state}) {
			return axios.get('/month')
				.then(function (response) {
					commit('setCurrentDate', response.data);
                    return response;
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		get_plaid_errors: function ({commit, state}) {

			return axios.get('/plaid/errors')
				.then(function (response) {
					commit('setPlaidError', response.data);
                    return response;
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		get_banks: function ({commit, state}) {

			return axios.get('/banks')
				.then(function (response) {
					commit('setInstitutions', response.data);
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		get_connected_banks: function ({commit, state}) {

			return axios.get('/connected-banks')
				.then(function (response) {
					commit('setConnectedBanks', response.data);
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		get_suggested_budgets: function ({commit, state}) {

			return axios.get('/budgetDefaults')
				.then(function (response) {
					commit('setSuggestedBudgets', response.data);
                    commit('setToggleSuggestedBudgetsModal', true)
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		stop_suggested_budgets: function ({commit, state}) {

			return axios.post('/users/' + state.user.id + '/budgetDefaults/stop')
				.then(function (response) {
                    localStorage.setItem('user', JSON.stringify(response.data));
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		store_suggested_budget: function ({dispatch, commit, state}, {budget, key}) {
			var that = this;
			return axios.post('/budgets', budget)
				.then(function (response) {
					state.budgets.push(response.data);
			        commit('setCurrentInput', {input: null});
					dispatch('sum_total_budgeted_spent');
				})
				.catch(function (error) {
					dispatch('handleErrors', {errors: error, errorsVar: 'new_budget_errors'});
				});
		},
		get_notifications: function ({commit, state}) {

			return axios.get('/notifications')
				.then(function (response) {
                    localStorage.setItem('notifications', JSON.stringify(response.data));
                    Object.assign(state.notifications, JSON.parse(localStorage.getItem('notifications')));
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		get_all_accounts_transactions_for_this_month: function ({dispatch, commit, state}) {

			if(state.accounts) {

				state.body_view = 'loading';
				state.loading_message = 'Loading transactions';
                commit('setLoadingTransactions', true);

				if(state.current_date) {
					return axios.get('/accounts/transactions/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
						.then(function (response) {
                        	commit('setLoadingTransactions', false);
							commit('setAccounts', response.data);
                    		dispatch('sum_total_budgeted_spent');
							/*
							 *commit('setSortMonthTransactions', {order: 'asc', column: 'date_short'}); 
							 *dispatch('sum_total_budgeted_spent');
							 */
							state.body_view = null;

						})
						.catch(function (error) {
							console.log(error);
							state.body_view = null;
						});
				}
			}
		},
		get_accounts: function ({commit, state}) {

            if(state.current_date) {
                return axios.get('/accounts/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
                    .then(function (response) {
                        commit('setAccounts', response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                return axios.get('/accounts')
                    .then(function (response) {
                        commit('setAccounts', response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
		},
		get_account_types: function ({commit, state}) {

			return axios.get('/accounts/types')
				.then(function (response) {
					commit('setAccountTypes', response.data);
				})
				.catch(function (error) {
					console.log(error);
				});
		},
        get_budgets: function ({commit, state}) {
            if(state.current_date) {
                state.body_view = 'loading';
                state.loading_message = 'Loading budgets';
                return axios.get('/budgets/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
                    .then(function (response) {
                        state.body_view = null;
                        commit('setBudgets', response.data);
console.log('getting budgets', state.user.finished_budget_suggestions);
                        if(response.data.length === 0) {
                            if(state.user.finished_budget_suggestions === 0) {
                                store.dispatch('get_suggested_budgets');
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
		get_goals: function ({commit, state}) {

            // At the time being I don't see a need for date based goals so i'm commenting this out for later when I realize it is necessary.
            /*
             *if(state.current_date) {
             *    axios.get('/api/goals/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
             *        .then(function (response) {
             *            commit('setGoals', response.data);
             *        })
             *        .catch(function (error) {
             *            console.log(error);
             *        });
             *} else {
             */
             return    axios.get('/goals')
                    .then(function (response) {
                        commit('setGoals', response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            //}
		},

		get_entities: function ({commit, state}) {

			store.dispatch('prefix_axios_requests').then(() => {
				return axios.get('/transactions/entities')
					.then(function (response) {
						commit('setEntities', response.data);
					})
					.catch(function (error) {
						console.log(error);
					});
			});
		},
		get_income_categories: function ({commit, state}) {

              return  axios.get('/transactions/categories/income')
                    .then(function (response) {
                        commit('setIncomeCategories', response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
		},

		get_expense_categories: function ({commit, state}) {

               return  axios.get('/transactions/categories/expenses')
                    .then(function (response) {
                        commit('setExpenseCategories', response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
		},
		get_month_transactions: function ({dispatch,commit, state}, {account}) {

            state.body_view = 'loading';
            state.loading_message = 'Loading transactions';
            if(state.current_date && account) {
               return axios.get('/accounts/' + account.id + '/transactions/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
				.then(function (response) {

					commit('setMonthTransactions', response.data);
					commit('setSortMonthTransactions', {order: 'desc', column: 'date_short'}); 
                    dispatch('sum_total_budgeted_spent');
                    state.body_view = null;

				})
				.catch(function (error) {
					console.log(error);
                    state.body_view = null;
				});
            } else if (!account && state.current_date) {

               return axios.get('/transactions/month/' + (state.current_date.getMonth()+1) + '/year/' + state.current_date.getFullYear())
				.then(function (response) {
					commit('setMonthTransactions', response.data);
					commit('setSortMonthTransactions', {order: 'asc', column: 'date_short'}); 
                    dispatch('sum_total_budgeted_spent');
                    state.body_view = null;

				})
				.catch(function (error) {
					console.log(error);
                    state.body_view = null;
				});
            } 
            /*
             *else {
             *    axios.get('/api/transactions')
             *        .then(function (response) {
             *            commit('setMonthTransactions', response.data);
			 *            commit('setSortMonthTransactions', {order: 'asc', column: 'date_short'}); 
             *        })
             *        .catch(function (error) {
             *            console.log(error);
             *        });
             *}
             */
		},

		create_budget: function ({dispatch, commit, state}, {transaction, key}) {

			// an expense vs income
			if (transaction.credit_amount) {
				axios.post('/budgets', transaction)
				.then(function (response) {
                    // removing this as a design pattern for now, not all new budgets will be shown in the current month.
                    //	commit('addBudget', response.data);
                    dispatch('get_budgets').then(() => {
                        if(!state.this_months_transactions) {
                            dispatch('get_all_accounts_transactions_for_this_month').then(() => {

                            });
                        }
                    })

				})
				.catch(function (error) {
					console.log(error);
				});
			}
		},


		update_budget: function ({dispatch, commit, state}, {budget}) {
		
			axios.patch('/budgets/' + budget.id, budget)
				.then(function (response) {
					var updatedBudget = response.data;
					commit('setCurrentInput', {input: null}); 
					commit('updateBudget', {
						budget: updatedBudget
					});
					// not sure why this is needed
					dispatch('sum_total_budgeted_spent');

				})
				.catch(function (error) {

					dispatch('handleErrors', {errors: error, errorsVar: 'update_budget_errors'});

				});
		},


		delete_budget: function ({commit, state}, {budget}) {

			var that = this;
			var currentDate = format(state.current_date, 'M/YYYY');

			return axios.delete('/budgets/' + budget.id, {data: {date: currentDate }})
			.then(function (response) {
				//let index = state.budgets.findIndex(sbudget => sbudget.id === budget.id);
				//state.budgets.splice(index, 1);
				commit('removeBudget', budget);
				commit('setCurrentInput', {input: null}); 
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		update_income: function ({commit, state}, {paycheck}) {

			axios.patch('/accounts/' + state.account.id + '/transactions/recurring/' + paycheck.id, paycheck)
			.then(function (response) {
				paycheck.mode = 'income';
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		delete_income: function ({commit, state}, {paycheck,index}) {

			var that = this;
			axios.delete('/accounts/' + state.account.id + '/transactions/recurring/' + paycheck.id)
			.then(function (response) {
				state.paychecks.splice(index, 1);
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		delete_expense: function ({commit, state}, {expense,index}) {

			var that = this;
			axios.delete('/accounts/' + state.account.id + '/transactions/recurring/' + expense.id)
			.then(function (response) {
				state.expenses.splice(index, 1);
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		add_new_transaction: function({dispatch, commit, state}, {transaction}) {

			var that = this;
			axios.post('/accounts/' + state.account.id + '/transactions', transaction)
			.then(function (response) {
				state.this_month_transactions.push(response.data);
			        commit('setCurrentInput', {input: null}); 
                    dispatch('get_account', {account_id: state.account.id})
			})
				.catch(function (errors) {
					dispatch('handleErrors', {errors: errors, errorsVar: 'new_transaction_errors'});
				});

		},

		update_transaction: function ({commit, state}, {transaction, key}) {



			return axios.patch('/accounts/' + state.account.id + '/transactions/' + transaction.id, transaction)
                .then(function (response) {

					var index, transaction;
                    transaction = response.data;
                    transaction.mode = "transaction";
                    index = state.this_month_transactions.findIndex(stransaction => Number(stransaction.id) === Number(transaction.id));

                    // findIndex returns -1 for false
                    if(index>-1) {
                        transaction = response.data;
                        transaction.mode = "transaction";
                        commit('updateTransaction', {
                            transaction: transaction,
                            key: index
                        });
               			commit('filterTransactions');

                    }

                })
                .catch(function (error) {
                    if (error.response) {
                        var key, count = 0, errorMessage;
                        for(key in error.response.data) {
                            if(error.response.data.hasOwnProperty(key)) {
                                errorMessage = error.response.data[key][0];
                                commit('addUpdateTransactionErrors', { 'message': errorMessage});
                            }
                        }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
		},
		update_account: function ({commit, state}, {account}) {

			return axios.patch('/accounts/' + account.id, account)
			.then(function (response) {
			    commit('setCurrentInput', {input: null}); 
			})
				.catch(function (error) {
					if (error.response) {
						var key, count = 0, errorMessage;
						for(key in error.response.data) {
							if(error.response.data.hasOwnProperty(key)) {
								errorMessage = error.response.data[key][0];
								commit('updateAccountErrors', { 'message': errorMessage});
							}
						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		},
		delete_account: function ({commit, state}, {account_id}) {
			
			return axios.delete('/accounts/' + account_id)
			.then(function (response) {

                var item = state.accounts.find(account => account.id === Number(account_id));
                var index = state.accounts.indexOf(item);
				state.accounts.splice(index, 1);
                if(state.accounts.length>0) {
                    state.account = state.accounts[state.accounts.length-1];
                }
                if(state.account.id===account_id) {
                    state.account = [];
                }
                store.dispatch('get_month_transactions', {account: null});
			    commit('setCurrentInput', {input: null}); 
                //commit('setToggleEditAccount', false);

			})
			.catch(function (error) {
				console.log(error);
			});
		},
		delete_transaction: function ({commit, state}, {transaction,index}) {
			
			return axios.delete('/accounts/' + state.account.id + '/transactions/' + transaction.id)
			.then(function (response) {

				transaction.mode = 'transaction';
				state.this_month_transactions.splice(index, 1);

			})
			.catch(function (error) {
				console.log(error);
			});
		},

		add_new_entity: function({commit, state}, {entity}) {

			return axios.post('/accounts/' + state.account.id + '/entities', entity)
			.then(function (response) {

				commit('appendEntity', {data: response.data});
                commit('setCurrentInput',{input: 'add-new-transaction'});
			})
			.catch(function (error) {
                    if (error.response) {
                        var key, count = 0, errorMessage;
                        for(key in error.response.data) {
                            if(error.response.data.hasOwnProperty(key)) {
                                errorMessage = error.response.data[key][0];
                                commit('addNewEntityErrors', { 'message': errorMessage});
                            }
                        }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
			});

		},
		loadPreviousMonthTransactions : function ({commit, state}, {month,index}) {
			return axios.get('/accounts/' + state.account.id  + '/transactions/month/' + month)
			.then(function (response) {
				alert(response);
			})
			.catch(function (error) {
				console.log(error);
			});
		},
        get_transactions_categories: function ({commit, state}) {
           return axios.get('/transactions/categories')
                .then(function (response) {
                    commit('setTransactionCategories', response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_account: function ({commit, state},{account_id}) {
            return axios.get('/accounts/' + account_id)
                .then(function (response) {
                    commit('setAccount', response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
	},
  	mutations: {
        initialiseToasts: (state) => {
            if(localStorage.getItem('toasts')) {
                Object.assign(state.toasts, JSON.parse(JSON.stringify(localStorage.getItem('toasts'))));
            }
        },
        initialiseNotifications: (state) => {
            if(localStorage.getItem('notifications')) {
                Object.assign(state.notifications, JSON.parse(localStorage.getItem('notifications')));
            }
        },
        clearPayForPlanErrors: (state) => {
			state.clearPayForPlanErrors = [];
        },
        clearMfaErrors: (state) => {
			state.mfa_errors = [];
        },
        clearLoginErrors: (state) => {
			state.login_errors = [];
        },
        clearResetPasswordErrors: (state) => {
			state.reset_password_errors = [];
        },
        clearRegisterErrors: (state) => {
			state.register_errors = [];
        },
        clearConnectBankAccountErrors: (state) => {
			state.connect_bank_errors = [];
        },
        clearNewAccountErrors: (state) => {
			state.new_account_errors = [];
        },
        clearNewTransactionErrors: (state) => {
			state.new_transaction_errors = [];
        },
        clearUpdateTransactionErrors: (state) => {
			state.update_transaction_errors = [];
        },
        clearNewBudgetErrors: (state) => {
			state.new_budget_errors = [];
        },
        clearUpdateBudgetErrors: (state) => {
			state.update_budget_errors = [];
        },
        clearNewGoalErrors: (state) => {
			state.new_goal_errors = [];
        },
        clearNewExpenseErrors: (state) => {
			state.new_expense_errors = [];
        },
        clearNewIncomeErrors: (state) => {
			state.new_expense_errors = [];
        },
		toggleBudgetMode: (state, error) => {
			    state.new_budget.mode = (state.new_budget.mode=='addNewBudget') ? '' : 'addNewBudget';
		},
		toggleTransactionMode: (state, error) => {
            state.new_transaction.mode = (state.new_transaction.mode=='addNewTransaction') ? '' : 'addNewTransaction';
		},
		toggleNewAllocationMode: (state, error) => {
            state.new_allocation.mode = (state.new_allocation.mode=='add-new-allocation') ? '' : 'add-new-allocation';
		},
		toggleExpenseMode: (state, error) => {

		    state.new_expense.mode = (state.new_expense.mode=='addNewExpense') ? 'addNewExpenseButton' : 'addNewExpense';
		},
        setLinkToken(state, linkToken) {
            state.link_token = linkToken;
        },
		addSubscription(state, {subscription}) {
            state.subscriptions = subscription;
        },
		addErrors(state, {errorsVar, error}) {
            var contains = false;
            var length = state[errorsVar].length;

            for(var i = 0; i < length; i++) {
                if(state[errorsVar][i] == error) {
                    contains = true;
                }
            }

            if(!contains) {
                state[errorsVar].push(error);
            }

		},

		addMfaErrors: (state, error) => {
            var contains = false;
            var length = state.mfa_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.mfa_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.mfa_errors.push(error);
            }
		},
        addResetPasswordErrors: (state, error) => {

            var contains = false;
            var errorsCount = state.reset_password_errors.length;
            if(error.hasOwnProperty('message') &&
                ! error.hasOwnProperty('errors')) {
                state.reset_password_errors.push(error);
            } else if(error.hasOwnProperty('errors')) {
                for(var k in error['errors']) {
                    var message = error['errors'][k];
                    for(var j = 0; j<errorsCount; j++) {
                        if(state.reset_password_errors[j] == message[0]) {
                            contains = true;
                        }
                    }
                    if(!contains) {
                        state.reset_password_errors.push(message[0]);
                    }
                }
            } else if (error.hasOwnProperty('email')) {
                for(var j = 0; j<errorsCount; j++) {
                    if(state.reset_password_errors[j] == error.email) {
                        contains = true;
                    }
                }
                if(!contains) {
                    state.reset_password_errors.push(error.email);
                }
            }
        },
		addRegisterErrors: (state, error) => {

            var contains = false;
            var length = state.register_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.register_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.register_errors.push(error);
            }

		},

		addNewGoalAllocationErrors: (state, error) => {

            var contains = false;
            var length = state.new_goal_allocation_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_goal_allocation_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_goal_allocation_errors.push(error);
            }
			console.log(JSON.stringify(state.new_goal_allocation_errors));

		},
		addNewGoalErrors: (state, error) => {

            var contains = false;
            var length = state.new_goal_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_goal_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_goal_errors.push(error);
            }

		},

		addConnectBankErrors: (state, error) => {

            var contains = false;
            var length = state.connect_bank_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.connect_bank_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.connect_bank_errors.push(error);
            }

		},

		updateAccountErrors: (state, error) => {

            var contains = false;
            var length = state.update_account_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.update_account_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.update_account_errors.push(error);
            }

		},
		addNewTransactionErrors: (state, error) => {

            var contains = false;
            var length = state.new_transaction_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_transaction_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_transaction_errors.push(error);
            }

		},
		addUpdateTransactionErrors: (state, error) => {

            var contains = false;
            var length = state.update_transaction_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.update_transaction_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.update_transaction_errors.push(error);
            }

		},
		addNewBudgetErrors: (state, error) => {

            var contains = false;
            var length = state.new_budget_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_budget_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_budget_errors.push(error);
            }

		},
		addUpdateBudgetErrors: (state, error) => {

            var contains = false;
            var length = state.update_budget_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.update_budget_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.update_budget_errors.push(error);
            }

		},
		addNewExpenseErrors: (state, error) => {

            var contains = false;
            var length = state.new_expense_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_expense_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_expense_errors.push(error);
            }

		},
		addNewIncomeErrors: (state, error) => {

            var contains = false;
            var length = state.new_income_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_income_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_income_errors.push(error);
            }

		},
        addNewEntityErrors: (state, error) => {

            var contains = false;
            var length = state.new_entity_errors.length;

            for(var i = 0; i < length; i++) {
                if(state.new_entity_errors[i].message == error.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.new_entity_errors.push(error);
            }
        },
		clearSuccessMessages: (state, success) => {
            state.success_messages = [];
		},
		addSuccessMessages: (state, success) => {

            var contains = false;
            var length = state.success_messages.length;

            for(var i = 0; i < length; i++) {
                if(state.success_messages[i].message == success.message) {
                    contains = true;
                }
            }

            if(!contains) {
                state.success_messages.push(success);
            }
		},
		round (state, {value, decimals}) {
			return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
		},
		setToggleBudgetedToGo(state, setting) {
			state.showBudgetedToGo = setting;
		},

		setPlaidTokenId(state, plaidTokenId) {
			state.plaidTokenId = plaidTokenId;
		},
		setMfaResponseModel(state, mfaResponseModel) {
			state.mfaResponseModel = mfaResponseModel;
		},
		setConnectBankAccountModalMessage(state, message) {
			state.connect_bank_account_modal_message = message;
		},
		setCsrfToken (state, token) {
			state.csrfToken = token;
		},
		setAuthUser (state, user) {
			state.user = user;
		},
		setAuthToken (state, token) {
			state.authToken = token;
		},
		setCurrentDate(state, date) {
            if(typeof(date)==='object') {
                state.current_date = date;
            } else {
                state.current_date = new Date(date);
            }
		},
		removeUncategorizedTransaction(state, {transaction, key}) {

			var m = state.budgets.find(budget => budget.transaction_subcategory_id === Number(transaction.transaction_subcategory_id));

			if(m) {
				// increase amount spent in relative budget
				m.spent += Number(transaction.credit_amount);
				console.log('mystery');
			} else {
				// create a new budget
				store.dispatch('create_budget', {
					transaction: transaction, 
					key: key
				});
				
			}
			state.uncategorized_transactions.splice(key, 1);
			state.this_month_transactions.push(transaction);
		},
        
        setBankDataImports(state, data) {
			state.bank_data_imports = data;
		},
        setCurrentInput(state, {input, data = null}) {
			state.current_input = input;
			state.current_data = data;
		},

		toggleAddNewGoalModal(state, mode) {
			state.showAddNewGoalModal = mode;
		},
        setRemoveConnectedBankButtonMode(state, mode) {
			state.remove_bank_button_mode = mode;
		},
		setFullPageLoaderPercent(state, percent) {
			state.full_page_loader_percent = percent;
		},
		setLoadingTransactions(state, mode) {
			state.loadingTransactions = mode;
		},
		setIsLoggingIn(state, mode) {
			state.isLoggingIn = mode;
		},
		setFullPageLoadingScreenData(state, {icon, message}) {
			state.full_page_loading_message = message;
		},
		setBankLoginMode(state, {mode, message}) {
			state.bank_login_mode = mode;
			state.bank_connect_message = message;
		},
        setToggleSuggestedBudgetsModal(state, setting) {
            state.showSuggestedBudgetsModal = setting;
        },
		setToggleMFAModal(state, setting) {
			state.showMFAModal = setting;
		},
		setToggleConnectBankAccountModal(state, setting) {
			state.showConnectBankAccountModal = setting;
		},
		setTogglePlaidLinkTokenModal(state, setting) {
			state.showPlaidLinkTokenModal = setting;
		},
		setToggleAddNewEntityModal(state, setting) {
			state.showAddNewEntityModal = setting;
		},
		setToggleRecurringIncomeModal(state, setting) {
			state.showRecurringIncomeModal = setting;
		},
		setToggleRecurringExpensesModal(state, setting) {
			state.showRecurringExpensesModal = setting;
		},
		setToggleAddNewAccountModal(state, setting) {
			state.showAddNewAccountModal = setting;
		},
		setToggleEditAccount(state, setting) {
			state.showEditAccount = setting;
		},
		setToggleSplitTransaction(state, setting) {
			state.showSplitTransaction = setting;
		},
		setToggleShowBudgetSummaryModal(state, {setting, budget, budget_transactions}) {
			state.budget_transactions_budget = budget;
			state.budget_transactions = budget_transactions;
			state.showBudgetSummaryModal = setting;
		},
		setToggleShowBudgetTransactionsModal(state, {setting, budget, budget_transactions}) {
			state.budget_transactions_budget = budget;
			state.budget_transactions = budget_transactions;
			state.showBudgetTransactionsModal = setting;
		},
		setToggleTransactionsGraphModal(state, {setting}) {
			state.showTransactionsGraphModal = setting;
		},
		setInstitutions(state, institutions) {
			state.institutions = institutions;
		},
		setPlaidError(state, error) {
			state.plaid_error = error;
		},
		addAccount(state, account) {
			state.accounts.push(account);
		},
		setGoals(state, goals) {
			state.goals = goals;
		},
        searchGoals(state, search) {

            if(search && search.length > 0) {
                state.search_goals = state.goals.filter(function(goal){return goal.title.toLowerCase().indexOf(search.toLowerCase())>=0;});
            } else {
                state.search_goals = null;
            }
        },
		addGoal(state, goal) {
			state.goals.push(goal);
		},
		setConnectedBanks(state, connected_banks) {
			state.connected_banks = connected_banks;
		},
        setSuggestedBudgets(state, suggested_budgets) {
			state.suggested_budgets = suggested_budgets;
        },
		setAccount(state, account) {
			state.account = account;
		},
		eraseAccount(state) {
			state.account = null;
		},
		setAccountTypes(state, account_types) {
			state.account_types = account_types;
		},
		setAccounts(state, accounts) {
			state.accounts = accounts;
		},
		setUncategorizedTransactions(state, uncategorized_transactions) {
			state.uncategorized_transactions = uncategorized_transactions;
		},
		setTransactionCategories(state, transaction_categories) {
			state.transaction_categories = transaction_categories;
		},
		setIncomeCategories(state, income_categories) {
			state.income_categories = income_categories;
		},
		setExpenseCategories(state, expense_categories) {
			state.expense_categories = expense_categories;
		},
		setBudgets(state, budgets) {
			state.budgets = budgets;
		},
        searchInstitutions(state, search) {

            if(search && search.length > 0) {
                state.institutions = state.budgets.filter(function(budget){return budget.subcategory.name.toLowerCase().indexOf(search.toLowerCase())>=0;});
            } else {
                state.search_budgets = null;
            }
        },
        searchBudgets(state, search) {

            if(search && search.length > 0) {
                state.search_budgets = state.budgets.filter(function(budget){return budget.subcategory.name.toLowerCase().indexOf(search.toLowerCase())>=0;});
            } else {
                state.search_budgets = null;
            }
        },
		addBudget(state, budget) {
			state.budgets.push(budget);
		},
		removeBudget(state, budget) {

			var index = state.budgets.findIndex(sbudget => sbudget.id === budget.id);
			state.budgets.splice(index, 1)
		},
        updateAccount(state, {account, key}) {

			account.mode = 'account';
            state.accounts.splice(key, 1, account);
            state.account=account;
        },
        updateBudget(state, {budget}) {
        
			var index = state.budgets.findIndex(sbudget => sbudget.id === budget.id);
            state.budgets.splice(index, 1, budget)

        },
        increaseBudgetSpentAmount(state, {amount,key}) {
        
			var budget = state.budgets[key];//find(budget => budget.id === Number(key));
				budget.spent += amount;
				state.budgets.splice(key, 1, budget);

        },
		updateTransaction(state, {transaction,key}) {
			state.this_month_transactions.splice(key, 1, transaction);
		},
		setMonth(state, month) {
			state.month = month;
		},
		setFirst(state, first) {
			state.first = first;
		},
		setLast(state, last) {
			state.last = last;
		},
		setMonthFirst(state, monthFirst) {
			state.monthFirst = monthFirst;
		},
		setMonthLast(state, monthLast) {
			state.monthLast = monthLast;
		},
		setToday(state, today) {
			state.today = today;
		},
		setPaychecks(state, paychecks) {
			state.paychecks = paychecks;
		},
		setExpenses(state, expenses) {
			state.expenses = expenses;
		},
		setThisMonthTransactions(state, transactions) {
			state.this_month_transactions = transactions;
		},
		setTransactionCategoryStatusFilter(state, filter) {
			state.transaction_category_status_filter = filter;
		},
		setTransactionTypeFilter(state, type) {
			state.transaction_type_filter = type;
		},
        filterTransactions(state) {

            var filtered;

            if(state.transaction_category_status_filter) {
                if(state.transaction_category_status_filter=='categorized') {
                    filtered = state.this_month_transactions.filter(function(transaction){
                        return transaction.subcategories.length!==0;
                    });
                } else if (state.transaction_category_status_filter=='uncategorized') {
                    filtered = state.this_month_transactions.filter(function(transaction){
                        return transaction.subcategories.length===0;
                    });
                } else if (state.transaction_category_status_filter=='all') {
                    filtered = state.this_month_transactions;
                }
            }

            if(state.transaction_type_filter) {
                if(filtered) {
                    filtered = filtered.filter(function(transaction) {
                        return transaction.type == state.transaction_type_filter;
                    });
                } else {
                    filtered = state.this_month_transactions.filter(function(transaction) {
                        return transaction.type == state.transaction_type_filter;
                    });
                }
            }

            state.this_month_transactions_filtered = filtered;

        },
        searchTransactions(state, search) {
            if(search && search.length > 0) {
                state.this_month_transactions_filtered = state.this_month_transactions.filter(function(transaction){
                    return transaction.entity.name.toLowerCase().indexOf(search.toLowerCase())>=0;
                });
            } else {
                state.this_month_transactions_filtered = null;
            }
        },
		setMonthTransactions(state, transactions) {
			state.this_month_transactions = transactions;
		},
		setSortMonthTransactions(state, {order, column}) {
			console.log('sorting by: ' + column + ' in order of ' + order);
				state.this_month_transactions = _.orderBy(state.this_month_transactions, [column],[order]);
		},
		setEntities(state, entities) {
			state.entities = entities;
		},
		setStates(state, states) {
			state.states = states;
		},
		appendEntity(state, data) {
			state.entities.push(data.data);
		},
		setUser(state, user) {
			state.user = user;
		},
		setActiveBudgetsTab(state, tab) {
			state.active_budgets_tab = tab;
		},
        appendToast(state, toast) {
            state.toasts.push(toast);
            localStorage.setItem('toasts', state.toasts);
        }
    },
	getters: {
        dueInFullBudgetStatus(state, getters) {
            return  budget => {
                if(getters.overBudgetStatus(budget)) {
                    return {
                        'icon': 'over-budget',
                        'label': 'Over Budget'
                    };
                } else if (getters.underBudgetStatus(budget)) {
                    return {
                        'icon': 'under-budget',
                        'label': 'Under Budget'
                    };
                } else if (getters.dueVerySoonBudgetStatus(budget)) {
                    return {
                        'icon': 'due-soon',
                        'label': 'Due soon'
                    };
                } else if (getters.dueSoonBudgetStatus(budget)) {
                    return {
                        'icon': 'unpaid',
                        'label': 'Unpaid'
                    };
                } else if (getters.pastDueBudgetStatus(budget)) {
                    return {
                        'icon': 'past-due',
                        'label': 'Past due'
                    };
                } else if (getters.dueTodayBudgetStatus(budget)) {
                    return {
                        'icon': 'due-today',
                        'label': 'Due today'
                    };
                } else if (getters.paidBudgetStatus(budget)) {
                    return {
                        'icon': 'paid',
                        'label': 'Paid'
                    };
                } else {
                    return {
                        'icon': '',
                        'label': 'PENDING'
                    }
                }
           }
        },
            pastDueBudgetStatus(state, getters) {
                return budget => {
                    var pastDue = false;
                    if (getters.paidBudgetStatus(budget) == false) {
                        if(typeof(budget.months) !=='undefined') {
                            var today = new Date(state.current_date);
                            var month = format(today, 'M');
                            var year = format(today, 'YYYY');
                            var currentBudgetMonth = budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
                            if(currentBudgetMonth) {
                                pastDue = budget.recurrence_date_this_month < getters.currentDayOfMonthFormatted && currentBudgetMonth.has_been_paid === 0;
                            }
                        } else if (budget.recurrence_date_this_month) {
                            pastDue = budget.recurrence_date_this_month < getters.currentDayOfMonthFormatted;
                        }
                    }
                    return pastDue;
                }
            },
        overBudgetStatus(state) {
            return budget => {
                return budget.remaining<0;
            }
        },
        underBudgetStatus(state, getters) {
            return budget => {
                return budget.remaining>0 && budget.remaining < getters.currentMonthAmount(budget);
            }
        },
        dueVerySoonBudgetStatus(state, getters) {
            return budget => {
                var dueVerySoon = false;
                if(getters.dueSoonBudgetStatus(budget)) {
                    if (getters.budgetDaysPastDue(budget)>=-7) {
                        dueVerySoon = true;
                    }
                }
                return dueVerySoon;
            }
        },
		paidBudgetStatus(state, getters) {
            return budget => {

                var paid = false;

                if(typeof(budget.months) !=='undefined') {
                    var today = new Date(state.current_date);
                    var month = format(today, 'M');
                    var year = format(today, 'YYYY');
                    var currentBudgetMonth = budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
                    if(currentBudgetMonth) {
                        paid = (currentBudgetMonth.has_been_paid === 1) || (budget.remaining<=0 && getters.currentMonthAmount(budget) > 0);
                    }
                } else if (budget.recurrence_date_this_month) {
                    pastDue = budget.remaining<=0 && getters.currentMonthAmount(budget) > 0;
                }
                return paid;
            }
		},
        dueTodayBudgetStatus(state) {
            return budget => {
                var dueToday = false;
                        var today = new Date();
                if (budget.remaining>0) {
                    if (budget.recurrence_date_this_month) {
                        var recurrenceThisMonthDateObject = new Date(budget.recurrence_date_this_month);
                        if(recurrenceThisMonthDateObject.valueOf() == state.current_date.valueOf()) {
                            dueToday = true;
                        }
                    } else if(typeof(budget.months) !=='undefined') {
                        var today = new Date(state.current_date);
                        var month = format(today, 'M');
                        var year = format(today, 'YYYY');
                        var currentBudgetMonth = budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
                        if(currentBudgetMonth) {
                            if (budget.recurrence_date_this_month) {
                                var recurrenceInSelectedMonthDateObject = new Date(currentBudgetMonth.recurrence_date_this_month);
                                dueToday = recurrenceInSelectedMonthDateObject.valueOf() == today.valueOf();
                            } 

                            var recurrenceDate;
                            if(budget.recurrences && budget.recurrences.length) {
                                recurrenceDate = budget.recurrences[0].repeat_start;
                            }
                            var dueDate = new Date(recurrenceDate);
                            return dueToday = dueDate.valueOf() == today.valueOf();
                        }
                    } else {

                        var dueSoon = false;
                        var recurrenceDate;
                        if(budget.recurrences && budget.recurrences.length) {
                            recurrenceDate = budget.recurrences[0].repeat_start;
                        }
                        var dueDate = new Date(recurrenceDate);
                        var thisMonth = format(today, 'M');
                        var thisYear = format(today, 'YYYY');
                        var thisDay = format(today, 'DD');
                        var dueMonth = format(dueDate, 'M');
                        var dueYear = format(dueDate, 'YYYY');
                        var dueDay = format(dueDate, 'DD');

                        var daysDiff = differenceInDays(
                            new Date(),
                            new dueDate
                        );
                        console.log(daysDiff);
                        if(budget.remaining>0 && daysDiff == 1) {
                            dueToday = true;
                        }
                        return dueToday;
                    }
                }
                return dueToday;
            }
        },
        dueSoonBudgetStatus(state, getters) {
            return budget => {
                var recurrenceDate;
                if(budget.recurrences && budget.recurrences.length) {
                    recurrenceDate = budget.recurrences[0].repeat_start;
                }

                var dueSoon = false;
                var dueDate = new Date(recurrenceDate);
                var today = new Date(state.current_date);
                var thisMonth = format(today, 'M');
                var thisYear = format(today, 'YYYY');
                var thisDay = format(today, 'DD');
                var dueMonth = format(dueDate, 'M');
                var dueYear = format(dueDate, 'YYYY');
                var dueDay = format(dueDate, 'DD');
                if((budget.remaining>0) && ((budget.due_in_full && (budget.recurrence_date_this_month) && (budget.recurrence_date_this_month > getters.currentDayOfMonthFormatted)) || (thisMonth == dueMonth && thisDay < dueDay && thisYear == dueYear))) {
                    dueSoon = true;
                }
                return dueSoon;
            }
        },
        budgetDaysPastDue(state, getters) {
            return budget => {
                var result = differenceInDays(
                    new Date(),
                    new Date(budget.recurrence_date_this_month)
                );
                return result;
            }
        },
		currentDayOfMonthFormatted() {
			var d = new Date();
			// https://date-fns.org/docs/format
			return format(d, 'MM/DD/YYYY');
		},
        budgetRecurrenceDateThisMonth(state) {
            return budget => {
                if(budget.recurrence_date_this_month) {
                    //return this.budget.recurrence_date_this_month;
                    var today = new Date(state.current_date);
                    var d = new Date(budget.recurrence_date_this_month);
                    var day = format(d, 'DD');
                    var month = format(today, 'MM');
                    var year = format(today, 'YYYY');
                    // https://date-fns.org/docs/format
                    var date = new Date(month + '/' + day + '/' + year);
                    return format(date, 'MM/DD/YYYY');
                } else {
                    var d = new Date(state.current_date);
                    // https://date-fns.org/docs/format
                    return format(d, 'MM/DD/YYYY');
                }
            }
        },
        budgetStatus(state, getters) {
            return budget => {
                var today = new Date(state.current_date);
                var totalDays = getLastDayOfMonth(today);
                var todayDay = format(today, 'D');
                var lastDayOfMonth = format(totalDays, 'D');
                var ratio = todayDay / lastDayOfMonth;
                var rounded = accounting.toFixed(ratio,2);
                var percentThroughMonth = rounded * 100;
                var percentSpent = getters.budgetTransactionsTotal(budget) / getters.currentMonthAmount(budget);
                var roundedPercentSpent = accounting.toFixed(percentSpent,2);
                var percentSpent = roundedPercentSpent * 100;
                if (percentSpent > percentThroughMonth) {
                    return {
                        'icon': '',
                        'label': 'Not good'
                    };
                } else {
                    return {
                        'icon': '',
                        'label': 'Good'
                    };
                }
            }
        },
        budgetTransactionsTotal(state) {
            return budget => {
                var matching, that = this, sum, grand = 0;
                state.budget_transactions.forEach(function(transaction, key) {
                    matching = transaction.subcategories.find(subcategory => Number(subcategory.id) === Number(budget.transaction_subcategory_id));
                    if (matching) {
                        sum = matching.pivot.amount;
                        grand +=sum;
                    }
                });
                return grand;
            }
        },
        currentMonthAmount(state) {
            return budget => {
                if(typeof(budget.months) !=='undefined') {
                    var today = new Date(state.current_date);
                    var month = format(today, 'M');
                    var year = format(today, 'YYYY');
                    var currentBudgetMonth = budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
                    if(currentBudgetMonth) {
                        return currentBudgetMonth.amount;
                    }
                }
            }
        },
		this_month_total_income: state => {

			var total_income = 0;

			state.this_month_transactions.forEach(function(val, key) {
				total_income +=Number(val['debit_amount']);
			});

			return total_income;

		},
        this_month_transactions: state => {

            if(state.this_month_transactions_filtered) {
				return state.this_month_transactions_filtered;
			} else {
				return state.this_month_transactions;
			}

        },
		budgets: state => {

			if(state.search_budgets) {
				return state.search_budgets;
			} else {
				return state.budgets;
			}

		},
		goals: state => {
			if(state.search_goals) {
				return state.search_goals;
			} else {
				return state.goals;
			}
		},
		expense_categories: state => {
			if (state.transaction_categories) {
                return state.transaction_categories.filter(transaction => {
                    return transaction.type === 'expense';
                });
			}
        },
            unbudgeted_expense_subcategories: (state, getters) => (categoryId, subcategoryId) => {
                var unbudgeted_expense_subcategories = [];
                if (state.expense_categories) {
                    var len = state.expense_categories.length;
                    for (var i=0; i<len; ++i) {
                        if (i in state.expense_categories) {
                            if( state.expense_categories[i].id===Number(categoryId)) {
                                var poop = state.expense_categories[i].subcategories.filter(subcategory => {

                                    //if(subcategoryId===subcategory.id) return subcategory;
                                    if(subcategoryId && subcategoryId===subcategory.id) unbudgeted_expense_subcategories.push(subcategory);

                                    var result = (state.budgets.find(budget => Number(budget.subcategory.id) === Number(subcategory.id)));
                                    //if(!result) return subcategory;
                                    if(!result) unbudgeted_expense_subcategories.push(subcategory)
                                    //unbudgeted_expense_subcategories.push(poop[0]);
                                });
                            }

                        }
                    }
                    return unbudgeted_expense_subcategories;
                }

            },

			budgetTransactionsBySubcategoryId: (state, getters) => (id) => {
				var transactions=[];
				state.accounts.forEach(function(account, key) {
					account.transactions.forEach(function(transaction, key) {
						var found = transaction.subcategories.find(subcategory => {
							return Number(subcategory.id) === Number(id);
						});
						if(found) {
                            if (transaction.type=="expense"){
                                transactions.push(transaction);
                            }
						}
					});
				});
				return transactions;
			},
			budgetBySubcategoryId: (state, getters) => (id) => {
				return state.budgets.find(budget => budget.subcategory.id === id);
			},
		total_income: state => {

			if (state.paychecks) {
				return state.paychecks.reduce(function(acc, val) {
					return acc + Number(val['debit_amount']);
				}, 0);
			}
		},
		total_expenses: state => {

			if (state.expenses) {
				return state.expenses.reduce(function(acc, val) {
					return acc + Number(val['credit_amount']);
				}, 0);
			}
		},
		total_goals_saved: state => {
			if (state.goals) {
				return state.goals.reduce(function(acc, val) {
					return 	Number(acc) + Number(val['total_allocated']);
				}, 0);
			}
		},
		total_goals: state => {
			if (state.goals) {
				return state.goals.reduce(function(acc, val) {
					return 	Number(acc) + Number(val['goal_amount']);
				}, 0);
			}
		},
        total_allocations_due_this_month: state => {
            return state.goals.reduce(function(acc, val) {
                return Number(acc) + Number(val['total_allocation_due_this_month']);
            }, 0);
        },
		total_budgeted: state => {
			if (state.budgets && state.budgets.length > 0) {
                return state.budgets.reduce(function(acc, val) {
                    if(typeof(val['months']) !=='undefined') {
                        return 	Number(acc) + Number(val['months'][0]['amount']);
                    } else if(typeof(val['most_recent_budget_month']) !=='undefined') {
                        return Number(acc) + Number(val['most_recent_budget_month']['amount']);
                    }
				}, 0);
			} else {
                return 0;
            }
		},
        ongoing_budgets: state => {
            if(state.budgets) {
                return state.budgets.filter(function(budget){return budget.due_in_full==0;});
            }
        },
        total_ongoing_budgeted: (state, getters) => {
            if (state.budgets) {
            console.log('ongoing budgeted has budgets');
                var result = getters.ongoing_budgets.reduce(function(acc, budget) {
                    if(typeof(budget.months) !=='undefined') {
            console.log('ongoing budgeted has budget months');
                        var ds = new Date(state.current_date);
                        var month = format(ds, 'M');
                        var year = format(ds, 'YYYY');
                        var date = format(ds, 'MM/01/YYYY');
                        var currentBudget = budget.months.find(budgetMonth => String(budgetMonth.month) === String(month) && String(budgetMonth.year) === String(year));
                        if(currentBudget){
                            var amount = (currentBudget.amount) ? Number(currentBudget.amount) : 0;
                            return 	Number(acc) + Number(amount);
                        }
                        return 0;
                    } else if(typeof(this.budget.most_recent_budget_month) !=='undefined') {
                        return Number(acc) + Number(budget.most_recent_budget_month.amount);
                    }

                    return Number(acc) + 0;
                }, 0);
                return result;
            }
        },
		total_ongoing_spent: (state, getters) => {

            if (state.budgets) {
                return getters.ongoing_budgets.reduce(function(acc, budget) {

                    var spent = 0;
                    state.accounts.forEach(function(account, key) {
                        account.transactions.forEach(function(transaction, key) {
                            var found = transaction.subcategories.find(subcategory => {
                                return Number(subcategory.id) === Number(budget.transaction_subcategory_id);
                            });
                            if(found) {
                                spent += Number(transaction.amount);
                            }
                        });
                    });
                    return Number(acc) + spent;
                    /*
                     *                    if(typeof(budget.months) !=='undefined') {
                     *                        var ds = new Date(state.current_date);
                     *                        var date = format(ds, 'MM/01/YYYY');
                     *                        let currentBudget = budget.months.find(budgetMonth => String(budgetMonth.date) === String(date));
                     *console.log('==> currentBudget', JSON.stringify(currentBudget));
                     *                        if(currentBudget){
                     *                            var spent = (currentBudget.spent) ? Number(currentBudget.spent) : 0;
                     *            console.log('has current month budgets' + spent);
                     *                            return 	Number(acc) + Number(spent);
                     *                        }
                     *                        return 0;
                     *                    } else if(typeof(this.budget.most_recent_budget_month) !=='undefined') {
                     *                        return Number(acc) + Number(budget.most_recent_budget_month.spent);
                     *                    }
                     *                    return Number(acc) + 0;
                     */
                }, 0);
            }
		},
        total_ongoing_remaining: (state, getters) => {
			if (state.budgets && state.budgets.length > 0) {
                var remaining = getters.total_ongoing_budgeted - getters.total_ongoing_spent;
                if(remaining < 0) {
                    remaining = 0;
                }
                return remaining;
			} else {
                return 0;
            }
        },
        bills_budgets: state => {
            if(state.budgets) {
                return state.budgets.filter(function(budget){return budget.due_in_full;});
            }
        },
		budgeted_to_go: state => {
            if(state.budgets) {
                return state.budgets.filter(function(budget){
                    if(typeof(budget.months) !=='undefined') {
                        var ds = new Date(state.current_date);
                        var date = format(ds, 'MM/01/YYYY');
                        var currentBudget = budget.months.find(budgetMonth => String(budgetMonth.date) === String(date));
                        if(currentBudget){
                            var amount = (currentBudget.amount) ? Number(currentBudget.amount) : 0;
                            var spent = (currentBudget.spent) ? Number(currentBudget.spent) : 0;
							if(amount>0) {
								return (amount - spent) > 0;
							}
                        }
                    } 
				});
            }
		},
        total_bills_budgeted: (state, getters) => {
            if (state.budgets) {
                return getters.bills_budgets.reduce(function(acc, budget) {
                    if(typeof(budget.months) !=='undefined') {
                        var ds = new Date(state.current_date);
                        var month = format(ds, 'MM');
                        // remove leading zero from months e.b. 09 => 9
                        month = parseInt(month, 10);
                        var year = format(ds, 'YYYY');
                        var date = format(ds, 'MM/01/YYYY');
                        var currentBudget = budget.months.find(budgetMonth => String(budgetMonth.month) === String(month) && String(budgetMonth.year) === String(year));
                        if(currentBudget){
                            var amount = (currentBudget.amount) ? Number(currentBudget.amount) : 0;
                            return 	Number(acc) + Number(amount);
                        }
                        return 0;
                    } else if(typeof(this.budget.most_recent_budget_month) !=='undefined') {
                        return Number(acc) + Number(budget.most_recent_budget_month.spent);
                    }
                    return Number(acc) + 0;

                }, 0);
            }
        },
		total_bills_spent: (state, getters) => {

            if (state.budgets) {
                return getters.bills_budgets.reduce(function(acc, budget) {

                    var spent = 0;
                    state.accounts.forEach(function(account, key) {
                        account.transactions.forEach(function(transaction, key) {
                            var found = transaction.subcategories.find(subcategory => {
                                return Number(subcategory.id) === Number(budget.transaction_subcategory_id);
                            });
                            if(found) {
                                spent += Number(transaction.amount);
                            }
                        });
                    });
                    return Number(acc) + spent;
                    /*
                     *                    if(typeof(budget.months) !=='undefined') {
                     *                        var ds = new Date(state.current_date);
                     *                        var date = format(ds, 'MM/01/YYYY');
                     *                        let currentBudget = budget.months.find(budgetMonth => String(budgetMonth.date) === String(date));
                     *console.log('==> currentBudget', JSON.stringify(currentBudget));
                     *                        if(currentBudget){
                     *                            var spent = (currentBudget.spent) ? Number(currentBudget.spent) : 0;
                     *            console.log('has current month budgets' + spent);
                     *                            return 	Number(acc) + Number(spent);
                     *                        }
                     *                        return 0;
                     *                    } else if(typeof(this.budget.most_recent_budget_month) !=='undefined') {
                     *                        return Number(acc) + Number(budget.most_recent_budget_month.spent);
                     *                    }
                     *                    return Number(acc) + 0;
                     */
                }, 0);
            }
		},
        total_bills_remaining: (state, getters) => {
            if (state.budgets) {
                if (state.budgets.length > 0) {
                    var remaining = getters.total_bills_budgeted - getters.total_bills_spent;
                    if(remaining < 0) {
                        remaining = 0;
                    }
                return remaining;
                } else {
                    return 0;
                }
            }
        },
		total_budgeted_spent: state => {

			if (state.budgets) {
				return state.budgets.reduce(function(acc, val) {
					return acc + Number(val['spent']);
				}, 0);
			}
		},
		total_to_be_budgeted: (state, getters) => {

			if (state.paychecks) {
				var available = state.paychecks.reduce(function(acc, val) {
					return acc + Number(val['debit_amount']);
				}, 0);
				return available - getters.total_budgeted;
			}
		},	
        
		total_budgeted_remaining: (state, getters) => {

			/*if (getters.total_budgeted && getters.total_spent) {
				return (getters.total_budgeted - getters.total_spent);
			}*/

			if (state.budgets.length > 0) {
                /*
				 *let total_budgeted = state.budgets.reduce(function(acc, val) {
				 *    return 	Number(acc) + Number(val['amount']);
				 *}, 0);
                 */

                /*
				 *let total_budgeted_spent = state.budgets.reduce(function(acc, val) {
				 *    return acc + Number(val['spent']);
				 *}, 0);
                 */
                return getters.total_budgeted - getters.total_budgeted_spent;
			} else {
                return 0;
            }
		},
        get_total_spent_in_budget: (state) => (budget) => {
            var spent = 0;
            state.accounts.forEach(function(account, key) {
                account.transactions.forEach(function(transaction, key) {
                    var found = transaction.subcategories.find(subcategory => {
                        return Number(subcategory.id) === Number(budget.transaction_subcategory_id);
                    });
                    if(found) {
                        if(found.type=="expense"){
                            if(found.pivot) {
                                spent += Number(found.pivot.amount);
                            }
                        }
                    }
                });
            });
            return spent;
        },

		pieSeries: (state, getters) => {

			var expenses = state.expenses;
			var amounts, data={}, i, expense_amount, len = expenses.length, amounts=[];
			len = (len>12) ? 12 : len;
			for (i=0; i<len; ++i) {
				if (i in expenses) {
					expense_amount = (expenses[i].credit_amount / getters.total_expenses);
					expense_amount = Number(Math.round(expense_amount+'e'+2)+'e-'+2) * 100;
					amounts.push(expense_amount);
				}
			}
			return amounts;
		},
		pieLabels: (state, getters) => {

			var expenses = state.expenses;
			var labels, data={}, i, len = expenses.length, labels=[];
			len = (len>12) ? 12 : len;
			for (i=0; i<len; ++i) {
				if (i in expenses) {
					labels.push(expenses[i].subcategory.name);
				}
			}
			return labels;
		},

		entities: (state, getters) => {
			var ordered_entities = _.orderBy(state.entities, 'name','asc');
			return ordered_entities;
		}
  	}
})
import MarketingLandingPage from './components/MarketingLandingPage.vue'
import Login from './components/Login.vue'
import ResetPassword from './components/ResetPassword.vue'
import ChangePassword from './components/ChangePassword.vue'
import Navbar from './components/Navbar.vue'
import NavbarMarketing from './components/NavbarMarketing.vue'
import Signup from './components/Signup.vue'
import Accounts from './components/Accounts.vue'
import Budgets from './components/Budgets.vue'
import EditBudget from './components/BudgetEdit.vue'
import Goals from './components/Goals.vue'
import Support from './components/Support.vue'
import Settings from './components/Settings.vue'
import ConnectedBanks from './components/ConnectedBankAccountsList.vue'
import FullScreenLoader from './components/FullPageLoadingScreen.vue'
import PageNotFound from './components/404.vue'
import Plan from './components/Plan.vue'
import Change from './components/Change.vue'
import Pay from './components/Pay.vue'

const routes = [
  { path: '/', name: 'home', components: {navbar: NavbarMarketing, default: MarketingLandingPage } },
  { path: '/login', name: 'login', component: Login },
  { path: '/password/reset', name: 'password-reset', component: ResetPassword },
  { path: '/reset-password/:token/:email', name: 'password-reset-token', component: ChangePassword },
  { path: '/signup', name: 'sign-up', component: Signup },
  { path: '/accounts', name: 'accounts', components: { navbar: Navbar, default: Accounts }, meta: { requiresAuth: true } },
  { path: '/budgets', name: 'budgets', components: { navbar: Navbar, default: Budgets }, meta: { requiresAuth: true } },
  { path: '/budgets/:id/edit', name: 'edit-budget', components: { navbar: Navbar, default: EditBudget }, meta: { requiresAuth: true } },
  { path: '/budgets/month/:month/year/:year', components: { navbar: Navbar, default: Budgets }, meta: { requiresAuth: true } },
  { path: '/goals', name: 'goals', components: { navbar: Navbar, default: Goals }, meta: { requiresAuth: true } },
  { path: '/support', name: 'support', components: { navbar: Navbar, default: Support }, meta: { requiresAuth: true } },
  { path: '/account', name: 'settings', components: { navbar: Navbar, default: Settings }, meta: { requiresAuth: true } },
  { path: '/account/plan', name: 'plan', components: { navbar: Navbar, default: Plan }, meta: { requiresAuth: true } },
  { path: '/account/plan/change', name: 'change', components: { navbar: Navbar, default: Change }, meta: { requiresAuth: true } },
  { path: '/purchase/plan/boss', name: 'bosspay', components: { navbar: Navbar, default: Pay }, meta: { requiresAuth: true } },
  { path: '/connected-banks', name: 'connected-banks', components: { navbar: Navbar, default: ConnectedBanks }, meta: { requiresAuth: true } },
  { path: '/loader', name: 'loader', component: FullScreenLoader },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
	mode: 'history',
  routes, // short for `routes: routes`
	store
})

router.beforeEach((to, from, next) => {

	if(to.meta.requiresAuth===true) {
		if(localStorage.getItem('token')===null) {
			return router.push("login");
		}
	} 

	if (to.name==='home' || to.name==='login' || to.name==='signup') {
		if(localStorage.getItem('token')!==null) {
			return router.push("accounts");
		}
	}

	return next();

})

const app = new Vue({
  	el: '#app',
	store,
	router,
    beforeCreate() {
        this.$store.commit('initialiseNotifications');
    }
});
