var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-danger",
          attrs: { type: "submit", disabled: "" }
        },
        [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
          _vm._v(" Please wait\n\t\t"),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }