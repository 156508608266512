<template>
    <div class="container" v-if='plaid_error'>
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="my-4">
                    <h3 class="card-title">{{ headline }}</h3>
                    <p class="card-text">{{ message }}</p>
                    <a href="#" class="btn btn-primary" @click="handleButton">{{ buttonText}}</a>
                </div>
            </div>
            <div class="col-2">
                <img src="/images/lock-icon.png" alt="Card image cap">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		props: ['php_plaid_error'],
		mounted: function() {
            if(this.php_plaid_error.length>0) {
                this.setPlaidError();
            } else {
                this.$store.dispatch('get_plaid_errors').then(() => {
                    // console.log(this.$store.state.plaid_error);
                });
            }
		},
		computed: {
            plaid_error() {
                return this.$store.state.plaid_error;
            },
            headline() {
                if(this.plaid_error) {
                    switch(Number(this.plaid_error.code)) {
                        case 1205:
                            return "Bank Account Locked";
						case 1215:
							return 'MFA Access Fail';
                            break;
                        default:
                            return "";
                    }
                }
            },
            message() {
                if(this.plaid_error) {
                    switch(Number(this.plaid_error.code)) {
                        case 1205:
                            return "Your bank account was recently locked. This is usually caused by failed login attempts. Each bank varies, but normally this requires you to update your bank login password to resolve. Once resolved on your bank's app or website, re-connect Budget It Up to your bank by logging in with your new password.";
						case 1215:
                            return "MFA (Multi Factor Authentication) access has changed or been revoked. To resolve click to complete MFA and follow the instructions.";
                            break;
                        default:
                            return "";
                    }
                }
            },
			buttonText() {
				switch(Number(this.plaid_error.code)) {
					case 1205:
						return 'Login';
					case 1215:
						return 'Resolve';
					default:
						return "";
				}
			},
            image() {
                return '/images/lock-icon.png';
            }
		},
		methods: {
            handleButton() {
                if(this.plaid_error) {
                    switch(Number(this.plaid_error.code)) {
                        case 1205:
				            return this.$store.commit('setToggleConnectBankAccountModal', true);
                            break;
                        case 1215:

				            return this.$store.commit('setToggleMFAModal', true);
                            break;
                        default:
                            return "";
                    }
                }
            },
			setPlaidError() {
				this.$store.commit('setPlaidError', this.php_plaid_error);
			},
		},
	}
</script>
