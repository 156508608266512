var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 15")]),
      _c("path", {
        attrs: {
          d:
            "M311.69,235.48l12.16-29.17c0-.12.09-.25.14-.37,1.9-5.57,2.77-16.3-6-21.19-8.3-4.61-21.24-4.4-32.62,10a6.46,6.46,0,0,0-.56.84s-2.69,4.65-8,4.87c-5.21-.22-7.89-4.73-8-4.87a6.46,6.46,0,0,0-.56-.84c-11.38-14.43-24.32-14.64-32.62-10-8.79,4.89-7.92,15.62-6,21.19.05.12.09.25.14.37l12.92,31-33.88,49.34V159.25H353.66a6,6,0,0,0,5.8-7.54c-.32-1.21-8.17-30.16-31.32-59.43C307.38,66,270.1,34.92,209.27,32.86l-.43-.08v-5a6,6,0,0,0-12,0v5A188.29,188.29,0,0,0,134.62,45C101.33,57.84,58,86.75,40.55,151.69a6,6,0,0,0,5.79,7.56h150.5V302.18a6,6,0,0,0,1.1,3.45,54.45,54.45,0,0,0,51.28,72.62h58.24a52.13,52.13,0,0,0,44-80.05Zm6.77-136.11a177.18,177.18,0,0,1,26.9,47.88H286.45a217.09,217.09,0,0,0-13.7-47.07c-9.44-22.39-21.89-40-36.7-52.19C269,54.72,296.61,71.91,318.46,99.37ZM208.84,45.48c47.52,19.17,62.4,85.2,65.42,101.77H208.84ZM54.34,147.25c13.85-44,42.14-74.55,84.19-90.87a173.11,173.11,0,0,1,31-8.72c-15,12.19-27.57,29.93-37.09,52.52a216.72,216.72,0,0,0-13.71,47.07Zm142.5,0H130.92c3-16.62,17.91-83,65.92-102ZM241,201.91c-.41-1.36-1.17-5.72.53-6.67,5.25-2.91,11.32-.55,17.12,6.65a22.36,22.36,0,0,0,16.94,10.55,6.25,6.25,0,0,0,.87.06h.78a6.4,6.4,0,0,0,.88-.06A22.36,22.36,0,0,0,295,201.89c5.8-7.2,11.87-9.56,17.11-6.65,1.68.94,1,5.16.53,6.69L300,232.25H253.61ZM342.62,345.46a39.6,39.6,0,0,1-35.16,20.79H249.22a42.37,42.37,0,0,1-34.93-66.35l38.21-55.65H303l38.3,60.38A39.61,39.61,0,0,1,342.62,345.46Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M277.21,272.35a34.9,34.9,0,1,0,34.9,34.9A34.94,34.94,0,0,0,277.21,272.35Zm0,57.8a22.9,22.9,0,1,1,22.9-22.9A22.93,22.93,0,0,1,277.21,330.15Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M171.84,247.25H99.34a6,6,0,0,0-6,6v42.93h-47a6,6,0,0,0-6,6v70.07a6,6,0,0,0,6,6h125.5a6,6,0,0,0,6-6v-119A6,6,0,0,0,171.84,247.25Zm-78.5,119h-41V355.82h41Zm0-22.43h-41V332h41Zm0-23.82h-41V308.18h41Zm72.5,46.25h-60.5V355.82h60.5Zm0-22.43h-60.5V332h60.5Zm0-23.82h-60.5V308.18h60.5Zm0-23.82h-60.5V284.35h60.5Zm0-23.83h-60.5v-13.1h60.5Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }