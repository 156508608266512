<template>
	<div>
    <div class="container connected-institutions" v-if='!plaid_error'>
        <div class="alert alert-primary alert-dismissible fade show" role="alert" v-if='notification'>
            <strong>{{ notification.title }}</strong> {{ notification.message }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click='setNotificationUser(notification.id)'>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Connected Institutions</h2>
            </div>
        </div>
        <div class="row" v-if='connected_banks.length==0'>
            <div class="col-12">
                <h5>You have no connected banks or credit cards, click connect below to connect one now.</h5>
            </div>
        </div>
        <div class="row header-row">
            <div class="col-5">
                <span>Bank Name</span>
            </div>
            <div class="col-5">
                <span>No.of accounts</span>
            </div>
        </div>
        <connected-bank
                 v-for="(bank, index) in connected_banks"
                 :bank="bank" :is='bank.mode'
                 :key="bank.index"
                 @remove="remove(bank, index)"
                 ></connected-bank>
        <button class='btn btn-primary' @click='showModal'>Connect</button>
    </div>
        <plaid-error-card v-else></plaid-error-card>
        <connect-bank-account-modal></connect-bank-account-modal>
        </div>
</template>
<script>

    export default {
		mounted: function() {
			if(this.$store.state.isLoggedIn || localStorage.getItem("token")){
				this.$store.dispatch('get_connected_banks').then(() => {
					// more stuff
				});
			}
		},
		computed: {
            notification() {
                var route = this.$router.currentRoute;
                var notifications = this.$store.state.notifications;
                if(notifications) {
                    var notification = notifications.find(notification => notification.url === route.name);
                    if(notification) {
                        return notification;
                    }
                }
                return false;
            },
            plaid_error() {
                return this.$store.state.plaid_error;
            },
			connected_banks() {
				return this.$store.state.connected_banks;
			},

		},
		methods: {
            setNotificationUser(notificationId) {
				this.$store.dispatch('set_notification_user', {'notificationId' : this.notification.id}).then(() => {
					// more stuff
				});
            },
			setConnectedBanks(connectedBanks) {
				this.$store.commit('setConnectedBanks', connectedBanks);
			},
			showModal() {
				return this.$store.commit('setToggleConnectBankAccountModal', true);
			}
		},
	}
</script>
