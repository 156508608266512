<template>
	<div class="card-box">
		<h4 class="mt0">UNCATEGORIZED TRANSACTIONS ({{ this_month_uncategorized_transactions_count }})</h4>
		<div class="row col-headers dlines">
			<div class="col-md-1">
				Date
			</div>
			<div class="col-md-4">
				Type
			</div>
			<div class="col-md-3">
				Entity
			</div>

			<div class="col-md-2 text-right">
				Amount
			</div>
			<!--<div class="col-md-2">
				Cash Out
				</div>-->
		</div>
		<transactions :account_id="account.id" ></transactions>
	</div>
</template>
<script>
    export default {
		computed: {
			account: function () {
				return this.$store.state.account;
			},
			this_month_uncategorized_transactions_count() {
				return this.$store.state.uncategorized_transactions.length;
			}
		}
	}
</script>
