<template>
	<div class="h-100">
		<div class="padded">
			<div class="container">
				<div id="app">
					<div class="row">
						<div class="col-md-12">
							<h3>Support</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

    export default {
		methods: {
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
		},
		mounted: function() {


		},
		computed: {
			userName: function () {
				return 'test';
			},
			userEmail: function () {
				return 'test';
			},
			subscriptionPlanName: function () {
				return 'test';
			},
			subscriptionAmount: function () {
				return 'test';
			},
			subscriptionStartDate: function () {
				return 'test';
			},
			subscriptionNumberOfAvailableConnectedBanks: function () {
				return 'test';
			}
		},
	}
</script>
