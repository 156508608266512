class Register {

	hasPromo() {
        var promoCodeInput = document.getElementById('promoCheckbox');
		if(typeof(promoCodeInput)==='object') {
			var promoChecked = promoCodeInput.checked;
			if(promoChecked) {
				return true;
			}
		}
		return false;
	}

    isBudgetBossPlan() {

        var radios = document.getElementsByName('plan');
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                if(radios[i].value=='budget_master') {
                    return true;
                }
            }
        }
        return false;
    }

    validateLogin() {

        var validator = new FormValidator();
        var emailInput = document.getElementById('email');
        var emailContainer = document.getElementById('email_container');
        var emailValue = emailInput.value;
        var validEmail = validator.validate(emailInput, emailContainer, validator.emailRule, emailValue, 'Invalid email address.','email');

        var passwordInput = document.getElementById('password');
        var passwordContainer = document.getElementById('password_container');
        var passwordValue = passwordInput.value;
        var validPassword = validator.validate(passwordInput, passwordContainer, validator.passwordRule, passwordValue, 'Password must be at least 8 characters with one uppercase, one lowercase, and a number.', 'password');

        var passwordConfirmInput = document.getElementById('password_confirmation');
        var passwordConfirmContainer = document.getElementById('password_confirm_container');
        var passwordConfirmValue = passwordConfirmInput.value;
        var passwords = new Array(passwordValue,  passwordConfirmValue);
        var validPasswordConfirm = validator.validate(passwordConfirmInput, passwordConfirmContainer, validator.passwordConfirmRule, passwords, 'Password confirm does not match password.','password confirm');

            if(validEmail && validPassword && validPasswordConfirm) {
                return true;
            } else {
                return false;
            }

    }

    paymentIsValid() {

            var validator = new FormValidator();
            var firstNameInput = document.getElementById('first_name');
            var firstNameContainer = document.getElementById('first_name_container');
            var firstNameValue = firstNameInput.value;

            var validFirstName = validator.validate(firstNameInput, firstNameContainer, validator.validateName, firstNameValue, 'First name invalid.','first name');

            var lastNameInput = document.getElementById('last_name');
            var lastNameContainer = document.getElementById('last_name_container');
            var lastNameValue = lastNameInput.value;

            var validLastName = validator.validate(lastNameInput, lastNameContainer, validator.validateName, lastNameValue, 'Last name invalid.','last name');

            var cardNumberInput = document.getElementById('card_number');
            var cardNumberContainer = document.getElementById('card_number_container');
            var cardNumberValue = cardNumberInput.value;

            var validCardNumber = validator.validate(cardNumberInput, cardNumberContainer, validator.validateCardNumber, cardNumberValue, 'Credit card number invalid.','credit card number');

            var cardSecurityCodeInput = document.getElementById('card_csc');
            var cardSecurityCodeContainer = document.getElementById('card_csc_container');
            var cardSecurityCodeValue = cardSecurityCodeInput.value;

            var validCardSecurityCode = validator.validate(cardSecurityCodeInput, cardSecurityCodeContainer, validator.validateCardSecurityCode, cardSecurityCodeValue, 'Credit card number invalid.','card security code');

            var cardExpirationInput = document.getElementById('card_expiration');
            var cardExpirationContainer = document.getElementById('card_expiration_container');
            var cardExpirationValue = cardExpirationInput.value;

            var validCardExpiry = validator.validate(cardExpirationInput, cardExpirationContainer, validator.validateCardExpiry, cardExpirationValue, 'Credit card expiration invalid.','card expiration');

            var postalInput = document.getElementById('postal');
            var postalContainer = document.getElementById('postal_container');
            var postalValue = postalInput.value;

            var validPostal = validator.validate(postalInput, postalContainer, validator.validatePostal, postalValue, 'Zipcode is invalid.','zipcode');

            if(validFirstName && validLastName && validCardNumber && validCardSecurityCode && validCardExpiry && validPostal) {
                return true;
            } else {
                return false;
            }

    }
}
export default Register
