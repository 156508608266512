var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 7")]),
      _c("path", {
        attrs: {
          d:
            "M32.67,82.33V316.67a6,6,0,0,0,6,6H65.5v15.41a35.25,35.25,0,0,0,70.5,0V322.67H262.5v15.41a35.25,35.25,0,0,0,70.5,0V322.67h28.33a6,6,0,0,0,6-6V82.33a55.72,55.72,0,0,0-55.66-55.66H88.33A55.72,55.72,0,0,0,32.67,82.33ZM124,338.08a23.25,23.25,0,0,1-46.5,0V322.67H124Zm197,0a23.25,23.25,0,0,1-46.5,0V322.67H321ZM355.33,82.33V310.67H44.67V82.33A43.71,43.71,0,0,1,88.33,38.67H311.67A43.71,43.71,0,0,1,355.33,82.33Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M82,89.5v64a12.18,12.18,0,0,0,12.17,12.17H303.5a12.18,12.18,0,0,0,12.17-12.17v-64A12.18,12.18,0,0,0,303.5,77.33H94.17A12.18,12.18,0,0,0,82,89.5Zm122.63-.17H303.5a.18.18,0,0,1,.17.17v64a.18.18,0,0,1-.17.17H204.63ZM94,89.5a.18.18,0,0,1,.17-.17h98.46v64.34H94.17a.18.18,0,0,1-.17-.17Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M124.25,204.46a29.13,29.13,0,1,0,29.13,29.12A29.15,29.15,0,0,0,124.25,204.46Zm0,46.25a17.13,17.13,0,1,1,17.13-17.13A17.14,17.14,0,0,1,124.25,250.71Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M271.4,204.46a29.13,29.13,0,1,0,29.13,29.12A29.15,29.15,0,0,0,271.4,204.46Zm0,46.25a17.13,17.13,0,1,1,17.13-17.13A17.14,17.14,0,0,1,271.4,250.71Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }