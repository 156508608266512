var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 6")]),
      _c("path", {
        attrs: {
          d:
            "M26,95.72c-9.46,23.33-10.4,47.91-10.28,64.36.24,34.17,11.08,68,31.33,97.74,30.13,44.28,80.83,80.65,150.69,108.08a5.89,5.89,0,0,0,2.29.46,6,6,0,0,0,2.39-.5c69.81-27.43,120.48-63.78,150.59-108a182.05,182.05,0,0,0,25.63-55.65,6.19,6.19,0,0,0,.34-1.28,168.29,168.29,0,0,0,5.36-40.81c.12-16.45-.82-41-10.28-64.36-10.72-26.44-30-45.14-57.29-55.56-25.34-9.69-54.26-8.48-83.63,3.49A164.54,164.54,0,0,0,200,61.88a164.54,164.54,0,0,0-33.11-18.23c-29.37-12-58.29-13.18-83.63-3.49C56,50.58,36.69,69.28,26,95.72ZM200,353.9C93.32,311.49,51.26,254.58,35.84,208.4H102a6,6,0,0,0,5.43-3.45l28.49-60.7,38,82.82a6,6,0,0,0,10.3,1l24-32.75L240.54,255a6,6,0,0,0,5.27,3.13h.35a6,6,0,0,0,5.22-3.74l34.83-86.22L306.64,203a6,6,0,0,0,5.18,3h53.12C350.18,252.6,308.54,310.75,200,353.9ZM87.54,51.37a87.06,87.06,0,0,1,31.27-5.67C160,45.7,194.32,72.55,196.13,74a6,6,0,0,0,7.81-.06c3.18-2.51,55.51-42.81,108.52-22.56,54.19,20.7,60.11,71.91,59.85,108.63a155,155,0,0,1-4.07,34h-53L290.4,151.59a6,6,0,0,0-10.74.78L245,238.12l-30.87-56.81a6,6,0,0,0-10.11-.68l-23.52,32.14-39.09-85.28A6,6,0,0,0,136,124h0a6,6,0,0,0-5.43,3.45l-32.37,69H32.34A154.74,154.74,0,0,1,27.69,160C27.43,123.28,33.35,72.07,87.54,51.37Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }