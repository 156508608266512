<template>
	<div class="account-progress">
		<h5 class="title1">Overview</h5>
		<h5 class="title2"><span v-if='activeTab=="budgets"'>On-going Expenses</span><span v-else-if='activeTab=="bills"'>Bills</span>
        <span v-else-if='activeTab=="all"'>Combined</span></h5>
		<ul class="stats-group" v-if='activeTab=="budgets"'>
			<li class="list-group-item mt-4 mb-4">
				Planned
				<span class="white">{{ formatMoney(total_ongoing_budgeted) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Spent
				<span>{{ formatMoney(total_ongoing_spent) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Remaining
				<span>{{ formatMoney(total_ongoing_remaining) }}</span>
			</li>
		</ul>
		<ul class="stats-group" v-else-if='activeTab=="bills"'>
			<li class="list-group-item mt-4 mb-4">
				Total
				<span class="white">{{ formatMoney(total_bills_budgeted) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Paid
				<span>{{ formatMoney(total_bills_spent) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Unpaid
				<span>{{ formatMoney(total_bills_remaining) }}</span>
			</li>
		</ul>
		<ul class="stats-group" v-else-if='activeTab=="all"'>
			<li class="list-group-item mt-4 mb-4">
				Total
				<span class="white">{{ formatMoney(total_combined) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Spent
				<span>{{ formatMoney(total_combined_spent) }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4">
				Remaining
				<span>{{ formatMoney(total_combined_remaining) }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
    export default {
		mounted: function() {
//            if (!this.$store.state.current_date) {
  //          alert('getting date from budgeted to go');
    //            this.$store.dispatch('get_current_date');
      //      }
            //console.log('==> this.$store.getters.total_ongoing_budgeted', JSON.stringify(this.$store.getters.total_ongoing_budgeted));
        },
		computed: {
			activeTab() {
				return this.$store.state.active_budgets_tab;
			},
			total_spent () {
				return this.round(this.$store.getters.total_budgeted_spent,2);
			},
            total_combined () {
                return this.$store.getters.total_ongoing_budgeted + this.$store.getters.total_bills_budgeted;
            },
			total_budgeted_remaining () {
				return this.round(this.$store.getters.total_budgeted_remaining,2);
			},
            total_combined_remaining () {
                return this.$store.getters.total_bills_remaining + this.$store.getters.total_ongoing_remaining;
            },
            total_combined_spent () {
                return this.$store.getters.total_ongoing_spent + this.$store.getters.total_bills_spent;
            },
			total_to_be_budgeted () {
				return this.round(this.$store.getters.total_to_be_budgeted,2);
			},
			percent_budgeted_spent() {
				var percent = 0;
				percent = (this.total_budgeted_no_animation>0) ? this.round((this.$store.getters.total_spent / this.total_budgeted_no_animation) * 100,0) : 0;
				return percent;
			},
            total_ongoing_budgeted() {
				return this.$store.getters.total_ongoing_budgeted;
            },
            total_ongoing_spent() {
				return this.$store.getters.total_ongoing_spent;
            },
			total_ongoing_remaining () {
				return this.$store.getters.total_ongoing_remaining;
			},
            total_bills_budgeted() {
				return this.$store.getters.total_bills_budgeted;
            },
            total_bills_spent() {
				return this.$store.getters.total_bills_spent;
            },
			total_bills_remaining () {
				return this.$store.getters.total_bills_remaining;
			},
		},
		methods: {
            formatMoney(amount) {
                return accounting.formatMoney(amount, '$', 0 ,"", "");
            },
			updateBudgetProgress() {
				$("#test-circle3").empty();
				var that = this;
				$("#test-circle3").circliful({
					animation: 1,
					animationStep: 6,
					foregroundBorderWidth: 5,
					backgroundBorderWidth: 1,
					percent: 	that.percent_budgeted_spent,
					iconColor: '#3498DB',
					icon: 'f09d',
					iconSize: '40',
					iconPosition: 'middle'
				});
			},
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			budgetTotal () {
                if(this.$store.state.budgets && this.$store.state.budgets.length) {
                    var budgets = this.$store.state.budgets;
                    budgets[budgets.length-1].amount += 1000;
                    this.$store.commit('setBudgets', budgets);
                }

			},
            monthStringFromDate(date) {
                var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
               if(typeof(date)==='object') {
                   return  monthNames[date.getMonth()];
               }
            }
		}
	}
</script>
<!--<div class="row">
    <div class="col-md-6">
    <span class="text-muted text-left little">Total Income</span>
    </div>
    <div class="col-md-6">
    <span class="text-right dollars">${{ total_income }}</span>
    </div>
    </div>-->
		<!--<h4 class="mt0 text-center">{{ month }} {{ year }}</h4>-->
        <!--
           -<div class="row">
           -    <div class="col-md-12">
           -        <h1>${{ totalBudgetedT }}</h1>
           -        <span class="text-muted text-left little">Total Budgeted</span>
           -    </div>
           -</div>
           -<div class="row">
           -    <div class="col-md-12">
           -        <h3>$<span v-if="totalSpentT<0">-</span>{{ totalSpentT }}</h3>
           -        <span class="text-muted text-left little">Total Spent</span>
           -    </div>
		   -</div>
           -<div class="row">
           -    <div class="col-md-12">
           -        <h3>$<span v-if="total_budgeted_remaining<0">-</span>{{ total_budgeted_remaining }}</h3>
           -        <span class="text-muted text-left little">Total Budgeted Remaining</span>
           -    </div>
		   -</div>
           -->

				<!--<div class="row">
					<div class="col-md-6">
						<span class="text-muted text-left little">Needs Budgeted</span>
					</div>
					<div class="col-md-6">
						<span class="text-right dollars">${{ total_needs_budgeted }}</span>
					</div>
				</div>-->
			<!--	<div class="row">
					<div class="col-md-6">
						<span class="text-muted text-left little">Spent</span>
					</div>
					<div class="col-md-6">
						<span class="text-right dollars">$-{{	totalSpentT }}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<span class="text-muted text-left little">Left</span>
					</div>
					<div class="col-md-6">
						<span class="text-right dollars" v-bind:class="{'':total_budgeted_remaining>0, 'credit': total_budgeted_remaining<0}">${{ total_budgeted_remaining }}</span>
					</div>
				</div>-->
				<!--<p class="text-muted little">Inflows</p>
				<h1 v-bind:class="{'debit':total_budgeted_remaining>0, 'credit': total_budgeted_remaining<0}">${{ total_budgeted_remaining }}</h1>-->
		<!--<div class="row">
			<div class="col-lg-12">
				<div class="text-center">
					<div id="test-circle3"></div>
				</div>
			</div>
		</div>-->
