<template>
    <div class="row boxshadow-1 institution-row" >
        <div class="col-6 col-lg-5">
            <span class="institution-name">{{ bank.name }}</span>
            <!--
                -<div class="form-group">
                -    <input type="text" class="form-control" id="editInstitutionName" placeholder="Wells Fargo">
                -</div>
            -->
        </div>
        <div class="col-6 col-lg-3 num-accounts-col">
            <span>{{ bank.accounts.length }} accounts</span>
        </div>
        <div class="col-12 col-lg-4 buttons-cont">
            <button class="btn btn-outline-edit btn-box-shadow btn-wide" @click="editAccount">Edit</button>
            <button class="btn btn-cancel-grey btn-box-shadow btn-wide">Cancel</button>
            <button class="btn btn-danger btn-box-shadow btn-wide">Remove</button>
        </div>
	</div>
</template>
<script>
export default {
	props:["bank"],
	computed: {
	},
	methods: {
		editAccount() {
			this.bank.mode = 'edit-connected-bank';
		},
	}
}
</script>
