var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row boxshadow-1 institution-row edit-enabled" }, [
      _c("div", { staticClass: "col-6 col-lg-5" }, [
        _c("span", { staticClass: "institution-name" }, [
          _vm._v(_vm._s(_vm.bank.name))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.bank.name,
                expression: "bank.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "editInstitutionName",
              placeholder: "Bank/credit card name"
            },
            domProps: { value: _vm.bank.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.bank, "name", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg-3 num-accounts-col" }, [
        _c("span", [_vm._v(_vm._s(_vm.bank.accounts.length) + " accounts")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-lg-4 buttons-cont" },
        [
          _c(_vm.removeBankButtonMode, {
            tag: "remove-connected-bank-button",
            attrs: { bank: _vm.bank }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row delete-note-row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", { staticClass: "text-right" }, [
          _c("span", { staticClass: "green" }, [_vm._v("Note:")]),
          _vm._v(
            " Deleting a connection will stop transactions and data from being imported."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }