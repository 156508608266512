<template>
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" v-bind:data-delay='toast.delay' v-bind:id="index">
        <!--
           -<div class="toast-header">
           -    <img src="" class="rounded mr-2" alt="">
           -    <strong class="mr-auto">{{ toast.title }}</strong>
           -    <small class="text-muted">{{ toast.when }}</small>
           -    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
           -        <span aria-hidden="true">&times;</span>
           -    </button>
           -</div>
           -->
        <div class="toast-body">
               <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
               </button>
            {{ toast.message }}
        </div>
    </div>
</template>
<script>
export default {
	props:["toast","index"],
    mounted: function() {
        $('#' + this.index).toast('show');
    },
}
</script>
