<template>
	<div class="main-wrap page-404">
		<img src="/images/404-middle.png" class="middle">
		<img src="/images/space-man.png" class="spaceman">
	</div>
</template>
<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	mounted: function() {
		document.getElementById('app').className='h-100';
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
		document.getElementById('app').className='';
	},
}
</script>
