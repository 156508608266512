var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("8")]),
      _c("path", {
        attrs: {
          d:
            "M253.43,104.1h-8.89c-1.79-8.89-8.44-14.8-16.77-14.8s-13.65,4.52-18,15.12c-3.52,8.41-5.8,11-9.75,11-3.29,0-7.12-2.34-7.12-8.92A21.75,21.75,0,0,1,196,95.15a1,1,0,0,0,.07-.84,1,1,0,0,0-.6-.59l-6.18-2.27a1,1,0,0,0-1.21.43,25.32,25.32,0,0,0-3.36,11.61H176.6a1,1,0,0,0-1,1v5.87a1,1,0,0,0,1,1h8.57c1.74,8.62,7.91,14.14,15.92,14.14,9.54,0,13.9-7.76,17.2-16.12,2.86-7.06,5.82-9.94,10.2-9.94,4.81,0,8.17,4.17,8.17,10.15a24.12,24.12,0,0,1-3.9,12.87,1,1,0,0,0-.12.87,1,1,0,0,0,.61.63l6.32,2.27a1,1,0,0,0,.34,0,1,1,0,0,0,.81-.42c2.37-3.35,4-8.71,4.28-13.88h8.43a1,1,0,0,0,1-1V105.1A1,1,0,0,0,253.43,104.1Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M292,41.13H138.05a32.13,32.13,0,0,0,0,64.26,5.59,5.59,0,0,0,1.37-.18v10.7c-6.95,3.46-21,13.69-34.78,43C98.93,171,93.88,180.7,89.82,188.46c-8.69,16.61-13.93,26.63-13.93,38.74,0,8.26,5,19.18,10.84,31.83,6.7,14.55,14.29,31,14.34,44.34-1.12,21.56-7,43.09-7,43.3s-.07.29-.1.43l-7.25.77a5.52,5.52,0,0,0,.58,11,4,4,0,0,0,.59,0l117-12.46a5.51,5.51,0,1,0-1.17-11l-7.91.84c1.09-6.64,10.15-20.24,15.71-28.57,2.52-3.79,4.7-7.06,6.3-9.76,7-11.8,10.69-27.94,10.69-46.68,0-8.27.69-18.23,1.65-28.05h59.25a5.51,5.51,0,0,0,5.52-5.51V105.25A32.12,32.12,0,0,0,292,41.13ZM224,183.65c-.9,6-2.36,16.13-3.67,27.45h0s-2.88,31.8-2.88,40.14c0,16.76-3.17,31-9.15,41.06-1.45,2.45-3.66,5.76-6,9.27-10,15-17.65,27.17-17.66,35.87l-79,8.41a238.57,238.57,0,0,0,6.48-42.07v-.28c0-15.73-8.15-33.45-15.34-49.08-5.06-11-9.83-21.37-9.83-27.22,0-9.4,4.5-18,12.67-33.63,4.11-7.86,9.22-17.64,15-30,9.4-20,18.73-29.85,24.8-34.54v88.67c0,.07,0,.13,0,.2a1.62,1.62,0,0,0,0,.22s0,.08,0,.12v.05c.69,8,7.31,19.41,22.86,18.25,6.68-.5,13.41-4.57,18.51-11.92l.1-.16c.28-.4.56-.82.83-1.25.12-.2.25-.4.36-.6a49.56,49.56,0,0,0,4.63-10.42h0c.53-1.71,1-3.52,1.39-5.41a.65.65,0,0,1,0-.1A72.39,72.39,0,0,0,189.54,192c0-21.18,8.6-37.15,20-37.15a12.6,12.6,0,0,1,10,4.45C223.92,164.43,225.54,173.29,224,183.65Zm59.87,28.52H231.28c1.28-11.07,2.71-21,3.6-26.87,2.07-13.74-.41-25.52-7-33.16a23.52,23.52,0,0,0-18.36-8.27c-18.27,0-31,19.81-31,48.17a54.43,54.43,0,0,1-3.15,19.29v0a27,27,0,0,1-8.22,11.84,11,11,0,0,1-5.64,2.33c-4.06.31-6.63-.8-8.26-2.33-3.44-4.64-2.78-13.43-2.78-13.43v-131H283.84Zm11-118V78.78h6.83a5.52,5.52,0,0,0,0-11H133.11a5.52,5.52,0,0,0,0,11h6.31V94.54a5.59,5.59,0,0,0-1.37-.18,21.1,21.1,0,0,1,0-42.2H292a21.09,21.09,0,0,1,2.89,42Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M162,173.57a5.51,5.51,0,0,0-5.51,5.51v22.83a5.52,5.52,0,0,0,11,0V179.08A5.52,5.52,0,0,0,162,173.57Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M182.11,222.59c-.11.2-.24.4-.36.6-.27.43-.55.85-.83,1.25Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }