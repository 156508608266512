var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      { staticClass: "masthead container-fluid container-fluid-90" },
      [
        _c("div", { staticClass: "intro-text row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: "images/logo.png" }
              }),
              _vm._v(" "),
              _c("h2", {}, [_vm._v("Create budgets.")]),
              _vm._v(" "),
              _c("h2", { staticClass: "h-green" }, [
                _vm._v("Track your spending.")
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-primary btn-box-shadow btn-primary-gradiend btn-lg d-none d-md-inline-block",
                  attrs: { to: { name: "sign-up" } }
                },
                [_vm._v("\n\t\t\t\t\t\tJoin for Free\n\t\t\t\t\t")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "green-underline" }, [
                _vm._v(
                  "\n                    Introducing Budget It Up, the user-friendly budgeting platform designed to help you take control of your finances. Our streamlined interface makes it easy to track your spending, set budgets, and achieve your savings goals. With Budget It Up, you'll always know exactly how much money you have available to spend on the things that matter most to you.\n                    "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "green-underline" }, [
                _vm._v(
                  "\n                    Say goodbye to financial stress and hello to a brighter financial future with Budget It Up. Our powerful tools and intuitive interface make managing your money a breeze. Join the Budget It Up community today and start reaching your financial goals like a pro!\n                    "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "green-underline" }, [
                _vm._v(
                  "\n                    Sign up now and experience the power of effortless financial management. With Budget It Up, your financial dreams are within reach.\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-primary btn-box-shadow btn-lg d-inline-block d-md-none",
                  attrs: { to: { name: "sign-up" } }
                },
                [
                  _vm._v(
                    "\n                        Join for Free\n                    "
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "container-fluid", attrs: { id: "get-started" } },
      [
        _c("h2", { staticClass: "h1-size" }, [_vm._v("Get started today")]),
        _vm._v(" "),
        _c("p", [_vm._v("Budget It Up helps you save more money.")]),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "btn btn-light", attrs: { to: { name: "sign-up" } } },
          [_vm._v("\n\t\t\t\tJoin For Free\n\t\t\t")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass: "container-fluid container-fluid-90",
        attrs: { id: "intro-section" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-10 offset-sm-1" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-sm-12 col-md-5 order-md-last" }, [
                  _c(
                    "h3",
                    { staticClass: "h1-size text-center text-md-left" },
                    [_vm._v("Categories? Yup.")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center text-md-left" }, [
                    _vm._v(
                      "Import your bank transactions and easily categorize your spending."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-7 order-md-first" },
                  [
                    _c("img", {
                      staticClass: "img-fluid rounded float-md-right",
                      attrs: { src: "images/screenshot1.jpg" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-sm-12 col-md-5" }, [
                  _c(
                    "h3",
                    { staticClass: "h1-size text-center text-md-right" },
                    [_vm._v("Budget it up.")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center text-md-right" }, [
                    _vm._v("Create budgets for each category of spending.")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 col-md-7" }, [
                  _c("img", {
                    staticClass: "img-fluid rounded",
                    attrs: { src: "images/screenshot2.jpg" }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-sm-12 col-md-5 order-md-last" }, [
                  _c(
                    "h3",
                    { staticClass: "h1-size text-center text-md-left" },
                    [_vm._v("Saving Goals")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center text-md-left" }, [
                    _vm._v(
                      "Create savings goals and keep track of your progress."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-7 order-md-first" },
                  [
                    _c("img", {
                      staticClass: "img-fluid rounded float-md-right",
                      attrs: { src: "images/screenshot3.jpg" }
                    })
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "container-fluid", attrs: { id: "save-section" } },
      [
        _c("h3", { staticClass: "h1-size" }, [_vm._v("Save")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "A Budget It Up plan will help you to grow your savings. Budget It Up will give you a picture of your monthly income versus your monthly spending and expenses, to calculate your disposable income. You can determine which expenses to reduce or eliminate, and start budgeting for monthly savings."
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-connector" }, [
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: "images/piggy.png" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass: "container-fluid container-fluid-90",
        attrs: { id: "how-works" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "h1-size" }, [_vm._v("How it works")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("img", { attrs: { src: "images/capital-icon.png" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("h4", [_vm._v("Import transcations")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Import your transactions directly from your bank")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("img", { attrs: { src: "images/car-pen-icon.png" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("h4", [_vm._v("Add Recurring Bills and Income")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "You get paid on the 1st and 15th, and your rent, utilities, and phone are due on..."
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("img", { attrs: { src: "images/report-icon.png" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("h4", [_vm._v("Categorize Transaction")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Attach categories to income and expenses, get a picture of your spending habits."
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("img", { attrs: { src: "images/statistics-icon.png" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("h4", [_vm._v("Build Budgets & Save")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Build categorized budgets to provide spending guidance so you don't overspend."
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "main-footer container-fluid" }, [
      _c(
        "a",
        { staticClass: "backtotop", attrs: { href: "", id: "backtotop" } },
        [_c("img", { attrs: { src: "images/arrow-up-icon.png" } })]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "copyright-msg" }, [
        _vm._v("Ⓒ 2018 Budget It Up, LLC All Rights Reserved")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }