var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("6")]),
      _c("path", {
        attrs: {
          d:
            "M262.75,71H95.44A23.11,23.11,0,0,0,72.36,94.08V305.92A23.11,23.11,0,0,0,95.44,329H262.75a23.11,23.11,0,0,0,23.08-23.08V269.79h28.93a12.9,12.9,0,0,0,12.88-12.88V145.24a12.9,12.9,0,0,0-12.88-12.88H285.83V94.08A23.11,23.11,0,0,0,262.75,71Zm13.1,234.92A13.12,13.12,0,0,1,262.75,319H95.44a13.12,13.12,0,0,1-13.1-13.11V94.08A13.12,13.12,0,0,1,95.44,81H262.75a13.12,13.12,0,0,1,13.1,13.11v38.28H247.31a12.9,12.9,0,0,0-12.88,12.88V256.91a12.9,12.9,0,0,0,12.88,12.88h28.54Zm41.81-160.68V256.91a2.9,2.9,0,0,1-2.9,2.9H247.31a2.9,2.9,0,0,1-2.9-2.9V145.24a2.9,2.9,0,0,1,2.9-2.9h67.45A2.9,2.9,0,0,1,317.66,145.24Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M214.49,113.82H109.59a5,5,0,0,0,0,10h104.9a5,5,0,0,0,0-10Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M214.49,156.33H109.59a5,5,0,0,0,0,10h104.9a5,5,0,0,0,0-10Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M214.49,274.4H109.59a5,5,0,0,0,0,10h104.9a5,5,0,0,0,0-10Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M261.63,178.37h37a14.2,14.2,0,0,0,14.19-14.18v-.42a14.2,14.2,0,0,0-14.19-14.19h-37a14.2,14.2,0,0,0-14.18,14.19v.42A14.19,14.19,0,0,0,261.63,178.37Zm-4.21-14.6a4.22,4.22,0,0,1,4.21-4.22h37a4.21,4.21,0,0,1,4.21,4.22v.42a4.21,4.21,0,0,1-4.21,4.21h-37a4.22,4.22,0,0,1-4.21-4.21Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M131.76,243.79a24.53,24.53,0,0,1-13.09-4,1,1,0,0,0-.87-.12,1,1,0,0,0-.63.61l-2.3,6.42a1,1,0,0,0,.36,1.15c3.4,2.41,8.86,4.08,14.12,4.35v8.59a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1v-9c9-1.82,15.05-8.57,15.05-17s-4.59-13.86-15.36-18.27c-8.56-3.59-11.17-5.91-11.17-9.93,0-3.34,2.38-7.25,9.08-7.25a22.13,22.13,0,0,1,11.53,3.17,1,1,0,0,0,.84.07,1,1,0,0,0,.59-.6l2.31-6.28a1,1,0,0,0-.44-1.21A25.67,25.67,0,0,0,138,191v-8.31a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1v8.73c-8.76,1.76-14.37,8-14.37,16.17,0,9.69,7.87,14.12,16.37,17.47,7.19,2.91,10.12,5.92,10.12,10.39C142.1,240.36,137.85,243.79,131.76,243.79Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }