var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-5")]),
      _c("path", {
        attrs: {
          d:
            "M362.43,179.94c-6.55-28.65-27.54-48.59-56.55-54a65.34,65.34,0,0,0-39.58-44c-19.36-7.55-40.25-5.4-58,5.84-24.41-17.94-62.59-19.42-94.74-3.22-31.7,16-49.75,45.31-48.87,79.05-16.74,9.05-27.15,24.72-28.77,43.54-1.82,21.18,7.84,42.32,24.62,53.86,16.17,11.11,36.61,12.12,57.92,2.95,16.62,15.07,43.06,23.73,70.77,23.73a130.9,130.9,0,0,0,16.84-1.09,74.3,74.3,0,0,0,139.22-46.49C361.13,228.2,368,204.39,362.43,179.94ZM272,314.74a62.45,62.45,0,1,1,62.31-62.45A62.44,62.44,0,0,1,272,314.74Zm0-136.9A74.45,74.45,0,0,0,201.25,275C171,278,140,269.22,124,252.59a6,6,0,0,0-6.94-1.23c-18.64,9.05-36.3,8.95-49.74-.28C54,242,46.41,225.09,47.87,208.14c.79-9.25,5.17-26.17,25.49-35.5a6,6,0,0,0,3.48-5.87C74.68,135.88,90,109.81,119,95.24c29.6-14.92,64.62-13.07,85.17,4.5a6,6,0,0,0,7.42.29c15.15-11,33.52-13.54,50.39-7a53.38,53.38,0,0,1,33.15,39.31,6,6,0,0,0,5.07,4.76c26,3.55,44.88,20.54,50.58,45.47,4,17.31.32,34.49-8.85,44.4A74.44,74.44,0,0,0,272,177.84Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M272,246.18c-5.41,0-10-3.48-10-7.6s4.58-7.6,10-7.6c5,0,9.23,2.83,9.9,6.59a6,6,0,0,0,11.81-2.12c-1.38-7.72-7.62-13.73-15.71-15.74V216a6,6,0,1,0-12,0v3.68c-9.21,2.34-16,9.9-16,18.86,0,10.81,9.87,19.6,22,19.6,5.42,0,10,3.48,10,7.6s-4.57,7.61-10,7.61c-5,0-9.33-3-9.92-6.73a6,6,0,0,0-11.86,1.85c1.22,7.8,7.6,14,15.78,16.12v3.92a6,6,0,0,0,12,0v-3.91c9.22-2.34,16-9.9,16-18.86C294,255,284.12,246.18,272,246.18Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }