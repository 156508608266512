var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errors.length > 0
    ? _c(
        "div",
        { staticClass: "alert alert-danger", attrs: { role: "alert" } },
        [
          _c(
            "ul",
            { staticClass: "errors-list" },
            _vm._l(_vm.errors, function(error) {
              return _c("li", [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }