<template>
			
			<!-- Navigation -->
			<nav class="navbar navbar-expand fixed-top navbar-main-page" id="mainNav">
				<div class="container-fluid container-fluid-90">

				<router-link :to="{ name: 'home' }" class="navbar-brand js-scroll-trigger">
						<img src="images/logo.png" class="ml-3">
					</router-link>

					<ul class="navbar-nav login-signup">
						<li class="nav-item">
							<router-link :to="{ name: 'login' }" class="btn btn-outline-primary btn-box-shadow">Login</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{ name: 'sign-up' }" class="btn btn-outline-primary btn-box-shadow">Sign Up</router-link>
						</li>
					</ul>

				</div>
			</nav>
</template>
<script>
    export default {
	mounted() {
	},
		methods: {

            authCheck() {
				if(this.$store.state.user) {
					return true;
				}
				return false;
			},
			logout() {
				this.$store.dispatch('logout');
			}
		},
        computed: {
            csrfToken() {
                return this.$store.state.csrfToken;
            },
            user() {
                return this.$store.state.user;
            },

		},
	}
</script>
