<template>
    <div>
        <accounts-menu-bar></accounts-menu-bar>
        <budgeted-to-go></budgeted-to-go>
        <component :is='current_input'></component>
        <graph v-if="showTransactionsGraphModal"></graph>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-lg-4 order-lg-last col-12">
                    <account-progress></account-progress>
                </div>
                <div class="col-lg-8 order-lg-first col-12">
                    <h3 class="mb-4">Transactions ({{ this_month_transactions.length }})</h3>
                    <transactions-menu-bar v-if="accounts.length!==0"></transactions-menu-bar>
                    <transactions-filters v-if="this_month_transactions_count!==0"></transactions-filters>
                    <div class="month-transations">
                        <div class="container-fluid">
                            <div class="row table-header">
                                <div class="col-2">
                                    <span @click='sort(sortDate, "date_short")' class="ml-1">Date</span>
                                    <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                                        <icon-sort slot="icon"></icon-sort>
                                    </icon-base>
                                    <!--<span @click='sort(sortDate, "date_short")' v-if='sortDate=="asc"'><i class="fa fa-angle-up"></i></span><span @click='sort(sortDate, "date_short")' v-if='sortDate=="desc"'><i class="fa fa-angle-down"></i></span>-->
                                </div>
                                <div class="col-4">
                                    <span @click='sort(sortPayee, "entity.name_short")'>Payee</span>
                                    <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                                        <icon-sort slot="icon"></icon-sort>
                                    </icon-base>
                                    <!--<span @click='sort(sortPayee, "entity.name_short")' v-if='sortPayee=="asc"'><i class="fa fa-angle-up"></i></span><span @click='sort(sortPayee, "entity.name_short")' v-if='sortPayee=="desc"'><i class="fa fa-angle-down"></i></span>-->
                                </div>
                                <div class="col-3">
                                    <span @click='sort(sortPayee, "subcategories[0].name")'>Category</span>
                                    <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                                        <icon-sort slot="icon"></icon-sort>
                                    </icon-base>
                                    <!--<span @click='sort(sortCategory, "subcategories[0].name")' v-if='sortCategory=="asc"'><i class="fa fa-angle-up"></i></span><span @click='sort(sortCategory, "subcategories[0].name")' v-if='sortCategory=="desc"'><i class="fa fa-angle-down"></i></span>-->
                                </div>
                                <div class="col-2 text-right">
                                    <span @click='sort(sortAmount, "absolute_value_amount")'>Amount</span>
                                    <icon-base icon-name="write" height="20" width="20" iconClass="ml-2 mt-1">
                                        <icon-sort slot="icon"></icon-sort>
                                    </icon-base>
                                    <!--<span @click='sort(sortAmount, "absolute_value_amount")' v-if='sortAmount=="asc"'><i class="fa fa-angle-up"></i></span><span @click='sort(sortAmount, "absolute_value_amount")' v-if='sortAmount=="desc"'><i class="fa fa-angle-down"></i></span>-->
                                </div>
                                <div class="col-1">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <span v-if="this.loadingTransactions == false && this_month_transactions.length > 0">
                            <transaction v-for="(transaction, index) in this_month_transactions"
                                         :is="transaction.mode"
                                         :transaction="transaction"
                                         :categories="transaction_categories"
                                         :index="index"
                                         :key="transaction.id"
                                         @edit="edit_transaction"
                                         @save="save(transaction, index)"
                                         @remove="remove_transaction(transaction, index)"
                                         @update="update_transaction"
                                         >
                            </transaction>
                        </span>
                        <span v-else-if="this.loadingTransactions">
                            <loading-transaction></loading-transaction>
                        </span>
                        <span v-else-if="this.loadingTransactions==false && this_month_transactions.length == 0">
                            <no-transactions></no-transactions>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	data() {
		return {
			sortDate: null,
			sortPayee: null,
			sortCategory: null,
			sortAmount: null,
		}
	},
	mounted: function() {

//		const socket = io('https://api.budgetitup.com',{
//			transports: ['websocket'],
//			secure: true,
//			rejectUnauthorized: false});
		// fetch
		//socket.on('channel.' + $scope.user.id + ':App\\Events\\EventBroadcasted', function(data) {
//		socket.on('dataReceived', function(data) {
//			console.log('yeah buddy!');
//		});
//		socket.on('connection', (socket) => {
//			console.log(JSON.stringify(socket));
//		});

        if(this.$store.state.isLoggedIn || localStorage.getItem("token")) {

            if (!this.$store.state.current_date) {
                console.log('getting date from accounts');
                this.$store.dispatch('get_current_date').then(() => {
                    this.$store.dispatch('get_account_types').then(() => {
                        this.$store.dispatch('get_budgets').then(() => {
                            this.$store.dispatch('get_all_accounts_transactions_for_this_month').then(() => {
                                this.setAccount();
                                if(this.$store.state.accounts.length===0 || this.$store.state.this_month_transactions.length===0) {
                                    this.$store.state.body_view = 'message-to-user';
                                } else {
                                    this.$store.state.body_view = null;
                                }
                            });
                        });
                    });
                });
            } else {
                this.$store.dispatch('get_account_types').then(() => {
                    this.$store.dispatch('get_budgets').then(() => {
                        this.$store.dispatch('get_all_accounts_transactions_for_this_month').then(() => {
                            this.setAccount();
                            if(this.$store.state.accounts.length===0 || this.$store.state.this_month_transactions.length===0) {
                                this.$store.state.body_view = 'message-to-user';
                            } else {
                                this.$store.state.body_view = null;
                            }
                        });
                    });
                });

            }
        }
	},
	computed: {
		this_month_transactions () {
			this.$store.getters.this_month_transactions.sort(function (a, b) {
				if(a.date_short < b.date_short) return 1;
				if(a.date_short > b.date_short) return -1;
				return 0;
			});

			return this.$store.getters.this_month_transactions;
			//	return this.$store.state.this_month_transactions;
			//Last used
			// return _.orderBy(this.$store.getters.this_month_transactions, ['date_short'], ['asc']);

		},
			transaction_categories () {
				return this.$store.state.transaction_categories;
			},
		user() {
			return this.$store.state.user;
		},
		body_view() {
			// @todo add messaging to allow user to connect bank
            if(this.$store.state.user && !this.$store.state.user.plaid_id && this.$store.state.accounts.length===0) {
                return 'message-to-user';
            } else if(this.$store.state.accounts.length!==0 && this.$store.state.this_month_transactions.length==0) {
                return 'message-to-user';
            } else {
                return null;
            }
		},
		current_input() {
			return this.$store.state.current_input;
		},
		new_transaction() {
			return this.$store.state.new_transaction;
		},
		this_month_transactions_count() {

            //return this.$store.state.account.transactions.length;
			return this.$store.state.this_month_transactions.length;
		},
		last_updated() {
			var result = format(
			  new Date(this.accounts[0].updated_at),
			  'MM/DD/YYYY h:mm a'
			);

			return result;
		},
        account () {
            return this.$store.state.account;
        },
		accounts() {
			return this.$store.state.accounts;
		},
		showAddNewAccountModal() {
			return this.$store.state.showAddNewAccountModal;
		},
		showEditAccount() {
			return this.$store.state.showEditAccount;
		},
		showTransactionsGraphModal() {
			return this.$store.state.showTransactionsGraphModal;
		},
		loadingTransactions() {
			return this.$store.state.loadingTransactions;
		},
	},
	methods: {
		update_transaction(selected, transaction) {


			// this.transaction.subcategories.push({id:null,amount: null, category_id: null, transaction_id: this.transaction.id});

			this.$store.state.transaction_categories.forEach(function(category) {
				var m = category.subcategories.find(subcategory => subcategory.id === Number(selected));
				if(m) {
					transaction.subcategory = m;
				}
			});
			transaction.transaction_subcategory_id = selected;

		},
			edit_transaction(transaction) {
                // console.log(JSON.stringify(transaction));
				transaction.mode = "transaction-edit"
			},
			remove_transaction(transaction, index) {

				var that = this;
				this.$store.dispatch('delete_transaction', {
					transaction: transaction,
					index: index
				});

			},
        set_current_input(input) {
			this.$store.commit('setCurrentInput', input);
		},
        sort(order, column) {
			if(order==null) {
				order = 'asc';
			} else if (order=='asc') {
				order = 'desc';
			} else if (order=='desc') {
				order = 'asc';
			}
			if(column=='date_short'){
				this.sortDate = order;
			}
			if(column=='entity.name_short'){
				this.sortPayee = order;
			}
			if(column=='subcategories[0].name'){
				this.sortCategory = order;
			}
			if(column=='absolute_value_amount'){
				this.sortAmount = order;
			}
			this.$store.commit('setSortMonthTransactions', {order: order, column: column});
		},

		toggle () {
			this.$store.commit('toggleTransactionMode');
		},

		update(account){

			this.$store.dispatch('update_account', {
				account: account
			})

		},
		remove(account, index) {

			var that = this;
			axios.delete('/api/accounts/' + account.id)
			.then(function (response) {
				that.accounts.splice(index, 1);
			    that.$store.commit('setCurrentInput', null);
			})
			.catch(function (error) {
				console.log(error);
			});

		},
        setCurrentDate() {
		    this.$store.commit('setCurrentDate', this.php_current_date);
        },
		setAccount(){
			var that = this;
            this.$store.state.accounts.forEach(function(val, key) {
                if(val['type']) {
                    if(val['type']['name']==='Checking'){
                        that.$store.commit('setAccount', val);
                        that.$store.commit('setThisMonthTransactions', val.transactions);
                    }
                }
			});
		},
		setAccountTypes() {
			this.$store.commit('setAccountTypes', this.php_account_types);
		},
		setAccounts() {
			this.$store.commit('setAccounts', this.php_accounts);
		},
		setUser() {
			this.$store.commit('setUser', this.php_user);
		},
	}
}
</script>
<!--
	-<edit-account v-if="showEditAccount"></edit-account>
-->
<!--
	-<add-new-account v-if="showAddNewAccountModal"></add-new-account>
-->
<!--
	-<keep-alive></keep-alive>
-->
