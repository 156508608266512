<template>
	<nav class="navbar navbar-expand-md fixed-top navbar-v2 navbar-logged-in" id="mainNav">
		<div class="mobile-bg d-block d-md-none"></div>
		<div class="container-fluid container-fluid-90 d-flex justify-content-between">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<router-link :to="{ name: 'home' }" class="navbar-brand" active-class="active">
				<img src="/images/budget_it_up.png" class="logo px-3 px-md-0 px-lg-0">
			</router-link>
			<div class="collapse navbar-collapse no-transition" id="navbarCollapse">
				<ul class="navbar-nav mr-auto" v-if="authCheck()">
					<li class="nav-item d-block d-md-none mobile-logo">
						<img src="images/budget_it_up.png" class="logo px-3 px-md-0 px-lg-0">
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'accounts' }" class="nav-link small" active-class="active">Accounts</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'budgets' }" class="nav-link small" active-class="active">Budget</router-link>
					</li>
					<!--<li class="nav-item">
						<router-link :to="{ name: 'goals' }" class="nav-link small" active-class="active">Goals</router-link>
					</li>-->
				</ul>
				<ul class="navbar-nav ml-auto" v-if="authCheck()">
					<li class="nav-item">
						<router-link :to="{ name: 'support' }" class="nav-link small" active-class="active">Support</router-link>
					</li>
					<li class="nav-item dropdown user-settings-menu">
						<a class="nav-link dropdown-toggle small dropdown" href="" id="dropdownSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{{user.email }}
						</a>
						<div class="dropdown-menu" aria-labelledby="dropdownSettings">
							<router-link :to="{ name: 'settings' }" class="dropdown-item">Account</router-link>

							<router-link :to="{ name: 'connected-banks' }" class="dropdown-item" v-if='isBudgetBoss'>Connected Institutions</router-link>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" href="/#" @click="logout">
							Logout
							</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
    export default {
	mounted() {

		var navbarCollapse = function() {
			var docWidth = $(document).width();
			if (docWidth>768) {
				if ($("#mainNav").offset().top > 100) {
					$("#mainNav").addClass("navbar-shrink");
				} else {
					$("#mainNav").removeClass("navbar-shrink");
				}
			} else {
				$("#mainNav").removeClass("navbar-shrink");
			}
		};
		// Collapse now if page is not at top
		navbarCollapse();
		// Collapse the navbar when page is scrolled or resized
		$(window).scroll(navbarCollapse);
		$(window).resize(navbarCollapse);


	    $('#backtotop').on('click', function (e) {
	        e.preventDefault();
	        $('html,body').animate({
	            scrollTop: 0
	        }, 700);
	    });

	    //Mobile display sidemenu
	    $('.navbar-toggler').on('click',function(){
	    	var expanded = $(this).attr('aria-expanded');
	    	if (expanded!=='true'){
	    		$('.navbar .mobile-bg').addClass('show');
	    	}
	    })

		//Close modal
		$(document).on('click',function(event){
			if ($(event.target).hasClass('mobile-bg')) {
				$('.navbar .mobile-bg').removeClass('show');
				$('.navbar-toggler').trigger('click');
			}
		})



	},
		methods: {
            authCheck() {
				if(this.$store.state.user) {
					return true;
				}
				return false;
			},
			logout() {
				this.$store.dispatch('logout');
			}
		},
        computed: {
            csrfToken() {
                return this.$store.state.csrfToken;
            },
            user() {
                return this.$store.state.user;
            },
            isBudgetBoss() {
                if(this.$store.state.user.is_budget_boss) {
                    return true;
                }
                return false;
            },
		},
	}
</script>
