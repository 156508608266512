var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M231.37,209a104.19,104.19,0,1,0-28.93,39" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M364,286.71,168.13,171.94a23.05,23.05,0,0,0-23.94,39.39l193.59,122"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "175.7", y1: "178.96", x2: "151.09", y2: "214.9" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "197.89", y1: "191.62", x2: "173.68", y2: "227.76" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M60.88,175a155.67,155.67,0,0,0-.81,21.7" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M70.19,140.41a99.68,99.68,0,0,0-3.75,9.28" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M133.42,91.24S105.9,95,84.55,118.61" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "355.65", y1: "246.51", x2: "368.51", y2: "254.71" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "277.6", y1: "196.69", x2: "337.1", y2: "234.67" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "272.66", y1: "328.58", x2: "283.39", y2: "335.78" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "199", y1: "279.18", x2: "254.18", y2: "316.19" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }