var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "empty-page" }, [
      _c("h5", [_vm._v(_vm._s(_vm.headline))]),
      _vm._v("\n\t" + _vm._s(_vm.message) + "\n")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }