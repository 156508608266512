var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100" }, [
    _c("div", { staticClass: "main-wrap login" }, [
      _c(
        "form",
        {
          staticClass: "form-signin",
          attrs: { method: "POST", action: "/forgot-password" }
        },
        [
          _c(
            "div",
            { staticClass: "text-center mb-4" },
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-link font-weight-bold small",
                  attrs: { to: { name: "home" } }
                },
                [
                  _c("img", {
                    staticClass: "mb-4",
                    attrs: { src: "/images/logo.png", alt: "Budget It Up Logo" }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-label-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "email",
                value: "",
                id: "inputEmail",
                placeholder: "Email address",
                required: "",
                autofocus: ""
              },
              domProps: { value: _vm.user.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "inputEmail" } }, [
              _vm._v("Email address")
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("errors", { attrs: { errors: _vm.errors } }),
          _vm._v(" "),
          _c("success-message", {
            attrs: { success_messages: _vm.success_messages }
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-lg btn-outline-primary btn-block mt-5",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.sendReset($event)
                }
              }
            },
            [_vm._v("Send Password Reset Link")]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2)
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-icon" }, [
      _c("i", { staticClass: "fa fa-envelope" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-5 mb-3 text-muted text-center" }, [
      _c("a", { staticClass: "underline-link", attrs: { href: "/signup" } }, [
        _vm._v("Don't have an account?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-5 mb-3 text-muted text-center" }, [
      _c("a", { staticClass: "underline-link", attrs: { href: "/login" } }, [
        _vm._v("Remember your password?")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }