var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 12")]),
      _c("path", {
        attrs: {
          d:
            "M200,23.06c-78.73,0-140.4,29.8-140.4,67.83,0,16.43,11.51,31.32,30.92,42.93V344.37a32.61,32.61,0,0,0,32.57,32.57H276.91a32.61,32.61,0,0,0,32.58-32.57V133.82c19.4-11.61,30.91-26.5,30.91-42.93C340.4,52.86,278.73,23.06,200,23.06Zm97.49,321.31a20.6,20.6,0,0,1-20.58,20.57H123.09a20.59,20.59,0,0,1-20.57-20.57V140.13c25.07,11.58,59.26,18.6,97.48,18.6s72.41-7,97.49-18.6ZM302.6,124a5.92,5.92,0,0,0-3.67,2c-23.77,12.54-59.63,20.7-98.93,20.7-69.6,0-128.4-25.57-128.4-55.84S130.4,35.06,200,35.06,328.4,60.63,328.4,90.89C328.4,103.16,318.74,114.66,302.6,124Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M252,62.07H148a14.7,14.7,0,0,0-14.68,14.68v12.1a6,6,0,0,0,12,0V76.75A2.68,2.68,0,0,1,148,74.07H252a2.68,2.68,0,0,1,2.68,2.68v12.1a6,6,0,1,0,12,0V76.75A14.7,14.7,0,0,0,252,62.07Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M152.27,208.78a6,6,0,0,0-6,6v77.68a6,6,0,0,0,12,0V214.78A6,6,0,0,0,152.27,208.78Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M244.43,301.69a6,6,0,0,0,6-6V211.54a6,6,0,0,0-12,0v84.15A6,6,0,0,0,244.43,301.69Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M192.35,192.93V314.3a6,6,0,0,0,12,0V192.93a6,6,0,0,0-12,0Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }