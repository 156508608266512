var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _vm.isLoggingIn
        ? _c("full-page-loading-screen")
        : _c("div", { staticClass: "main-wrap login" }, [
            _c(
              "form",
              {
                staticClass: "form-signin",
                attrs: { method: "POST", action: "/login" }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center mb-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link font-weight-bold small",
                        attrs: { to: { name: "home" } }
                      },
                      [
                        _c("img", {
                          staticClass: "mb-4",
                          attrs: {
                            src: "/images/logo.png",
                            alt: "Budget It Up Logo"
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-label-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      value: "",
                      id: "inputEmail",
                      placeholder: "Email address",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "inputEmail" } }, [
                    _vm._v("Email address")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-icon" }, [
                    _c("i", { staticClass: "fa fa-envelope" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-label-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password,
                        expression: "user.password"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      id: "inputPassword",
                      placeholder: "Password",
                      required: ""
                    },
                    domProps: { value: _vm.user.password },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          $event.keyCode !== 13
                        ) {
                          return null
                        }
                        return _vm.login($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "password", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "inputPassword" } }, [
                    _vm._v("Password")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-icon" }, [
                    _c("i", { staticClass: "fa fa-lock" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mt-4 mb-0" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 custom-control custom-checkbox" },
                      [
                        _c("input", {
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "checkbox",
                            value: "remember-me",
                            name: "rememberme",
                            id: "rememberme"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "rememberme" }
                          },
                          [_vm._v("Remember me")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 text-right" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "underline-link",
                            attrs: { to: { name: "password-reset" } }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tForgot Your Password?\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("errors", { attrs: { errors: _vm.errors } }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-lg btn-outline-primary btn-block mt-5",
                    attrs: { href: "#", id: "signIn" },
                    on: {
                      click: function($event) {
                        return _vm.login($event)
                      }
                    }
                  },
                  [_vm._v("Sign in")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mt-5 mb-3 text-muted text-center" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "underline-link",
                        attrs: { to: { name: "sign-up" } }
                      },
                      [_vm._v("\n\t\t\t\tDon't have an account?\n\t\t\t")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }