var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-7")]),
      _c("path", {
        attrs: {
          d:
            "M234,247.55V102.93h29.71a6,6,0,0,0,6-6V42.83a6,6,0,0,0-6-6H192.36l-5.62,0h0a6,6,0,0,0-6,5.32,33.75,33.75,0,0,1-67.06,0,6,6,0,0,0-5.92-5.31H36.24a6,6,0,0,0-6,6v54.1a6,6,0,0,0,6,6h29.7V247.55L55.15,359.85a6,6,0,0,0,5.15,6.52l73.94,10.17a6,6,0,0,0,6.78-5.23l9-74.79,9,74.79a6,6,0,0,0,6,5.29,5.66,5.66,0,0,0,.82-.06l73.94-10.17a6,6,0,0,0,5.16-6.52ZM156.5,250h65.71l7.43,77.24-62.72,9.7Zm-23.45,86.94-62.71-9.7L77.76,250h65.72ZM222,238H77.94V227.75H222ZM42.24,90.93V48.83H102.7a45.74,45.74,0,0,0,89,0h66v42.1H228a6,6,0,0,0-6,6V215.75H77.94V96.93a6,6,0,0,0-6-6Zm25.4,264.33,1.55-16.06,62.44,9.67-1.8,14.95Zm102.5,8.56-1.79-14.95,62.44-9.67,1.54,16.06Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M365.64,243.94h-6.36V177.52a6,6,0,0,0-6-6H308.09a6,6,0,0,0-6,6v64a10.64,10.64,0,0,1-10.63,10.63,46.13,46.13,0,0,0-46.08,46.07,6,6,0,0,0,6,6h63.06v9.71a10.64,10.64,0,0,1-10.62,10.63,46.13,46.13,0,0,0-46.08,46.08,6,6,0,0,0,6,6h101.9a6,6,0,0,0,6-6V249.94A6,6,0,0,0,365.64,243.94ZM257.91,292.18a34.13,34.13,0,0,1,33.55-28.07,22.66,22.66,0,0,0,22.63-22.63v-58h33.19v60.42H320.44a6,6,0,0,0-6,6v42.24Zm45.91,44.34a22.65,22.65,0,0,0,22.62-22.63V255.94h33.2v67C342.06,327.12,329,343.59,329,362.67c0,.65,0,1.29,0,1.93H270.27A34.13,34.13,0,0,1,303.82,336.52ZM341,364.6c0-.64-.06-1.28-.06-1.93,0-12.47,7.8-23.35,18.67-27.25V364.6Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }