var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-expand fixed-top navbar-main-page",
      attrs: { id: "mainNav" }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid container-fluid-90" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-brand js-scroll-trigger",
              attrs: { to: { name: "home" } }
            },
            [
              _c("img", {
                staticClass: "ml-3",
                attrs: { src: "images/logo.png" }
              })
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav login-signup" }, [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline-primary btn-box-shadow",
                    attrs: { to: { name: "login" } }
                  },
                  [_vm._v("Login")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline-primary btn-box-shadow",
                    attrs: { to: { name: "sign-up" } }
                  },
                  [_vm._v("Sign Up")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }