<template>
    <div class="modal is-active new-transation-modal">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <div class="px-3 py-3">
                    <h4 class="mb-4 mt-2 text-center">{{ budget.display_name }} Budget</h4>
                    <div class="form-group top-radio-btns">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" value="0" v-model="budget.due_in_full" name="options" id="option2" autocomplete="off">
                            <label class="custom-control-label price-value two" for="option2" v-on:click="setType(0)">Standard (Spread out over month)</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" value="1" v-model="budget.due_in_full" name="options" id="option3" autocomplete="off">
                            <label class="custom-control-label price-value two" for="option3" v-on:click="setType(1)">Bill (Due in full on due date)</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Display Name</label>
                            <input type="text" class="form-control no-border" v-model="budget.display_name">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Amount</label>
                            <currency-input :amount='budget_amount'
                             @subtractFromAmountValue='subtractFromAmountValue'
                             @addToAmountValue='addToAmountValue'
                             @passValue='passValue'
                             ></currency-input>
                        </div>
                        <div class="col-sm-6">
                            <label>Starting</label>
                            <date @setDate="setStartDate" :date='budget_repeat_start'></date>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 form-group" @click='handleNoType'>
                            <label>Category</label>
                            <div class="clearfix"></div>
                            <div class="clearfix"></div>
                            <semantic-select :items="expense_categories" @update='setCategory' :preSelect='(budget.subcategory) ? budget.subcategory.transaction_category_id : null'></semantic-select>
                        </div>
                        <div class="col-sm-6 form-group" @click='handleNoType'>
                            <label>Sub-category</label>
                            <div class="clearfix"></div>
                            <semantic-select :items="unbudgeted_expense_subcategories" @update='setSubcategory' :preSelect='budget.transaction_subcategory_id'></semantic-select>
                        </div>
                    </div>
                    <div class="row">
						<div v-if='budget.due_in_full==1'class="col-sm-6">
							<div class="form-group">
								<label class="mt-2 dark-label">Paid with cash or in previous month.</label>
								<div class="custom-control custom-checkbox">
									<input class="custom-control-input" type="checkbox" v-model="budget_has_been_paid"
																						v-bind:true-value="1"
																						v-bind:false-value="0"
																						id="paidCheck"
																						>
																						<label class="custom-control-label" for="paidCheck">
																							Mark as paid
																						</label>
								</div>
							</div>
						</div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Does this budget repeat?</label>
                                <div class="custom-control custom-toggle-checkbox">
                                    <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
                                                                                        v-bind:true-value="true"
                                                                                        v-bind:false-value="false"
                                                                                        id="defaultCheck">
                                    <div class="toggle-labels">
                                        <label class="unchecked-label" for="defaultCheck">No</label>
                                        <label class="checked-label" for="defaultCheck">Yes</label>
                                    </div>
                                </div>
                                <!--
                                    -<div class="custom-control custom-checkbox">
                                    -    <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
                                    -                                                        v-bind:true-value="1"
                                    -                                                        v-bind:false-value="0"
                                    -                                                        id="defaultCheck"
                                    -                                                        >
                                    -                                                        <label class="custom-control-label" for="defaultCheck">
                                    -                                                            Yes
                                    -                                                        </label>
                                    -</div>
                                -->
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" v-if='budget.is_recurring===true'>
                        <!--
                            -<div class="col-sm-4">
                            -    <div class="form-group">
                            -        <label>Starting</label>
                            -        <date @setDate="setStartDate" :date='first'></date>
                            -    </div>
                            -</div>
                        -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Every</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_interval">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-model="budget.repeat_frequency">
                                    <option value="month" selected="selected">{{ this.formatPlural(budget.repeat_interval, 'Month') }}</option>
                                    <option value="year">{{ this.formatPlural(budget.repeat_interval, 'Year') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-if="budget.repeat_frequency=='month'">
                                    <option value="month">On day {{ start_day }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Ending</label>
                                <select class="form-control ui dropdown transparent-dropdown min-width-0" v-model='budget.ending'>
                                    <option value="never">No end date</option>
                                    <option value="end_after">End after</option>
                                    <option value="end_by">End by</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_after'">
                                <label>Number of occurences</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_num_occurrences" @change="setEndDateToNull">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_by'">
                                <label>End Date</label>
                                <date @setDate="setEndDate" :date='today'></date>
                            </div>
                        </div>
                    </div>
                </div>
                <div  v-if='budget.due_in_full==1' class="row">
                    <div class="col-6 form-group">
                        <label>Online Payments Website</label>
                        <input type="text" class="form-control no-border" v-model="budget.website">
                    </div>
                </div>
                <errors :errors='errors'></errors>
				<div class="form-row mt-4">
					<div class="form-group order-md-last col-sm-8">
						<div class="text-right text-sm-right text-center">
							<button class="btn btn-outline-primary btn-box-shadow btn-wide" @click="cancel">Cancel</button>
							<button class="btn btn-primary btn-box-shadow btn-wide ml-4" @click="saveBudget">Save</button>
						</div>
					</div>
					<div class="form-group order-md-first col-sm-4 text-sm-left text-center">
						<button class="btn btn-danger btn-wide btn-box-shadow mt10" @click="removeBudget">Remove</button>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
		index: Number,
		mode: String,
    },
    data() {
        return {
			subcategories: []
        }
    },
	created: function() {

		this.budget = Object.assign({}, this.$store.state.current_data);
		this.budget_months = Object.assign({}, this.$store.state.current_data.months);

		if(!this.budget.repeat_frequency || this.budget.repeat_frequency=='') {
			this.budget.repeat_frequency = 'month';
		}

		if(!this.budget.repeat_interval || this.budget.repeat_interval=='') {
			this.budget.repeat_interval=1;
		}

        if(typeof(this.firstSubcategory)!=='undefined') {
            var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(this.firstSubcategory.transaction_category_id));
        }

		if(m) {
			this.subcategories = m.subcategories;
		}

	},
	mounted() {
        console.log('budget: ' + JSON.stringify(this.budget));
//		console.log('==> state.expense_categories', JSON.stringify(this.$store.state.expense_categories));
//		console.log('==> getters.expense_categories', JSON.stringify(this.$store.getters.expense_categories));
        // console.log(JSON.stringify(this.budget));
	},
    computed: {
        errors() {
            return this.$store.state.update_budget_errors;
        },
		expense_categories () {
			return this.$store.state.expense_categories;
		},
		expense_subcategories () {
			return this.$store.state.expense_subcategories;
		},
        firstSubcategory() {
            if (this.budget.subcategory) {
                return this.budget.subcategory;
            }
        },
		first() {
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			return new Date(y, m, 1);
			//var lastDay = new Date(y, m + 1, 0);
		},
        width: function () {
			if(this.budget) {
            	return 'width: ' + this.round((Number(this.budget.spent) / Number(this.budget.amount)) * 100, 0) + '%';
			}
        },
        widthLabel: function () {
            //return (Number(this.budget.amount) > 0) ? ( '$' + Number(this.budget.spent) + ' of $' + Number(this.budget.amount)) : Number(0);
			if(this.budget) {
            	return '$' + this.round(Number(this.budget.spent),2);
			}
        },
        remaining: function() {
			if(this.budget) {
            	return this.round(Number(this.budget.amount) - Number(this.budget.spent),2);
			}
        },
        percentSpent: function() {
			if(this.budget) {
	            return (this.budget.spent) ? this.round((Number(this.budget.spent) / Number(this.budget.amount)) * 100, 0) : 100;		}
        },
        percentDaysPassed () {
            return this.round(((this.$store.state.today / this.$store.state.last)*100),0);
        },
        amount () {
			if(this.budget) {
            	return this.round(Number(this.budget.amount), 2);
			}
        },
        unbudgeted_expense_subcategories() {
            return this.$store.getters.unbudgeted_expense_subcategories(this.budget.subcategory.transaction_category_id, this.budget.transaction_subcategory_id);
        },
        budget_is_recurring: {
            get: function () {
                var isRecurring = 0;
                if(this.budget.recurrences && this.budget.recurrences.length) {
                    isRecurring = (this.budget.recurrences[0].repeat_start) ? 1 : 0;
                }
                return isRecurring;
            },
			set: function (newValue) {
                this.budget.is_recurring = newValue;
                if (newValue === 1) {
                    this.budget.repeat_frequency = null;
                    this.budget.repeat_interval = null;
                }
			}
        },
		budget_amount: {
			get: function () {

				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
				var month = format(d, 'M');
				var year = format(d, 'YYYY');
				var currentBudgetMonth;
				currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
				if(currentBudgetMonth){
					return currentBudgetMonth.amount;
				}

				return 0;
			},
			set: function (newValue) {
				if (this.budget.months) {
					var d = new Date(this.$store.state.current_date);
					// https://date-fns.org/docs/format
					var month = format(d, 'M');
					var year = format(d, 'YYYY');
					var currentBudgetMonth;
					currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
					if(currentBudgetMonth){
						return currentBudgetMonth.amount = newValue;
					}
                } else {
                    var months = [];
                    months.push({amount: newValue});
                    this.budget.push(months);
                }
			}
		},
        budget_has_been_paid: {
			get: function () {
                if(typeof(this.budget.months) !=='undefined' && this.budget.months.length) {
					var d = new Date(this.$store.state.current_date);
					// https://date-fns.org/docs/format
					var month = format(d, 'M');
					var year = format(d, 'YYYY');
					var currentBudgetMonth;
                    currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));

					if(currentBudgetMonth){
						return currentBudgetMonth.has_been_paid;
					}
                }
                return 0;
            },
            set: function (newValue) {
                if (this.budget.months) {
                    var d = new Date(this.$store.state.current_date);
                    // https://date-fns.org/docs/format
                    var month = format(d, 'M');
                    var year = format(d, 'YYYY');
                    var currentBudgetMonth;
                    currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
                    if(currentBudgetMonth){
                        console.log('test');
                        return currentBudgetMonth.has_been_paid = newValue;
                    }
                } else {
                    var months = [];
                    months.push({has_been_paid: newValue});
                    this.budget.push(months);
                }

            }
        },
        budget_repeat_start: {
            get: function() {
                var repeatStart = null;
                if(this.budget.recurrences && this.budget.recurrences.length) {
                    repeatStart = this.budget.recurrences[0].repeat_start;
                }
                return repeatStart;
            },
            set: function (newValue) {
                this.budget.repeat_start = newValue;
            }
        }
    },
    data() {
        return {
            widthTween: 0,
			start_day: new Date().getDate(),
			budget: null
        }
    },
    methods: {
		removeBudget() {
            this.$store.dispatch('delete_budget', {
				budget: this.budget,
			})
		},
		saveBudget() {

            this.budget.months.forEach(function(month) {
                month.amount = accounting.unformat(month.amount)
            });
			this.$store.dispatch('update_budget', {
				budget: this.budget
			})
		},
        setType(type) {
            this.budget.due_in_full = type;
        },
        setCategory(value) {
            this.budget.subcategory.transaction_category_id = value;
        },
        setSubcategory(selected) {

			var that = this;
			var i;

			var once_monthly;
			var numberCategories = this.$store.state.unbudgeted_expense_categories.length;
			for (i = 0; i < numberCategories; i++) {

				selected = Number(selected);
				const test = that.$store.state.unbudgeted_expense_categories[i].subcategories.find(subcategory => subcategory.id === selected);

				if(test) {
					//console.log(JSON.stringify(test));
				}

				if(test && test.once_monthly == 1) {
					once_monthly = 1;
					break;
				} else {
					once_monthly = 0;
					break;
				}
			}

			that.budget.once_monthly = once_monthly;
			this.budget.transaction_subcategory_id = selected;
        },
        subtractFromAmountValue(index) {
            if(accounting.unformat(this.budget.credit_amount)-10>0) {
                return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) - 10;
            }
        },
        addToAmountValue(index) {
            return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) + 10;
        },
		handleNoType() {
			if(!this.type) {
				this.showChooseType=true;
			}
		},
		get_budget_amount(budget) {

			if(typeof(budget.months) !=='undefined') {
				return budget.months[0].amount;
			} else if(typeof(budget.most_recent_budget_month) !=='undefined') {
				return budget.most_recent_budget_month.amount;
			}
			return 0;
		},
		balanceBudget(budget_id) {

			/*var budget = this.$store.state.budgets.find(budget => budget.id === budget_id);
			if(budget && ( Number(this.get_budget_amount(budget)) - Number(budget.spent) ) <= 0) {
				var diff =  Number(this.get_budget_amount(budget)) - Number(budget.spent);
				budget.months[0].amount = budget.months[0].amount + Math.abs(diff);

			}*/
			if(Number(this.budget_amount) - Number(this.budget.spent) <=0) {
				var diff =  Number(this.budget_amount) - Number(this.budget.spent);
				this.budget_amount = Number(this.budget_amount) + Math.abs(diff);
			}
		},
		formatMoney(amount) {
			return accounting.formatMoney(amount);
		},
        subtractFromAmountValue(index) {
            if(accounting.unformat(this.budget.credit_amount)-10>0) {
                return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) - 10;
            }
        },
        addToAmountValue(index) {
            return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) + 10;
        },
		passValue(value) {

			if (this.budget.months) {
            console.log('has months');
				var d = new Date(this.$store.state.current_date);
				// https://date-fns.org/docs/format
				var month = format(d, 'M');
				var year = format(d, 'YYYY');
				var currentBudgetMonth;
				currentBudgetMonth= this.budget.months.find(budgetMonth => Number(budgetMonth.month) === Number(month) && Number(budgetMonth.year) === Number(year));
				if(currentBudgetMonth){
                    console.log('current month found');
					currentBudgetMonth.amount = value;
				}
			} else {
				var months = [];
				months.push({amount: value});
				this.budget.push(months);
			}
		},
        formatCurrency() {
            return this.budget.amount = accounting.unformat(this.budget.amount);
        },
		setStartDate(date) {
            //**Monthly recurring rules ** By default, if your start date is on the 29th, 30th, or 31st, Recurr will skip following months that don't have at least that many days. (e.g. Jan 31 + 1 month = March)
			this.budget.repeat_start = date;
			var date = new Date(date);
			this.start_day = date.getDate();
		},
		setEndDate(date) {
			this.budget.repeat_end = date;
            this.budget.repeat_num_occurrences = null;
		},
		formatPlural(count, noun, suffix = 's') {
			return noun += (Number(count) !== 1) ? suffix : '';
		},
        setDate(date) {
            this.budget.repeat_start = date;
        },
        cancel () {
			this.$store.commit('setCurrentInput', {input: null});
        },
        round (value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },
		update(selected){

			//console.log(JSON.stringify(selected));
			var that = this;
			var i;

			var once_monthly;
			var numberCategories = this.$store.state.unbudgeted_expense_categories.length;
			for (i = 0; i < numberCategories; i++) {

				selected = Number(selected);
				const test = that.$store.state.unbudgeted_expense_categories[i].subcategories.find(subcategory => subcategory.id === selected);

				if(test) {
					console.log(JSON.stringify(test));
				}

				if(test && test.once_monthly == 1) {
					once_monthly = 1;
					break;
				} else {
					once_monthly = 0;
					break;
				}
			}

			this.budget.once_monthly = once_monthly;
			this.budget.transaction_subcategory_id = selected;

		},
    },
    watch: {
        widthLabel: function(newValue, oldValue) {

            //console.log(newValue, oldValue)
            var vm = this
            var animationFrame
            function animate (time) {
                TWEEN.update(time)
                animationFrame = requestAnimationFrame(animate)
            }

            new TWEEN.Tween({ tweeningNumber: oldValue })
                .easing(TWEEN.Easing.Quadratic.Out)
                .to({ tweeningNumber: newValue }, 1200)
                .onUpdate(function () {
                    vm.widthTween = this.tweeningNumber.toFixed(0)
                })
                .onComplete(function () {
                    cancelAnimationFrame(animationFrame)
                })
                .start()
            animationFrame = requestAnimationFrame(animate)
        }
    },
}
</script>
<!--<div class="row pv20">
    <div class="col-md-12">
    <h1 class="text-center" v-if="!index || budgets[index - 1].category_name != budgets[index].category_name">{{ budget.category_name }}</h1>
    </div>
    </div>-->
    <!--<div class="col-md-1">
        $ {{ budget.spent }}
        </div>
        <div class="col-md-1">
        <span class="label" v-bind:class="{ 'label-success': budget.remaining>0, 'label-danger': budget.remaining<=0 }">
        $ {{ budget.remaining }}
        </span>
        </div>-->
        <!--<div class="col-md-3">
            <div class="progress">
            <div class="progress-bar" v-bind:class="{ 'progress-bar-onpoint': remaining==0, 'progress-bar-success': remaining>0 && percentSpent<=percentDaysPassed, 'progress-bar-danger': remaining<0, 'progress-bar-warning': percentSpent>percentDaysPassed && remaining>0 }" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="width">
            'progress-bar-onpoint': remaining==0
            <span class="text-left pl20">
            {{ widthLabel }}
            </span>
            <span class="text-right pr20">
            </span>
            </div>
            </div>
            </div>-->
<!--				<input type="text" :value="transaction.debit_amount">
				 	<input type="text" :value="transaction.credit_amount">-->
                    <!--
                       -<div class="checkbox">
                       -    <label>
                       -        <input type="checkbox" v-model="budget.is_due_on_recurring_date"
                       -                               v-bind:true-value="1"
                       -                               v-bind:false-value="0"
                       -                               > Is due in full on recurring date
                       -    </label>
                       -</div>
                       -->



						<!-- <div class="col-12">
		  <div class="btn-group btn-group-toggle" data-toggle="buttons">
		  <label class="btn btn-primary active">
		  <input type="radio" name="options" id="option1" autocomplete="off" checked> Yes
		  </label>
		  <label class="btn btn-primary">
		  <input type="radio" name="options" id="option2" autocomplete="off"> No
		  </label>
		  </div>
		  </div> -->
					  <!-- <label class="custom-control-label" for="defaultCheck">
		   Yes
		   </label> -->
				  <!--
		  -<div class="form-check">
		  -    <input class="form-check-input" type="checkbox" v-model="budget.is_recurring"
		  -                                                    v-bind:true-value="1"
		  -                                                    v-bind:false-value="0"
		  -                                                    id="defaultCheck"
		  -                                                    >
		  -                                                    <label class="form-check-label" for="defaultCheck">
		  -                                                        Yes
		  -                                                    </label>
				  -->
				  <!--
		  -</div>
				  -->
					  <!--<option value="day">{{ this.formatPlural(budget.repeat_interval, 'Day') }}</option>
		   <option value="week">{{ this.formatPlural(budget.repeat_interval, 'Week') }}</option>-->
					  <!--<option>Daily</option>
		   <option>Weekly on (day of week)</option>-->
					<!-- <div v-if='budget.is_recurring==1'>
		 <div class="form-row" v-if="budget.repeat_frequency=='week'">
		 <div class="form-group col">
		 <label>On the following day(s):</label>
		 <div class="clearfix"></div>
		 <checkbox v-model="budget.repeat_weekdays" :options="options"></checkbox>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="sunday"
		 > Sunday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="monday"
		 > Monday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="tuesday"
		 > Tuesday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="wednesday"
		 > Wednesday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="thursday"
		 > Thursday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="friday"
		 > Friday
		 </label>
		 </div>
		 <div class="checkbox">
		 <label>
		 <input type="checkbox"
		 v-model='budget.repeat_weekdays'
		 value="saturday"
		 > Saturday
		 </label>
		 </div>
		 </div>
		 </div>
		 <div class="form-row">
		 <div class="form-group col">

		 <div class="form-group col" v-if="budget.ending=='end_after'">
		 <label>Number of occurences</label>
		 <input type="text" class="form-control" v-model="budget.repeat_num_occurrences" @change="setEndDateToNull">
		 </div>
		 <div class="form-group col" v-if="budget.ending=='end_by'">
		 <label>End Date</label>
		 <date @setDate="setEndDate" :date='today'></date>
		 </div>
		 </div>
		 </div>
		 </div> -->
<!--
   -    <div class="modal is-active">
   -        <div class="modal-background"></div>
   -        <div class="modal-content">
   -            <div class="box">
   -                <div class="px-3 py-3">
   -                    <h4 class="mb-5 mt-3">{{ budget.subcategory.name }} Budget</h4>
   -                    <div class="row">
   -                        <div class="col-md-6">
   -                            <div class="form-group">
   -                                <label>Amount</label>
   -                                <currency-input :amount='budget_amount'
   -                                 @subtractFromAmountValue='subtractFromValue(index)'
   -                                 @addToAmountValue='addToValue(index)'
   -                                 @passValue='passValue'
   -                                 @formatCurrency='formatCurrency'
   -                                 :balance='true'
   -                                 @balanceBudget='balanceBudget(budget.id)'
   -                                 ></currency-input>
   -                            </div>
   -                        </div>
   -                        <div class="col-md-6">
   -                            <div class="form-group">
   -                                <label>Spent</label>
   -                                <p class="form-control no-border">{{ formatMoney(budget.spent) }}</p>
   -                            </div>
   -                        </div>
   -                        <div class="col-md-6 mt-4 mt-md-0">
   -                            <div class="form-group">
   -                                <label>Remaining</label>
   -                                <p class="form-control no-border">{{ formatMoney(remaining) }}</p>
   -                            </div>
   -                        </div>
   -                        <div class="col-md-6 mt-4 mt-md-0">
   -                            <div class="form-group">
   -                                <label>Budget Method</label>
   -                                <select class="form-control ui dropdown transparent-dropdown" v-model='budget.is_due_on_recurring_date'>
   -                                    <option value="0">Spread out over month</option>
   -                                    <option value="1">Due in full on due date</option>
   -                                </select>
   -                            </div>
   -                        </div>
   -
   -
   -          <div class="col-12 mt-4 mb-2">
   -              <div class="form-group">
   -                  <label class="mb-3 dark-label">Does this budget repeat?</label>
   -                  <div class="custom-control custom-toggle-checkbox">
   -                      <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
   -                                                                          v-bind:true-value="1"
   -                                                                          v-bind:false-value="0"
   -                                                                          id="defaultCheck">
   -           <div class="toggle-labels">
   -               <label class="unchecked-label" for="defaultCheck">No</label>
   -               <label class="checked-label" for="defaultCheck">Yes</label>
   -           </div>
   -                  </div>
   -              </div>
   -          </div>
   -
   -          <div v-if='budget.is_recurring==1' class="col-sm-6 mt-4 mt-md-0">
   -              <div class="form-group">
   -                  <label>Starting</label>
   -                  <date @setDate="setStartDate" :date='budget.repeat_start'></date>
   -              </div>
   -          </div>
   -          <div v-if='budget.is_recurring==1' class="col-sm-6 mt-4 mt-md-0">
   -              <div class="form-group">
   -                  <label>Ending</label>
   -                  <select class="form-control ui dropdown transparent-dropdown" v-model='budget.ending'>
   -                      <option value="never">No end date</option>
   -                      <option value="end_after">End after</option>
   -                      <option value="end_by">End by</option>
   -                  </select>
   -              </div>
   -          </div>
   -
   -          <div v-if='budget.is_recurring==1' class="col-md-4 col-12">
   -              <div class="form-group">
   -                  <label>Every</label>
   -                  <input type="text" class="form-control no-border" v-model="budget.repeat_interval" value="1">
   -              </div>
   -          </div>
   -          <div v-if='budget.is_recurring==1' class="col-md-4 col-6">
   -              <div class="form-group">
   -                  <label>&nbsp;</label>
   -                  <select class="form-control ui dropdown transparent-dropdown min-width-0" v-model="budget.repeat_frequency">
   -           <option value="month" selected="selected">Month</option>
   -           <option value="year">{{ this.formatPlural(budget.repeat_interval, 'Year') }}</option>
   -                  </select>
   -              </div>
   -          </div>
   -          <div v-if='budget.is_recurring==1' class="col-md-4 col-6">
   -              <div class="form-group">
   -                  <label>&nbsp;</label>
   -                  <select class="form-control ui dropdown transparent-dropdown min-width-0" v-if="budget.repeat_frequency=='month'">
   -           <option value="month">On day {{ start_day }}</option>
   -                  </select>
   -              </div>
   -          </div>
   -          <div v-if='budget.is_recurring==1' class="col-12">
   -              <div class="form-group">
   -                  <label class="mt-2 dark-label">Paid with cash or other method</label>
   -                  <div class="custom-control custom-checkbox">
   -                      <input class="custom-control-input" type="checkbox" v-model="budget_has_been_paid"
   -                                                                          v-bind:true-value="1"
   -                                                                          v-bind:false-value="0"
   -                                                                          id="paidCheck"
   -                                                                          >
   -                                                                          <label class="custom-control-label" for="paidCheck">
   -                                                                              Mark as paid
   -                                                                          </label>
   -                  </div>
   -              </div>
   -          </div>
   -                    </div>
   -
   -
   -         <div class="form-row mt-4">
   -             <div class="form-group order-md-last col-sm-8">
   -                 <div class="text-right text-sm-right text-center">
   -                     <button class="btn btn-outline-primary btn-box-shadow btn-wide" @click="cancel">Cancel</button>
   -                     <button class="btn btn-primary btn-box-shadow btn-wide ml-4" @click="$emit('update', budget)">Save</button>
   -                 </div>
   -             </div>
   -             <div class="form-group order-md-first col-sm-4 text-sm-left text-center">
   -                 <button class="btn btn-danger btn-wide btn-box-shadow mt10" @click="$emit('remove', budget)">Remove</button>
   -             </div>
   -         </div>
   -                </div>
   -            </div>
   -        </div>
   -    </div>
   -->


                            <!--
                                -<div class="form-group">
                                -    <label>Budget Type</label>
                                -    <select class="form-control ui dropdown transparent-dropdown" v-model='budget.is_due_on_recurring_date'>
                                -        <option value="0">Standard (Spread out over month)</option>
                                -        <option value="1">Bill (Due in full on due date)</option>
                                -    </select>
                                -</div>
                            -->
