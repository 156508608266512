var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("circle", {
        staticClass: "cls-1",
        attrs: { cx: "283.52", cy: "58", r: "30.94" }
      }),
      _c("polyline", {
        staticClass: "cls-1",
        attrs: {
          points:
            "328.31 187.27 356.12 187.27 325.75 108.01 235.38 108.01 211.67 187.27 245.16 187.27"
        }
      }),
      _c("polyline", {
        staticClass: "cls-1",
        attrs: {
          points: "258.34 143.57 218.34 276.21 356.12 276.21 314.63 143.57"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M252.45,282.21v81.93c0,11.72,27.75,11.72,27.75,0,0,11.72,27.09,11.72,27.09,0V282.21"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "283.52", y1: "276.21", x2: "283.52", y2: "364.14" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M185.81,267.77a71,71,0,1,1-71-71v71Z" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "43.88", y1: "267.77", x2: "114.85", y2: "267.77" }
      }),
      _c("circle", {
        staticClass: "cls-1",
        attrs: { cx: "79.36", cy: "352.34", r: "20.59" }
      }),
      _c("circle", {
        staticClass: "cls-1",
        attrs: { cx: "152.21", cy: "352.34", r: "20.59" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }