var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _vm.isLoggingIn
        ? _c("full-page-loading-screen")
        : _c("div", { staticClass: "main-wrap login" }, [
            _c(
              "form",
              {
                staticClass: "form-signin",
                attrs: { method: "POST", action: "/reset-password" }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center mb-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link font-weight-bold small",
                        attrs: { to: { name: "home" } }
                      },
                      [
                        _c("img", {
                          staticClass: "mb-4",
                          attrs: {
                            src: "/images/logo.png",
                            alt: "Budget It Up Logo"
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-label-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      value: "",
                      id: "inputEmail",
                      placeholder: "Email address",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "inputEmail" } }, [
                    _vm._v("Email address")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-icon" }, [
                    _c("i", { staticClass: "fa fa-envelope" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-label-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password,
                        expression: "user.password"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      id: "inputPassword",
                      placeholder: "Password",
                      required: ""
                    },
                    domProps: { value: _vm.user.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "password", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "inputPassword" } }, [
                    _vm._v("Password")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-icon" }, [
                    _c("i", { staticClass: "fa fa-lock" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-label-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password_confirmation,
                        expression: "user.password_confirmation"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      id: "inputPasswordConfirmation",
                      placeholder: "Password confirmation",
                      required: ""
                    },
                    domProps: { value: _vm.user.password_confirmation },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.user,
                          "password_confirmation",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "inputPasswordConfirmation" } }, [
                    _vm._v("Password confirmation")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-icon" }, [
                    _c("i", { staticClass: "fa fa-lock" })
                  ])
                ]),
                _vm._v(" "),
                _c("errors", { attrs: { errors: _vm.errors } }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-lg btn-outline-primary btn-block mt-5",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.sendReset($event)
                      }
                    }
                  },
                  [_vm._v("Reset Password")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mt-5 mb-3 text-muted text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "underline-link",
                      attrs: { href: "/signup" }
                    },
                    [_vm._v("Don't have an account?")]
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-5 mb-3 text-muted text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "underline-link",
                      attrs: { href: "/login" }
                    },
                    [_vm._v("Remember your password?")]
                  )
                ])
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }