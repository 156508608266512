<template>
	<div>
		<header class="masthead container-fluid container-fluid-90">
			<div class="intro-text row">
				<div class="col-12">
					<img src="images/logo.png" class="logo">
					<h2 class="">Create budgets.</h2>
					<h2 class="h-green">Track your spending.</h2>
					<router-link :to="{ name: 'sign-up' }" class="btn btn-primary btn-box-shadow btn-primary-gradiend btn-lg d-none d-md-inline-block">
						Join for Free
					</router-link>

                    <p class="green-underline">
                    Introducing Budget It Up, the user-friendly budgeting platform designed to help you take control of your finances. Our streamlined interface makes it easy to track your spending, set budgets, and achieve your savings goals. With Budget It Up, you'll always know exactly how much money you have available to spend on the things that matter most to you.
                    </p>
                    <p class="green-underline">
                    Say goodbye to financial stress and hello to a brighter financial future with Budget It Up. Our powerful tools and intuitive interface make managing your money a breeze. Join the Budget It Up community today and start reaching your financial goals like a pro!
                    </p>
                    <p class="green-underline">
                    Sign up now and experience the power of effortless financial management. With Budget It Up, your financial dreams are within reach.
                    </p>
                    <router-link :to="{ name: 'sign-up' }" class="btn btn-primary btn-box-shadow btn-lg d-inline-block d-md-none">
                        Join for Free
                    </router-link>

				</div>
			</div>
		</header>

		<section class="container-fluid container-fluid-90" id="intro-section">
			<div class="row">

				<div class="col-12">

				</div>

				<div class="col-sm-10 offset-sm-1">
					<div class="container-fluid">

						<div class="row align-items-center">
							<div class="col-sm-12 col-md-5 order-md-last">
								<h3 class="h1-size text-center text-md-left">Categories? Yup.</h3>
								<p class="text-center text-md-left">Import your bank transactions and easily categorize your spending.</p>
							</div>

							<div class="col-sm-12 col-md-7 order-md-first">
								<img class="img-fluid rounded float-md-right" src="images/screenshot1.jpg">
							</div>
						</div><!-- .row -->

						<div class="row align-items-center">
							<div class="col-sm-12 col-md-5">
								<h3 class="h1-size text-center text-md-right">Budget it up.</h3>
								<p class="text-center text-md-right">Create budgets for each category of spending.</p>
							</div>

							<div class="col-sm-12 col-md-7">
								<img class="img-fluid rounded" src="images/screenshot2.jpg">
							</div>
						</div><!-- .row -->
						<div class="row align-items-center">
								<div class="col-sm-12 col-md-5 order-md-last">
									<h3 class="h1-size text-center text-md-left">Saving Goals</h3>
									<p class="text-center text-md-left">Create savings goals and keep track of your progress.</p>
								</div>

								<div class="col-sm-12 col-md-7 order-md-first">
									<img class="img-fluid rounded float-md-right" src="images/screenshot3.jpg">
								</div>
							</div><!-- .row -->

					</div><!-- .container-fluid -->
				</div><!-- .col-sm-10 -->


			</div>
		</section>

		<!-- #save-section -->
		<section class="container-fluid" id="save-section">
			<h3 class="h1-size">Save</h3>
			<p>A Budget It Up plan will help you to grow your savings. Budget It Up will give you a picture of your monthly income versus your monthly spending and expenses, to calculate your disposable income. You can determine which expenses to reduce or eliminate, and start budgeting for monthly savings.</p>
			<div class="section-connector">
				<img src="images/piggy.png" class="img-fluid">
			</div>
		</section>

		<!-- #how-works -->
		<section id="how-works" class="container-fluid container-fluid-90">
			<div class="row">
				<div class="col-12">
					<h2 class="h1-size">How it works</h2>
				</div>
				<div class="col-12 col-md-6 col-lg-3">
					<div class="card">
						<div class="card-icon">
							<img src="images/capital-icon.png">
						</div>
						<div class="card-body">
							<h4>Import transcations</h4>
							<p>Import your transactions directly from your bank</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3">
					<div class="card">
						<div class="card-icon">
							<img src="images/car-pen-icon.png">
						</div>
						<div class="card-body">
							<h4>Add Recurring Bills and Income</h4>
							<p>You get paid on the 1st and 15th, and your rent, utilities, and phone are due on...</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3">
					<div class="card">
						<div class="card-icon">
							<img src="images/report-icon.png">
						</div>
						<div class="card-body">
							<h4>Categorize Transaction</h4>
							<p>Attach categories to income and expenses, get a picture of your spending habits.</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3">
					<div class="card">
						<div class="card-icon">
							<img src="images/statistics-icon.png">
						</div>
						<div class="card-body">
							<h4>Build Budgets & Save</h4>
							<p>Build categorized budgets to provide spending guidance so you don't overspend.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- #get-started -->
		<section class="container-fluid" id="get-started">
			<h2 class="h1-size">Get started today</h2>
			<p>Budget It Up helps you save more money.</p>
			<router-link :to="{ name: 'sign-up' }" class="btn btn-light">
				Join For Free
			</router-link>
		</section>

		<!-- .main-footer -->
		<footer class="main-footer container-fluid">
			<a href="" class="backtotop" id="backtotop">
				<img src="images/arrow-up-icon.png">
			</a>
			<span class="copyright-msg">&#x24B8; 2018 Budget It Up, LLC All Rights Reserved</span>
		</footer>

	</div>
</template>

<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
	},
	mounted:function(){
		// Collapse Navbar
		var navbarCollapse = function() {
            if($("#mainNav").length) {
                if ($("#mainNav").offset().top > 100) {
                    $("#mainNav").addClass("navbar-shrink");
                } else {
                    $("#mainNav").removeClass("navbar-shrink");
                }
            }
		};
		// Collapse now if page is not at top
		navbarCollapse();
		// Collapse the navbar when page is scrolled
		$(window).scroll(navbarCollapse);


	    $('#backtotop').on('click', function (e) {
	        e.preventDefault();
	        $('html,body').animate({
	            scrollTop: 0
	        }, 700);
	    });

	}
}

</script>
