<template>
<transition
name="fade"
>
<div class="empty-page">
	<h5>{{ headline }}</h5>
	{{ message }}
</div>
</transition>

</template>
<script>
export default {
    props: {
        page: {
            type: String,
            required: true
        },
    },
	computed: {

        headline() {

            switch(this.page) {
                case 'accounts':
                    if(!this.$store.state.user.plaid_id && this.$store.state.accounts.length===0) {
                        return 'Create some accounts.';
                    } else if(this.$store.state.accounts.length!==0 && this.$store.state.this_month_transactions.length==0) {
                        return 'Create some transactions';
                    } else {
                        return null;
                    }
                    break;
                case 'budgets':
                    if(this.$store.state.budgets.length===0) {
                        return 'Create some budgets';
                    } else {
                        return null;
                    }
                    break;
                case 'goals':
                    if(this.$store.state.goals.length===0) {
                        return 'Create some goals';
                    } else {
                        return null;
                    }
                    break;
                default:
                    return null;
            }

        },
        message() {

            switch(this.page) {
                case 'accounts':
                    if(!this.$store.state.user.plaid_id && this.$store.state.accounts.length===0) {
                        return 'Get started by clicking \'Add Account\' button above.';
                    } else if(this.$store.state.accounts.length!==0 && this.$store.state.this_month_transactions.length==0) {
                        return 'Get started by clicking \'Add Transaction\' button above.';
                    } else {
                        return null;
                    }
                    break;
                case 'budgets':
                    if(this.$store.state.budgets.length===0) {
                        return 'Get started by clicking \'Add Budget\' button above.';
                    } else {
                        return null;
                    }
                    break;
                case 'goals':
                    if(this.$store.state.goals.length===0) {
                        return 'Get started by clicking \'Add Goal\' button above.';
                    } else {
                        return null;
                    }
                    break;
                default:
                    return null;
            }

        },
        image() {
            switch(this.page) {
                case 'accounts':
                    if(!this.$store.state.user.plaid_id && this.$store.state.accounts.length===0) {
                        return '/images/graph-thumb.png';
                    } else if(this.$store.state.accounts.length!==0 && this.$store.state.this_month_transactions.length==0) {
                        return '/images/transaction.png';
                    } else {
                        return null;
                    }
                    break;
                case 'budgets':
                    if(this.$store.state.budgets.length===0) {
                        return '/images/graph-thumb.png';
                    } else {
                        return null;
                    }
                    break;
                case 'goals':
                    if(this.$store.state.goals.length===0) {
                        return '/images/bars-of-gold.png';
                    } else {
                        return null;
                    }
                    break;
                default:
                    return null;
            }

        }
	}
}
</script>
