var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-15")]),
      _c("path", {
        attrs: {
          d:
            "M338,132.33c-7.31-12.93-19.76-22.45-36.14-27.67a39.11,39.11,0,0,0-18.06-23c-9.56-5.45-21-6.69-32.66-3.62-6.16-7.08-22.48-22.15-50.66-23.51-.23,0-.47,0-.71,0h-.15C171.39,55.88,155.07,71,148.91,78c-11.64-3.07-23.1-1.83-32.66,3.62a39.11,39.11,0,0,0-18.06,23c-16.38,5.22-28.83,14.74-36.14,27.67a53.87,53.87,0,0,0-4.87,41,54.43,54.43,0,0,0,25.35,33.08c12.16,7,26.64,9.1,42.23,6.2l8.73,82.48h0l4.77,45a6,6,0,0,0,6,5.37H255.77a6,6,0,0,0,6-5.37l13.5-127.51a71.08,71.08,0,0,0,13.1,1.24c28.56,0,48.45-18.48,54.48-40.51A53.87,53.87,0,0,0,338,132.33ZM200,333.49H149.62l-3.49-33H253.87l-3.49,33ZM331.25,170.17A42.51,42.51,0,0,1,311.48,196c-11.08,6.37-24.88,7.57-39.91,3.45a6,6,0,0,0-7.55,5.15l-8.88,83.87H144.86L136,204.62a6,6,0,0,0-7.56-5.15c-15,4.12-28.83,2.92-39.91-3.45a42.51,42.51,0,0,1-19.77-25.85,41.92,41.92,0,0,1,3.75-31.93c6.2-11,17.36-18.93,32.27-23a6,6,0,0,0,4.3-4.62,27,27,0,0,1,13.12-18.53c7.63-4.34,17.17-4.86,26.88-1.44a6,6,0,0,0,7-2.33C156.19,88.09,169.9,68,200,66.52c29.82,1.5,43.39,21,43.94,21.78a6,6,0,0,0,7,2.33c9.71-3.42,19.25-2.9,26.88,1.44a27,27,0,0,1,13.12,18.53,6,6,0,0,0,4.3,4.62C327.87,124.17,336.78,150,331.25,170.17Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }