<template>
<div class="row editable">
	<div class="form-group">
		<div class="col-md-4">
			{{ expense.subcategory.name }}
		</div>
		<div class="col-md-4">
			<span class="medium">
				${{ expense.credit_amount }}
			</span>
		</div>
		<div class="col-md-2">
			<p class="medium">{{ expense.repeat_day }}th</p>
		</div>
		<div class="col-md-2">
			<a @click="$emit('edit', expense)">Edit <i class="fa fa-pencil"></i></a>
		</div>
	</div>
</div>
</template>
<script>
	export default {
		props:["expense"],
		methods: {
		}
	}
</script>
