<template>
	<div class="h-100">
		<full-page-loading-screen v-if='isLoggingIn'></full-page-loading-screen>
		<div class="main-wrap login" v-else>
			<form class="form-signin" method="POST" action="/login">
				<div class="text-center mb-4">
					<router-link :to="{ name: 'home' }" class="nav-link font-weight-bold small">
						<img src="/images/logo.png" class="mb-4" alt="Budget It Up Logo">
					</router-link>
					</a>
				</div>
				<div class="form-label-group">
					<input type="email" v-model="user.email" value="" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
					<label for="inputEmail">Email address</label>
					<div class="input-icon">
						<i class="fa fa-envelope"></i>
					</div>
				</div>
				<div class="form-label-group">
					<input type="password" id="inputPassword" class="form-control" v-on:keyup.13="login($event)" v-model="user.password" placeholder="Password" required>
					<label for="inputPassword">Password</label>
					<div class="input-icon">
						<i class="fa fa-lock"></i>
					</div>
				</div>
				<div class="form-group mt-4 mb-0">
					<div class="form-row">
						<div class="col-6 custom-control custom-checkbox">
							<input type="checkbox" value="remember-me" name="rememberme" id="rememberme" class="custom-control-input">
							<label class="custom-control-label" for="rememberme">Remember me</label>
						</div>
						<div class="col-6 text-right">
							<router-link :to="{ name: 'password-reset' }" class="underline-link">
								Forgot Your Password?
							</router-link>
						</div>
					</div>
				</div>
				<errors :errors="errors"></errors>
				<a href="#" class="btn btn-lg btn-outline-primary btn-block mt-5" @click="login($event)" id='signIn'>Sign in</a>
				<p class="mt-5 mb-3 text-muted text-center">
				<router-link :to="{ name: 'sign-up' }" class="underline-link">
					Don't have an account?
				</router-link>
				</p>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	mounted: function() {
		document.getElementById('app').className='h-100';
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
		document.getElementById('app').className='';
	},
	data() {
		return {
			user: {
				email: null,
				password: null
			},
		}
	},
	computed: {
		isLoggingIn() {
			return this.$store.state.isLoggingIn;
		},
		errors() {
			return this.$store.state.login_errors;
		},
	},
	methods: {
		login(event) {
			event.preventDefault();
			this.$store.commit('clearLoginErrors');
			if(this.validate()) {
				this.$store.dispatch('login', {userCredentials: this.user})
			}
		},
		validate() {
			if(this.user.email && this.user.password) {
				return true;
			} else if (this.user.email && !this.user.password) {
                this.$store.commit('addErrors', {'errorsVar': 'login_errors', 'error': 'Please enter your password.'});
			} else if (!this.user.email && this.user.password) {
                this.$store.commit('addErrors', {'errorsVar': 'login_errors', 'error': 'Please enter your email.'});
			}
		}
	}
}
</script>
