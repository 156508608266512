<template>
	<transition
		name="fade"
		>
		<div class="modal inside-close-btn is-active">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<div class="px-3 py-3">
						<h4 class="mb-5 mt-2">{{ budget_transactions_budget.subcategory_name }} Transactions</h4>
						<div class="row mb-3">
							<div class="col-3">
								<p class="strong green">Place</p>
							</div>
							<div class="col-3">
								<p class="strong green">Amount</p>
							</div>
							<div class="col-3">
								<p class="strong">Bank / Credit Card</p>
							</div>
							<div class="col-3">
								<p class="strong green">Date</p>
							</div>
						</div>
						<div class="row mb-2" v-for="transaction in budget_transactions">
							<div class="col-3">
								<p class="strong">{{ transaction.entity.name }}</p>
							</div>
							<div class="col-3">
								<p class="strong">{{ sumCategorySpending(transaction.subcategories) }}</p>
							</div>
							<div class="col-3">
								<p class="strong">{{ transaction_account_name(transaction) }}</p>
							</div>
							<div class="col-3">
								<p class="strong">{{ transaction.date_short }}</p>
							</div>
						</div>
						<p class="total border-top mt-3 pt-4 pb-4 text-center green">
						<span>Total - </span>
						<span>{{ formatMoney(transactions_total) }}</span>
						</p>
					</div>
				</div>
				<button class="modal-close" @click="skip"></button>
			</div>
		</div>
	</transition>
</template>
<script>
    export default {
		data() {
			return {
				counter: 0,
			}
		},
		mounted() {
            console.log(this.transaction);
		},
		computed: {
			budget_transactions() {
				return this.$store.state.budget_transactions;
			},
			budget_transactions_budget() {
				return this.$store.state.budget_transactions_budget;
			},
            transactions_total() {

                var matching, that = this, sum, grand = 0;
                this.$store.state.budget_transactions.forEach(function(transaction, key) {
                    matching = transaction.subcategories.find(subcategory => Number(subcategory.id) === Number(that.budget_transactions_budget.transaction_subcategory_id));
                    if (matching) {
                        sum = matching.pivot.amount;
                        grand +=sum;
                    }
                });
                return grand;
            }
		},
		methods: {
            transaction_account_name(transaction) {
                var name = '';
                if(transaction.account) {
                    name = transaction.account.name;
                }
                return name;
            },
            sumCategorySpending(subcategories) {
                var sum=0;
                if(subcategories.length>1) {
                    sum = subcategories.reduce(function(total, subcategory) { return total +Number(subcategory.pivot.amount); });
                } else {
                    sum = subcategories[0].pivot.amount;
                }

                return accounting.formatMoney(sum);

            },
            formatMoney(amount) {
                return accounting.formatMoney(amount);
            },
			skip() {
				this.$store.commit('setToggleShowBudgetTransactionsModal', false)
			},
		}
	}
</script>
