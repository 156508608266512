<template>
    <div class="row">
        <div class="col-12">
            <div class="editable single-transation container-fluid">
                <div class="row">
                    <div class="col-2">
                        <span class="dateContain">
                            <span class="dateMonth ml-1">
                                <div class="text-line"> </div>
                            </span>
                            <span class="dateDay ml-1">
                                <div class="text-line"> </div>
                            </span>
                        </span>
                    </div>
                    <div class="col-4">
                        <div class="text-line"> </div>
                    </div>
                    <div class="col-3">
                        <div class="text-line"> </div>
                    </div>
                    <div class="col-2 text-right">
                        <div class="text-line"> </div>
                    </div>
                    <div class="col-1">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
