<template>
	<select class="form-control ui dropdown transparent-dropdown">
		<option v-for="(daccount, index) in accounts" v-if="accounts.length>0" v-bind:value='daccount.id'>{{ daccount.name }}</option>
	</select>
</template>
<script>
    export default {
		props: {
			accounts: Array,
			disabled: false,
			current: null
		},
		mounted: function () {
			var that = this;
			$(this.$el).dropdown({
			     onChange: function(selected) {
			         that.onSelect(selected);
			     }
			});
			if (this.current) {
				$(this.$el).dropdown('set selected', this.current);
			}

		},
		methods: {
			onSelect(selected) {
				this.$emit('update', selected);
			}
		}
	}
</script>
