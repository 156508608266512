var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-box" },
    [
      _c("h4", { staticClass: "mt0" }, [
        _vm._v(
          "UNCATEGORIZED TRANSACTIONS (" +
            _vm._s(_vm.this_month_uncategorized_transactions_count) +
            ")"
        )
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("transactions", { attrs: { account_id: _vm.account.id } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row col-headers dlines" }, [
      _c("div", { staticClass: "col-md-1" }, [_vm._v("\n\t\t\tDate\n\t\t")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [_vm._v("\n\t\t\tType\n\t\t")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [_vm._v("\n\t\t\tEntity\n\t\t")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-2 text-right" }, [
        _vm._v("\n\t\t\tAmount\n\t\t")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }