<template>
	<div>
		<button class="btn btn-danger btn-box-shadow btn-wide" @click="remove(bank)">Remove</button>
		<button class="btn btn-cancel-grey btn-box-shadow btn-wide" @click="cancel">Cancel</button>
	</div>
</template>
<script>
    export default {
		props: ['bank'],
		methods: {
			remove(bank) {
				this.$store.dispatch('delete_connected_bank', {
					connected_bank: bank
				});
			},
			cancel() {
				this.bank.mode = 'connected-bank';
			}
		}
	}
</script>
