window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.Tether = require('tether');
window.Popper = require('popper.js').default;
require('bootstrap');
window.accounting = require("accounting");
import 'babel-polyfill'
import FormValidator from './Validator';
window.FormValidator = FormValidator;
import Register from './Register';
window.Register = Register;
//window.TWEEN = require('@tweenjs/tween.js');
window.Vue = require('vue');
window.Vuex = require('vuex');
window.VueRouter = require('vue-router').default;
window.creditCardType = require('credit-card-type');
window.pluralize = require('pluralize')

const CONFIG = {
   API_BASE_URL: 'http://api.budgetitup.here/',
  // API_BASE_URL: 'https://api.budgetitup.com/',
}
window.axios = require('axios');
window.axios.defaults.baseURL = CONFIG.API_BASE_URL;
window.Chartist = require('chartist');
import fontawesome from '@fortawesome/fontawesome-free';
