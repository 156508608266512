var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row boxshadow-1 institution-row" }, [
    _c("div", { staticClass: "col-6 col-lg-5" }, [
      _c("span", { staticClass: "institution-name" }, [
        _vm._v(_vm._s(_vm.bank.name))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 col-lg-3 num-accounts-col" }, [
      _c("span", [_vm._v(_vm._s(_vm.bank.accounts.length) + " accounts")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-4 buttons-cont" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-edit btn-box-shadow btn-wide",
          on: { click: _vm.editAccount }
        },
        [_vm._v("Edit")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-cancel-grey btn-box-shadow btn-wide" },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-danger btn-box-shadow btn-wide" }, [
        _vm._v("Remove")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }