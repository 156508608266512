<template>
	<div>
        <div class='input-group date'>
            <input type="text" name="date" class="form-control datepicker no-border" data-date-format="mm/dd/yyyy" placeholder="Select date">
			<div class="input-group-addon">
        <icon-base icon-name="calendar" width='25' height='25' view-box='0 0 25 25' class="icon-right-nm"><icon-calendar slot="icon"></icon-calendar></icon-base>

			</div>
        </div>
	</div>
</template>
<script>
    export default {
		props: ['date'],
		computed: {
		},
		created() {
		},
		mounted: function () {

			// instantiate the datepicker
//			var dp = $(this.$el.firstChild).datepicker();
            var dp = $(this.$el).find(".datepicker").datepicker();
			var d = (this.date) ? this.date : null;//new Date();

			if(d) {
				dp.datepicker('setDate',new Date(d));
				// set the transaction date on the model
				this.$emit('setDate', dp.val());
			}
			var that = this;
            if(dp) {
                dp.change(function () {
                    that.$emit('setDate', dp.val());
                });
            }
		},
	}
// <div class="ui floating labeled icon dropdown button">
// <button v-on:click="counter -= 1">-</button><slot></slot><input type="text" v-model="counter"><button v-on:click="counter += 1">+</button>
</script>
