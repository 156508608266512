<template>
	<select class="form-control ui dropdown transparent-dropdown">
		<option :value="null">Select {{ defaultItem }}</option>
		<option v-for="(item, index) in items" v-if="items.length>0" :key="item.id" v-bind:value='item.id'>{{ item.name }}</option>
	</select>
</template>
<script>
    export default {
		data: function() {
			return {
			}
		},
		props: {
			items: Array,
			defaultItem: String,
			disabled: false,
			preSelect: null,
		},
		mounted: function () {

			var that = this;
			$(this.$el).dropdown({
			     onChange: function(selected) {
                     that.$emit('update', selected);
			     }
			});
			if (this.preSelect) {
				$(this.$el).dropdown('set selected', this.preSelect);
			}

		},
		watch: {
			value: function (newValue) {
				this.preSelect = newValue
			}
		}
	}
</script>
