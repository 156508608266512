var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.plaid_error
        ? _c(
            "div",
            { staticClass: "container connected-institutions" },
            [
              _vm.notification
                ? _c(
                    "div",
                    {
                      staticClass:
                        "alert alert-primary alert-dismissible fade show",
                      attrs: { role: "alert" }
                    },
                    [
                      _c("strong", [_vm._v(_vm._s(_vm.notification.title))]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.notification.message) +
                          "\n            "
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-dismiss": "alert",
                            "aria-label": "Close"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setNotificationUser(
                                _vm.notification.id
                              )
                            }
                          }
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×")
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm.connected_banks.length == 0
                ? _c("div", { staticClass: "row" }, [_vm._m(1)])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.connected_banks, function(bank, index) {
                return _c(bank.mode, {
                  key: bank.index,
                  tag: "connected-bank",
                  attrs: { bank: bank },
                  on: {
                    remove: function($event) {
                      return _vm.remove(bank, index)
                    }
                  }
                })
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.showModal }
                },
                [_vm._v("Connect")]
              )
            ],
            2
          )
        : _c("plaid-error-card"),
      _vm._v(" "),
      _c("connect-bank-account-modal")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Connected Institutions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h5", [
        _vm._v(
          "You have no connected banks or credit cards, click connect below to connect one now."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row header-row" }, [
      _c("div", { staticClass: "col-5" }, [_c("span", [_vm._v("Bank Name")])]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c("span", [_vm._v("No.of accounts")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }