<template>
	<div>
	    <select class="form-control ui dropdown transparent-dropdown" id="entity">
			<option value="0">Select a place/entity</option>
			<option :value="(entity) ? entity.id : null" v-for="entity in entities"  v-bind:selected="(entity && current) ? Number(entity.id) === Number(current.id) : null">{{ entity.name }}</option>
		</select>
		<p><a href="#" @click="set_current_input('add-new-entity', $event)">Add new</a></p>
	</div>
</template>
<script>
    export default {
		props: {
			current: null,
		},
		computed: {
			entities () {
				return this.$store.getters.entities;
			}
		},
		mounted: function () {

			var that = this;
			$('#entity')
			  .dropdown({
					onChange: function(selected) {
						that.onSelect(selected);
					}
				});
            if(this.current) {
		    	$('#entity').dropdown('set selected', this.current.id);
            }
		},
		watch: {

			entities: function(newValue, oldValue) {

				$('#entity').dropdown('refresh');
				var newEntity = _.differenceBy(newValue, oldValue,'name');
				if (newEntity) {
					$('#entity').dropdown('set selected', newEntity[0].id);
				}

			},
		},
		methods: {
            set_current_input(input, event) {
                event.preventDefault();
                this.$store.commit('setCurrentInput', input);
            },
			addNew(event) {
                event.preventDefault();
				this.$store.commit('setToggleAddNewEntityModal', true)
			},
			onSelect(selected) {
				this.$emit('setEntity', selected);
			},
		}
	}
// <div class="ui floating labeled icon dropdown button">
// <button v-on:click="counter -= 1">-</button><slot></slot><input type="text" v-model="counter"><button v-on:click="counter += 1">+</button>
</script>
