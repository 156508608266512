var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMFAModal
    ? _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "modal is-active" }, [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content-login" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col" },
                  [
                    _c("h3", { staticClass: "text-center" }, [_vm._v("MFA")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v("Complete the MFA form below")
                    ]),
                    _vm._v(" "),
                    _vm.mfaResponseModel
                      ? _c("p", [
                          _vm._v(
                            _vm._s(_vm.mfaResponseModel.mfa_message.message)
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("label", { attrs: { for: "name" } }, [
                          _vm._v("Code/Access token")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mfa_response,
                              expression: "mfa_response"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "username", id: "name" },
                          domProps: { value: _vm.mfa_response },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.mfa_response = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" }),
                    _vm._v(" "),
                    _c("errors", { attrs: { errors: _vm.errors } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("div", { staticClass: "text-right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link",
                              on: { click: _vm.close }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-large btn-primary",
                              attrs: { type: "submit" },
                              on: { click: _vm.submit }
                            },
                            [_vm._v("Submit")]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" }),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "modal-close",
                      on: { click: _vm.close }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }