<template>
    <div class="bg-light container account-info-inner boxshadow-1">
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="card bg-white flex-md-row mb-4 box-shadow h-md-250">
                    <div class="card-body" v-if='currentPlan'>
                        <div class="row">
                            <div class="col-12">
                                <h5 class="h-green text-center"><strong>Your Plan</strong></h5>
                                <h6 class="text-center"><strong>{{ currentPlan.name }}</strong></h6>
                                <h6 class="text-center" v-if='currentPlan.isPaidSubscription'><strong>${{ currentPlan.amount }} / {{ currentPlan.billingInterval }}</strong></h6>
                                <h6 class="text-center" v-else><strong>{{ (typeof(currentPlan.price) === 'string') ? currentPlan.price : '$' + currentPlan.price }}</strong></h6>
                            </div>
                        </div>
                        <div class="row" v-if='currentPlan.isPaidSubscription'>
                            <div class="col-12">
                                <p class="text-center">
                                    Your plan will automatically renew on <strong>{{ currentPlan.next_bill_date }}</strong> and you'll be charged <strong>${{ currentPlan.price }}</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5 text-center">
            <div class="col-12">
                <h5><strong>Change plans</strong></h5>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="card bg-white flex-md-row mb-4 box-shadow h-md-250">
                    <div class="card-body">
                        <div class="row" v-for="(plan, index) in availablePlans" v-if='plan.isCurrent==false'>
                            <div class="col-8">
                                <h6 class="m-2">
                                    <strong><a class="text-dark" href="#">{{ plan.name }}</a></strong>
                                </h6>
                                <h6 class="m-2">
                                    <a class="text-dark" href="#">{{ plan.benefits }}</a>
                                </h6>
                                <h6 class="m-2">
                                    <a class="text-muted" href="#">{{ (plan.impactOfSwitching) ? plan.impactOfSwitching : '$' + plan.price + ' / ' + plan.billingInterval }}</a>
                                </h6>
                            </div>
                            <div class="col-4 d-flex align-items-center text-center" v-if="plan.isPaidSubscription && plan.isCurrent">
                                <button class="btn btn-primary" @click='switchPlan(plan.id)'>{{ plan.cancelButtonText }}</button>
                            </div>
                            <div class="col-4 d-flex align-items-center text-center" v-if="plan.isPaidSubscription && !plan.isCurrent">
                                <button class="btn btn-primary" @click='switchPlan(plan.id)'>{{ plan.upgradeButtonText }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        data: function() {
            return {
                subscription:null,
                plan: {name: ''},
                availablePlans: []
            }
        },
        methods: {
            switchToBasic () {
                this.$store.dispatch('switch_to_basic').then(() => {
                    this.subscription = this.$store.state.subscriptions;
                });
            },
            switchPlan(planId){
                if(planId==2) {
                    this.$router.push("/purchase/plan/boss");
                }
                if(planId==3) {
                    this.$router.push("/purchase/plan/pro");
                }
                if(planId==4) {
                    this.$router.push("/purchase/plan/advanced");
                }
            },
            round (value, decimals) {
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
            },
        },
        mounted: function() {

            var that = this;
            this.$store.dispatch('get_subscriptions').then(() => {
                this.availablePlans = [{
                    id: 1,
                    name: 'Basic Budgeter',
                    benefits: 'Cannot import transactions from banks and credit cards.',
                    impactOfSwitching: 'Switching to Basic Budgeter will cancel your Budget Boss subscription.',
                    isPaidSubscription: true,
                    isCurrent: false,
                    price: 'FREE',
                    billingInterval: null,
                    cancelButtonText: 'Delete Account and All Data',
                    upgradeButtonText: 'Add Transaction Imports'
                }]
                that.subscription = this.$store.state.subscriptions;
            }) .catch((e)=>{
                this.availablePlans = [{
                    id: 1,
                    name: 'Basic Budgeter',
                    benefits: 'Cannot import transactions from banks and credit cards.',
                    impactOfSwitching: 'Switching to Basic Budgeter will cancel your Budget Boss subscription.',
                    isPaidSubscription: false,
                    isCurrent: true,
                    price: 'FREE',
                    billingInterval: null,
                    cancelButtonText: 'Delete Account and All Data',
                    upgradeButtonText: 'Add Transaction Imports'
                },
                {
                    id: 2,
                    name: 'Budget Boss',
                    benefits: 'Imports transactions from 1 financial institution.',
                    impactOfSwitching: null,
                    isPaidSubscription: true,
                    isCurrent: false,
                    price: 5.99,
                    billingInterval: 'month',
                    cancelButtonText: 'Cancel '+ this.name,
                    upgradeButtonText: 'Add Transaction Imports'
                },
                ]
                this.plan = {
                    name: 'Basic Budgeter'
                }
            });

        },
        computed: {
            currentPlan: function() {
                var currentPlan = this.availablePlans.find(function(plan){return plan.isCurrent==true;});
                console.log('==> currentPlan', JSON.stringify(currentPlan));
				return currentPlan;
            },
            user: function() {
                return this.$store.state.user;
            },
            userName: function () {
                return 'test';
            },
            userEmail: function () {
                return 'test';
            },
            subscriptionPlanName: function () {
                return 'Budget Boss';
            },
            subscriptionAmount: function () {
                return 9.99;
            },
            subscriptionStartDate: function () {
                return 'test';
            },
            subscriptionNumberOfAvailableConnectedBanks: function () {
                return 'test';
            },
        },
    }
</script>

