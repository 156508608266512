<template>
	<transition
		name="fade"
		>
		<div class="modal is-active new-transation-modal">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
                    <div class="px-3 py-3">
                        <h4 class="mb-4 mt-2 text-center">New Transaction</h4>
						<div class="form-group top-radio-btns">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="options" id="option2" autocomplete="off">
                                <label class="custom-control-label price-value two income-text" for="option2" v-on:click="setType('income')">Income</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="options" id="option3" autocomplete="off">
                                <label class="custom-control-label price-value two expense-text" for="option3" v-on:click="setType('expense')">Expense</label>
                            </div>
                           <!--  <div class="btn-radio-group">
                                <input type="radio" name="options" id="option2" autocomplete="off">
                                <label class="btn btn-outline-primary" for="option2" v-on:click="setType('income')">Income</label>
                                <input type="radio" name="options" id="option3" autocomplete="off">
                                <label class="btn btn-outline-primary" for="option3" v-on:click="setType('expense')">Expense</label>
                            </div> -->
                        </div>
							<p class='top-radio-btns' v-if="showChooseType==true">Choose Income or Expense</p>
                        <div class="row">
                            <div class="col-8 form-group">
                                <label>Amount</label>
                                <currency-input :amount='transaction.amount'
                                 @subtractFromAmountValue='subtractFromAmountValue'
                                 @addToAmountValue='addToAmountValue'
                                 @passValue='passValue'
                                 ></currency-input>
                            </div>
                            <div class="col-sm-4 form-group">
                                <label>Date</label>
                                <date @setDate="setDate" :date='first'></date>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 form-group" @click='handleNoType'>
                                <label>Category</label>
                                <div class="clearfix"></div>
                                <div class="clearfix"></div>
								<semantic-select :items="expense_categories" @update='setCategory' v-if='type=="expense"'></semantic-select>
								<semantic-select :items="income_categories" @update='setCategory' v-else-if='type=="income"'></semantic-select>
                            </div>
                            <div class="col-sm-6 form-group" @click='handleNoType'>
                                <label>Sub-category</label>
                                <div class="clearfix"></div>
								<semantic-select :items="subcategories" @update='setSubcategory' v-if='type=="expense"'></semantic-select>
								<semantic-select :items="subcategories" @update='setSubcategory' v-else-if='type=="income"'></semantic-select>
							</div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label>Where did you spend the money?</label>
                                <entitySelect @setEntity="setEntity"></entitySelect>
                            </div>
                        </div>
                        <errors :errors='errors'></errors>
                        <div class="form-row mt-4 justify-content-center">
                            <div class="form-group">
                                <button class="btn btn-outline-primary btn-wide" @click="cancel">Cancel</button>
                                <button class="btn btn-primary btn-wide ml-4" @click="addNew">Save</button>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>

    export default {
		props: {
			expense: Array,
		},
        mounted() {
            this.$store.commit('clearNewTransactionErrors');
        },
		data() {
			return {
				type: null,
                transaction: {
                    amount: 0,
					type: null,
					subcategories: []
                },
                subcategories: [],
				showChooseType: false
			}
		},
		computed: {
            showAddNewEntityModal() {
                return this.$store.state.showAddNewEntityModal;
            },
			income_categories () {
				return this.$store.state.income_categories;
			},
			expense_categories () {
				return this.$store.state.expense_categories;
			},
			income_subcategories () {
				return this.$store.state.income_subcategories;
			},
			expense_subcategories () {
				return this.$store.state.expense_subcategories;
			},
			transaction_categories () {
				return this.$store.state.transaction_categories;
			},
			account () {
				return this.$store.state.account;
			},
            first() {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
                date = new Date(y, m, d);
                return date;
                //var lastDay = new Date(y, m + 1, 0);
            },
            errors() {
                return this.$store.state.new_transaction_errors;
            },
		},
		methods: {
            setCategory(value) {

                if(this.type==='expense'){
                    var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(value));
                } else if (this.type==='income') {
                    var m = this.$store.state.income_categories.find(category => Number(category.id) === Number(value));
                }
                if(m) {
                    this.subcategories = m.subcategories;
                }
            },
            setSubcategory(value) {
				this.transaction.subcategories.push({id: value, amount: this.transaction.amount});
            },
            handleNoType() {
                if(!this.type) {
					this.showChooseType=true;
                }
            },
            set_current_input(input) {
				this.$store.commit('setCurrentInput', {input: input});
            },
			setType(type) {
				this.showChooseType = false;
				this.type = type;
				//this.transaction.type = type;
			    this.transaction.type = type;
			},
            subtractFromAmountValue(index) {
                if(accounting.unformat(this.transaction.amount)-10>0) {
                    return this.transaction.amount = accounting.unformat(this.transaction.amount) - 10;
                }
            },
            addToAmountValue(index) {
                return this.transaction.amount = accounting.unformat(this.transaction.amount) + 10;
            },
            passValue(value) {
                return this.transaction.amount = (value);
            },
			cancel() {
                //this.mode = 'addNewTransactionButton';
				//this.$emit('toggle');
				this.$store.commit('setCurrentInput', {input: null});
			},
			addNew() {

                if(this.transaction.subcategories.length>0) {
				    this.transaction.subcategories[0].amount = this.transaction.amount;
                }
                this.$store.commit('clearNewTransactionErrors');
				this.$store.dispatch('add_new_transaction', {transaction: this.transaction});

			},
			update: function(selected, subcategory_index){

				var that = this;
				if(this.transaction.type=='expense') {
					this.$store.state.expense_categories.forEach(function(category) {
						var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
						if(transaction_subcategory) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, amount: that.transaction.amount});
						}
					});
				} else if (this.transaction.type=='income') {
					this.$store.state.income_categories.forEach(function(category) {
						var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
						if(transaction_subcategory) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, amount: that.transaction.amount});
						}
					});
				}
			},
			setEntity(entityId) {
				this.transaction.entity = {id: entityId};
			},
			setDate(date) {
				this.transaction.date = date;
			}
		}
	}
</script>
