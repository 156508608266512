<template>
	<transition name="fade">
        <div class="main-wrap loading-screen">
        <connect-bank-account-modal></connect-bank-account-modal>
        <plaid-link-token-modal></plaid-link-token-modal>
            <div class="inner-wrap">
                <div class="loader">
                    <!--
                       -<img src="images/piggybank.gif">
                       -->
                    <div class="circle"></div>
                </div>
                <h4 class="white">{{ message }}</h4>
                <div class="row" v-if='percent'>
					<div class="col-12">
						<div class="progress">
							<div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="width">
								<div class="percentage-indicator">
									<span class="value">
										<i class="fa fa-check d-none" aria-hidden="true" v-if="percent==100"></i>
										{{ percent }}%
									</span>
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
	</transition>
</template>
<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	mounted: function() {
        this.$store.commit('setFullPageLoadingScreenData', {message: this.$route.query.message});
        this.$store.commit('setFullPageLoaderPercent', this.$route.query.percent);
		document.getElementById('app').className='h-100';
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
		document.getElementById('app').className='';
	},
	computed: {
		percent() {
			return this.$store.state.full_page_loader_percent;
		},
		message() {
			return this.$store.state.full_page_loading_message;
		},
        width: function () {
            return 'width: ' + this.percent + '%';
        },
	}
}
</script>
