var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "mt-4" }, [_vm._v("Split Category Transaction")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-5" }, [
        _c(
          "a",
          {
            staticClass: "green ml-2",
            attrs: { href: "" },
            on: {
              click: function($event) {
                return _vm.addRow($event)
              }
            }
          },
          [_vm._v("Add row")]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.transaction.subcategories, function(subcategory, index) {
        return _c("split-transaction-item", {
          key: subcategory.id,
          attrs: {
            subcategory: subcategory,
            transaction: _vm.transaction,
            index: index
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }