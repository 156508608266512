var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("circle", {
        staticClass: "cls-1",
        attrs: { cx: "198.61", cy: "250.37", r: "91.13" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M142.48,322.16l-12.82,24H80.14s-23-38.45-25.94-98.59S88,145.2,88,145.2h34s15.06,21.32,20.48,33.38"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d: "M88,145.2,83.56,78.08a22.75,22.75,0,1,1,45.32.84L122,145.2"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "86.13", y1: "116.89", x2: "124.75", y2: "116.89" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M257.52,322.16l12.82,24h49.52s23-38.45,25.94-98.59S312,145.2,312,145.2H278s-15.06,21.32-20.48,33.38"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d: "M312,145.2l4.45-67.12a22.75,22.75,0,1,0-45.32.84L278,145.2"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "313.87", y1: "116.89", x2: "275.25", y2: "116.89" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M170.29,192.87s-10,5-12.82,14.33" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M176.7,226.22s-10,5-12.82,14.32" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M203.77,197.64s-10.06,5-12.82,14.33" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }