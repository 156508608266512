<template>
<transition
name="fade"
 v-if="showBudgetedToGo"
>
<div class="modal is-active">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="box">
			<h1>Budgeted To Go</h1>
            <budget v-for="(budget, index) in budgetsToGo"
                    :is="budget.mode"
                    :budget="budget"
                    :budgets="budgetsToGo"
                    :index="index"
                    :percent="percent"
                    :key="budget.id"
                    >
			</budget>
		</div>
	</div><button class="modal-close is-large" @click='close'></button>
</div>

</transition>
</template>

<script>
    export default {
		mounted: function() {
            if (!this.$store.state.current_date) {
                this.$store.dispatch('get_current_date').then(() => {
                    this.$store.dispatch('get_budgets').then(() => {
                    });
                });
            }
		},
		methods: {
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			close() {
				//this.$store.commit('clearConnectBankAccountErrors');
				this.$store.commit('setToggleBudgetedToGo', false);
			},
		},
		computed: {
			showBudgetedToGo: function() {
				return this.$store.state.showBudgetedToGo;
			},
			budgetsToGo: function() {
				this.$store.getters.budgeted_to_go.sort(function (a, b) {
					if(a.subcategory.name < b.subcategory.name) return -1;
					if(a.subcategory.name > b.subcategory.name) return 1;
					return 0;
				});
				return this.$store.getters.budgeted_to_go;
			},
			percent: function () {
				return 'left: ' + this.round(((this.$store.state.today / this.$store.state.last)*100),0) + '%';
			}
		}
	}
</script>
