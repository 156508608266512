var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-group date" }, [
      _c("input", {
        staticClass: "form-control datepicker no-border",
        attrs: {
          type: "text",
          name: "date",
          "data-date-format": "mm/dd/yyyy",
          placeholder: "Select date"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group-addon" },
        [
          _c(
            "icon-base",
            {
              staticClass: "icon-right-nm",
              attrs: {
                "icon-name": "calendar",
                width: "25",
                height: "25",
                "view-box": "0 0 25 25"
              }
            },
            [_c("icon-calendar", { attrs: { slot: "icon" }, slot: "icon" })],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }