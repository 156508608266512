class FormValidator {

	constructor () {
		// this.test = test;
	}

	addError (container, input, message) {

		  this.removeError(container, input);
		  if(typeof(message)!=='undefined' && message.length) {
			  var messageEl = document.createElement('div');
			  messageEl.className = 'invalid-feedback';
			  messageEl.innerHTML = message;
			  container.appendChild(messageEl);
			  input.classList.add("is-invalid");
		  }

	}

	removeError (container, input) {

		var elements = container.getElementsByTagName('div');
		while (elements[0]) elements[0].parentNode.removeChild(elements[0]);
		if(input) {
			input.classList.remove("is-invalid");
		}

	}

	validate (input, container, rule, value, message, label=null) {

		if(value.length===0) {

			event.preventDefault();
			event.stopPropagation();
			this.addError(container, input, 'The ' + label + ' field is required.');
            return false;

		} else if(!Array.isArray(value) && rule(value)===false) {

			event.preventDefault();
			event.stopPropagation();
			this.addError(container,input,message);
            return false;

		} else if(Array.isArray(value) && rule.apply(this, value)===false) {

			event.preventDefault();
			event.stopPropagation();
			this.addError(container,input,message);
            return false;

		} else if (!Array.isArray(value) && rule(value)===true) {
			this.removeError(container,input);
            return true;
		} else if (Array.isArray(value) && rule.apply(this, value)===true) {
			this.removeError(container,input);
            return true;
		}
	}

	emailRule (value) {

		var regularExpression = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,6}$/i;

		if(!regularExpression.test(value)) {
			return false;
		}

		return true;
	}

	passwordRule (value) {

		// between 6 to 20 characters which contain at least one numeric digit, one uppercase, and one lowercase letter
		var regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
		if(!regularExpression.test(value)) {
			return false;
		}

		return true;
	}

	passwordConfirmRule (value, valueConfirm) {
		if(value!==valueConfirm) {
			return false;
		}
		return true;
	}

    validateName (value) {

        if(value.length < 2) {
            return false;
        }

        return true;

    }

    validateCardNumber (value) {

        if(!$.payment.validateCardNumber(value)) {
            return false;
        }
        return true;
    }

    validateCardSecurityCode (value) {

        if(!$.payment.validateCardCVC(value)) {
            return false;
        }
        return true;
    }

    validateCardExpiry (value){

        var res = value.trim().split("/");

        if(res.length==2) {
            if(!$.payment.validateCardExpiry(res[0].trim(), res[1].trim())) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    validatePostal (value) {
        if(value.length < 5) {
            return false;
        }

        return true;
    }
}

export default FormValidator
