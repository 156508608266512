var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _vm.isLoggingIn
        ? _c("full-page-loading-screen")
        : _c("div", { staticClass: "main-wrap register" }, [
            _c(
              "nav",
              {
                staticClass: "navbar navbar-expand navbar-v2",
                attrs: { id: "mainNav" }
              },
              [
                _c(
                  "div",
                  { staticClass: "container-fluid container-fluid-90" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-brand js-scroll-trigger",
                        attrs: {
                          to: { name: "home" },
                          "active-class": "active"
                        }
                      },
                      [_c("img", { attrs: { src: "images/logo.png" } })]
                    ),
                    _vm._v(" "),
                    _c("ul", { staticClass: "navbar-nav" }, [
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-outline-primary btn-box-shadow btn-sm",
                              attrs: { to: { name: "login" } }
                            },
                            [_vm._v("Login")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-outline-primary btn-box-shadow btn-sm",
                              attrs: { to: { name: "sign-up" } }
                            },
                            [_vm._v("Sign Up")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "register_container container full-cont-sm" },
              [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12 col-lg-5 order-lg-last" },
                    [
                      _c("div", { staticClass: "pricing-tables-cont" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6 col-lg-12" }, [
                            _c("div", { staticClass: "pricing-grid" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-radio dark-bg"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.register.plan,
                                        expression: "register.plan"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "radio",
                                      name: "typeBudget",
                                      id: "basicBudgeter",
                                      value: "free"
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.register.plan, "free")
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.register,
                                          "plan",
                                          "free"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "custom-control-label price-value two",
                                      attrs: { for: "basicBudgeter" }
                                    },
                                    [
                                      _c("h3", [_vm._v("Basic Budgeter")]),
                                      _vm._v(" "),
                                      _c("h4", [_vm._v("( FREE )")])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Manual entry of transactions and categories. Great for those on limited budget or those who don't mind a more hands on approach."
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-lg-12" }, [
                            _c("div", { staticClass: "pricing-grid" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-radio dark-bg"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.register.plan,
                                        expression: "register.plan"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "radio",
                                      value: "budget_master",
                                      id: "budgetBoss"
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.register.plan,
                                        "budget_master"
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.register,
                                          "plan",
                                          "budget_master"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "custom-control-label price-value two",
                                      attrs: { for: "budgetBoss" }
                                    },
                                    [
                                      _c("h3", [_vm._v("Budget Boss")]),
                                      _vm._v(" "),
                                      _c("h4", [_vm._v("( $5.99 monthly )")])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Refine your budget process with automatic transaction imports and some categorization."
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-12 col-lg-7 order-lg-first" },
                    [
                      _c(
                        "div",
                        { staticClass: "card register-payment-form-card" },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "form",
                              {
                                staticClass: "validate",
                                attrs: {
                                  role: "form",
                                  method: "POST",
                                  action: "/register",
                                  id: "registerPaymentForm",
                                  novalidate: ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-label-group",
                                    attrs: { id: "email_container" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.register.email,
                                          expression: "register.email"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "email",
                                        type: "email",
                                        value: "",
                                        placeholder: "E-Mail Address",
                                        autofocus: ""
                                      },
                                      domProps: { value: _vm.register.email },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.register,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: "email" } }, [
                                      _vm._v("E-Mail Address *")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-label-group",
                                    attrs: { id: "password_container" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.register.password,
                                          expression: "register.password"
                                        }
                                      ],
                                      staticClass: "form-control ",
                                      attrs: {
                                        id: "password",
                                        type: "password",
                                        placeholder: "Password",
                                        autofocus: ""
                                      },
                                      domProps: {
                                        value: _vm.register.password
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.register,
                                            "password",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { attrs: { for: "password" } },
                                      [_vm._v("Password *")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-label-group",
                                    attrs: { id: "password_confirm_container" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.register.password_confirmation,
                                          expression:
                                            "register.password_confirmation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "password_confirmation",
                                        type: "password",
                                        placeholder: "Confirm password",
                                        autofocus: ""
                                      },
                                      domProps: {
                                        value:
                                          _vm.register.password_confirmation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.register,
                                            "password_confirmation",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "password_confirmation" }
                                      },
                                      [_vm._v("Confirm Password *")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-4 mb-0" },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c("div", { staticClass: "col-6" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-radio"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.register.plan,
                                                  expression: "register.plan"
                                                }
                                              ],
                                              staticClass:
                                                "custom-control-input",
                                              attrs: {
                                                type: "radio",
                                                value: "free",
                                                id: "customRadio1"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.register.plan,
                                                  "free"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.register,
                                                    "plan",
                                                    "free"
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "custom-control-label",
                                                attrs: { for: "customRadio1" }
                                              },
                                              [
                                                _vm._v(
                                                  "  Basic Budgeter (FREE)"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-6" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-radio"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.register.plan,
                                                  expression: "register.plan"
                                                }
                                              ],
                                              staticClass:
                                                "custom-control-input",
                                              attrs: {
                                                type: "radio",
                                                value: "budget_master",
                                                id: "customRadio2"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.register.plan,
                                                  "budget_master"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.register,
                                                    "plan",
                                                    "budget_master"
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "custom-control-label",
                                                attrs: { for: "customRadio2" }
                                              },
                                              [
                                                _vm._v("  Budget Boss ("),
                                                _c("strong", [
                                                  _vm._v("$5.99 a month")
                                                ]),
                                                _vm._v(")")
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.register.plan === "budget_master"
                                  ? _c(
                                      "div",
                                      { attrs: { id: "credit_card_billing" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group",
                                            attrs: { id: "group-name" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-12 col-md-6 form-label-group",
                                                    attrs: {
                                                      id: "first_name_container"
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.register
                                                              .first_name,
                                                          expression:
                                                            "register.first_name"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "first_name",
                                                        type: "text",
                                                        placeholder:
                                                          "First name",
                                                        value: ""
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.register
                                                            .first_name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.register,
                                                            "first_name",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "first_name"
                                                        }
                                                      },
                                                      [_vm._v("First name *")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-12 col-md-6 form-label-group",
                                                    attrs: {
                                                      id: "last_name_container"
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.register
                                                              .last_name,
                                                          expression:
                                                            "register.last_name"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "last_name",
                                                        type: "text",
                                                        placeholder:
                                                          "Last name",
                                                        value: ""
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.register.last_name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.register,
                                                            "last_name",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "last_name"
                                                        }
                                                      },
                                                      [_vm._v("Last name *")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label-group",
                                            attrs: {
                                              id: "card_number_container"
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.register.card_number,
                                                  expression:
                                                    "register.card_number"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "card_number",
                                                placeholder:
                                                  "Credit card number",
                                                disabled: _vm.register.promo
                                              },
                                              domProps: {
                                                value: _vm.register.card_number
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.register,
                                                    "card_number",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { attrs: { for: "card_number" } },
                                              [_vm._v("Card Number *")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-label-group" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-6 form-label-group",
                                                    attrs: {
                                                      id: "card_csc_container"
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.register
                                                              .card_csc,
                                                          expression:
                                                            "register.card_csc"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        id: "card_csc",
                                                        placeholder: "CSC",
                                                        disabled:
                                                          _vm.register.promo
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.register.card_csc
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.register,
                                                            "card_csc",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "card_csc"
                                                        }
                                                      },
                                                      [_vm._v("CSC *")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-6 form-label-group",
                                                    attrs: {
                                                      id:
                                                        "card_expiration_container"
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.register
                                                              .card_expiration,
                                                          expression:
                                                            "register.card_expiration"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        id: "card_expiration",
                                                        placeholder: "MM / YY",
                                                        disabled:
                                                          _vm.register.promo
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.register
                                                            .card_expiration
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.register,
                                                            "card_expiration",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "card_expiration"
                                                        }
                                                      },
                                                      [_vm._v("Expiration *")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label-group",
                                            attrs: { id: "postal_container" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.register.postal,
                                                  expression: "register.postal"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: "Postal code",
                                                id: "postal",
                                                value: "",
                                                disabled: _vm.register.promo
                                              },
                                              domProps: {
                                                value: _vm.register.postal
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.register,
                                                    "postal",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { attrs: { for: "postal" } },
                                              [_vm._v("Postal Code *")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group mt-4 mb-0"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-control custom-checkbox mr-sm-2"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.register.promo,
                                                      expression:
                                                        "register.promo"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "custom-control-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "promoCheckbox"
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.register.promo
                                                    )
                                                      ? _vm._i(
                                                          _vm.register.promo,
                                                          null
                                                        ) > -1
                                                      : _vm.register.promo
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.register.promo,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.register,
                                                              "promo",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.register,
                                                              "promo",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.register,
                                                          "promo",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-control-label",
                                                    attrs: {
                                                      for: "promoCheckbox"
                                                    }
                                                  },
                                                  [_vm._v("  Promo")]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.register.promo
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "form-label-group",
                                                attrs: {
                                                  id: "postal_container"
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.register.code,
                                                      expression:
                                                        "register.code"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "Promo code",
                                                    id: "promoCode",
                                                    value: ""
                                                  },
                                                  domProps: {
                                                    value: _vm.register.code
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.register,
                                                        "code",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: { for: "promoCode" }
                                                  },
                                                  [_vm._v("Code *")]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    value: "standard",
                                    name: "encryption",
                                    checked: ""
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group mt-5 text-center text-md-left"
                                  },
                                  [
                                    _c("p", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "By clicking Create Account, you agree to our "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/terms",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Terms")]
                                      ),
                                      _vm._v(".")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "payment-errors" }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.register.timezone,
                                      expression: "register.timezone"
                                    }
                                  ],
                                  attrs: {
                                    type: "hidden",
                                    value: "",
                                    id: "timezone"
                                  },
                                  domProps: { value: _vm.register.timezone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.register,
                                        "timezone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group text-center text-md-left"
                                  },
                                  [
                                    _c("errors", {
                                      attrs: { errors: _vm.errors }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: { href: "#", id: "register" },
                                        on: {
                                          click: function($event) {
                                            return _vm.save()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\tCreate Account\n\t\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "main-footer container-fluid main-footer-v2" },
              [
                _c(
                  "a",
                  {
                    staticClass: "backtotop",
                    attrs: { href: "", id: "backtotop" }
                  },
                  [_c("img", { attrs: { src: "images/arrow-up-icon.png" } })]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "copyright-msg" }, [
                  _vm._v("Ⓒ 2018 Budget It Up, LLC All Rights Reserved")
                ])
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }