<template>
<transition name="fade" v-if="showConnectBankAccountModal">
<div class="modal is-active">
	<div class="modal-background"></div>
	<div class="modal-content-login">
		<div class="box">
			<bank-login-form :is="bankLoginMode"></bank-login-form>
		</div>
	</div>
</div>
</transition>
</template>
<script>
export default {
    mounted: function() {
    },
    computed: {
        bankLoginMode() {
            return this.$store.state.bank_login_mode;
        },
        showConnectBankAccountModal() {
            return this.$store.state.showConnectBankAccountModal;
        },
	},
}
</script>
