var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "20",
        viewBox: "0 0 25 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1.39273 20C1.14282 20 0.892905 19.9444 0.670759 19.8053C0.00432318 19.416 -0.190054 18.5539 0.1987 17.8864L3.58642 12.2131C3.83633 11.8237 4.25285 11.5456 4.72491 11.5456C5.19697 11.5178 5.64126 11.7403 5.91894 12.1297L7.36289 14.132L10.4174 8.90365C10.6395 8.51431 11.0838 8.23621 11.5281 8.2084C12.0002 8.18058 12.4445 8.40307 12.7221 8.7646L15.3879 12.3521L22.4132 0.671779C22.802 0.00432974 23.6628 -0.190343 24.3292 0.199002C24.9957 0.588348 25.1901 1.45047 24.8013 2.11792L16.7208 15.5503C16.4708 15.9397 16.0543 16.19 15.61 16.2178C15.138 16.2456 14.6937 16.0231 14.416 15.6616L11.7503 12.1018L8.66799 17.3302C8.41808 17.7474 8.00156 17.9977 7.5295 18.0255C7.05744 18.0533 6.61315 17.8308 6.33547 17.4414L4.89152 15.4391L2.58676 19.3047C2.33685 19.7497 1.86479 20 1.39273 20Z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }