var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("10")]),
      _c("path", {
        attrs: {
          d:
            "M96.6,216.93c13.29-12.26,25.87-16.62,26-16.66a5,5,0,0,0,.81-.36,36.54,36.54,0,0,0,66.52-2.16,111.74,111.74,0,0,1,24.83,16c20.37,17.31,32.31,39.74,35.49,66.67a5.67,5.67,0,0,0,5.63,5,5,5,0,0,0,.67,0,5.68,5.68,0,0,0,5-6.3,112.79,112.79,0,0,0-39.78-74.31A121.19,121.19,0,0,0,192.6,186.5c.07-.92.12-1.83.12-2.76a36.6,36.6,0,0,0-30.87-36.11V107.06a15.29,15.29,0,1,0-11.36,0v40.57a36.59,36.59,0,0,0-30.87,36.11,36,36,0,0,0,.42,5.52,5.6,5.6,0,0,0-1.06.24c-.59.2-14.61,5-29.64,18.68-13.78,12.56-31.12,35.36-35.53,72.67a5.67,5.67,0,0,0,5,6.3,5.17,5.17,0,0,0,.68,0,5.68,5.68,0,0,0,5.63-5C68.27,255.23,78.87,233.27,96.6,216.93Zm59.57-128a4,4,0,0,1,3.94,3.94,3.82,3.82,0,0,1-.07.68,5.66,5.66,0,0,0-7.74,0,3.82,3.82,0,0,1-.07-.68A4,4,0,0,1,156.17,88.93Zm0,69.61a25.2,25.2,0,1,1-25.2,25.2A25.22,25.22,0,0,1,156.17,158.54Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M207.63,163.13a4.58,4.58,0,0,0,.64,0,5.68,5.68,0,0,0,5.64-5L218.78,115a15.31,15.31,0,1,0-11.28-1.29l-4.88,43.12A5.68,5.68,0,0,0,207.63,163.13Zm4.65-65.94a3.92,3.92,0,0,1,2.45-.85,3.39,3.39,0,0,1,.45,0,3.93,3.93,0,0,1,3.47,4.36,5,5,0,0,1-.14.67,5.65,5.65,0,0,0-7.69-.87,3.18,3.18,0,0,1,0-.69A3.91,3.91,0,0,1,212.28,97.19Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M113.67,128.5a15.3,15.3,0,1,0-16.13,4.82l3.84,43.22a5.68,5.68,0,0,0,5.65,5.18,4.36,4.36,0,0,0,.51,0,5.67,5.67,0,0,0,5.15-6.15l-3.84-43.21A15.36,15.36,0,0,0,113.67,128.5Zm-12.08-13.74.36,0a3.94,3.94,0,0,1,3.91,3.59,3.18,3.18,0,0,1,0,.69,5.65,5.65,0,0,0-4-1.19,5.72,5.72,0,0,0-3.72,1.87A3.78,3.78,0,0,1,98,119,3.94,3.94,0,0,1,101.59,114.76Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M320.68,75.45A32.72,32.72,0,1,0,288,108.16,32.75,32.75,0,0,0,320.68,75.45Zm-54.08,0A21.37,21.37,0,1,1,288,96.81,21.39,21.39,0,0,1,266.6,75.45Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M315,110.23c-19.53,0-26.17,11.82-31,20.45-1.64,2.93-3.19,5.69-4.93,7.45l0,0c-3.69,3.31-29,4.2-54.16,1.92a5.68,5.68,0,0,0-1,11.31c.53.05,13,1.17,26.75,1.3,13.08.13,21.39-.7,26.83-2,.54,37.71,4.71,66.34,12.41,85.17,4.16,10.17,8.57,15.32,11.62,17.88v84a19.52,19.52,0,1,0,39,0V247c4.19-7.05,6.1-24.12,5.68-50.92C345.53,150,345,110.23,315,110.23ZM330.89,241a5.65,5.65,0,0,0-1.75,4.1v92.64a8.17,8.17,0,1,1-16.33,0v-87c0-2.55-1.07-4.58-3.47-5.25-1.84-1.25-20.32-15.91-20.62-101.2a57,57,0,0,0,5.18-8.1c4.41-7.86,8.22-14.65,21.1-14.65,5.85,0,11.77,2,15.47,16.65,3.46,13.69,4,35.23,4.35,58C335.37,231.39,331.57,239.79,330.89,241Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M113,351.6a5.68,5.68,0,0,0,11.36,0V274.5h75.1v77.1a5.68,5.68,0,0,0,11.36,0V274.5h26.47a5.67,5.67,0,0,0,5.11-8.16c-1.95-4-19.75-39.51-41.94-43.84-17.77-3.47-34.49,1.82-40.28,4a105.65,105.65,0,0,0-41.63-3.39c-26.93,3.06-42.46,42-43.11,43.66a5.68,5.68,0,0,0,5.29,7.75H113Zm6.83-117.23c22.05-2.5,38.39,3.41,38.54,3.47a5.69,5.69,0,0,0,4.29-.12c.17-.08,17.54-7.6,35.62-4.08,11,2.16,22.67,18.08,29.46,29.5H89.59C95.53,251.81,106.63,235.87,119.81,234.37Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }