<template>
<transition name="fade" v-if="showPlaidLinkTokenModal">
<div class="modal is-active">
	<div class="modal-background"></div>
	<div class="modal-content-login">
		<div class="box">
			<link-token-login-form :is="plaidLinkTokenLoginMode"></link-token-login-form>
		</div>
	</div>
</div>
</transition>
</template>
<script>
export default {
    mounted: function() {
    },
    computed: {
        plaidLinkTokenLoginMode() {
            return this.$store.state.plaid_link_token_login_mode;
        },
        showPlaidLinkTokenModal() {
            return this.$store.state.showPlaidLinkTokenModal;
        },
	},
}
</script>
