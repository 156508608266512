var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _vm.showAddNewGoalModal ? _c("add-new-goal-modal") : _vm._e(),
      _vm._v(" "),
      _c("goal-search-add"),
      _vm._v(" "),
      _c("div", { staticClass: "container h-100" }, [
        _c("div", { staticClass: "row no-gutters h-100" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 order-lg-last col-12" },
            [_c("goal-progress")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-lg-8 order-lg-first col-12 goal-categories-cont"
            },
            [
              _c(_vm.body_view, { tag: "component", attrs: { page: "goals" } }),
              _vm._v(" "),
              _vm._l(_vm.goals, function(goal, index) {
                return _c(goal.mode, {
                  key: goal.id,
                  tag: "goal",
                  attrs: { goal: goal, goals: _vm.goals, index: index },
                  on: {
                    edit: _vm.edit,
                    save: _vm.save,
                    remove: function($event) {
                      return _vm.remove(goal, index)
                    },
                    update: _vm.update
                  }
                })
              })
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }