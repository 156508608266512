<template>
	<div>
		<div class="row">
			<div class="col-lg-4 col-md-5" v-if="accounts.length>0">
				<select-dropdown :accounts="accounts" @update="function (id) { setAccount(id,0) }"></select-dropdown>

				<!--
				   -<select class="form-control ui dropdown transparent-dropdown" @change='setAccount' v-bind:value="account.id">
				   -    <option v-for="(daccount, index) in accounts" v-if="accounts.length>0" v-bind:value='daccount.id'>{{ daccount.name }}</option>
				   -</select>
				   -->
			</div>
			<div class="col-lg-8 col-md-7 text-center text-md-left mt-5 mt-md-0">
				<a href="#" class="btn btn-outline-light btn-wide mr-3" @click="set_current_input('edit-account')" v-if="accounts.length>0">Edit Account</a>
				<a href="#" class="btn btn-light btn-wide" @click="set_current_input('add-new-account')">Add Account</a>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	computed: {
		last_updated() {
			var result = format(
			  new Date(this.accounts[0].updated_at),
			  'MM/DD/YYYY h:mm a'
			);

			return result;

		},
        account () {
            return this.$store.state.account;
        },
		accounts() {
			return this.$store.state.accounts;
		},
	},
	methods: {
        set_current_input(input) {
			this.$store.commit('setCurrentInput', {input: input}); 
		},
        editAccount() {
			this.$store.commit('setToggleEditAccount', true);
        },
		showAddAccountModal() {
			this.$store.commit('setToggleAddNewAccountModal', true);
		},
        setAccount(account_id) {

            var account = this.accounts.find(account => account.id === Number(account_id))
            if(this.account) {
                this.$store.dispatch('get_account', {
                    account_id: account.id
                }).then(() => {
                    this.$store.dispatch('get_month_transactions', {account: account});
                });
            }
        },
		update(account){

			this.$store.dispatch('update_account', {
				account: account
			})

		},
		remove(account, index) {

			var that = this;
			axios.delete('/api/accounts/' + account.id)
			.then(function (response) {
				that.accounts.splice(index, 1);
			})
			.catch(function (error) {
				console.log(error);
			});

		},
	}
}
</script>
        <!--
            -<p v-if='accounts.length>0'><strong>Last updated at:</strong> {{ last_updated }}</p>
        -->
		<!--
           -<div class="py-4" v-if="accounts.length==0">
           -    <div role="alert" class="alert alert-info">
           -        <strong>You currently have no accounts!</strong> <a href="/connected-banks">Click here</a> to add a bank connection.
           -        <p></p>
           -    </div>
           -</div>
		   -->
<!--
   -             <div class="col-md-12">
   -                 <ul class="table">
   -                     <li>
   -                         <div class="row">
   -                             <div class="col-md-3"><strong>Account Name</strong></div>
   -                             <div class="col-md-2" v-if="user.stripe_id"><strong>Available Balance</strong></div>
   -                             <div class="col-md-2"><strong>Current Balance</strong></div>
   -
   -                             <div class="col-md-2"></div>
   -                             <div class="col-md-2"></div>
   -                         </div>
   -                     </li>
   -                     <li v-for="(account, index) in accounts" v-if="accounts.length>0">
   -                         <accountListItem :account="account" :is='account.mode' 
   -                           @remove="remove(account, index)" 
   -                           @update="update"
   -                         ></accountListItem>
   -                     </li>
   -                     <li v-else>
   -                         <div class="row">
   -                             <div class="col-md-12">No accounts yet.</div>
   -                         </div>
   -                     </li>
   -                 </ul>
   -             </div>
   -         </div>
   -->
		 <!--if(count(Auth::user()->subscriptions))
		  <p><a href="/accounts/import" class="btn btn-primary">Import Account(s)</a></p>
		  @endif-->
