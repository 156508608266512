var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-1")]),
      _c("path", {
        attrs: {
          d:
            "M367,141.69c-7-20.68-32.65-35.17-68.65-38.77-16.72-1.67-70,.29-119.3,3.48l54.73-59.11A6,6,0,0,0,225,39.13L161.63,107.6l-9.06.67L89.41,39.16a6,6,0,0,0-8.86,8.1l56.88,62.24c-22.72,1.94-41.09,4-49.3,5.9-16.7,3.83-29.64,14.19-37.56,22.2a42.16,42.16,0,0,0-12,30.9C39.78,206,43,272.33,50.81,296.13c11.59,35.45,29.58,52.24,100.39,51.81,29.14.29,61.4.44,89.12.44,25.94,0,47.91-.13,59.62-.39a33.13,33.13,0,0,0,9-1.37c22-6.75,47.81-38.1,52.46-82.83C363.48,246.7,373.39,160.48,367,141.69ZM349.5,262.46c-3.92,37.74-25.92,67.11-44.06,72.69a21.14,21.14,0,0,1-5.77.84c-24.11.54-92.05.52-148.45-.05H148.6c-67.24,0-77.55-16.52-86.39-43.55-7.59-23.23-10.69-94.66-11.67-124.3A30.1,30.1,0,0,1,59.1,146c6.8-6.89,17.82-15.76,31.71-18.94,27.07-6.21,177.52-15.12,206.39-12.24,30.71,3.07,53.11,14.83,58.47,30.67C359.48,156.8,355.46,212.84,349.5,262.46Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M135.06,130.09c-18.3,2-33.88,6.5-46.33,13.33a50.34,50.34,0,0,0-25.68,47.17c1.56,24.78,4.78,69,8.88,86.61C75.15,291,83.46,302.68,96,311s29,13,47.69,13.58,33.09.84,45.18.84c16.52,0,29-.44,44-1l6.71-.24c31.26-1.08,49.17-26.5,53.11-42.27,3.26-13,3.71-37.34,3.71-61.66,0-4.49.14-9.08.27-13.52.61-20.39,1.24-41.48-11.85-56.43C258.48,120.11,171.32,126.11,135.06,130.09Zm149.53,76.29c-.13,4.53-.27,9.21-.27,13.88,0,22.43-.4,46.94-3.35,58.75-2.61,10.45-16.29,32.3-41.88,33.19l-6.72.23c-25.58.91-44.06,1.56-88.33.12-32.53-1.05-55.11-15.29-60.42-38.07-3.91-16.78-7.06-60.23-8.59-84.64a38.26,38.26,0,0,1,19.48-35.9c11.06-6.07,25.14-10.08,41.86-11.92,70.53-7.75,123.91-1.55,139.33,16.18C285.69,169.62,285.16,187.47,284.59,206.38Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M179.9,252.94a12.71,12.71,0,1,0,12.71,12.71A12.72,12.72,0,0,0,179.9,252.94Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M178.6,174.16a93.5,93.5,0,0,0-70.29,31.77,6,6,0,0,0,9,7.92,81.63,81.63,0,0,1,123.8,1.47,6,6,0,1,0,9.21-7.7A93.23,93.23,0,0,0,178.6,174.16Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M178.6,200.23A67.7,67.7,0,0,0,125,226.68a6,6,0,1,0,9.55,7.27,55.53,55.53,0,0,1,89.64,2.11,6,6,0,0,0,4.95,2.6,5.9,5.9,0,0,0,3.39-1.06,6,6,0,0,0,1.55-8.34A67.26,67.26,0,0,0,178.6,200.23Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M178.6,226.31a40.86,40.86,0,0,0-35,19.17,6,6,0,1,0,10.18,6.35,29.4,29.4,0,0,1,51.42,3.53,6,6,0,0,0,11-4.8A40.87,40.87,0,0,0,178.6,226.31Z"
        }
      }),
      _c("path", {
        attrs: {
          d: "M331.16,181.41H319.47a6,6,0,0,0,0,12h11.69a6,6,0,0,0,0-12Z"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "323.92",
          cy: "220.09",
          r: "7.24",
          transform: "translate(-60.75 293.51) rotate(-45)"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "319.47",
          cy: "256.78",
          r: "7.24",
          transform: "translate(-50.45 80.27) rotate(-13.28)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }