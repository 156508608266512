var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-2")]),
      _c("path", {
        attrs: {
          d:
            "M247.35,91.17a18.47,18.47,0,0,0-1.29-6.75l-8.43-29.29,9.51,5.77a6,6,0,1,0,6.22-10.26L230.73,36.91a6,6,0,0,0-8.88,6.79l8.34,29c-.47,0-.94-.08-1.41-.08a18.57,18.57,0,1,0,18.57,18.57Zm-25.14,0a6.57,6.57,0,1,1,6.57,6.57A6.57,6.57,0,0,1,222.21,91.17Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M193.41,78.29,170.77,64.56a6,6,0,0,0-8.87,6.79l8.34,29c-.47,0-.94-.07-1.42-.07a18.62,18.62,0,1,0,17.28,11.81l-8.43-29.29,9.52,5.77a6,6,0,1,0,6.22-10.26ZM168.82,125.4a6.57,6.57,0,1,1,6.57-6.57A6.58,6.58,0,0,1,168.82,125.4Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M168.33,160.91a5.54,5.54,0,0,0-.58-.17l-33.46-7.91c-4.1-3.13-7.52-5.65-10.57-7.9-2.08-1.54-4-2.93-5.73-4.27a42.59,42.59,0,1,0-49.1-.48,43.6,43.6,0,0,0-20.91,37V255l-6,87.07a22,22,0,0,0,21.9,23.47h.7A22,22,0,0,0,86.06,348l.06-.3,9.28-59.61,17.16,62.36c1.83,8.72,10.08,15,19.71,15A22,22,0,0,0,153.5,338l-22.08-83.73,4.24-38.07,17.4,4.66a1.22,1.22,0,0,0,.26.06,17.79,17.79,0,0,0,3.9.43,18.12,18.12,0,0,0,15.72-27.23,18.8,18.8,0,0,0-4.61-33.17Zm.38,19.63a6.79,6.79,0,0,1-7.58,4.85l-23.73-6.92a5.73,5.73,0,0,0,.26-1.72V166l27,6.38A6.79,6.79,0,0,1,168.71,180.54ZM63.19,105.66a30.58,30.58,0,1,1,30.58,30.58A30.62,30.62,0,0,1,63.19,105.66Zm56.34,150.55L141.9,341a10,10,0,0,1-9.63,12.5c-4,0-7.35-2.35-8-5.57,0-.15-.06-.3-.1-.45L99.55,258a6,6,0,0,0-11.71.67l-13.56,87a10,10,0,0,1-9.71,7.8h-.7a10,10,0,0,1-9.93-10.65l6-87.27c0-.13,0-.27,0-.41v-78a31.47,31.47,0,0,1,23.33-30.29,42.57,42.57,0,0,0,22.62-.48c3.38,2.73,6.79,5.26,10.66,8.12,2.67,2,5.64,4.16,9.08,6.78,0,.07,0,.15,0,.23v13.46l-5.58-1.62-10.7-8.74h0a18.22,18.22,0,0,0-23.16,28.13l17,14.08a6.21,6.21,0,0,0,2.28,1.18l18.67,5a5.65,5.65,0,0,0-.36,1.47L119.37,254A6,6,0,0,0,119.53,256.21Zm43.79-52a6.16,6.16,0,0,1-7.3,5l-46.18-12.37-16-13.23a6.17,6.17,0,0,1-2.24-4.54,6.1,6.1,0,0,1,1.88-4.7,6.21,6.21,0,0,1,8.27-.37h0l11.64,9.51a6.18,6.18,0,0,0,2.12,1.12l41.35,12a6.9,6.9,0,0,0,.94.31l.46.09.07,0,.3.08A6.18,6.18,0,0,1,163.32,204.18Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M356.92,342.05l-6-87.07V177.23a43.59,43.59,0,0,0-20.9-37,42.57,42.57,0,1,0-49.1.48c-1.78,1.34-3.65,2.73-5.73,4.27-3.05,2.25-6.47,4.77-10.57,7.9l-33.46,7.91c-.2,0-.39.1-.59.17a18.8,18.8,0,0,0-4.6,33.17,18.09,18.09,0,0,0-2.22,12.1,18.16,18.16,0,0,0,17.94,15.13,17.79,17.79,0,0,0,3.9-.43,1.22,1.22,0,0,0,.26-.06l17.4-4.66,4.24,38.07L245.39,338a22,22,0,0,0,21.23,27.56c9.63,0,17.88-6.31,19.7-15l17.17-62.36,9.28,59.61.06.3a22,22,0,0,0,21.49,17.48h.7a22,22,0,0,0,21.9-23.47ZM274.54,105.66a30.58,30.58,0,1,1,30.58,30.58A30.62,30.62,0,0,1,274.54,105.66Zm-40.3,66.68,27-6.38v10.79a5.73,5.73,0,0,0,.26,1.72l-23.73,6.92a6.8,6.8,0,0,1-3.52-13Zm8.63,36.85a6.16,6.16,0,0,1-7.3-5,6.17,6.17,0,0,1,4.65-7l.3-.08.07,0,.46-.09a7.39,7.39,0,0,0,.94-.31l41.35-12a6,6,0,0,0,2.11-1.12L297.1,174h0a6.21,6.21,0,0,1,8.27.37,6.21,6.21,0,0,1-.37,9.24l-15.95,13.23Zm99.42,141.16a9.84,9.84,0,0,1-7.27,3.17h-.7a10,10,0,0,1-9.72-7.8l-13.55-87a6,6,0,0,0-11.71-.67L274.7,347.5c0,.15-.08.3-.11.45-.62,3.22-4,5.57-8,5.57A10,10,0,0,1,257,341l22.36-84.81a6,6,0,0,0,.17-2.2l-4.4-39.47a6.08,6.08,0,0,0-.36-1.47l18.67-5a6.09,6.09,0,0,0,2.27-1.18l17-14.08a18.22,18.22,0,0,0-23.16-28.13h0l-10.7,8.74-5.58,1.62V161.59c0-.08,0-.16,0-.23,3.44-2.62,6.41-4.81,9.08-6.78,3.87-2.86,7.28-5.39,10.66-8.12a42.57,42.57,0,0,0,22.62.48,31.47,31.47,0,0,1,23.32,30.29v78c0,.14,0,.28,0,.41l6,87.27A9.84,9.84,0,0,1,342.29,350.35Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }