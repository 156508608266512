<template>
	<div class="editing">
		<div class="row">	
			<div class="col-md-4">
				<dropdown @update="update" :expense_categories="income_categories"></dropdown>
			</div>
			<div class="col-md-3">
				<select v-model="paycheck.repeat_day" class="form-control">
					<option>Select day of month</option>
					<option v-for="n in 31" :value="n">{{n}}</option>
				</select>
			</div>
			<div class="col-md-4">
				<div class="input-group">
					<span class="input-group-btn">
						<button class="btn btn-default" type="button" @click="subtractFromValue"><i class="fa fa-angle-down"></i></button>
					</span>
					<input type="text" class="form-control" v-model.number="paycheck.debit_amount" placeholder="0.00">
					<span class="input-group-btn">
						<button class="btn btn-default" type="button" @click="addToValue"><i class="fa fa-angle-up"></i></button>
					</span>
				</div>
			</div>
		</div>
		<div class="row errors" v-if="errors.length > 0">
			<div class="col-md-12">
				<ul class="errors-list">
					<li v-for="error in errors">
					{{ error.message }}
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-offset-3 col-md-4">
				<div class="text-right">
					<button class="btn btn-link mt10" @click="cancel">Cancel</button>
					<button class="btn btn-primary" @click="addNew">Add New</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				paycheck: {
					debit_amount: 0,
					repeat_day: 1
				}
			}
		},
		computed: {
            errors() {
				return this.$store.state.new_income_errors;
            },
			income_categories () {
				return this.$store.state.income_categories;
			},
			account () {
				return this.$store.state.account;
			},

			paychecks (){
				return this.$store.state.paychecks;
			},

		},
		methods: {
			update(selected){
				this.paycheck.transaction_subcategory_id = selected;
			},
			subtractFromValue(index) {
				if (this.paycheck.debit_amount - 10 > 0) {
					this.paycheck.debit_amount -= 10;
				}
			},	
			addToValue(index) {
				this.paycheck.debit_amount += 10;
			},
			cancel() {
				this.$emit('toggle');	
			},
			addNew() {

				this.$store.dispatch('add_new_income', {income: this.paycheck});

				//this.$emit('toggle');	
			},
		}
	}
</script>
