<template>
    <div>
        <form id='plaid-link-form'></form>
    </div>
</template>
      <script>
export default {
    mounted() {
//link-token-login-form
		var that = this;
		var linkScript = document.createElement('script')
		linkScript.async = true
		linkScript.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js')
		document.head.appendChild(linkScript)
        linkScript.onload = () => {
            // Initialize Link with the token parameter
            // set to the generated link_token for the Item
            const linkHandler = window.Plaid.create({
				webhook: 'https://api.budgetitup.com/plaid/webhooks',
				language: 'en',
				countryCodes: ['US'],
				env: 'development',
				clientName: 'Budget It Up',
				key: '8e933a50d8a17cd2717bc66123c5c9',
                token: 'link-development-3302455a-cb23-42ae-9169-b1b7a9ad7d01', //this.$store.state.link_token,
                onSuccess: (public_token, metadata) => {
                    that.$store.commit('setIsLoggingIn', false);
					return this.$router.push(this.$store.state.loginRedirectTo);
                    // You do not need to repeat the /item/public_token/exchange
                    // process when a user uses Link in update mode.
                    // The Item's access_token has not changed.
                },
                onExit: (err, metadata) => {
                    // The user exited the Link flow.
                    if (err != null) {
                        // The user encountered a Plaid API error prior
                        // to exiting.
                    }
                    that.$store.commit('setIsLoggingIn', false);
					return this.$router.push(this.$store.state.loginRedirectTo);
                    // metadata contains the most recent API request ID and the
                    // Link session ID. Storing this information is helpful
                    // for support.
                },
            });
            linkHandler.open();
        }
    },
}
</script>
