var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "ui transparent-dropdown no-border form-control dropdown button",
      class: { disabled: _vm.disabled }
    },
    [
      _c("span", { staticClass: "text" }, [_vm._v("Choose Category")]),
      _vm._v(" "),
      _c("i", { staticClass: "dropdown icon" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu" },
        _vm._l(_vm.expense_categories, function(category) {
          return _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(category.name))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "menu" },
              _vm._l(category.subcategories, function(subcategory) {
                return _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: { "data-value": subcategory.id }
                  },
                  [_vm._v(_vm._s(subcategory.name))]
                )
              }),
              0
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }