var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d: "M74.19,347.85V96.91a44.75,44.75,0,0,1,44.75-44.76H215.8V75.31"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M255.55,114.69h-82.4A38.8,38.8,0,0,1,212,75.89h4.8A38.8,38.8,0,0,1,255.55,114.69Z"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M128.6,223.29s6.28-50.22,22.9-50.22H287s18.92,15.88,18.3,46a8.67,8.67,0,0,0,3.26,7c14.65,11.7,17.21,14.56,17.21,19v62.79H109s-3-49.12,0-58.35S128.6,223.29,128.6,223.29Z"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "128.6", y1: "223.29", x2: "305.62", y2: "223.29" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M123.59,309.86v19.81c0,2.87,2.72,5.2,6.08,5.2h28.61c3.36,0,6.09-2.33,6.09-5.2V309.86"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M268.65,309.86v19.81c0,2.87,2.73,5.2,6.09,5.2h28.61c3.36,0,6.09-2.33,6.09-5.2V309.86"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "134.04", y: "253.82", width: "34.25", height: "28.84" }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "268.65", y: "253.82", width: "34.25", height: "28.84" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "188.48", y1: "266.44", x2: "246.16", y2: "266.44" }
      }),
      _c("ellipse", {
        attrs: { cx: "178.24", cy: "143.08", rx: "7.57", ry: "9.79" }
      }),
      _c("ellipse", {
        attrs: { cx: "215.8", cy: "143.08", rx: "7.57", ry: "9.79" }
      }),
      _c("ellipse", {
        attrs: { cx: "253.57", cy: "143.08", rx: "7.57", ry: "9.79" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }