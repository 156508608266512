var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal inside-close-btn is-active" }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "px-3 py-3" }, [
            _vm.welcomeShown === false
              ? _c("h5", { staticClass: "mb-2 mt-2" }, [
                  _vm._v(
                    "Welcome aboard, to get started you will need to create some budgets."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.welcomeShown === true
              ? _c("div", [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c(
                      "div",
                      { staticClass: "text-left" },
                      [
                        _c(
                          "icon-base",
                          {
                            staticClass: "ml-2 mr-4",
                            attrs: {
                              "icon-name": "write",
                              height: "30",
                              width: "30",
                              iconColor: "#111"
                            }
                          },
                          [
                            _c(
                              _vm.getIconName(
                                _vm.suggested[_vm.counter].subcategory.slug
                              ),
                              {
                                tag: "component",
                                attrs: { slot: "icon" },
                                slot: "icon"
                              }
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "col-12" }, [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.suggested[_vm.counter].subcategory.name
                            ) + " "
                          ),
                          _vm.suggested[_vm.counter].subcategory
                            .once_monthly === 1
                            ? _c("span", [_vm._v(" Bill")])
                            : _vm.suggested[_vm.counter].subcategory
                                .once_monthly === 0
                            ? _c("span", [_vm._v(" Expense")])
                            : _vm._e()
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-auto" }, [
                      _c("div", { attrs: { claass: "col-3" } }, [
                        _c("h5", [
                          _vm._v(
                            _vm._s(_vm.currentCount) +
                              " / " +
                              _vm._s(_vm.suggestedCount)
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 form-group" },
                      [
                        _c("label", [
                          _vm.suggested[_vm.counter].subcategory
                            .once_monthly === 1
                            ? _c("span", [_vm._v(" Amount")])
                            : _vm.suggested[_vm.counter].subcategory
                                .once_monthly === 0
                            ? _c("span", [_vm._v(" Amount for the month")])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("currency-input", {
                          attrs: { amount: _vm.budget.credit_amount },
                          on: {
                            subtractFromAmountValue:
                              _vm.subtractFromAmountValue,
                            addToAmountValue: _vm.addToAmountValue,
                            passValue: _vm.passValue
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.budget.due_in_full === 1
                      ? _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("label", [_vm._v("Next Due Date")]),
                            _vm._v(" "),
                            _c("date", {
                              attrs: { date: _vm.first },
                              on: { setDate: _vm.setStartDate }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Does this budget repeat?")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-toggle-checkbox"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.budget.is_recurring,
                                  expression: "budget.is_recurring"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                "true-value": 1,
                                "false-value": 0,
                                id: "defaultCheck"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.budget.is_recurring)
                                  ? _vm._i(_vm.budget.is_recurring, null) > -1
                                  : _vm._q(_vm.budget.is_recurring, 1)
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.budget.is_recurring,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.budget,
                                          "is_recurring",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.budget,
                                          "is_recurring",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.budget, "is_recurring", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "toggle-labels" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "unchecked-label",
                                  attrs: { for: "defaultCheck" }
                                },
                                [_vm._v("No")]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "checked-label",
                                  attrs: { for: "defaultCheck" }
                                },
                                [_vm._v("Yes")]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.budget.is_recurring == 1
                    ? _c("div", { staticClass: "row mt-4" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Every")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.budget.repeat_interval,
                                  expression: "budget.repeat_interval"
                                }
                              ],
                              staticClass: "form-control no-border",
                              attrs: { type: "text", value: "1" },
                              domProps: { value: _vm.budget.repeat_interval },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.budget,
                                    "repeat_interval",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v(" ")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.budget.repeat_frequency,
                                    expression: "budget.repeat_frequency"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.budget,
                                      "repeat_frequency",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "month",
                                      selected: "selected"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        this.formatPlural(
                                          _vm.budget.repeat_interval,
                                          "Month"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "year" } }, [
                                  _vm._v(
                                    _vm._s(
                                      this.formatPlural(
                                        _vm.budget.repeat_frequency,
                                        "Year"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v(" ")]),
                            _vm._v(" "),
                            _vm.budget.repeat_frequency == "month"
                              ? _c("select", { staticClass: "form-control" }, [
                                  _c("option", { attrs: { value: "month" } }, [
                                    _vm._v(
                                      "On day " + _vm._s(_vm.budget.start_day)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Ending")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.budget.ending,
                                    expression: "budget.ending"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.budget,
                                      "ending",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "never" } }, [
                                  _vm._v("No end date")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "end_after" } },
                                  [_vm._v("End after")]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "end_by" } }, [
                                  _vm._v("End by")
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _vm.budget.ending == "end_after"
                            ? _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Number of occurences")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.budget.repeat_num_occurrences,
                                      expression:
                                        "budget.repeat_num_occurrences"
                                    }
                                  ],
                                  staticClass: "form-control no-border",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.budget.repeat_num_occurrences
                                  },
                                  on: {
                                    change: _vm.setEndDateToNull,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.budget,
                                        "repeat_num_occurrences",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _vm.budget.ending == "end_by"
                            ? _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("End Date")]),
                                  _vm._v(" "),
                                  _c("date", {
                                    attrs: { date: _vm.today },
                                    on: { setDate: _vm.setEndDate }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-check-inline" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.doNotShowAgain,
                            expression: "doNotShowAgain"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.doNotShowAgain)
                            ? _vm._i(_vm.doNotShowAgain, null) > -1
                            : _vm.doNotShowAgain
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.doNotShowAgain,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.doNotShowAgain = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.doNotShowAgain = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.doNotShowAgain = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "Do not show this again.\n                                "
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ml-auto" }, [
                _vm.welcomeShown == false
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.skipOnce }
                      },
                      [_vm._v("Close")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.welcomeShown == false
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.showNextBudgetSuggestion }
                      },
                      [_vm._v("Continue")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.welcomeShown == true && _vm.counter > 0
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary",
                        on: { click: _vm.showPreviousBudgetSuggestion }
                      },
                      [_vm._v("Back")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.welcomeShown == true &&
                _vm.counter + 1 < this.suggested.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary pull-right",
                        on: { click: _vm.showNextBudgetSuggestion }
                      },
                      [_vm._v("Skip")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.welcomeShown == true &&
                _vm.counter + 1 == this.suggested.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary pull-right",
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v("Skip and Close")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.welcomeShown == true &&
                _vm.counter + 1 < this.suggested.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { disabled: _vm.validated },
                        on: { click: _vm.saveBudgetSuggestion }
                      },
                      [_vm._v("Save and Continue")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.counter + 1 == this.suggested.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { disabled: _vm.validated },
                        on: { click: _vm.saveBudgetSuggestion }
                      },
                      [_vm._v("Save and Close")]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }