var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light container account-info-inner boxshadow-1" },
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass: "card bg-white flex-md-row mb-4 box-shadow h-md-250"
            },
            [
              _vm.currentPlan
                ? _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("h6", { staticClass: "text-center" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.currentPlan.name))])
                        ]),
                        _vm._v(" "),
                        _vm.currentPlan.isPaidSubscription
                          ? _c("h6", { staticClass: "text-center" }, [
                              _c("strong", [
                                _vm._v(
                                  "$" +
                                    _vm._s(_vm.currentPlan.amount) +
                                    " / " +
                                    _vm._s(_vm.currentPlan.billingInterval)
                                )
                              ])
                            ])
                          : _c("h6", { staticClass: "text-center" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    typeof _vm.currentPlan.price === "string"
                                      ? _vm.currentPlan.price
                                      : "$" + _vm.currentPlan.price
                                  )
                                )
                              ])
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.currentPlan.isPaidSubscription
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                                Your plan will automatically renew on "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.currentPlan.next_bill_date))
                              ]),
                              _vm._v(" and you'll be charged "),
                              _c("strong", [
                                _vm._v("$" + _vm._s(_vm.currentPlan.price))
                              ]),
                              _vm._v(".\n                            ")
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass: "card bg-white flex-md-row mb-4 box-shadow h-md-250"
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(_vm.availablePlans, function(plan, index) {
                  return plan.isCurrent == false
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("h6", { staticClass: "m-2" }, [
                            _c("strong", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-dark",
                                  attrs: { href: "#" }
                                },
                                [_vm._v(_vm._s(plan.name))]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("h6", { staticClass: "m-2" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "#" }
                              },
                              [_vm._v(_vm._s(plan.benefits))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("h6", { staticClass: "m-2" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-muted",
                                attrs: { href: "#" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    plan.impactOfSwitching
                                      ? plan.impactOfSwitching
                                      : "$" +
                                          plan.price +
                                          " / " +
                                          plan.billingInterval
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        plan.isPaidSubscription && plan.isCurrent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 d-flex align-items-center text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.switchPlan(plan.id)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(plan.cancelButtonText))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        plan.isPaidSubscription && !plan.isCurrent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 d-flex align-items-center text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.switchPlan(plan.id)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(plan.upgradeButtonText))]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                }),
                0
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "h-green text-center" }, [
      _c("strong", [_vm._v("Your Plan")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5 text-center" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h5", [_c("strong", [_vm._v("Change plans")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }