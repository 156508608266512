<template>
	<div class="shadow-b secondary-menu-bar">
		<div class="container">
			<div class="row">
				<div class="col-xl-4 col-lg-5 order-lg-last text-center text-lg-right month-switch">
					<a href="#" class="btn btn-link btn-link-light today" @click="loadCurrentMonth">Today</a>
					<a href="#" class="btn btn-link btn-link-light" @click="loadPreviousMonth"><i class="fa fa-angle-left"></i></a>
					<a href="#" class="btn btn-link btn-link-light" @click="loadNextMonth"><i class="fa fa-angle-right"></i></a>
					<span class="btn btn-link btn-link-light month">{{ month }} {{ year }}</span>
				</div>
				<div class="col-xl-8 col-lg-7 order-lg-first mt-4 mt-lg-0">
					<div class="row">
						<div class="col-xl-8 col-lg-7 col-md-7">
							<div class="input-group custom-search ml-auto mr-auto ml-lg-3" v-bind:class="{ 'disabled':(budgets && budgets.length==0 || (budgets && budgets.length==0 && !search)) }">
								<div class="input-group-prepend">
									<div class="input-group-text" id="btnGroupAddon2"><i class="fa fa-search"></i></div>
								</div>
								<input class="form-control" type="search" placeholder="Search Budgets" v-model="search" :disabled="budgets && budgets.length==0 || (budgets && budgets.length==0 && !search)">
							</div>
						</div>
						<div class="col-xl-4 col-lg-5 col-md-5 mt-4 mt-md-0 text-center text-md-left">
							<button class="btn btn-orange btn-wide" @click="set_current_input('add-new-budget')" :disabled="current_input==='add-new-budget' || !current_month_is_shown_month">Add Budget</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            category_filter: 'all',
            search: '',
        }
    },
    mounted: function() {

        if(this.$store.state.entities.length==0) {
            this.$store.dispatch('get_entities');
        }

        if(this.$store.state.income_categories.length==0) {
            this.$store.dispatch('get_income_categories');
        }

        if(this.$store.state.expense_categories.length==0) {
            this.$store.dispatch('get_expense_categories');
        }

    },
    methods: {
        set_current_input(input) {
			this.$store.commit('setCurrentInput', {input: input});
        },
        loadCurrentMonth() {
            var current = new Date();
            var current_year = this.$store.state.current_date.setYear(current.getFullYear());
            var current_month = this.$store.state.current_date.setMonth(current.getMonth());
            this.$store.commit('setCurrentDate', new Date(current_month));


            this.$store.dispatch('get_budgets').then(() => {
				this.$store.dispatch('get_all_accounts_transactions_for_this_month', {account: null})
            });


        },
        loadPreviousMonth() {
            var previous_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()-1);
            this.$store.commit('setCurrentDate', new Date(previous_month));

			this.$store.dispatch('get_budgets').then(() => {
				this.$store.dispatch('get_all_accounts_transactions_for_this_month', {account: null})
			});

        },
        loadNextMonth() {
            var next_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()+1);
            this.$store.commit('setCurrentDate', new Date(next_month));

			this.$store.dispatch('get_budgets').then(() => {
				this.$store.dispatch('get_all_accounts_transactions_for_this_month', {account: null})
			});
        },
        monthStringFromDate(date) {
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            if(typeof(date)==='object') {
                return  monthNames[date.getMonth()];
            }
        },
        round (value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },
        searchBudgets() {
            this.$store.commit('searchBudgets', this.search)
        }
    },
    computed: {
        current_month_is_shown_month() {

            // Current date
            var d = new Date();
            var dateMonth = d.getMonth();
            var dateYear = d.getYear();

            // Shown date
            var ds = new Date(this.$store.state.current_date);
            var monthShown = ds.getMonth();
            var yearShown = ds.getYear();

            if(monthShown === dateMonth && yearShown === dateYear) {
                return true;
            }

            return false;

        },
        budgets() {
            return this.$store.state.budgets;
        },
        current_input() {
            return this.$store.state.current_input;
        },
        month() {
            if(this.$store.state.current_date){
                return this.monthStringFromDate(this.$store.state.current_date);
            }
        },
        year() {
            if(this.$store.state.current_date){
                return this.$store.state.current_date.getFullYear();
            }
        },
		new_budget() {
			return this.$store.state.new_budget;
		},
		unbudgeted_expense_categories() {
			return this.$store.state.unbudgeted_expense_categories;
		},
    },
    watch: {
        search: function(newValue, oldValue) {
            this.$store.commit('searchBudgets', newValue)
        },
    }
}
</script>
