var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "main-wrap loading-screen" },
      [
        _c("connect-bank-account-modal"),
        _vm._v(" "),
        _c("plaid-link-token-modal"),
        _vm._v(" "),
        _c("div", { staticClass: "inner-wrap" }, [
          _c("div", { staticClass: "loader" }, [
            _c("div", { staticClass: "circle" })
          ]),
          _vm._v(" "),
          _c("h4", { staticClass: "white" }, [_vm._v(_vm._s(_vm.message))]),
          _vm._v(" "),
          _vm.percent
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "progress" }, [
                    _c(
                      "div",
                      {
                        staticClass: "progress-bar progress-bar-success",
                        style: _vm.width,
                        attrs: {
                          role: "progressbar",
                          "aria-valuenow": "0",
                          "aria-valuemin": "0",
                          "aria-valuemax": "100"
                        }
                      },
                      [
                        _c("div", { staticClass: "percentage-indicator" }, [
                          _c("span", { staticClass: "value" }, [
                            _vm.percent == 100
                              ? _c("i", {
                                  staticClass: "fa fa-check d-none",
                                  attrs: { "aria-hidden": "true" }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.percent) +
                                "%\n\t\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }