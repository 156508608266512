var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ins", {
    staticClass: "adsbygoogle",
    style: _vm.adStyle,
    attrs: {
      "data-ad-client": _vm.adClient,
      "data-ad-slot": _vm.adSlot,
      "data-ad-format": _vm.adFormat
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }