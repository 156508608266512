var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M276.14,263.81s66.54-24.9,78.76-24.9,24.43,7.66,18.68,19.87S278,317.7,255,322.73s-96.15-5.61-119.89,1.47c-16.33,4.88-33,15.29-41.81,21.31a13.67,13.67,0,0,1-13.92.89c-13.74-7-42.52-23.32-54.45-42.25,0,0,82.48-38.53,111.08-62.31,20.77-8.13,43.05,2.71,59.91,7.53s68,1.2,75.26,8.42,7.83,27.1-7.23,27.1H192.34"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "184.7", y1: "245.78", x2: "184.7", y2: "159.96" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M182.11,109.73H339.95a17.16,17.16,0,0,1,17.16,17.16v29.83a0,0,0,0,1,0,0H165a0,0,0,0,1,0,0V126.89A17.16,17.16,0,0,1,182.11,109.73Z"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "337.01", y1: "156.72", x2: "337.01", y2: "238.91" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "261.03", y1: "156.72", x2: "261.03", y2: "253.1" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M261,96.55s-25.31-53.41-53.25-43.13c-18.72,6.89,3.59,44.34,31.31,56.31"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M261,96.55s25.31-53.41,53.25-43.13C333,60.31,310.69,97.76,283,109.73"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }