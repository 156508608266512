<template>	
	<div class="card-box">
		<h4 class="mt0 text-center">MONTHLY INCOME</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="col-md-6">
					<span class="text-muted text-left little">Total</span>
				</div>
				<div class="col-md-6">
					<span class="text-right dollars">${{ totalIncomeT }}</span>
				</div>
				<p><a href="#" v-on:click="showRecurringIncomeModal">View</a></p>
				<h4 class="mt0 text-center">MONTHLY EXPENSES</h4>
				<div class="col-md-6">
					<span class="text-muted text-left little">Total</span>
				</div>
				<div class="col-md-6">
					<span class="text-right dollars">${{ totalExpensesT }}</span>
				</div>
				<p><a href="#" v-on:click="showRecurringExpensesModal">View</a></p>
				<h4 class="mt0 text-center">DISPOSABLE INCOME</h4>
				<div class="col-md-6">
					<span class="text-muted text-left little">Total</span>
				</div>
				<div class="col-md-6">
					<span class="text-right dollars">${{ monthly_disposable_income }}</span>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<p v-if="expenses.length>0">Top expenses</p>
					<ul class="legend">
						<li v-for="(expense, index) in expenses" :class="getClass(index)">
							{{ expense.subcategory.name }} 
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="ct-chart"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
//		props: ["expenses"],
		data() {
			return {
				 totalExpensesT: 0,
				 totalIncomeT: 0,
				 alpha: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n'],
			}
		},
		mounted() {

			this.$nextTick(function() {

				var pieData = {
					series: this.pieSeries,
					labels: this.pieLabels,
				}

				var chart = new Chartist.Pie('.ct-chart', pieData, {
					  donut: true,
                      donutWidth: 40,
					  showLabel: false,
					  stackBars: true,
				});
			});


		},
		computed: {
			monthly_disposable_income() {
				return this.round(this.$store.getters.total_income - this.$store.getters.total_expenses, 2);
			},
			pieLabels() {
				return this.$store.getters.pieLabels;
			},
			pieSeries() {
				return this.$store.getters.pieSeries;
			},
			expenses () {
				return _.orderBy(this.$store.state.expenses, 'credit_amount','desc');
				//return this.$store.state.expenses;
			},
			total_income () {
			    return this.$store.getters.total_income;
			},
			total_expenses () {
		    	return this.$store.getters.total_expenses;
			},
		},
		watch: {

             total_expenses: function(newValue, oldValue) {
 
                 var vm = this
                 var animationFrame
                 function animate (time) {
                     TWEEN.update(time)
                     animationFrame = requestAnimationFrame(animate)
                 }
 
                 new TWEEN.Tween({ tweeningNumber: oldValue })
                 .easing(TWEEN.Easing.Quadratic.Out)
                 .to({ tweeningNumber: newValue }, 500)
                 .onUpdate(function () {
                     vm.totalExpensesT = this.tweeningNumber.toFixed(0)
                     //vm.$store.commit('setBudgets', budgets)
                 })
                 .onComplete(function () {
                     cancelAnimationFrame(animationFrame)
                 })
                 .start()
                 animationFrame = requestAnimationFrame(animate)
             },
             total_income: function(newValue, oldValue) {
 
                 var vm = this
                 var animationFrame
                 function animate (time) {
                     TWEEN.update(time)
                     animationFrame = requestAnimationFrame(animate)
                 }
 
                 new TWEEN.Tween({ tweeningNumber: oldValue })
                 .easing(TWEEN.Easing.Quadratic.Out)
                 .to({ tweeningNumber: newValue }, 500)
					 .onUpdate(function () {
						 if(this.tweeningNumber) {
							 vm.totalIncomeT = this.tweeningNumber.toFixed(0)
						 }
                     //vm.$store.commit('setBudgets', budgets)
                 })
                 .onComplete(function () {
                     cancelAnimationFrame(animationFrame)
                 })
                 .start()
                 animationFrame = requestAnimationFrame(animate)
             }
		},
		methods: {
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			getClass(index) {
				return 'series-' + this.alpha[index];
			},
			showRecurringIncomeModal() {
				this.$store.commit('setToggleRecurringIncomeModal', true)
			},
			showRecurringExpensesModal() {
				this.$store.commit('setToggleRecurringExpensesModal', true)
			}
		},
	}
</script>
