var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "main-footer main-footer-v2 container-fluid" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "copyright-msg" }, [
        _vm._v(
          "Ⓒ " +
            _vm._s(new Date().getFullYear()) +
            " Budget It Up, LLC All Rights Reserved"
        )
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "questionmark", attrs: { href: "" } }, [
        _vm._v("?")
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "backtotop", attrs: { href: "", id: "backtotop" } },
      [_c("img", { attrs: { src: "images/arrow-up-icon.png" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }