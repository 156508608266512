var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "87.33", y1: "268.03", x2: "312.67", y2: "268.03" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "67.68", y1: "296.36", x2: "332.32", y2: "296.36" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "52.23", y1: "324.68", x2: "347.77", y2: "324.68" }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points: "93.42 140.03 198.57 75.32 306.58 140.03 93.42 140.03"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "106.28", y1: "268.03", x2: "106.28", y2: "143.64" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "135.74", y1: "268.03", x2: "135.74", y2: "143.64" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "264.26", y1: "268.03", x2: "264.26", y2: "143.64" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "293.71", y1: "268.03", x2: "293.71", y2: "143.64" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M170.22,242.67A38.22,38.22,0,0,1,200,221c22.27-3,32,13.86,32,13.86"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "187.1", y1: "184.8", x2: "187.1", y2: "200" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "208.77", y1: "184.8", x2: "208.77", y2: "198.7" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }