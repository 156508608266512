var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    { staticClass: "form-control ui dropdown transparent-dropdown" },
    [
      _c("option", { domProps: { value: null } }, [
        _vm._v("Select " + _vm._s(_vm.defaultItem))
      ]),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _vm.items.length > 0
          ? _c("option", { key: item.id, domProps: { value: item.id } }, [
              _vm._v(_vm._s(item.name))
            ])
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }