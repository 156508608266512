var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-md fixed-top navbar-v2 navbar-logged-in",
      attrs: { id: "mainNav" }
    },
    [
      _c("div", { staticClass: "mobile-bg d-block d-md-none" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "container-fluid container-fluid-90 d-flex justify-content-between"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "navbar-brand",
              attrs: { to: { name: "home" }, "active-class": "active" }
            },
            [
              _c("img", {
                staticClass: "logo px-3 px-md-0 px-lg-0",
                attrs: { src: "/images/budget_it_up.png" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse no-transition",
              attrs: { id: "navbarCollapse" }
            },
            [
              _vm.authCheck()
                ? _c("ul", { staticClass: "navbar-nav mr-auto" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link small",
                            attrs: {
                              to: { name: "accounts" },
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Accounts")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link small",
                            attrs: {
                              to: { name: "budgets" },
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Budget")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.authCheck()
                ? _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link small",
                            attrs: {
                              to: { name: "support" },
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Support")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item dropdown user-settings-menu" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "nav-link dropdown-toggle small dropdown",
                            attrs: {
                              href: "",
                              id: "dropdownSettings",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.user.email) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "dropdownSettings" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: { name: "settings" } }
                              },
                              [_vm._v("Account")]
                            ),
                            _vm._v(" "),
                            _vm.isBudgetBoss
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: { name: "connected-banks" } }
                                  },
                                  [_vm._v("Connected Institutions")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-divider" }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/#" },
                                on: { click: _vm.logout }
                              },
                              [_vm._v("\n\t\t\t\t\t\tLogout\n\t\t\t\t\t\t")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item d-block d-md-none mobile-logo" }, [
      _c("img", {
        staticClass: "logo px-3 px-md-0 px-lg-0",
        attrs: { src: "images/budget_it_up.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }