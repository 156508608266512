var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-13")]),
      _c("path", {
        attrs: {
          d:
            "M351,155.25H315.33V77.6a16.57,16.57,0,0,0-16.56-16.55h-244A16.56,16.56,0,0,0,38.19,77.6V244.91a16.56,16.56,0,0,0,16.55,16.55h35.7v77.65A16.57,16.57,0,0,0,107,355.66H351a16.58,16.58,0,0,0,16.55-16.55V171.8A16.58,16.58,0,0,0,351,155.25ZM54.74,73.05h244a4.56,4.56,0,0,1,4.56,4.55v29.24H50.19V77.6A4.55,4.55,0,0,1,54.74,73.05Zm0,176.41a4.55,4.55,0,0,1-4.55-4.55V118.84H303.33v36.41H107A16.57,16.57,0,0,0,90.44,171.8v77.66Zm300.83,89.65a4.55,4.55,0,0,1-4.55,4.55H107a4.55,4.55,0,0,1-4.55-4.55V171.8a4.55,4.55,0,0,1,4.55-4.55H351a4.55,4.55,0,0,1,4.55,4.55Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M170.17,183.33H126.38a6,6,0,0,0-6,6v31.56a6,6,0,0,0,6,6h43.79a6,6,0,0,0,6-6V189.33A6,6,0,0,0,170.17,183.33Zm-6,31.56H132.38V195.33h31.79Z"
        }
      }),
      _c("path", {
        attrs: { d: "M322.21,281H246.92a6,6,0,1,0,0,12h75.29a6,6,0,1,0,0-12Z" }
      }),
      _c("path", {
        attrs: {
          d: "M322.21,309.36H188.85a6,6,0,0,0,0,12H322.21a6,6,0,0,0,0-12Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }