import { render, staticRenderFns } from "./NavbarMarketing.vue?vue&type=template&id=6f86fd4a&"
import script from "./NavbarMarketing.vue?vue&type=script&lang=js&"
export * from "./NavbarMarketing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Library/WebServer/Documents/budgetitup-spa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f86fd4a')) {
      api.createRecord('6f86fd4a', component.options)
    } else {
      api.reload('6f86fd4a', component.options)
    }
    module.hot.accept("./NavbarMarketing.vue?vue&type=template&id=6f86fd4a&", function () {
      api.rerender('6f86fd4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/NavbarMarketing.vue"
export default component.exports