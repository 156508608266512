var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        staticClass: "form-control ui dropdown transparent-dropdown",
        attrs: { id: "entity" }
      },
      [
        _c("option", { attrs: { value: "0" } }, [
          _vm._v("Select a place/entity")
        ]),
        _vm._v(" "),
        _vm._l(_vm.entities, function(entity) {
          return _c(
            "option",
            {
              domProps: {
                value: entity ? entity.id : null,
                selected:
                  entity && _vm.current
                    ? Number(entity.id) === Number(_vm.current.id)
                    : null
              }
            },
            [_vm._v(_vm._s(entity.name))]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("p", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              return _vm.set_current_input("add-new-entity", $event)
            }
          }
        },
        [_vm._v("Add new")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }