<template>
    <div class="container account-info-inner boxshadow-1">
        <div class="row mb-5" v-if="subscription">
            <div class="col-12">
                <h4>Subscription & payment</h4>
            </div>
            <div class="col-12">
                <h4 class="h-green"><i class="fa fa-check-circle h-green" aria-hidden="true"></i> {{ subscriptionPlanName }}</h4>
                <p class="card-compact">
                Your plan will automatically renew on <strong>{{ subscription.next_bill_date }}</strong> and you'll be charged <strong>${{ subscription.amount }}</strong>.
                </p>
                <router-link class="btn btn-primary" :to="{ name: 'change' }">Change or Cancel</router-link>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        data: function() {
            return {
                subscription: null
            }
        },
        methods: {
            round (value, decimals) {
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
            },
        },
        mounted: function() {

            var that = this;
            this.$store.dispatch('get_subscriptions').then(() => {
                that.subscription = this.$store.state.subscriptions;
            }) .catch((e)=>{
				that.$router.push("/account");
            });

        },
        computed: {
            user: function() {
                return this.$store.state.user;
            },
            userName: function () {
                return 'test';
            },
            userEmail: function () {
                return 'test';
            },
            subscriptionPlanName: function () {
                return 'Budget Boss';
            },
            subscriptionAmount: function () {
                return 9.99;
            },
            subscriptionStartDate: function () {
                return 'test';
            },
            subscriptionNumberOfAvailableConnectedBanks: function () {
                return 'test';
            },
        },
    }
</script>

