<template>
	<div class="row">
		<div class="col-3">
            <div class="text-line"></div>
		</div>
		<div class="col-2">
            <div class="text-line"></div>
		</div>
		<div class="col-2">
            <div class="text-line"></div>
		</div>
		<div class="col-3">
            <div class="text-line"></div>
		</div>
		<div class="col-2 icons-col">
		</div>
	</div>
</template>
<script>
export default {}
</script>
