var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBudgetedToGo
    ? _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "modal is-active" }, [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("h1", [_vm._v("Budgeted To Go")]),
                _vm._v(" "),
                _vm._l(_vm.budgetsToGo, function(budget, index) {
                  return _c(budget.mode, {
                    key: budget.id,
                    tag: "budget",
                    attrs: {
                      budget: budget,
                      budgets: _vm.budgetsToGo,
                      index: index,
                      percent: _vm.percent
                    }
                  })
                })
              ],
              2
            )
          ]),
          _c("button", {
            staticClass: "modal-close is-large",
            on: { click: _vm.close }
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }