var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-danger btn-box-shadow btn-wide",
        on: {
          click: function($event) {
            return _vm.remove(_vm.bank)
          }
        }
      },
      [_vm._v("Remove")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-cancel-grey btn-box-shadow btn-wide",
        on: { click: _vm.cancel }
      },
      [_vm._v("Cancel")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }