<template>
	<div>
		<div class="form-row">
			<div class="form-group col-12 col-sm-2 col-12">
				<label>Amount</label>
				<currency-input :amount='amount'
				 @passValue='passValue'
				 ></currency-input>
			</div>
			<div class="form-group col-10 col-sm-4">
				<label>Category</label>
				<div class="clearfix"></div>
				<semantic-select :items="expense_categories" :preSelect='(firstSubcategory && index===0) ? firstSubcategory.transaction_category_id : null' @update='function (id) { setCategory(id,0) }' v-if='transaction.type=="expense"'></semantic-select>
				<semantic-select :items="income_categories" :preSelect='(firstSubcategory) ? firstSubcategory.transaction_category_id : null' @update='function (id) { setCategory(id,0) }' v-else-if='transaction.type=="income"'></semantic-select>
			</div>
			<div class="col-sm-4 form-group">
				<label>Sub-category</label>
				<div class="clearfix"></div>
				<semantic-select :items="subcategories" @update='function (id) { update(id,index) }' v-if='transaction.type=="expense"' :preSelect='(firstSubcategory) ? firstSubcategory.id : null'></semantic-select>
				<semantic-select :items="subcategories" @update='function (id) { update(id,index) }' v-else-if='transaction.type=="income"' :preSelect='(firstSubcategory) ? firstSubcategory.id : null'></semantic-select><!--  :preSelect="" -->
			</div>
			<div class="form-group col-2">
				<a href="" class="text-center d-inline-block pt-3 remove-btn" @click='remove(index,$event)'>
					<span class="d-block">Remove</span>
					<i class="fas fa-trash-alt"></i>
				</a>
			</div>
		</div>
	</div>
</template>

			<!--
			   -<dropdown @update="function (id) { update(id,index) }" :expense_categories="expense_categories" v-if='(transaction && transaction.type=="expense")' :current='subcategory.id'></dropdown>
			   -<dropdown @update="function (id) { update(id,index) }" :expense_categories="income_categories" v-else-if='(transaction && transaction.type=="income")' :current='subcategory.id'></dropdown>
			   -<dropdown @update="function (id) { update(id,index) }" :disabled='true' v-else></dropdown>
			   -->

<script>
export default {
	props:{
		index: Number,
		subcategory: Object,
		transaction: Object,
    },
	data() {
		return {
			subcategories: []
		}
	},
    mounted: function() {
        // this.addRow(null);
        // console.log(JSON.stringify(this.subcategory));
    },
	created: function() {
		if(this.index===0) {
			if(	this.transaction.type==='expense'){
				var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(this.firstSubcategory.transaction_category_id));
			} else if (this.transaction.type==='income') {
				var m = this.$store.state.income_categories.find(category => Number(category.id) === Number(this.firstSubcategory.transaction_category_id));
			}
			if(m) {
				this.subcategories = m.subcategories;
			}
		}

	},
	computed: {
        firstSubcategory() {
            if (this.transaction.subcategories.length>0) {
                return this.transaction.subcategories[0];
            }
        },
        amount: {
            get: function() {
                if(typeof(this.subcategory.pivot) !=='undefined'){
                    return this.subcategory.pivot.amount
                } else if (typeof(this.subcategory.amount !== 'undefined')) {
                    return this.subcategory.amount;
                }
            },
            set: function(newAmount) {
				console.log(newAmount);
                if (typeof(this.subcategory)!=='undefined') {
                    if(typeof(this.subcategory.pivot) !=='undefined'){
                        return this.subcategory.pivot.amount = newAmount;
                    } else if (typeof(this.subcategory.amount !== 'undefined')) {
                        return this.subcategory.amount = newAmount;
                    }
                }
            }
        },
		income_categories () {
			return this.$store.state.income_categories;
		},
		expense_categories () {
			return this.$store.state.expense_categories;
		},
		transaction_categories () {
			return this.$store.state.transaction_categories;
		},
    },
	methods: {

		setCategory(value) {
			if(this.transaction.type==='expense'){
				var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(value));
			} else if (this.transaction.type==='income') {
				var m = this.$store.state.income_categories.find(category => Number(category.id) === Number(value));
			}
			if(m) {
				this.subcategories = m.subcategories;
			}
		},
		setSubcategory: function(selected) {
			var that = this;

			if(this.transaction.type=='expense') {
				this.$store.state.expense_categories.forEach(function(category) {
					var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
					if(transaction_subcategory) {
						if(that.transaction.subcategories.length==1) {
							that.transaction.subcategories[0].id = transaction_subcategory.id;
							//that.transaction.subcategories.splice(subcategory_index, 1, {id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						} else if (that.transaction.subcategories.length==0) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						}
					}
				});

			} else if (this.transaction.type=='income') {
				this.$store.state.income_categories.forEach(function(category) {
					var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
					if(transaction_subcategory) {
						if(that.transaction.subcategories.length==1 && transaction_subcategory) {
							that.transaction.subcategories[0].id = transaction_subcategory.id;
							// that.transaction.subcategories.splice(subcategory_index, 1, {id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						} else if (that.transaction.subcategories.length==0) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						}
					}
				});
			}

		},
        passValue(value) {
            return this.amount = (value);
        },
        formatMoney(amount) {
            return accounting.formatMoney(amount);
        },
        addRow(event) {
            (!event) ? null : event.preventDefault();
//            var remaining_amount = this.transaction.amount;
  //          var num_subcategories = this.transaction.subcategories.length;
    //        if(num_subcategories) {
      //          for(let i =0; i<num_subcategories; i++) {
        //            remaining_amount -= this.transaction.subcategories[i].amount;
          //      }
            //}
            this.transaction.subcategories.push({id:null, pivot: { amount: null}});
        },
		update(selected, index){
			//this.$emit('update', selected, this.transaction, index);
            var subcat = this.transaction.subcategories[index];
            subcat.id = selected;
            this.transaction.subcategories.splice(index, 1, subcat);
		},
        remove(index, event) {
            event.preventDefault();
            this.transaction.subcategories.splice(index, 1);
        }
    }
}
</script>
