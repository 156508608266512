<template>
	<transition
		name="fade"
		v-if="showMFAModal"
		>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-content-login">
				<div class="box">
					<div class="form-row">
						<div class="form-group col">
							<h3 class="text-center">MFA</h3>
							<p class="text-center">Complete the MFA form below</p>
							<p v-if='mfaResponseModel'>{{ mfaResponseModel.mfa_message.message }}</p>
							<div class="form-row">
								<div class="form-group col">    
									<label for="name">Code/Access token</label>
									<input type="text" name="username" class="form-control" id="name" v-model="mfa_response">
								</div>
							</div> 
							<div class="clearfix"></div>
							<errors :errors="errors"></errors>
							<div class="form-row">
								<div class="form-group col">
									<div class="text-right">
										<button class="btn btn-link" @click="close">Cancel</button>
										<button type="submit" class="btn btn-large btn-primary" @click='submit'>Submit</button>
									</div>
								</div>
							</div>
							<div class="clearfix"></div>
							<button class="modal-close" @click="close"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	data() {
		return {
			mfa_response: null
		}
	},
	computed: {
		mfaResponseModel() {
			return this.$store.state.mfaResponseModel;
		},
		showMFAModal() {
			return this.$store.state.showMFAModal;
		},
		errors() {
			return this.$store.state.mfa_errors;
		},
	},
	methods: {
		close() {
			//this.$store.commit('clearConnectBankAccountErrors');
			this.$store.commit('setToggleMFAModal', false);
		},
		submit() {
			this.$store.dispatch('submit_mfa', {
				mfa_response: this.mfa_response
			});
		}
	}	
}
</script>
