var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 11")]),
      _c("path", {
        attrs: {
          d:
            "M367.52,159a6.39,6.39,0,0,0-.89-.4l-53.71-36.2V36.75a6,6,0,0,0-6-6H95.25a6,6,0,0,0-6,6v84L32.06,159.27l-.17.13-.18.14a5.46,5.46,0,0,0-.45.39l-.09.08a6.3,6.3,0,0,0-.48.55l-.06.08c-.13.17-.24.34-.35.51l-.09.16a4.53,4.53,0,0,0-.27.54l0,.1a5.3,5.3,0,0,0-.23.67c0,.06,0,.12,0,.17s-.08.36-.11.54l0,.2c0,.24,0,.49,0,.73v199a6,6,0,0,0,6,6H364.58a6,6,0,0,0,6-6v-199A6,6,0,0,0,367.52,159Zm-54.6-22.13,40.37,27.21-40.37,24.57Zm-211.67-13V42.75H300.92V196l-99.86,60.77-99.81-60Zm-12,11.27v54.38L46.58,164ZM358.58,357.25H41.42V174.85l156.57,94a6,6,0,0,0,6.21,0l154.38-94Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M208.58,211.16V198.23c19.32-1.48,38.6-12.64,38.6-34.45,0-26.12-24.59-31.75-47.64-35-16.1-2.25-27.49-4.65-27.49-15.12a12.85,12.85,0,0,1,3.84-9.53c4.53-4.59,13.1-7.33,22.9-7.33,9.37.13,18,3.11,23.39,7.77A9,9,0,0,0,234.1,91.18c-7.34-6.71-16.38-9-25.36-10.16V68.56a2,2,0,0,0-2-2H192.28a2,2,0,0,0-2,2V81c-18.35,2.08-37.65,11.76-37.49,32.84.16,24.78,20.37,29.42,44.77,32.58,15.44,2.18,29.73,5.79,29.73,17.35,0,15.5-18.17,17.82-29,17.82-10.41,0-22.6-3.74-29.42-10.95a9.29,9.29,0,0,0-11-1.79,9.39,9.39,0,0,0-2.42,14.8c8,8.46,20.24,13.93,34.85,15.2v12.3a3.06,3.06,0,0,0,3.06,3.07h12.14A3.06,3.06,0,0,0,208.58,211.16Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }