var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-progress" }, [
    _c("h5", { staticClass: "title1" }, [_vm._v("Overview")]),
    _vm._v(" "),
    _c("ul", { staticClass: "stats-group" }, [
      _c("li", { staticClass: "list-group-item" }, [
        _vm._v("\n\t\t\tTotal Saved\n\t\t\t"),
        _c("span", [_vm._v("$" + _vm._s(_vm.total_goals_saved))])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _vm._v("\n\t\t\tTotal To Go\n\t\t\t"),
        _c("span", [_vm._v("$" + _vm._s(_vm.total_left_to_save))])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _vm._v("\n\t\t\tTotal Goals\n\t\t\t"),
        _c("span", [_vm._v("$" + _vm._s(_vm.total_goals_amount))])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _vm._v("\n\t\t\tNumber of Goals\n\t\t\t"),
        _c("span", [_vm._v(_vm._s(_vm.total_number_of_goals))])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _vm._v("\n\t\t\tTotal Due This Month\n\t\t\t"),
        _c("span", [_vm._v("$" + _vm._s(_vm.left_to_allocate_this_month))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }