var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 5")]),
      _c("path", {
        attrs: {
          d:
            "M368.6,94.27H107.23l-5.14-32A49.5,49.5,0,0,0,53.22,21.13H31.4a6,6,0,1,0,0,12H53.22a37.5,37.5,0,0,1,37,31.11l28.27,176.07a39.8,39.8,0,0,0,39.29,33.07H361.1a6,6,0,0,0,0-12H157.81a27.81,27.81,0,0,1-27.46-23l-2.83-17.65H294.41a44.42,44.42,0,0,0,40.92-27l38.79-91a6,6,0,0,0-5.52-8.36Zm-44.31,94.66a32.43,32.43,0,0,1-29.88,19.75H125.59L109.15,106.27H359.52Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M200.84,342.77a36.1,36.1,0,1,0-36.1,36.1A36.14,36.14,0,0,0,200.84,342.77Zm-60.2,0a24.1,24.1,0,1,1,24.1,24.1A24.13,24.13,0,0,1,140.64,342.77Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M326.87,342.77a36.1,36.1,0,1,0-36.1,36.1A36.14,36.14,0,0,0,326.87,342.77Zm-60.2,0a24.1,24.1,0,1,1,24.1,24.1A24.13,24.13,0,0,1,266.67,342.77Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }