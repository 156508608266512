<template>
	<div class="h-100">
		<full-page-loading-screen v-if='isLoggingIn'></full-page-loading-screen>
		<div class="main-wrap register" v-else>
			<!-- Navigation -->
			<nav class="navbar navbar-expand navbar-v2" id="mainNav">
				<div class="container-fluid container-fluid-90">
					<router-link :to="{ name: 'home' }" class="navbar-brand js-scroll-trigger" active-class="active">
						<img src="images/logo.png">
					</router-link>
					<ul class="navbar-nav">
						<li class="nav-item">
							<router-link :to="{ name: 'login' }" class="btn btn-outline-primary btn-box-shadow btn-sm">Login</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{ name: 'sign-up' }" class="btn btn-outline-primary btn-box-shadow btn-sm">Sign Up</router-link>
						</li>
					</ul>

				</div>
			</nav>

			<div class="register_container container full-cont-sm">
				<div class="row align-items-center">

					<div class="col-md-12 col-lg-5 order-lg-last">
						<div class="pricing-tables-cont">
							<div class="row">

								<div class="col-6 col-lg-12">
									<div class="pricing-grid">
										<!-- <img src="/images/bird.png" class="img text-center animal"> -->
										<div class="custom-control custom-radio dark-bg">
											<input class="custom-control-input" type="radio" name="typeBudget" id="basicBudgeter" value="free" v-model="register.plan">
											<label class="custom-control-label price-value two" for="basicBudgeter">
												<h3>Basic Budgeter</h3>
												<h4>( FREE )</h4>
											</label>
										</div>
										<p>Manual entry of transactions and categories. Great for those on limited budget or those who don't mind a more hands on approach.</p>
									</div>
								</div>

								<div class="col-6 col-lg-12">
									<div class="pricing-grid">
										<!-- <img src="/images/whale.png" class="img text-center animal"> -->
										<div class="custom-control custom-radio dark-bg">
											<input class="custom-control-input" type="radio" value="budget_master" v-model="register.plan" id="budgetBoss">
											<label class="custom-control-label price-value two" for="budgetBoss">
												<h3>Budget Boss</h3>
												<h4>( $5.99 monthly )</h4>
											</label>
										</div>
										<p>Refine your budget process with automatic transaction imports and some categorization.</p>
									</div>
								</div>

							</div><!-- .pricing-tables-cont -->
						</div>
					</div>

					<div class="col-md-12 col-lg-7 order-lg-first">
						<div class="card register-payment-form-card">
							<div class="card-body">
								<form class="validate" role="form" method="POST" action="/register" id="registerPaymentForm" novalidate>

									<div class="form-label-group" id="email_container">
										<input id="email" type="email" class="form-control" v-model="register.email" value="" placeholder="E-Mail Address" autofocus>
										<label for="email">E-Mail Address *</label>
									</div>

									<div class="form-label-group" id="password_container">
										<input id="password" type="password" class="form-control " v-model="register.password" placeholder="Password" autofocus>
										<label for="password">Password *</label>
									</div>

									<div class="form-label-group" id="password_confirm_container">
										<input id="password_confirmation" type="password" class="form-control" v-model="register.password_confirmation" placeholder="Confirm password" autofocus>
										<label for="password_confirmation">Confirm Password *</label>
									</div>

									<div class="form-group mt-4 mb-0">
										<div class="form-row">
											<div class="col-6">
												<div class="custom-control custom-radio">
													<input type="radio" value="free" id="customRadio1" class="custom-control-input" v-model="register.plan">
													<label class="custom-control-label" for="customRadio1">&nbsp;&nbsp;Basic Budgeter (FREE)</label>
												</div>
											</div>
											<div class="col-6">
												<div class="custom-control custom-radio">
													<input type="radio" value="budget_master" v-model="register.plan" id="customRadio2"  class="custom-control-input">
													<label class="custom-control-label" for="customRadio2">&nbsp;&nbsp;Budget Boss (<strong>$5.99 a month</strong>)</label>
												</div>
											</div>
										</div>
									</div>

									<div v-if="register.plan==='budget_master'" id="credit_card_billing">

										<div class="form-group" id="group-name">
											<div class="form-row">
												<div class="col-12 col-md-6 form-label-group" id="first_name_container">
													<input id="first_name" type="text" class="form-control" v-model="register.first_name" placeholder="First name" value="">
													<label for="first_name">First name *</label>
												</div>
												<div class="col-12 col-md-6 form-label-group" id="last_name_container">
													<input id="last_name" type="text" class="form-control" v-model="register.last_name" placeholder="Last name" value="">
													<label for="last_name">Last name *</label>
												</div>
											</div>
										</div>

										<div class="form-label-group" id="card_number_container">
											<input type="text" class="form-control" v-model="register.card_number" id="card_number" placeholder="Credit card number" :disabled="register.promo"/>
											<label for="card_number">Card Number *</label>
										</div>
										<div class="form-label-group">
											<div class="form-row">
												<div class="col-6 form-label-group" id="card_csc_container">
													<input type="text" class="form-control" v-model="register.card_csc" id="card_csc" placeholder="CSC" :disabled="register.promo">
													<label for="card_csc">CSC *</label>
												</div>
												<div class="col-6 form-label-group" id="card_expiration_container">
													<input type="text" class="form-control" v-model="register.card_expiration" id="card_expiration" placeholder="MM / YY" :disabled="register.promo">
													<label for="card_expiration">Expiration *</label>
												</div>
											</div>
										</div>
										<div class="form-label-group" id="postal_container">
											<input type="text" class="form-control" v-model="register.postal" placeholder="Postal code" id="postal" value="" :disabled="register.promo">
											<label for="postal">Postal Code *</label>
										</div>
										<div class="form-group mt-4 mb-0">
											<div class="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox" v-model="register.promo" id="promoCheckbox"  class="custom-control-input">
												<label class="custom-control-label" for="promoCheckbox">&nbsp;&nbsp;Promo</label>
											</div>
										</div>
										<div class="form-label-group" id="postal_container" v-if="register.promo">
											<input type="text" class="form-control" v-model="register.code" placeholder="Promo code" id="promoCode" value="">
											<label for="promoCode">Code *</label>
										</div>
									</div>
									<input type="hidden" value="standard" name="encryption" checked>
									<div class="form-group mt-5 text-center text-md-left">
										<p class="text-muted">By clicking Create Account, you agree to our <a href="/terms" target="_blank">Terms</a>.</p>
									</div>
									<div class="payment-errors"></div>
									<input type="hidden" v-model="register.timezone" value="" id="timezone">
									<div class="form-group text-center text-md-left">
										<errors :errors="errors"></errors>
										<a href="#" class="btn btn-primary" id="register" @click="save()">
											Create Account
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>

				</div>
			</div>

			<!-- .main-footer -->
			<footer class="main-footer container-fluid main-footer-v2">
				<a href="" class="backtotop" id="backtotop">
					<img src="images/arrow-up-icon.png">
				</a>
				<span class="copyright-msg">&#x24B8; 2018 Budget It Up, LLC All Rights Reserved</span>
			</footer>

		</div> <!-- .main-wrap -->
	</div>
</template>
<script>
export default {

	data() {
		return {
			register: {
				plan: 'free',
				promo: false
			}
		}
	},
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	destroyed: function(){
		document.body.className = '';
	},
	computed: {
		isLoggingIn() {
			return this.$store.state.isLoggingIn;
		},
		errors() {
			return this.$store.state.register_errors;
		},
		plan() {
			return this.register.plan;
		}
	},
	watch: {
		plan: function (plan) {
			if(plan==='budget_master'){
				$( document ).ready(function() {
					$('#card_number').payment('formatCardNumber');
					$('#card_expiration').payment('formatCardExpiry');
					$('#card_csc').payment('formatCardCVC');
				});
			}
		},
	},
	mounted: function() {
		document.body.className = 'pt-0';

        // insert the stripe api
        var stripe = document.createElement('script')
        stripe.setAttribute('src', 'https://js.stripe.com/v2/')
        document.head.appendChild(stripe)

		// Set the user timezone
		var visitortimezone = -(new Date().getTimezoneOffset() / 60);
		this.register.timezone = visitortimezone;
	},
	methods: {
		stripeResponseHandler(status, response) {
			var $form = $('#registerPaymentForm');
			if (response.error) {
                this.$store.commit('setIsLoggingIn', false);
                this.$store.commit('setFullPageLoadingScreenData', {message: ''});
				event.preventDefault();
				event.stopPropagation();
				$form.find('.payment-errors').text(response.error.message);
				$form.find('.payment-errors').addClass('alert alert-danger');
				$form.find('#register').prop('disabled', false);
				$('#register').button('reset');
			} else {
				event.preventDefault();
				event.stopPropagation();
				var token = response.id;
                this.register.stripe_token = token;
                this.$store.dispatch('register', {registerCredentials: this.register})
			}
		},
        save() {

            event.preventDefault();
            this.$store.commit('clearRegisterErrors');
            this.validateBasic();

            var reg = new Register();
            if (this.register.plan==='budget_master' && !reg.hasPromo()){
                if (reg.paymentIsValid()) {
                    event.preventDefault();
                    var $form = $(this);
                    $form.find('#register').prop('disabled', true);

                    this.$store.commit('setIsLoggingIn', true);
                    this.$store.commit('setFullPageLoadingScreenData', {message: 'Processing payment.'});

                    Stripe.setPublishableKey("pk_live_VZwyQo5D3EXwOWtK6c5xi5VO");
                    Stripe.card.createToken({
                        number: $('#card_number').val(),
                        cvc: $('#card_csc').val(),
                        exp_month: $.payment.cardExpiryVal($('#card_expiration').val()).month,
                        exp_year: $.payment.cardExpiryVal($('#card_expiration').val()).year,
                        address_zip: $('#postal').val()
                    }, this.stripeResponseHandler);
                }
            } else {
                this.$store.dispatch('register', {registerCredentials: this.register})
            }
        },
		validateBasic() {

			var validator = new FormValidator();
			var emailInput = document.getElementById('email');
			var emailContainer = document.getElementById('email_container');
			var emailValue = emailInput.value;
			var validEmail = validator.validate(emailInput, emailContainer, validator.emailRule, emailValue, 'Invalid email address.','email');

			var passwordInput = document.getElementById('password');
			var passwordContainer = document.getElementById('password_container');
			var passwordValue = passwordInput.value;
			var validPassword = validator.validate(passwordInput, passwordContainer, validator.passwordRule, passwordValue, 'Password must be at least 8 characters with one uppercase, one lowercase, and a number.', 'password');

			var passwordConfirmInput = document.getElementById('password_confirmation');
			var passwordConfirmContainer = document.getElementById('password_confirm_container');
			var passwordConfirmValue = passwordConfirmInput.value;
			var passwords = new Array(passwordValue,  passwordConfirmValue);
			var validPasswordConfirm = validator.validate(passwordConfirmInput, passwordConfirmContainer, validator.passwordConfirmRule, passwords, 'Password confirm does not match password.','password confirm');

			if(validEmail && validPassword && validPasswordConfirm) {
				return true;
			} else {
				return false;
			}

		},
		validatePayment() {

			var validator = new FormValidator();
			var firstNameInput = document.getElementById('first_name');
			var firstNameContainer = document.getElementById('first_name_container');
			var firstNameValue = firstNameInput.value;

			var validFirstName = validator.validate(firstNameInput, firstNameContainer, validator.validateName, firstNameValue, 'First name invalid.','first name');

			var lastNameInput = document.getElementById('last_name');
			var lastNameContainer = document.getElementById('last_name_container');
			var lastNameValue = lastNameInput.value;

			var validLastName = validator.validate(lastNameInput, lastNameContainer, validator.validateName, lastNameValue, 'Last name invalid.','last name');

			var cardNumberInput = document.getElementById('card_number');
			var cardNumberContainer = document.getElementById('card_number_container');
			var cardNumberValue = cardNumberInput.value;

			var validCardNumber = validator.validate(cardNumberInput, cardNumberContainer, validator.validateCardNumber, cardNumberValue, 'Credit card number invalid.','credit card number');

			var cardSecurityCodeInput = document.getElementById('card_csc');
			var cardSecurityCodeContainer = document.getElementById('card_csc_container');
			var cardSecurityCodeValue = cardSecurityCodeInput.value;

			var validCardSecurityCode = validator.validate(cardSecurityCodeInput, cardSecurityCodeContainer, validator.validateCardSecurityCode, cardSecurityCodeValue, 'Credit card number invalid.','card security code');

			var cardExpirationInput = document.getElementById('card_expiration');
			var cardExpirationContainer = document.getElementById('card_expiration_container');
			var cardExpirationValue = cardExpirationInput.value;

			var validCardExpiry = validator.validate(cardExpirationInput, cardExpirationContainer, validator.validateCardExpiry, cardExpirationValue, 'Credit card expiration invalid.','card expiration');

			var postalInput = document.getElementById('postal');
			var postalContainer = document.getElementById('postal_container');
			var postalValue = postalInput.value;

		    var validPostal = validator.validate(postalInput, postalContainer, validator.validatePostal, postalValue, 'Zipcode is invalid.','zipcode');

			if(validFirstName && validLastName && validCardNumber && validCardSecurityCode && validCardExpiry && validPostal) {
				return true;
			} else {
				return false;
			}

		}
	}

}
</script>
