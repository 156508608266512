var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal is-active" }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("h1", [_vm._v("Your Monthly Income")]),
            _vm._v(" "),
            _c("h5", [
              _vm._v("Enter in your fixed monthly income such as paychecks.")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  { staticClass: "text-left" },
                  [
                    _c(_vm.newincome.mode, {
                      tag: "component",
                      on: { toggle: _vm.toggle }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c("p", { staticClass: "strong" }, [_vm._v("Category")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4" }, [
                _c("p", { staticClass: "strong" }, [_vm._v("Amount")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4" }, [
                _c("p", { staticClass: "strong" }, [
                  _vm._v("Repeat day of month")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4" })
            ]),
            _vm._v(" "),
            _vm._l(_vm.paychecks, function(paycheck, index) {
              return _c(paycheck.mode, {
                key: paycheck.id,
                tag: "income",
                attrs: { paycheck: paycheck },
                on: {
                  update: _vm.update,
                  edit: _vm.edit,
                  remove: function($event) {
                    return _vm.remove(paycheck, index)
                  },
                  subtractFromValue: function($event) {
                    return _vm.subtractFromValue(index)
                  },
                  addToValue: function($event) {
                    return _vm.addToValue(index)
                  }
                }
              })
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-default", on: { click: _vm.skip } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "modal-close", on: { click: _vm.skip } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }