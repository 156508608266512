var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap budgets", class: { mainWrap: _vm.mainWrap } },
    [
      _c("router-view", { attrs: { name: "navbar" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { position: "relative" },
          attrs: { "aria-live": "polite", "aria-atomic": "true" }
        },
        [
          _c(
            "div",
            { staticStyle: { position: "absolute", top: "0", right: "0" } },
            _vm._l(_vm.toasts, function(toast, index) {
              return _c("toast", {
                key: index,
                attrs: { toast: toast, index: index },
                on: {
                  hide: function($event) {
                    return _vm.hideToask(toast, index)
                  }
                }
              })
            }),
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }