var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPlaidLinkTokenModal
    ? _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "modal is-active" }, [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content-login" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(_vm.plaidLinkTokenLoginMode, {
                  tag: "link-token-login-form"
                })
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }