var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M314.46,192.11H200s-7.52,29.62-24.93,29.62c-28,0-29.49-29.62-29.49-29.62s-8.23,29.62-27.57,29.62-32.47-29.62-32.47-29.62H200s9.89,29.62,28.75,29.62,29-29.62,29-29.62,8.7,29.62,28.15,29.5c16.44-.11,28.54-20.67,28.54-20.67"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M200,118.65,85.54,192.11s5.72,70.6,0,95.87-14.31,45.3-36.24,67.24H350.7c-21.93-21.94-30.52-42-36.24-67.24s0-95.87,0-95.87L200,118.65V44.78l44.34,24.74L200.07,95.6"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "330.08", y1: "329.27", x2: "233.8", y2: "329.27" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "163.7", y1: "329.27", x2: "69.93", y2: "329.27" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M166.23,349.76V290A32.89,32.89,0,0,1,232,290V348.8" }
      }),
      _c("circle", { attrs: { cx: "186.22", cy: "314.14", r: "8.85" } }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "74.85", y1: "192.11", x2: "325.29", y2: "192.11" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }