var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 14")]),
      _c("path", {
        attrs: {
          d:
            "M279.79,19.62H120.21a15.1,15.1,0,0,0-15.08,15.09V365.29a15.09,15.09,0,0,0,15.08,15.08H279.79a15.1,15.1,0,0,0,15.09-15.08V34.71A15.11,15.11,0,0,0,279.79,19.62Zm3.09,345.67a3.09,3.09,0,0,1-3.09,3.08H120.21a3.08,3.08,0,0,1-3.08-3.08V327.12H282.88Zm0-50.17H117.13V70.62H282.88Zm0-256.5H117.13V34.71a3.09,3.09,0,0,1,3.08-3.09H279.79a3.1,3.1,0,0,1,3.09,3.09Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M200,364.87a17,17,0,1,0-17-17A17,17,0,0,0,200,364.87Zm0-22a5,5,0,1,1-5,5A5,5,0,0,1,200,342.87Z"
        }
      }),
      _c("path", {
        attrs: { d: "M223,38.83H177a6,6,0,0,0,0,12h46a6,6,0,0,0,0-12Z" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }