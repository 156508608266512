var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("14")]),
      _c("path", {
        attrs: {
          d:
            "M180.28,174.24c3.67,2.59,9.56,4.38,15.23,4.66v9.32a1,1,0,0,0,1,1h6.43a1,1,0,0,0,1-1v-9.8c9.73-1.93,16.2-9.18,16.2-18.28s-4.93-14.87-16.49-19.6c-9.25-3.87-12.07-6.39-12.07-10.76,0-3.63,2.58-7.88,9.85-7.88a23.83,23.83,0,0,1,12.45,3.43,1,1,0,0,0,1.43-.53l2.48-6.77a1,1,0,0,0-.43-1.2,27.52,27.52,0,0,0-12.75-3.67v-9a1,1,0,0,0-1-1h-6.42a1,1,0,0,0-1,1v9.46c-9.43,1.87-15.47,8.6-15.47,17.34,0,10.38,8.45,15.14,17.58,18.73,7.77,3.15,10.94,6.41,10.94,11.25,0,5.32-4.61,9-11.21,9a26.51,26.51,0,0,1-14.14-4.28,1,1,0,0,0-1.49.49l-2.49,6.91A1,1,0,0,0,180.28,174.24Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M339.08,202.56H303.71l-24.62-43.12a5.38,5.38,0,0,0-4.66-2.7H263.1a64,64,0,1,0-126.21,0h-8.21a5.37,5.37,0,0,0-4.53,2.48L96.46,202.56H60.92a11.74,11.74,0,0,0-11.73,11.72v60.1a11.74,11.74,0,0,0,11.73,11.73H80.07c2.55,17.94,17.69,31.77,35.93,31.77s33.37-13.83,35.92-31.77h95.94c2.55,17.94,17.68,31.77,35.92,31.77s33.37-13.83,35.92-31.77h19.38a11.74,11.74,0,0,0,11.73-11.73v-60.1A11.74,11.74,0,0,0,339.08,202.56ZM200,92.86a53.26,53.26,0,1,1-53.26,53.26A53.32,53.32,0,0,1,200,92.86ZM116,307.14c-14.1,0-25.57-11.84-25.57-26.39h0c0-14.55,11.47-26.39,25.57-26.39s25.56,11.85,25.56,26.4S130.09,307.14,116,307.14Zm167.78,0c-14.1,0-25.57-11.84-25.57-26.4s11.47-26.4,25.57-26.4,25.57,11.85,25.57,26.4S297.88,307.14,283.78,307.14Zm56.29-32.76a1,1,0,0,1-1,1H319.7c-2.55-17.94-17.69-31.76-35.92-31.76s-33.37,13.82-35.92,31.76H151.92c-2.56-17.94-17.69-31.76-35.92-31.76s-33.37,13.82-35.93,31.76H60.92a1,1,0,0,1-1-1v-60.1a1,1,0,0,1,1-1H99.41a5.38,5.38,0,0,0,4.52-2.48l27.69-43.34h8a64,64,0,0,0,120.65,0h11l24.62,43.11a5.39,5.39,0,0,0,4.66,2.71h38.49a1,1,0,0,1,1,1Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M116,263.28a17.47,17.47,0,1,0,17.14,17.46A17.32,17.32,0,0,0,116,263.28Zm0,24.19a6.73,6.73,0,1,1,6.4-6.73A6.58,6.58,0,0,1,116,287.47Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M283.78,263.28a17.47,17.47,0,1,0,17.15,17.46A17.32,17.32,0,0,0,283.78,263.28Zm0,24.19a6.73,6.73,0,1,1,6.41-6.73A6.59,6.59,0,0,1,283.78,287.47Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }