var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.plaid_error
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "my-4" }, [
              _c("h3", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.headline))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "card-text" }, [
                _vm._v(_vm._s(_vm.message))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: "#" },
                  on: { click: _vm.handleButton }
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c("img", {
        attrs: { src: "/images/lock-icon.png", alt: "Card image cap" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }