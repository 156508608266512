var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 3")]),
      _c("path", {
        attrs: {
          d:
            "M176,80.92A98.07,98.07,0,0,0,102.2,176v.7c0,3-.22,48.05,41,91.1v65.32a11,11,0,0,0,11,11h8.34v3.27a35.7,35.7,0,1,0,71.39,0v-3.27h8.35a11,11,0,0,0,11-11V267.8c41.18-43.05,41-88.15,41-91.1V176A98,98,0,0,0,220.4,80.92a93.49,93.49,0,0,0-44.45,0Zm45.92,266.44a23.7,23.7,0,1,1-47.39,0v-3.27h47.39Zm19.32-15.27h-86V308.86h86Zm0-35.23h-86V272.28h86Zm41-120.86v.77a.66.66,0,0,0,0,.14c0,.43.42,43.31-38.39,83.37H152.58c-38.81-40.06-38.39-82.94-38.38-83.36V176a86.07,86.07,0,0,1,64.73-83.46,81.71,81.71,0,0,1,38.5,0A86.06,86.06,0,0,1,282.15,176Z"
        }
      }),
      _c("path", {
        attrs: { d: "M192.58,22.94v32a6,6,0,1,0,12,0v-32a6,6,0,1,0-12,0Z" }
      }),
      _c("path", {
        attrs: {
          d: "M93.17,94.49A6,6,0,0,0,101.65,86L79,63.38a6,6,0,0,0-8.49,8.48Z"
        }
      }),
      _c("path", {
        attrs: { d: "M61,182.64a6,6,0,0,0,0-12H29a6,6,0,0,0,0,12Z" }
      }),
      _c("path", {
        attrs: {
          d:
            "M69.4,290.38a6,6,0,0,0,8.49,0l22.62-22.62A6,6,0,0,0,92,259.27L69.4,281.89A6,6,0,0,0,69.4,290.38Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M306.83,94.49l22.63-22.63A6,6,0,0,0,321,63.38L298.35,86a6,6,0,0,0,8.48,8.49Z"
        }
      }),
      _c("path", {
        attrs: { d: "M371,170.64h-32a6,6,0,0,0,0,12h32a6,6,0,1,0,0-12Z" }
      }),
      _c("path", {
        attrs: {
          d:
            "M322.11,290.38a6,6,0,0,0,8.49-8.49L308,259.27a6,6,0,0,0-8.49,8.49Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }