var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-group col-12 col-sm-2 col-12" },
        [
          _c("label", [_vm._v("Amount")]),
          _vm._v(" "),
          _c("currency-input", {
            attrs: { amount: _vm.amount },
            on: { passValue: _vm.passValue }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group col-10 col-sm-4" },
        [
          _c("label", [_vm._v("Category")]),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }),
          _vm._v(" "),
          _vm.transaction.type == "expense"
            ? _c("semantic-select", {
                attrs: {
                  items: _vm.expense_categories,
                  preSelect:
                    _vm.firstSubcategory && _vm.index === 0
                      ? _vm.firstSubcategory.transaction_category_id
                      : null
                },
                on: {
                  update: function(id) {
                    _vm.setCategory(id, 0)
                  }
                }
              })
            : _vm.transaction.type == "income"
            ? _c("semantic-select", {
                attrs: {
                  items: _vm.income_categories,
                  preSelect: _vm.firstSubcategory
                    ? _vm.firstSubcategory.transaction_category_id
                    : null
                },
                on: {
                  update: function(id) {
                    _vm.setCategory(id, 0)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-4 form-group" },
        [
          _c("label", [_vm._v("Sub-category")]),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }),
          _vm._v(" "),
          _vm.transaction.type == "expense"
            ? _c("semantic-select", {
                attrs: {
                  items: _vm.subcategories,
                  preSelect: _vm.firstSubcategory
                    ? _vm.firstSubcategory.id
                    : null
                },
                on: {
                  update: function(id) {
                    _vm.update(id, _vm.index)
                  }
                }
              })
            : _vm.transaction.type == "income"
            ? _c("semantic-select", {
                attrs: {
                  items: _vm.subcategories,
                  preSelect: _vm.firstSubcategory
                    ? _vm.firstSubcategory.id
                    : null
                },
                on: {
                  update: function(id) {
                    _vm.update(id, _vm.index)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-2" }, [
        _c(
          "a",
          {
            staticClass: "text-center d-inline-block pt-3 remove-btn",
            attrs: { href: "" },
            on: {
              click: function($event) {
                return _vm.remove(_vm.index, $event)
              }
            }
          },
          [
            _c("span", { staticClass: "d-block" }, [_vm._v("Remove")]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-trash-alt" })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }