<template>
	<transition
		name="fade"
		>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <div class="px-3 py-3">
                        <h4 class="mb-4 mt-2">New Account</h4>
                        <div class="form-row">
                            <div class="form-group col-sm-6">    
                                <label for="name">Account Name</label>
                                <input type="text" name="name" class="form-control no-border" id="name" v-model='account.name'>
                            </div>
                            <div class="form-group col-sm-6">    
                                <label>Account Type</label>
								<semantic-select :items="account_types" :defaultItem='defaultItem' @update='setAccountType'></semantic-select>
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="form-group col-sm-6">    
                                <label>Account Balance</label>
								 <currency-input :amount='account.most_recent_balance.current_balance'
								 @subtractFromAmountValue='subtractFromAmountValue'
								 @addToAmountValue='addToAmountValue'
								 @passValue='passValue'
								 ></currency-input> 
                            </div>
                            <div class="col-sm-6">    
                                <label>Balance Date</label>
                                <date @setDate="setDate" :date="today"></date>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <errors :errors='errors'></errors>
                        <div class="form-row mt-4">
                            <div class="form-group col">
                                <div class="text-center text-sm-right">
                                    <button class="btn btn-outline-primary btn-wide" @click="cancel">Cancel</button>
                                    <button type="button" class="btn btn-primary btn-wide ml-4" @click='saveAccount'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</transition>
</template>
<script>
export default {
	mounted() {
		this.$store.commit('clearNewAccountErrors');
	},
	data() {
		return {
			account:
			{
                account_type_id: null,
				most_recent_balance: 
				{
					current_balance: 0,
				},
                type: []

			},
			defaultItem: 'account type'
		}
	},
	computed: {
		errors() {
			return this.$store.state.new_account_errors;
		},
		account_types() {
			return this.$store.state.account_types;
		},
		today() {
			return new Date();
		},
	},
	methods: {
		setAccountType(id) {
			this.account.account_type_id = id;
		},
		cancel() {
			//this.$store.commit('setToggleAddNewAccountModal', false);
			this.$store.commit('setCurrentInput', {input: null});
		},
		saveAccount() {
        
			//this.account.account_type_id = this.account_type.id;
			this.$store.commit('clearNewAccountErrors');
			//this.account.most_recent_balance.current_balance = Math.abs(this.account.most_recent_balance.current_balance);

		    let account_type = this.account_types.find(type => type.id === Number(this.account.account_type_id));
            this.account.type = account_type;
			this.$store.dispatch('add_new_account', {account: this.account});
			//this.$store.commit('setToggleAddNewAccountModal', false);
		},
		setAmount(amount) {
			this.account.balance = {debit_amount: amount};
		},
		setDate(date) {
            this.account.most_recent_balance.date = date;
		},
        subtractFromAmountValue(index) {
            if(accounting.unformat(this.account.most_recent_balance.current_balance)-10>0) {
                return this.account.most_recent_balance.current_balance = accounting.unformat(this.account.most_recent_balance.current_balance) - 10;
            }
        },	
        addToAmountValue(index) {
            return this.account.most_recent_balance.current_balance = accounting.unformat(this.account.most_recent_balance.current_balance) + 10;
        },
        passValue(value) {
            return this.account.most_recent_balance.current_balance = (value);
        },
	}
}
</script>
