var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy")]),
      _c("path", {
        attrs: {
          d:
            "M42.55,378.65H186.21a14,14,0,0,0,14-14V212.36a68.37,68.37,0,0,0-26.13-54l-10.76-8.5a41.79,41.79,0,0,1-16-33V64.12H163.2a7.63,7.63,0,0,0,7.63-7.62V29a7.63,7.63,0,0,0-7.63-7.62H66.25A7.63,7.63,0,0,0,58.63,29V56.5a7.63,7.63,0,0,0,7.62,7.62H81.43v52.82a41.79,41.79,0,0,1-16,33l-10.75,8.5a68.38,68.38,0,0,0-26.14,54V364.68A14,14,0,0,0,42.55,378.65ZM70.63,52.12V33.35h88.2V52.12Zm-30,160.24a56.48,56.48,0,0,1,21.58-44.55l10.75-8.5a53.7,53.7,0,0,0,20.52-42.37V64.12h41.9v52.82a53.7,53.7,0,0,0,20.52,42.37l10.76,8.5a56.47,56.47,0,0,1,21.57,44.55V364.68a2,2,0,0,1-2,2H42.55a2,2,0,0,1-2-2Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M291.3,296.59v70.06H257.7a6,6,0,1,0,0,12h81.89a6,6,0,1,0,0-12H303.3V296.59a73,73,0,0,0,68-77.5l-6.95-107.86a6,6,0,0,0-6-5.62H236.27a6,6,0,0,0-6,5.62l-6.95,107.86a73,73,0,0,0,68,77.5Zm-49.4-179H352.7l2.79,43.31c-22.06,17.42-29.09,11.5-37.18,4.67-4.41-3.73-9.41-8-16.46-8-9.93,0-14.61,3.17-19.14,6.23a47.45,47.45,0,0,1-17.81,7.86c-16,3.86-21.15-8.64-21.7-10.12a6,6,0,0,0-3.9-3.71Zm-6.59,102.25,2.85-44.24A28,28,0,0,0,259,184.49a37.23,37.23,0,0,0,8.77-1.1,59.43,59.43,0,0,0,21.72-9.58c4-2.69,6.16-4.17,12.41-4.17,2.53,0,5,1.94,8.72,5.11,10.9,9.22,22.74,16,45.84.36l2.88,44.75a61,61,0,0,1-60.86,64.91h-2.26a61,61,0,0,1-60.86-64.91Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }