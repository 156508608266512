<template>
	<div class="collapse" id="collapseExample">
		<div class="filters-cont">
			<div class="container">
				<div class="row">
					<div class="col">
						<h5 class="title1">Filters</h5>
					</div>
					<div class="col">
						<button type="button" class="close" aria-label="Close" @click='close'>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-5 col-lg-12 col-md-5 form-group">
						<label class="label1">Type</label>
						<div class="btn-radio-group">
							<input type="radio" name="options"  autocomplete="off" v-model="type_filter" value="" id="filterAll"> 
							<label class="btn btn-outline-primary btn-box-shadow" v-bind:class="{ 'active': type_filter==null }" v-on:click="setType(null)" for="filterAll">All</label>
							<input type="radio" name="options"  autocomplete="off" v-model="type_filter" value="income" id="filterIncome"> 
							<label class="btn btn-outline-primary btn-box-shadow" v-bind:class="{ 'active': type_filter=='income' }" v-on:click="setType('income')" for="filterIncome">Income</label>
							<input type="radio" name="options" value="expense" v-model="type_filter" id="filterExpense">
							<label class="btn btn-outline-primary btn-box-shadow" v-bind:class="{ 'active': type_filter=='expense' }" v-on:click="setType('expense')" for="filterExpense">Expense</label>
						</div>
					</div>
					<div class="col-xl-7 col-lg-12 col-md-7">
						<label class="mb-3 pl-1 label1">Category status:</label>
						<div class="clearfix"></div>    
						<div class="custom-control custom-radio custom-radio-inline">
							<input class="custom-control-input" type="radio" v-model="category_filter" id="categoryFilter1" value="all">
							<label class="custom-control-label" for="categoryFilter1">All</label>
						</div>
						<div class="custom-control custom-radio custom-radio-inline">
							<input class="custom-control-input" type="radio" v-model="category_filter" id="categoryFilter2" value="categorized">
							<label class="custom-control-label" for="categoryFilter2">Categorized</label>
						</div>
						<div class="custom-control custom-radio custom-radio-inline">
							<input class="custom-control-input" type="radio" v-model="category_filter" id="categoryFilter3" value="uncategorized">
							<label class="custom-control-label" for="categoryFilter3">Uncategorized</label>
						</div>
					</div>
					<!--
		 -<div class="col form-group">
		 -    <label>Category</label>
		 -    <div class="clearfix"></div>    
		 -    <dropdown @update="function (id) { update(id,0) }" :expense_categories="expense_categories" v-if='(transaction.type=="expense")' :current='null'></dropdown>
		 -    <dropdown @update="function (id) { update(id,0) }" :expense_categories="income_categories" v-else-if='(transaction.type=="income")' :current='null'></dropdown>
		 -    <dropdown @update="function (id) { update(id,0) }" :disabled='true' v-else></dropdown>
		 -</div>
					-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
	},
	data() {
		return {
			category_filter: 'all',
			search: '',
			type_filter: '',
		}
	},
	mounted: function() {

		if(this.$store.state.entities.length==0) {
			this.$store.dispatch('get_entities');
		}

		if(this.$store.state.income_categories.length==0) {
			this.$store.dispatch('get_income_categories');
		}

		if(this.$store.state.expense_categories.length==0) {
			this.$store.dispatch('get_expense_categories');
		}

	},
	methods: {
		setType(type) {
			this.type_filter = type;
		},
		loadCurrentMonth() {
			var current = new Date();
			var current_year = this.$store.state.current_date.setYear(current.getFullYear());
			var current_month = this.$store.state.current_date.setMonth(current.getMonth());
			this.$store.commit('setCurrentDate', new Date(current_month)); 
			this.$store.dispatch('get_budgets');
			this.$store.dispatch('get_month_transactions', {account: this.account});
		},
		loadPreviousMonth() {
			var previous_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()-1);
			this.$store.commit('setCurrentDate', new Date(previous_month)); 
			this.$store.dispatch('get_budgets');
			this.$store.dispatch('get_month_transactions', {account: this.account});
		},
		loadNextMonth() {
			var next_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()+1);
			this.$store.commit('setCurrentDate', new Date(next_month)); 
			this.$store.dispatch('get_budgets');
			this.$store.dispatch('get_month_transactions', {account: this.account});
		},
		monthStringFromDate(date) {
			var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			if(typeof(date)==='object') {
				return  monthNames[date.getMonth()];
			}
		},
		toggle () {
			this.$store.commit('toggleTransactionMode'); 
		},
		set_current_input(input) {
			this.$store.commit('setCurrentInput', input); 
		},
		close () {
			$('.collapse').collapse('hide');
		}
	},
	computed: {

		income_categories () {
			return this.$store.state.income_categories;
		},
		expense_categories () {
			return this.$store.state.expense_categories;
		},
		account() {
			return this.$store.state.account; 
		},
		accounts() {
			return this.$store.state.accounts; 
		},
		month() {
			if(this.$store.state.current_date){
				return this.monthStringFromDate(this.$store.state.current_date);
			}
		},
		year() {
			if(this.$store.state.current_date){
				return this.$store.state.current_date.getFullYear();
			}
		},
		new_transaction() {
			return this.$store.state.new_transaction;
		},
		this_month_transactions() {
			return this.$store.getters.this_month_transactions;
		}
	},
	watch: {
		search: function(newValue, oldValue) {
			this.$store.commit('searchTransactions', newValue)
		},
		category_filter: function(newValue, oldValue) {
			this.$store.commit('setTransactionCategoryStatusFilter', newValue)
			this.$store.commit('filterTransactions')
		},
		type_filter: function(newValue, oldValue) {
			this.$store.commit('setTransactionTypeFilter', newValue)
			this.$store.commit('filterTransactions')
		}

	}
}
</script>
