var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "219.9 66.16 394.31 113.47 271.85 238.71 101.15 188.62 219.9 66.16"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "247.73", y1: "73.71", x2: "128.16", y2: "196.54" }
      }),
      _c("polyline", {
        staticClass: "cls-1",
        attrs: {
          points: "387.79 120.14 387.79 161.37 269.97 287.45 269.97 238.71"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M104,185.15s-33.77,24.8-2.59,48C147.62,247.05,270,287.45,270,287.45"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "183.68", y1: "188.61", x2: "259.05", y2: "212.73" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "241.36", y1: "178.62", x2: "273.78", y2: "191.68" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M163.22,124.3s-18.07-10.11-32.38-3.5c-10.11,10-109.18,113.26-109.18,113.26s-33.26,26.53-3.79,49.26c41.68,10.95,173.89,50.52,173.89,50.52l7-68.82"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "191.76", y1: "333.84", x2: "238.84", y2: "284.72" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "21.66", y1: "234.06", x2: "191.76", y2: "289.6" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "86.87", y1: "210.26", x2: "56.53", y2: "244.18" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }