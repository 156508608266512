var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-width-horizontal-bar" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-9" }, [
          _c("h5", { staticClass: "strong" }, [
            _vm._v(
              "\n\t\t\t\t\t\t$" +
                _vm._s(this.ending_balance) +
                " available balance - $" +
                _vm._s(_vm.total_budgeted) +
                " budgeted - $0.00 goals\n                    "
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h5", [_vm._v("Ok to spend")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [_c("h4", [_vm._v("$0.00")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }