<template>
	<transition
		name="fade"
		>
		<div class="modal inside-close-btn is-active">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<div class="px-3 py-3">
						<h4 class="mb-2 mt-2">{{ budget_transactions_budget.subcategory_name }}</h4>
                         <h5 class="mb-2 mt-2">Spending Summary for {{ month }}</h5>

                        <div class="row mb-5 mt-5">
                            <div class="col-4">
                                <p class="modalTitle">Budgeted</p>
                                <p class="modalAmount">{{ formatMoney(current_month_amount) }}</p>
                            </div>
                            <div class="col-4">
                                <p class="modalTitle">Spent</p>
                                <p class="modalAmount">{{ formatMoney(transactions_total) }}</p>
                            </div>
                            <div class="col-4">

                                <p class="modalTitle">Status</p>
                                <p class="modalAmount">{{ status.label }}</p>
                            </div>
                        </div>
                        <div class="row table-header mt-2 mb-3">
                            <div class="col-3">
                                Place
                            </div>
                            <div class="col-3">
                                Amount
                            </div>
                            <div class="col-3">
                                Bank / Credit Card
                            </div>
                            <div class="col-3">
                                Date
                            </div>
                        </div>

                        <div v-if="budget_transactions.length > 0" class="month-transations">
                            <div class="row list-item-wrapper mb-2 flex-center" v-for="transaction in budget_transactions">
                                <div class="col-3">
                                    <p class="strong">{{ transaction.entity.name }}</p>{{ transaction.id }}
                                </div>
                                <div class="col-3">
                                    <p class="strong">{{ sumCategorySpending(transaction.subcategories) }}</p>
                                </div>
                                <div class="col-3">
                                    <p class="strong">{{ transaction.account.name }}</p>
                                </div>
                                <div class="col-3">
                                    <p class="strong">{{ transaction.date_short }}</p>
                                </div>
                            </div>
                        </div>

                        <span v-else-if="budget_transactions.length == 0">
                            <no-transactions></no-transactions>
                        </span>
					</div>
				</div>
				<button class="modal-close" @click="skip"></button>
			</div>
		</div>
	</transition>
</template>
<script>
    export default {
		data() {
			return {
				counter: 0,
			}
		},
        computed: {
            month() {
                if(this.$store.state.current_date){
                    return this.monthStringFromDate(this.$store.state.current_date);
                }
            },
            year() {
                if(this.$store.state.current_date){
                    return this.$store.state.current_date.getFullYear();
                }
            },
            budget() {
                return this.$store.state.budget_transactions_budget;
            },
            status: function () {
                if(this.budget.due_in_full) {
                    return this.$store.getters.dueInFullBudgetStatus(this.budget);
                } else {
                    return this.$store.getters.budgetStatus(this.budget);
                }
            },
            current_month_amount() {
                return this.$store.getters.currentMonthAmount(this.budget);
            },
            budget_transactions_count() {
                return this.$store.state.budget_transactions.length;
            },
            budget_transactions() {
                return this.$store.state.budget_transactions;
            },
			budget_transactions_budget() {
				return this.$store.state.budget_transactions_budget;
			},
            transactions_total() {
                return this.$store.getters.budgetTransactionsTotal(this.budget_transactions_budget);
            }
		},
		methods: {
            monthStringFromDate(date) {
                var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                if(typeof(date)==='object') {
                    return  monthNames[date.getMonth()];
                }
            },
            sumCategorySpending(subcategories) {
                var sum=0;
                if(subcategories.length>1) {
                    sum = subcategories.reduce(function(total, subcategory) { return total +Number(subcategory.pivot.amount); });
                } else {
                    sum = subcategories[0].pivot.amount;
                }

                return accounting.formatMoney(sum);

            },
            formatMoney(amount) {
                return accounting.formatMoney(amount);
            },
			skip() {
				this.$store.commit('setToggleShowBudgetSummaryModal', false)
			},
		}
	}
</script>
