<template>
    <transition
        name="fade"
        >
        <div class="modal inside-close-btn is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <div class="px-3 py-3">
                        <h5 v-if="welcomeShown===false" class="mb-2 mt-2">Welcome aboard, to get started you will need to create some budgets.</h5>
                        <div v-if="welcomeShown===true">
                            <div class="d-flex justify-content-between">
                                <div class="text-left">
                                    <icon-base icon-name="write" height="30" width="30" iconColor="#111" class="ml-2 mr-4">
                                        <component v-bind:is='getIconName(suggested[counter].subcategory.slug)' slot="icon"></component>
                                    </icon-base>
                                </div>
                                <div>
                                    <div class="col-12">
                                        <h5>{{suggested[counter].subcategory.name}} <span v-if='suggested[counter].subcategory.once_monthly===1'> Bill</span><span v-else-if="suggested[counter].subcategory.once_monthly===0"> Expense</span></h5>
                                    </div>
                                </div>
                                <div class='ml-auto'>
                                    <div claass="col-3">
                                        <h5>{{currentCount}} / {{suggestedCount}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 form-group">
                                    <label><span v-if='suggested[counter].subcategory.once_monthly===1'> Amount</span><span v-else-if="suggested[counter].subcategory.once_monthly===0"> Amount for the month</span></label>
                                    <currency-input :amount='budget.credit_amount'
                                     @subtractFromAmountValue='subtractFromAmountValue'
                                     @addToAmountValue='addToAmountValue'
                                     @passValue='passValue'
                                     ></currency-input>
                                </div>
                                <div class="col-sm-6" v-if="budget.due_in_full===1">
                                    <label>Next Due Date</label>
                                    <date @setDate="setStartDate" :date='first'></date>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Does this budget repeat?</label>
                                        <div class="custom-control custom-toggle-checkbox">
                                            <input class="custom-control-input" type="checkbox" v-model="budget.is_recurring"
                                                                                                v-bind:true-value="1"
                                                                                                v-bind:false-value="0"
                                                                                                id="defaultCheck">
                                            <div class="toggle-labels">
                                                <label class="unchecked-label" for="defaultCheck">No</label>
                                                <label class="checked-label" for="defaultCheck">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    <div class="row mt-4" v-if='budget.is_recurring==1'>
                        <!--
                           -<div class="col-sm-4">
                           -    <div class="form-group">
                           -        <label>Starting</label>
                           -        <date @setDate="setStartDate" :date='first'></date>
                           -    </div>
                           -</div>
                           -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Every</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_interval" value="1">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control" v-model="budget.repeat_frequency">
                                    <option value="month" selected="selected">{{ this.formatPlural(budget.repeat_interval, 'Month') }}</option>
                                    <option value="year">{{ this.formatPlural(budget.repeat_frequency, 'Year') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <select class="form-control" v-if="budget.repeat_frequency=='month'">
                                    <option value="month">On day {{ budget.start_day }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Ending</label>
                                <select class="form-control" v-model='budget.ending'>
                                    <option value="never">No end date</option>
                                    <option value="end_after">End after</option>
                                    <option value="end_by">End by</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_after'">
                                <label>Number of occurences</label>
                                <input type="text" class="form-control no-border" v-model="budget.repeat_num_occurrences" @change="setEndDateToNull">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" v-if="budget.ending=='end_by'">
                                <label>End Date</label>
                                <date @setDate="setEndDate" :date='today'></date>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <!-- <p><a href="#" @click="skip">Do not show this again.</a></p> -->
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" v-model='doNotShowAgain'>Do not show this again.
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class='ml-auto'>
                            <button v-if="welcomeShown==false" class="btn btn-primary" @click="skipOnce">Close</button>
                            <button v-if="welcomeShown==false" class="btn btn-primary" @click="showNextBudgetSuggestion">Continue</button>
                            <button v-if="welcomeShown==true && counter>0" class="btn btn-sm btn-secondary" @click="showPreviousBudgetSuggestion">Back</button>
                            <button v-if="welcomeShown==true && (counter+1) < this.suggested.length" class="btn btn-sm btn-secondary pull-right" @click="showNextBudgetSuggestion">Skip</button>
                            <button v-if="welcomeShown==true && (counter+1) == this.suggested.length" class="btn btn-sm btn-secondary pull-right" @click="closeModal">Skip and Close</button>
                            <button v-if="welcomeShown==true && (counter+1) < this.suggested.length" class="btn btn-primary" @click="saveBudgetSuggestion" :disabled="validated">Save and Continue</button>
                            <button v-if="(counter+1) == this.suggested.length" class="btn btn-primary" @click="saveBudgetSuggestion" :disabled="validated">Save and Close</button>
                        </div>
                    </div>
                </div>
                </div>
                <!-- <button class="modal-close" @click="skip"></button> -->
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        mounted() {
            this.suggestedCount = this.$store.state.suggested_budgets.length;
        },
		data() {
			return {
                welcomeShown: false,
				counter: 0,
                currentCount: 1,
                suggestedCount: 0,
                budgets: [],
                doNotShowAgain: false,
                budget: {
                    is_recurring: false,
                    repeat_frequency: 'month',
                    repeat_interval: 1,
                    day_of_month: "1",
                    credit_amount: 0,
                    start_date: this.first,
                    end_date: null,
                    repeat_start: this.first,
                    start_day: this.firstDay,
                    repeat_num_occurrences: null,
                    ending: 'never',
                    due_in_full: 0
                },
			    start_day: new Date().getDate(),
			}
		},
        computed: {
            suggested() {
                return this.$store.state.suggested_budgets;
            },
            first() {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                return new Date(y, m, 1);
                //var lastDay = new Date(y, m + 1, 0);
            },
            firstDay() {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                return new Date(y, m, 1).getDate();
                //var lastDay = new Date(y, m + 1, 0);
            },
            today() {
                return new Date();
                //var lastDay = new Date(y, m + 1, 0);
            },
            validated() {
                if (typeof this.budget.credit_amount === 'number') {
                    return this.budget.credit_amount == 0;
                } else if (this.budget.credit_amount == '$0.00') {
                    return true;
                }
                return false;
            }
		},
		methods: {
            formatPlural(count, noun, suffix = 's') {
                return noun += (Number(count) !== 1) ? suffix : '';
            },
            subtractFromAmountValue(index) {
                if(accounting.unformat(this.budget.credit_amount)-10>0) {
                    return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) - 10;
                }
            },
            addToAmountValue(index) {
                return this.budget.credit_amount = accounting.unformat(this.budget.credit_amount) + 10;
            },
            passValue(value) {
                return this.budget.credit_amount = (value);
            },
            getIconName(slug) {
                return 'icon-' + slug;
            },
            setDate(date) {
                this.budget.repeat_start = date;
            },
            formatMoney(amount) {
                return accounting.formatMoney(amount);
            },
			showPreviousBudgetSuggestion() {
                this.counter -=1;
                this.currentCount -=1;
                this.initNewBudget();
            },
            initNewBudget() {
                this.budget = {
                    is_recurring: (this.suggested[this.counter].hasOwnProperty('is_recurring')) ? this.suggested[this.counter].is_recurring : false,
                    repeat_frequency: 'month',
                    repeat_interval: 1,
                    day_of_month: "1",
                    credit_amount: 0,
                    repeat_start: this.first,
                    start_day: this.firstDay,
                    start_date: this.first,
                    end_date: null,
                    repeat_num_occurrences: null,
                    ending: 'never',
                    due_in_full: (this.suggested[this.counter].hasOwnProperty('due_in_full')) ? this.suggested[this.counter].due_in_full : 0,
                };
                var dp = $(this.$el).find(".datepicker").datepicker();
                dp.datepicker('setDate', this.first);
                console.log('==> this.budget', JSON.stringify(this.budget));
                console.log('==> this.suggested[counter].subcategory.slug', this.suggested[this.counter].subcategory.slug);

            },
			showNextBudgetSuggestion() {
                if (this.welcomeShown == false) {
                    this.welcomeShown = true;
                } else {
                    if (this.counter + 1 > this.suggested.length) {
                        return;
                    }

                    this.counter +=1;
                    this.currentCount +=1;

                }
                this.initNewBudget();
            },
            saveBudgetSuggestion() {

                this.budget.credit_amount = accounting.unformat(this.budget.credit_amount);
                this.budget.transaction_subcategory_id = this.suggested[this.counter].subcategory.id;
			    this.$store.dispatch('add_new_budget', {budget: this.budget}).then(() => {
                    if (this.counter + 1 == this.suggested.length) {
                        return this.$store.dispatch('stop_suggested_budgets').then(() => {
                            this.$store.commit('setToggleSuggestedBudgetsModal', false)
                        });
                    } else {
                        this.suggested.splice(this.counter, 1);
                        this.currentCount +=1;
                        this.initNewBudget();
                    }
                });
            },
            closeModal() {
                return this.$store.dispatch('stop_suggested_budgets').then(() => {
                    this.$store.commit('setToggleSuggestedBudgetsModal', false)
                });
            },
			skipOnce() {
                if(this.doNotShowAgain) {
                    return this.$store.dispatch('stop_suggested_budgets').then(() => {
                        this.$store.commit('setToggleSuggestedBudgetsModal', false)
                    });
                } else {
                    this.$store.commit('setToggleSuggestedBudgetsModal', false);
                }
			},
			skip() {
                return this.$store.dispatch('stop_suggested_budgets').then(() => {
                    this.$store.commit('setToggleSuggestedBudgetsModal', false)
                });
			},
            setStartDate(date) {
                //**Monthly recurring rules ** By default, if your start date is on the 29th, 30th, or 31st, Recurr will skip following months that don't have at least that many days. (e.g. Jan 31 + 1 month = March)
                this.budget.start_date = date;
                var date = new Date(date);
                this.budget.start_day = date.getDate();
            },
            setEndDate(date) {
                this.budget.end_date = date;
                this.budget.repeat_num_occurrences = null;
            },

            setEndDateToNull() {
                this.budget.end_date = null;
            },
		}
	}
</script>
