var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.this_month_transactions, function(transaction, index) {
      return _c(transaction.mode, {
        key: transaction.id,
        tag: "transaction",
        attrs: {
          transaction: transaction,
          categories: _vm.transaction_categories,
          index: index
        },
        on: {
          edit: _vm.edit,
          save: function($event) {
            return _vm.save(transaction, index)
          },
          remove: function($event) {
            return _vm.remove(transaction, index)
          },
          update: _vm.update
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }