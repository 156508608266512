var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-9")]),
      _c("path", {
        attrs: {
          d:
            "M347.64,75,199.25,27.22a6,6,0,0,0-3.79,0L53.66,76.17a6,6,0,0,0-.16,11.29l9.84,3.72v55.43A21.7,21.7,0,0,0,48.2,167.27v25.12a6,6,0,0,0,6,6H85.54a6,6,0,0,0,6-6V167.27a21.71,21.71,0,0,0-16.2-21V95.72l37.39,14.15v38.89a6.33,6.33,0,0,0,0,.76,5.91,5.91,0,0,0,.73,3.71,6,6,0,0,0,8.17,2.27c20.95-11.86,48.93-18.4,78.79-18.4,30.86,0,60.54,7.27,81.42,20a6,6,0,0,0,8.9-6.71,5.85,5.85,0,0,0,.22-1.58V109.22a5.06,5.06,0,0,0-.05-.68l57-22.2a6,6,0,0,0-.34-11.3ZM79.54,167.27v19.12H60.2V167.27a9.67,9.67,0,0,1,19.34,0Zm45.19-26.81V111.14c20.57-10.81,47.3-16.73,75.74-16.73,29.47,0,57.85,6.62,78.54,18.26v29.12C257.17,131,229.72,125.1,200.47,125.1,172.6,125.1,146.12,130.51,124.73,140.46ZM281.52,99.34a6.31,6.31,0,0,0-1,.52c-22.07-11.29-50.09-17.45-80-17.45S142.23,88.64,120.08,100a6,6,0,0,0-1.33-.71L73.26,82.11,197.48,39.26l130.41,42Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M199.43,171.87A90.17,90.17,0,1,0,289.59,262,90.27,90.27,0,0,0,199.43,171.87Zm0,168.33A78.17,78.17,0,1,1,277.59,262,78.25,78.25,0,0,1,199.43,340.2Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M199.43,145.6A116.44,116.44,0,1,0,315.86,262,116.57,116.57,0,0,0,199.43,145.6Zm0,220.87A104.44,104.44,0,1,1,303.86,262,104.55,104.55,0,0,1,199.43,366.47Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M198.93,255.78a5.85,5.85,0,0,0-1.58-.21c-8.54,0-15.5-5.75-15.5-12.8s7-12.8,15.5-12.8c6.24,0,11.85,3.09,14.3,7.86a6,6,0,1,0,10.68-5.47c-3.62-7.07-10.73-12.11-19-13.78v-7a6,6,0,0,0-12,0v6.95c-12.28,2.48-21.5,12.38-21.5,24.2,0,13.19,11.49,24,25.92,24.75a5.85,5.85,0,0,0,1.58.22c8.55,0,15.51,5.51,15.51,12.29s-7,12.3-15.51,12.3c-7.81,0-14.43-4.64-15.38-10.8a6,6,0,0,0-11.86,1.85c1.58,10.15,10.28,18.17,21.24,20.35v6.92a6,6,0,0,0,12,0v-6.91c12.29-2.43,21.51-12.13,21.51-23.71C224.86,267.11,213.37,256.51,198.93,255.78Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }