<template>
    <div class="full-width-horizontal-bar">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h5>Ok to spend</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4>$0.00</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <h5 class="strong">
						${{ this.ending_balance }} available balance - ${{ total_budgeted }} budgeted - $0.00 goals
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            safeToSpend: 0,
        }
    },
    mounted: function() {
    },
    methods: {
		round (value, decimals) {
			return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
		}
    },
    computed: {
			total_goals: function() {
				return this.round(this.$store.getters.total_goals,2);
			},
			total_budgeted: function () {
				return this.round(this.$store.getters.total_budgeted,2);
			},
        account: function() {
            return this.$store.state.account;
        },
		ending_balance: function() {

			var endingBalance = (this.account_balance) ? this.account_balance.available_balance : 0;
			var endingBalanceDate = (this.account_balance) ? new Date(this.account_balance.date) : new Date(this.account.created_at_js);

			this.$store.state.this_month_transactions.forEach(function(val, key) {


				var transactionDate = new Date(val['date_short']);

				if(endingBalanceDate<transactionDate) {
					endingBalance -=Number(val['credit_amount']);
					endingBalance +=Number(val['debit_amount']);
				}

			});
			return this.round(endingBalance,2);
		},
		account_balance: function() {
			if (this.$store.state.account.balance) {
				var num_balances = this.$store.state.account.balance.length;
				return this.$store.state.account.balance[num_balances-1];
			}
		},
    },
    watch: {


    }
}
</script>
