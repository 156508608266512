var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("polyline", {
        staticClass: "cls-1",
        attrs: {
          points:
            "275.79 220.47 329.88 220.47 329.88 86.33 70.12 86.33 70.12 220.47 125.92 220.47"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "125.92", y: "135.44", width: "149.87", height: "178.22" }
      }),
      _c("polyline", {
        staticClass: "cls-1",
        attrs: {
          points:
            "275.79 171.9 296.55 171.9 296.55 135.44 275.79 135.44 125.92 135.44 105.16 135.44 105.16 171.9 125.92 171.9"
        }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "125.92", y1: "267.37", x2: "275.79", y2: "267.37" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "125.92", y1: "290.03", x2: "275.79", y2: "290.03" }
      }),
      _c("polyline", {
        staticClass: "cls-1",
        attrs: { points: "202.32 244.7 202.32 163.61 169.02 195.51" }
      }),
      _c("line", {
        staticClass: "cls-1",
        attrs: { x1: "202.32", y1: "163.61", x2: "232.24", y2: "194.98" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }