<template>
	<div>
		<h4 class="mt-4">Split Category Transaction</h4>
		<p class="mb-5"><a href="" class="green ml-2" @click='addRow($event)'>Add row</a></p>
		<split-transaction-item v-for="(subcategory, index) in transaction.subcategories"
                                :key="subcategory.id"
								:subcategory="subcategory"
								:transaction="transaction"
								:index="index"
								>
		</split-transaction-item>
	</div>
</template>
<script>
export default {
	props:{
		transaction: Object,
    },
    mounted: function() {
        // console.log(JSON.stringify(this.transaction));
		this.addRow();
    },
	methods: {
        addRow(event) {
            (!event) ? null : event.preventDefault();
//            var remaining_amount = this.transaction.amount;
  //          var num_subcategories = this.transaction.subcategories.length;
    //        if(num_subcategories) {
      //          for(let i =0; i<num_subcategories; i++) {
        //            remaining_amount -= this.transaction.subcategories[i].amount;
          //      }
            //}
            this.transaction.subcategories.push({id:null, pivot: { amount: null}});
        },
    }
}
</script>
            <!--
               -<div class="col form-group">
               -    <label>Amount</label>
               -    <div class="input-group">
               -        <div class="input-group-prepend">
               -            <button class="btn btn-outline-secondary" type="button" @click='decreaseAmount(index)'><i class="fa fa-angle-down"></i></button>
               -            <div class="input-group-text bg-white" id="btnGroupAddon2">$</div>
               -        </div>
               -        <input type="text" v-model.number='subcategory.pivot.amount' class="form-control" placeholder="0.00">
               -        <div class="input-group-append">
               -            <button class="btn btn-outline-secondary" type="button" @click='increaseAmount(index)'><i class="fa fa-angle-up"></i></button>
               -        </div>
               -    </div>
               -</div>
               -<div class="col form-group">
               -    <label>Category</label>
               -    <div class="clearfix"></div>
               -    <dropdown @update="function (id) { update(id,index) }" :expense_categories="expense_categories" v-if='(transaction && transaction.type=="expense")' :current='subcategory.id'></dropdown>
               -    <dropdown @update="function (id) { update(id,index) }" :expense_categories="income_categories" v-else-if='(transaction.subcategory && transaction.subcategory.type=="income")' :current='subcategory.id'></dropdown>
               -    <dropdown @update="function (id) { update(id,index) }" :disabled='true' v-else></dropdown>
               -</div>
               -<div class="col">
               -    <label>Remove</label>
               -    <div class="clearfix"></div>
               -    <a href="#" @click='remove(index,$event)'> <i class="fa fa-times"></i></a>
               -</div>
               -->
