var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1")]),
      _c("path", {
        attrs: {
          d:
            "M24.14,191.9H44.3V372.5a6,6,0,0,0,6,6H240.23a6,6,0,1,0,0-12H218.71V254.25a6,6,0,0,0-6-6H143a6,6,0,0,0-6,6V366.5H56.3V191.9h242V213a6,6,0,0,0,12,0V191.9h20.16a6,6,0,0,0,4.33-10.17l-24.49-25.41V78.49a6,6,0,0,0-6-6H277a6,6,0,0,0-6,6v37.09L182.15,23.34a6,6,0,0,0-4.31-1.84h0a6,6,0,0,0-4.31,1.82L19.83,181.72a6,6,0,0,0,4.31,10.18ZM149,260.25h57.76V366.5H149ZM283,127.31V84.49h15.25v59.38L283,128A5.07,5.07,0,0,0,283,127.31ZM177.81,36.13,316.31,179.9h-278Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M234.23,303.76c0,41.21,33.11,74.74,73.81,74.74s73.82-33.53,73.82-74.74S348.75,229,308,229,234.23,262.55,234.23,303.76Zm135.63,0c0,34.59-27.73,62.74-61.82,62.74s-61.81-28.15-61.81-62.74S274,241,308,241,369.86,269.17,369.86,303.76Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M302.56,256.74v8.43c-12.42,1.41-25.49,8-25.38,22.23.1,16.78,13.79,19.92,30.31,22.06,10.45,1.47,20.12,3.92,20.12,11.74,0,10.5-12.29,12.07-19.62,12.07-7.05,0-15.3-2.53-19.92-7.41a6.27,6.27,0,0,0-7.46-1.21h0a6.36,6.36,0,0,0-1.65,10c5.39,5.73,13.71,9.43,23.6,10.29v8.33a2.07,2.07,0,0,0,2.07,2.07h8.22a2.08,2.08,0,0,0,2.08-2.07v-8.76c13.08-1,26.13-8.56,26.13-23.33,0-17.68-16.65-21.49-32.25-23.67-10.9-1.53-18.61-3.15-18.61-10.24a8.7,8.7,0,0,1,2.6-6.46c3.06-3.1,8.86-5,15.5-5,6.34.09,12.2,2.11,15.83,5.27a6.08,6.08,0,0,0,8.08-9.08c-5-4.55-11.09-6.07-17.17-6.88v-8.44a1.34,1.34,0,0,0-1.34-1.34h-9.8A1.34,1.34,0,0,0,302.56,256.74Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }