var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("4")]),
      _c("path", {
        attrs: {
          d:
            "M335.05,104.85H258a32.71,32.71,0,0,0-31.31,23.37,32.49,32.49,0,0,0-9.55-6.32L184,107.52h0l-25.37-11h0l-12.21-5.31a32.67,32.67,0,0,0-43,17L35,265.73a32.66,32.66,0,0,0,17,43l12.2,5.3h0l25.36,11h0l33.11,14.37a32.66,32.66,0,0,0,43-16.94L225.31,185v124.3A32.7,32.7,0,0,0,258,342h77.08a32.7,32.7,0,0,0,32.66-32.66V137.51A32.7,32.7,0,0,0,335.05,104.85Zm-30.37,11.46h16.21V330.52H304.68ZM293.22,330.52H277V116.31h16.2ZM99.36,316.79l85.33-196.48,14.86,6.46L114.22,323.25ZM74,305.77l85.33-196.48,14.86,6.46L88.84,312.23ZM45.23,286.52a21.14,21.14,0,0,1,.28-16.23L114,112.71a21.18,21.18,0,0,1,27.89-11l7,3L63.47,301.21l-7-3A21.05,21.05,0,0,1,45.23,286.52ZM223.54,160.3,155.1,317.89a21.19,21.19,0,0,1-27.89,11l-2.47-1.08,85.33-196.47,2.47,1.07a21.26,21.26,0,0,1,12.77,19.76v.07A21.08,21.08,0,0,1,223.54,160.3Zm13.23,149V152.57c0-.34,0-.67,0-1V137.51a21.22,21.22,0,0,1,21.2-21.2h7.58V330.52H258A21.22,21.22,0,0,1,236.77,309.32Zm119.48,0a21.22,21.22,0,0,1-21.2,21.2h-2.7V116.31h2.7a21.22,21.22,0,0,1,21.2,21.2Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }