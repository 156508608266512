<template>
	<transition
	name="fade"
	>
	<div class="modal is-active">
  		<div class="modal-background"></div>
 		<div class="modal-content">
			<div class="box">
				<h1>Your Monthly Income</h1>
					<h5>Enter in your fixed monthly income such as paychecks.</h5>
				<div class="row">
					<div class="col-md-12">
						<div class="text-left">
							<component :is="newincome.mode" @toggle="toggle"></component>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<p class="strong">Category</p>
					</div>
					<div class="col-md-4">
						<p class="strong">Amount</p>
					</div>
					<div class="col-md-4">
						<p class="strong">Repeat day of month</p>
					</div>
					<div class="col-md-4">
					</div>
				</div>
				<income v-for="(paycheck, index) in paychecks"
		   			    v-bind:paycheck="paycheck"
                        :key="paycheck.id"
						@update="update"
						@edit="edit"
						@remove="remove(paycheck,index)"
						@subtractFromValue="subtractFromValue(index)"
						@addToValue="addToValue(index)"
						:is="paycheck.mode"
		        ></income>
				<div class="form-group">
					<div class="text-right">
						<button class="btn btn-default" v-on:click="skip">Close</button>
						<!--<button class="btn btn-primary" v-on:click="save(paychecks)">Save</button>-->
					</div>
				</div>
			</div>
		</div>
  		<button class="modal-close" @click="skip"></button>
	</div>
	</transition>
</template>

<script>
    export default {
		props:["paychecks"],
		data() {
			return {
				counter: 0,
				newincome: {
					mode: 'addNewIncomeButton'
				}
			}
		},
		mounted() {
			//this.fetch();
		},
		computed: {
			account () {
				return this.$store.state.account;
			},
		},
		methods: {
			toggle () {
			    this.newincome.mode = (this.newincome.mode=='addNewIncome') ? 'addNewIncomeButton' : 'addNewIncome';
			},
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
			subtractFromValue(index) {
				if (this.$store.state.paychecks[index].debit_amount - 10 > 0) {
				    this.$store.state.paychecks[index].debit_amount = this.round(this.$store.state.paychecks[index].debit_amount,0);
					this.$store.state.paychecks[index].debit_amount	-= 10;
				}
			},
			addToValue(index) {
				this.$store.state.paychecks[index].debit_amount = this.round(this.$store.state.paychecks[index].debit_amount,0);
				this.$store.state.paychecks[index].debit_amount += 10;
			},
			edit(paycheck) {
				paycheck.mode = 'edit-income';
			},
			remove(paycheck, index) {

				this.$store.dispatch('delete_income', {
					paycheck: paycheck,
					index: index
				})
			},
			update(paycheck){
				this.$store.dispatch('update_income', {
					paycheck: paycheck
				})
			},
			add() {
				this.paychecks.push({
						debit_amount : null,
						repeat_day : null
					});
				this.counter = this.paychecks.length;
			},
			subtract(paycheck, index) {
				this.paychecks.splice(index, 1);
				this.counter = this.paychecks.length;
			},
			skip() {

				this.$store.dispatch('hide_modal');
			},
			save(paychecks) {

				// console.log('==> paychecks', JSON.stringify(paychecks));

				var that = this;
				axios.post('/api/accounts/' + that.$store.state.account.id + '/transactions/recurring?type=paycheck', {'paychecks':paychecks})
				.then(function (response) {
					that.$store.paychecks.push(paycheck);
					that.$store.dispatch('hide_modal');
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		}
	}
</script>
