var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("2")]),
      _c("path", {
        attrs: {
          d:
            "M53.45,236.23c1.26,10.63,5.69,19.19,12.82,24.75a4.69,4.69,0,0,0,3.86.89c30.52-6.39,48-22.21,55.08-30.22A170.57,170.57,0,0,0,140,246.17c-.14,34.41-22.91,49.41-23.87,50a4.72,4.72,0,0,0,.46,8.23c1.58.76,37.08,17.72,78.25,17.72q3.6,0,7.24-.18c56.39-2.82,93.24-53.81,98.21-61.07a125.16,125.16,0,0,0,46.31-57.65,4.83,4.83,0,0,0,.34-1.76v-3a4.83,4.83,0,0,0-.34-1.76,125,125,0,0,0-46.31-57.64c-4.94-7.22-41.8-58.26-98.21-61.08-44.16-2.2-83.82,16.73-85.49,17.54a4.72,4.72,0,0,0-.47,8.22c1,.62,23.74,15.62,23.88,50a173.56,173.56,0,0,0-14.77,14.52c-7.13-8-24.56-23.83-55.08-30.21a4.68,4.68,0,0,0-3.86.89c-7.13,5.57-11.56,14.12-12.82,24.75C52,176.11,55.15,190.43,61.26,200,55.15,209.57,52,223.89,53.45,236.23Zm148.14,76.31c-31.24,1.57-60.89-8.44-73.94-13.63,7.56-7.14,19.26-21.77,21.42-45.33,17.25,13.13,41.7,26.58,70.91,28.32,2.87.17,5.73.26,8.56.26a131.18,131.18,0,0,0,49.86-9.6C262.9,288.86,235.86,310.83,201.59,312.54ZM127.65,101.09c13-5.19,42.68-15.2,73.94-13.63,34.21,1.71,61.27,23.69,76.79,40A132.68,132.68,0,0,0,220,118.1c-29.21,1.73-53.66,15.18-70.91,28.32C146.91,122.86,135.21,108.23,127.65,101.09Zm92.89,26.42c27.25-1.63,52.36,5,73,18.29a4.78,4.78,0,0,0,1.67,1.07c18.46,12.39,33.16,30.29,42.31,52.57v1.12a115.64,115.64,0,0,1-43.23,53c-21.19,14-46.69,20.51-73.72,18.9-47.61-2.83-82.89-40.84-90.28-49.42,3.2-14.84.67-38.71-.21-45.91C137,169.08,172.47,130.36,220.54,127.51ZM70.72,197.07c-5.07-6.39-9.42-19.5-7.92-32.18.62-5.14,2.46-12,7.66-17,31.4,7.27,47,25.64,50.14,29.69.71,5.71,3.62,31.25.18,44.67-2.71,3.58-18.32,22.53-50.32,29.94-5.2-5-7-11.9-7.66-17-1.5-12.69,2.85-25.8,7.92-32.19A4.69,4.69,0,0,0,70.72,197.07Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M234,254a4.71,4.71,0,0,0,6.24-7.06c-.22-.2-21.59-19.37-21.59-44.64,0-25.09,21.25-44.34,21.59-44.65a4.71,4.71,0,0,0-6.24-7.06c-1,.9-24.78,22.23-24.78,51.71S233,253.14,234,254Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M280.44,211.89a19.13,19.13,0,1,0-19.12-19.13A19.15,19.15,0,0,0,280.44,211.89Zm0-28.82a9.7,9.7,0,1,1-9.7,9.69A9.71,9.71,0,0,1,280.44,183.07Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }