var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box" }, [
    _c("h4", { staticClass: "mt0 text-center" }, [_vm._v("MONTHLY INCOME")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("span", { staticClass: "text-right dollars" }, [
            _vm._v("$" + _vm._s(_vm.totalIncomeT))
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: { click: _vm.showRecurringIncomeModal }
            },
            [_vm._v("View")]
          )
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "mt0 text-center" }, [
          _vm._v("MONTHLY EXPENSES")
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("span", { staticClass: "text-right dollars" }, [
            _vm._v("$" + _vm._s(_vm.totalExpensesT))
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: { click: _vm.showRecurringExpensesModal }
            },
            [_vm._v("View")]
          )
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "mt0 text-center" }, [
          _vm._v("DISPOSABLE INCOME")
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("span", { staticClass: "text-right dollars" }, [
            _vm._v("$" + _vm._s(_vm.monthly_disposable_income))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm.expenses.length > 0
            ? _c("p", [_vm._v("Top expenses")])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "legend" },
            _vm._l(_vm.expenses, function(expense, index) {
              return _c("li", { class: _vm.getClass(index) }, [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(expense.subcategory.name) +
                    " \n\t\t\t\t\t"
                )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("span", { staticClass: "text-muted text-left little" }, [
        _vm._v("Total")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("span", { staticClass: "text-muted text-left little" }, [
        _vm._v("Total")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("span", { staticClass: "text-muted text-left little" }, [
        _vm._v("Total")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "ct-chart" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }