var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 13")]),
      _c("path", {
        attrs: {
          d:
            "M177.71,260a27.86,27.86,0,0,0,39.34,0l11.57-11.58a27.83,27.83,0,0,0,0-39.33l-3.38-3.38a27.82,27.82,0,0,0-39.33,0l-11.58,11.58a27.85,27.85,0,0,0,0,39.33Zm42.43-20.06L208.56,251.5A15.83,15.83,0,0,1,188,253l34.8-31.94A15.85,15.85,0,0,1,220.14,239.92Zm-37.32-14.17,11.57-11.57a15.82,15.82,0,0,1,20.14-1.85l-34.62,31.78A15.86,15.86,0,0,1,182.82,225.75Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M291.21,22.12H113a12.65,12.65,0,0,0-12.63,12.63v16H71.08A10.42,10.42,0,0,0,60.67,61.12V89.79a10.42,10.42,0,0,0,10.41,10.42H88.45l19.88,267.43a11.1,11.1,0,0,0,11,10.23H284.61a11.08,11.08,0,0,0,11-10.3l18.1-267.36h15.18a10.42,10.42,0,0,0,10.41-10.42V61.12a10.42,10.42,0,0,0-10.41-10.41H303.83v-16A12.64,12.64,0,0,0,291.21,22.12ZM112.33,34.75a.63.63,0,0,1,.63-.63H291.21a.63.63,0,0,1,.62.63v16H112.33Zm171.4,331.12H120.24l-5.19-69.75h173.4Zm5.53-81.75H114.16l-7.66-103H296.24Zm7.79-115H105.61l-5.13-68.91H301.72ZM327.33,62.71v25.5H72.67V62.71Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }