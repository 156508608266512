var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("11")]),
      _c("path", {
        attrs: {
          d:
            "M186.63,273.92a22.75,22.75,0,1,0-22.75,22.75A22.77,22.77,0,0,0,186.63,273.92Zm-34,0a11.3,11.3,0,1,1,11.3,11.31A11.31,11.31,0,0,1,152.58,273.92Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M123,347.61a5.72,5.72,0,0,0,11.44,0c0-20.14,13.19-36.53,29.4-36.53s29.4,16.39,29.4,36.53a5.72,5.72,0,0,0,9.92,3.88,5.71,5.71,0,0,0,9.91-3.88c0-20.14,13.19-36.53,29.4-36.53s29.4,16.39,29.4,36.53a5.72,5.72,0,1,0,11.44,0c0-26.45-18.32-48-40.84-48-18.7,0-34.49,14.84-39.31,35-4.83-20.17-20.62-35-39.32-35C141.36,299.64,123,321.16,123,347.61Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M265.26,273.92a22.75,22.75,0,1,0-22.75,22.75A22.77,22.77,0,0,0,265.26,273.92Zm-34,0a11.3,11.3,0,1,1,11.3,11.31A11.31,11.31,0,0,1,231.21,273.92Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M299.88,233.69V52.39a5.72,5.72,0,0,0-5.72-5.72H105.84a5.72,5.72,0,0,0-5.72,5.72v181.3a5.72,5.72,0,0,0,5.72,5.72H294.16A5.72,5.72,0,0,0,299.88,233.69ZM288.44,58.12V189l-38.93-42.16a5.73,5.73,0,0,0-4.2-1.84h0a5.69,5.69,0,0,0-4.21,1.87l-25.69,28.23-36.76-40.52a5.67,5.67,0,0,0-4.22-1.88h0a5.72,5.72,0,0,0-4.23,1.86l-58.59,64.1V58.12ZM111.57,228V215.29a6.22,6.22,0,0,0,1.16-1l61.64-67.43,36.77,40.53a5.72,5.72,0,0,0,4.23,1.87h0a5.73,5.73,0,0,0,4.23-1.87l25.73-28.26,43.1,46.68V228Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M223.06,134.24a29.48,29.48,0,1,0-29.47-29.47A29.51,29.51,0,0,0,223.06,134.24Zm0-47.51a18,18,0,1,1-18,18A18.06,18.06,0,0,1,223.06,86.73Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }