<template>
<div>
    <div class="row boxshadow-1 institution-row edit-enabled">
        <div class="col-6 col-lg-5">
			<span class="institution-name">{{ bank.name }}</span>
            <div class="form-group">
                <input type="text" v-model='bank.name' class="form-control"  id="editInstitutionName" placeholder="Bank/credit card name">
            </div>
        </div>
        <div class="col-6 col-lg-3 num-accounts-col">
            <span>{{ bank.accounts.length }} accounts</span>
        </div>
        <div class="col-12 col-lg-4 buttons-cont">
            <remove-connected-bank-button :is='removeBankButtonMode' :bank='bank'></remove-connected-bank-button>
        </div>
    </div>
    <div class="row delete-note-row">
        <div class="col-12">
            <p class="text-right"><span class="green">Note:</span> Deleting a connection will stop transactions and data from being imported.</p>
        </div>
    </div>
</div>

    <!--
	   -<div class="editing">
	   -    <div class="row">
	   -        <div class="col-md-4">
	   -            <label>Bank Name</label>
	   -            <input type="text" class="form-control" v-model="bank.bank_name" disabled>
	   -        </div>
	   -        <div class="col-md-4">
	   -            <label>Number of accounts</label>
	   -            <p>({{ bank.accounts.length }} accounts)</p>
	   -        </div>
	   -    </div>
	   -    <div class="row">
	   -        <div class="col-md-12">
	   -            <div class="text-right">
	   -                <p><strong>NOTE: </strong>Deleting a connection will delete all related accounts and data.</p>
	   -                <remove-connected-bank-button :is='removeBankButtonMode' :bank='bank'></remove-connected-bank-button>
	   -            </div>
	   -        </div>
	   -    </div>
	   - </div>
       -->
</template>
<script>
export default {
	props:["bank"],
	computed: {
		removeBankButtonMode() {
			//
			return this.$store.state.remove_bank_button_mode;
		}
	},
	methods: {
	}
}
</script>
