<template>
	<div class="account-progress">
		<!--<h5 class="title1">{{ (account) ? account.name : null}}</h5>-->
		<h5 class="title1">{{ (account && account.type) ? account.type.name + '' : null }}</h5>
		<!--<p class="subtitle">{{ (account && account.type) ? account.type.name + ' account' : null }}</p>-->
		<h5 class="title2">Monthly overview</h5>
		<ul class="stats-group" v-if="account && typeof(account.type) !=='undefined' && account.type && account.type.name=='Checking'">
			<li class="list-group-item mt-4 mb-4" v-if='this.budgets && this.budgets.length!==0'>
				Available to Spend
                <span class="white">{{ ok_to_spend }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4"v-if="account && typeof(account.type) !=='undefined' && account.type">
				Current Balance
				<span class="white" v-if='this.accounts.length!==0'>{{current_balance}}</span>
                <div class="text-line-sm" v-else></div>
			</li>
			<li class="list-group-item mt-4 mb-4" v-if="account && typeof(account.type) !=='undefined' && account.type">
				Available Balance
				<span class="white" v-if='this.accounts.length!==0'>{{available_balance}}</span>
                <div class="text-line-sm" v-else></div>
			</li>
			<li class="list-group-item mt-4 mb-4" v-if='account.subtype=="credit card"'>
				Limit
				<span class="white" v-if='this.accounts.length!==0'>{{limit}}</span>
			</li>
            <!-- @todo bring this back ASAP -->
		<!--	<li class="list-group-item" v-if="account && typeof(account.type) !=='undefined' && account.type && account.type.name=='Checking'">
				<a href="#" @click="showBudgetedToGo">Budgeted To Go</a>
				<span class="green" v-if='this.accounts && this.accounts.length!==0 && this.budgets && this.budgets.length!==0'>{{total_budgeted_remaining}}</span>
                <div class="text-line-sm" v-else></div>
			</li>-->
            <!-- @todo bring this back at your convenience -->
			<!--<li class="list-group-item" v-if="account && typeof(account.type) !=='undefined' && account.type && account.type.name=='Checking'">
				Goals To Go
				<span class="green" v-if='this.goals && this.goals.length!==0'>{{left_to_allocate_this_month}}</span>
                <div class="text-line-sm" v-else></div>
			</li>-->
		</ul>
		<ul class="stats-group" v-if="account && typeof(account.type) !=='undefined' && account.type && account.type.name=='Credit card'">
			<li class="list-group-item mt-4 mb-4" v-if='this.budgets && this.budgets.length!==0'>
				Balance
                <span class="white">{{ current_balance }}</span>
			</li>
			<li class="list-group-item mt-4 mb-4" v-if="account && typeof(account.type) !=='undefined' && account.type">
				Available Balance
				<span class="white" v-if='this.accounts.length!==0'>{{available_balance}}</span>
                <div class="text-line-sm" v-else></div>
			</li>
			<li class="list-group-item mt-4 mb-4" v-if='account.subtype=="credit card"'>
				Limit
				<span class="white" v-if='this.accounts.length!==0'>{{limit}}</span>
			</li>
        </ul>
	</div>
</template>
<script>
export default {
	data() {
		return {
			totalBudgetedT: 0,
			totalSpentT: 0,
			percentBudgetedSpentT: 0
		}
	},
	mounted: function() {
		if(!this.budgets) {
			this.$store.dispatch('get_budgets');
		}
//		if(!this.goals) {
//			this.$store.dispatch('get_goals');
//		}
	},
	computed: {

		accounts: function() {
			return this.$store.state.accounts;
		},
		account: function() {
			return this.$store.state.account;
		},
		current_balance: function() {
			if(this.$store.state.account) {
				return (this.account.most_recent_balance) ? accounting.formatMoney(this.account.most_recent_balance.current_balance) : 0;
			}
		},
		available_balance: function() {
			if(this.$store.state.account) {
				return (this.account.most_recent_balance) ? accounting.formatMoney(this.account.most_recent_balance.available_balance) : 0;
			}
		},
		limit: function() {
			if(this.$store.state.account) {
				return (this.account.most_recent_balance) ? accounting.formatMoney(this.account.most_recent_balance.limit) : 0;
			}
		},
		ending_balance: function() {

			if(this.$store.state.account) {
				var endingBalance = (this.account.most_recent_balance) ? this.account.most_recent_balance.available_balance : 0;
				var endingBalanceDate = (this.account.most_recent_balance) ? new Date(this.account.most_recent_balance.date) : new Date(this.account.created_at_js);

				this.$store.state.this_month_transactions.forEach(function(val, key) {


					var transactionDate = new Date(val['date_short']);

					if(endingBalanceDate<=transactionDate) {
						endingBalance -=Number(val['credit_amount']);
						endingBalance +=Number(val['debit_amount']);
					}

				});
				return this.round(endingBalance,2);
			}
			return 0;
		},
		account_balance: function() {
			if (this.$store.state.account && this.$store.state.account.balance) {
				var num_balances = this.$store.state.account.balance.length;
				return this.$store.state.account.balance[num_balances-1];
			}
		},
		total_income() {
			return this.$store.getters.this_month_total_income;
		},
		total_needs_budgeted () {
			return this.round(this.$store.getters.total_income - this.total_budgeted_no_animation, 2);
		},
		total_goals_amount () {

			if(this.$store.getters.total_goals) {
				return this.number_format(this.$store.getters.total_goals);
			}
			return 0;

		},
		left_to_allocate_this_month() {
			return accounting.formatMoney(this.$store.getters.total_allocations_due_this_month);
		},
		month () {
			return this.$store.state.month;
		},
		first () {
			return this.$store.state.first;
		},
		last () {
			return this.$store.state.last;
		},
		budgets () {
			return this.$store.state.budgets;
		},
		goals () {
			return this.$store.state.goals;
		},
		total_budgeted_remaining () {
			var remaining = this.$store.getters.total_ongoing_remaining + this.$store.getters.total_bills_budgeted;
			return accounting.formatMoney(remaining);
		},
		total_to_be_budgeted () {
			return this.round(this.$store.getters.total_to_be_budgeted,2);
		},
		ok_to_spend() {
			if(accounting.unformat(this.available_balance) > 0 && accounting.unformat(this.available_balance) > accounting.unformat(this.total_budgeted_remaining)) {
				return accounting.formatMoney(accounting.unformat(this.available_balance) - accounting.unformat(this.total_budgeted_remaining));
                //- accounting.unformat(this.left_to_allocate_this_month));
			} else {
				return accounting.formatMoney(0);
			}
		}
	},
	watch: {

		total_spent: function(newValue, oldValue) {

			var vm = this
			var animationFrame
			function animate (time) {
				TWEEN.update(time)
				animationFrame = requestAnimationFrame(animate)
			}

			new TWEEN.Tween({ tweeningNumber: oldValue })
				.easing(TWEEN.Easing.Quadratic.Out)
				.to({ tweeningNumber: newValue }, 500)
				.onUpdate(function () {
					if(this.tweeningNumber) {
						vm.totalSpentT = this.tweeningNumber.toFixed(0)
					}
					//vm.$store.commit('setBudgets', budgets)
				})
				.onComplete(function () {
					cancelAnimationFrame(animationFrame)
				})
				.start()
			animationFrame = requestAnimationFrame(animate)
		},
		total_budgeted: function(newValue, oldValue) {
			var vm = this
			var animationFrame
			function animate (time) {
				TWEEN.update(time)
				animationFrame = requestAnimationFrame(animate)
			}

			new TWEEN.Tween({ tweeningNumber: oldValue })
				.easing(TWEEN.Easing.Quadratic.Out)
				.to({ tweeningNumber: newValue }, 250)
				.onUpdate(function () {
					if(this.tweeningNumber) {
						vm.totalBudgetedT = this.tweeningNumber.toFixed(0)
					}
				})
				.onComplete(function () {
					cancelAnimationFrame(animationFrame)
				})
				.start()
			animationFrame = requestAnimationFrame(animate)
		},
	},
	methods: {
		showBudgetedToGo() {
			this.$store.commit('setToggleBudgetedToGo', true);
		},
		number_format(number, decimals=2) {
			var n = !isFinite(+number) ? 0 : +number,
				prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
				sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
				dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
				toFixedFix = function (n, prec) {
					// Fix for IE parseFloat(0.55).toFixed(0) = 0;
					var k = Math.pow(10, prec);
					return Math.round(n * k) / k;
				},
				s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
			if (s[0].length > 3) {
				s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec) {
				s[1] = s[1] || '';
				s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		updateBudgetProgress() {
			$("#test-circle3").empty();
			var that = this;
			$("#test-circle3").circliful({
				animation: 1,
				animationStep: 6,
				foregroundBorderWidth: 5,
				backgroundBorderWidth: 1,
				percent: that.percent_budgeted_spent,
				iconColor: '#3498DB',
				icon: 'f09d',
				iconSize: '40',
				iconPosition: 'middle'
			});
		},
		round (value, decimals) {
			return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
		},
		budgetTotal () {

			var budgets = this.$store.state.budgets;
			if(budgets) {
                budgets[budgets.length-1].amount += 1000;
                this.$store.commit('setBudgets', budgets);
            }

		},
	}
}
</script>
<!--<div class="row">
	<div class="col-md-12">
	<div class="row">
	<div class="col-md-6">
	<span class="text-muted text-left little">Available Balance</span>
	</div>
	<div class="col-md-6">
	<span class="text-right dollars">${{ ending_balance }}</span>
	</div>
	</div>
	<div class="row">
	<div class="col-md-6">
	<span class="text-muted text-left little">Total Income</span>
	</div>
	<div class="col-md-6">
	<span class="text-right dollars">${{ total_income }}</span>
	</div>
	</div>
	<div class="row">
	<div class="col-md-6">
	<span class="text-muted text-left little">Spent</span>
	</div>
	<div class="col-md-6">
	<span class="text-right dollars">$-{{	totalSpentT }}</span>
	</div>
	</div>
	</div>-->
