var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "117.97 283.48 288.39 283.48 288.39 309.05 276.72 325.08 128.57 325.08 117.7 309.05 117.97 283.48"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M200.27,280.86S160,197.57,155.19,197.57s-14.77,6.92-7.92,23.06c12.35,29.09,29.6,62.85,29.6,62.85"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M198.21,280.86s40.26-83.29,45.08-83.29,14.77,6.92,7.92,23.06c-12.35,29.09-29.6,62.85-29.6,62.85"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M160,249H64.64c-55.28-24.33-38.36-82.79,12.69-93.39C75,120.71,113.53,99,144.94,114c13.88-54.61,100-51.08,117.88,4.94,33-25.53,66,7.12,64.18,26.1,60.29,17.86,51.07,101.31,7.05,104h-93.8"
        }
      }),
      _c("circle", { attrs: { cx: "242.25", cy: "304.28", r: "5.7" } }),
      _c("circle", { attrs: { cx: "265.94", cy: "304.28", r: "5.7" } }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M148.28,161.14c26.8-30.56,81.37-28.95,105.26,0" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M166.31,176.65c18.44-16.1,51.83-15.87,71.67,1.36" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: { d: "M183.19,195.36c13.12-8.49,25.55-8.31,38.46,1.17" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }