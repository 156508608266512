<template>
<div class="row editable">
	<div class="form-group">
		<div class="col-md-4">
			{{ paycheck.subcategory.name }}
		</div>
		<div class="col-md-4">
			<span class="editable medium">
				${{ paycheck.debit_amount }}
			</span>
		</div>
		<div class="col-md-2">
			<p class="medium">{{ paycheck.repeat_day }}th</p>
		</div>
		<div class="col-md-2">
			<a @click="$emit('edit', paycheck)">Edit <i class="fa fa-pencil"></i></a>
		</div>
	</div>
</div>
</template>
<script>
	export default {
		props:["paycheck"],
		methods: {
		}
	}
</script>
