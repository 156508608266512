var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("13")]),
      _c("path", {
        attrs: {
          d:
            "M294.56,78.52H105.44A24.55,24.55,0,0,0,80.92,103V297a24.55,24.55,0,0,0,24.52,24.52H294.56A24.55,24.55,0,0,0,319.08,297V103A24.55,24.55,0,0,0,294.56,78.52ZM105.44,89.31H294.56A13.75,13.75,0,0,1,308.29,103v18.63H91.71V103A13.75,13.75,0,0,1,105.44,89.31ZM294.56,310.69H105.44A13.75,13.75,0,0,1,91.71,297V132.46H308.29V297A13.75,13.75,0,0,1,294.56,310.69Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M246.71,194.61H219.6V180.56a17.44,17.44,0,0,0-17.42-17.42H196a11.93,11.93,0,0,0-11.91,11.92v19.55H156.48a11.94,11.94,0,0,0-11.93,11.92v50.19a11.94,11.94,0,0,0,11.93,11.93h90.23a11.94,11.94,0,0,0,11.92-11.93V206.53A11.94,11.94,0,0,0,246.71,194.61Zm-91.37,62.11V206.53a1.14,1.14,0,0,1,1.14-1.14h12.84v52.47H156.48A1.14,1.14,0,0,1,155.34,256.72Zm91.37,1.14H180.1V205.39h9.36a5.39,5.39,0,0,0,5.39-5.39V175.06a1.12,1.12,0,0,1,1.12-1.13h6.21a6.64,6.64,0,0,1,6.63,6.63V200a5.39,5.39,0,0,0,5.4,5.39h32.5a1.14,1.14,0,0,1,1.13,1.14v4H225a5.4,5.4,0,0,0,0,10.79h22.8v4.94H225A5.4,5.4,0,0,0,225,237h22.8V242H225a5.4,5.4,0,0,0,0,10.79h22.8v4A1.14,1.14,0,0,1,246.71,257.86Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M107.21,113.27h6.26a5.39,5.39,0,1,0,0-10.78h-6.26a5.39,5.39,0,1,0,0,10.78Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M129.11,113.27h6.27a5.39,5.39,0,1,0,0-10.78h-6.27a5.39,5.39,0,0,0,0,10.78Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M150.38,113.27h6.26a5.39,5.39,0,1,0,0-10.78h-6.26a5.39,5.39,0,1,0,0,10.78Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }