var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", [
      _vm.showMessage
        ? _c("h3", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.message))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("i", { staticClass: "fa fa-refresh fa-spin fa-3x fa-fw" }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }