<template>
<div>
<form id='plaid-link-form'></form>
<!--
   -<loading></loading>
   -->
</div>
</template>
<script>
export default {
	mounted() {

//		$.getScript( "https://cdn.plaid.com/link/v2/stable/link-initialize.js", function(  ) {
//			console.log( Plaid ); // Data returned
//		});
		var that = this;
		var linkScript = document.createElement('script')
		linkScript.async = true
		linkScript.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js')
		document.head.appendChild(linkScript)
		linkScript.onload = () => {
			this.plaid = window.Plaid;
				this.plaid.create({
				clientName: 'Budget It Up',
				// Optional, specify an array of country codes to localize Link
				countryCodes: ['US'],
				env: 'development',
				// Replace with your public_key from the Dashboard
				key: '8e933a50d8a17cd2717bc66123c5c9',
				product: ['transactions'],
				// Optional, use webhooks to get transaction and error updates
				webhook: 'https://api.budgetitup.com/plaid/webhooks',
				// Optional, specify a language to localize Link
				language: 'en',
				// Optional, specify a user object to enable all Auth features
				//    user: {
				//    legalName: 'John Appleseed',
				//  emailAddress: 'jappleseed@yourapp.com',
				//},
				onLoad: function() {
					// Optional, called when Link loads
				},
				onSuccess: function(public_token, metadata) {
                    that.$store.commit('clearConnectBankAccountErrors');
                    that.$store.dispatch('exchangePlaidToken', {
                        token: public_token,
                        metadata: metadata
                    }).then(()=> {
                        that.$store.dispatch('get_connected_banks').then(() => {
                        });
                    }).then(()=> {

                        that.$store.dispatch('import_transactions', {
								user_id: that.$store.state.user.id,
								fromLoginScreen: false
							}).then(() => {
                            that.$store.commit('setToggleConnectBankAccountModal', false);
                        });
                    });
					// Send the public_token to your app server.
					// The metadata object contains info about the institution the
					// user selected and the account ID or IDs, if the
					// Select Account view is enabled.
					//      $.post('/get_access_token', {
					//      public_token: public_token,
					//  });
				},
				onExit: function(err, metadata) {
					// The user exited the Link flow.
					if (err != null) {
                        console.log(JSON.stringify(err));
						// The user encountered a Plaid API error prior to exiting.
					}
                    that.$store.commit('clearConnectBankAccountErrors');
                    that.$store.commit('setToggleConnectBankAccountModal', false);
					// metadata contains information about the institution
					// that the user selected and the most recent API request IDs.
					// Storing this information can be helpful for support.
				},
				onEvent: function(eventName, metadata) {
					// Optionally capture Link flow events, streamed through
					// this callback as your users connect an Item to Plaid.
					// For example:
					// eventName = "TRANSITION_VIEW"
					// metadata  = {
					//   link_session_id: "123-abc",
					//   mfa_type:        "questions",
					//   timestamp:       "2017-09-14T14:42:19.350Z",
					//   view_name:       "MFA",
					// }
				}
			}).open();

		}
	},
	data() {
		return {
			plaid: null,
			bank_connection: {
				username: null,
				password: null,
				bank: null,
				bank_name: null,
				pin: null
			},
            search: '',
		};

	},
	computed: {
		message() {
			return this.$store.state.connect_bank_account_modal_message;
		},
        institutions() {
            return this.$store.state.institutions;
        },
		errors() {
			return this.$store.state.connect_bank_errors;
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.$store.commit('clearConnectBankAccountErrors');
			this.$store.dispatch('connect_bank_account', {bank_connection: this.bank_connection});
		},
		close() {
			this.$store.commit('clearConnectBankAccountErrors');
			this.$store.commit('setToggleConnectBankAccountModal', false);
		},
		setUsername(amount) {
			this.username = username;
		},
		setPassword(password) {
			this.password = password;
		},
		update(bankId) {
			this.bank_connection.bank = (bankId);
			this.bank_connection.bank_name = $('.ui.dropdown').dropdown('get text');
		}
	},
}
</script>

<!--
   -<div class="form-row">
   -    <div class="form-group col">
   -        <h3 class="text-center">Bank Account Login</h3>
   -        <p class="text-center">{{ message }}</p>
   -
   -        <div class="row">
   -            <div class="col form-group">
   -                <label>Bank / Institution</label>
   -                <div class="clearfix"></div>
   -                <institutions-search @update="update"></institutions-search>
   -            </div>
   -        </div>
   -
   -        <div class="form-row">
   -            <div class="form-group col">
   -                <label for="name">Bank login username</label>
   -                <input type="text" name="username" class="form-control" id="name" v-model="bank_connection.username">
   -            </div>
   -        </div>
   -        <div class="form-row">
   -            <div class="form-group col">
   -                <label for="password">Password</label>
   -                <input type="password" name="password" v-on:keyup.13="submit($event)" class="form-control" id="password" v-model="bank_connection.password">
   -            </div>
   -        </div>
   -        <div class="clearfix"></div>
   -        <errors :errors="errors"></errors>
   -
   -        <div class="form-row">
   -            <div class="form-group col">
   -                <div class="text-right">
   -                    <button class="btn btn-link" @click="close">Cancel</button>
   -                    <button type="submit" class="btn btn-large btn-primary" @click='submit'>Submit</button>
   -                </div>
   -            </div>
   -        </div>
   -        <div class="clearfix"></div>
   -        <p><strong>Note:</strong></p>
   -        <p><i>Budget It Up does not store login information entered on this page. You may be asked to re-enter it later.</i></p>
   -        <button class="modal-close" @click="close"></button>
   -    </div>
   -->
        <!--
		   -<div style="display: none;">
		   -    <div class="row">
		   -        <div class="col-sm-10 col-md-12">
		   -            <label for="pin">Pin</label>
		   -            <input type="password" name="pin" class="form-control" id="pin">
		   -        </div>
		   -    </div>
		   -</div>
           -->
