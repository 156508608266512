var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("5")]),
      _c("path", {
        attrs: {
          d:
            "M342,116.81a18.57,18.57,0,0,0-20.88-15.52l-28.3,4L279.32,82.13a18.56,18.56,0,0,0-25.11-6.8L190.33,111.5h0L170.8,122.56,88.62,134.18a18.51,18.51,0,0,0-15.71,21l3.11,21L49.91,191A18.5,18.5,0,0,0,43,216.31l58.28,101.48a18.49,18.49,0,0,0,25.32,6.79L221,269.82l122.8-18.69a18.53,18.53,0,0,0,15.5-21.08Zm-82.39-32a7.51,7.51,0,0,1,3.73-1,7.65,7.65,0,0,1,6.62,3.78l15.3,26.34,0,.09,42.19,72.63a7.61,7.61,0,0,1-2.77,10.42l-60.26,35-64-113.74ZM83.67,153.61A7.63,7.63,0,0,1,90.14,145L145,137.19,86.16,170.48Zm37.46,161.56a7.62,7.62,0,0,1-10.43-2.79L52.42,210.9a7.63,7.63,0,0,1,2.85-10.43L84.72,183.8l.06,0,90.32-51.14.05,0,15.79-8.94L255,237.49Zm221-74.79-95.46,14.53,83.49-48.44a18.5,18.5,0,0,0,6.71-25.29l-38.19-65.73,23.93-3.39a7.65,7.65,0,0,1,8.61,6.39l17.3,113.24A7.63,7.63,0,0,1,342.12,240.38Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M219.8,130.63a5.46,5.46,0,0,0,4.65,2.6,5.38,5.38,0,0,0,2.82-.79l38-23.14a5.43,5.43,0,0,0-5.66-9.28l-37.95,23.13A5.45,5.45,0,0,0,219.8,130.63Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M231.56,148a5.43,5.43,0,0,0,7.48,1.81l38-23.13a5.44,5.44,0,1,0-5.67-9.28l-37.94,23.13A5.43,5.43,0,0,0,231.56,148Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M240.36,164.87a5.42,5.42,0,0,0,7.47,1.81l37.95-23.14a5.43,5.43,0,0,0-5.66-9.28l-38,23.14A5.44,5.44,0,0,0,240.36,164.87Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M208.41,193.31a15.51,15.51,0,0,0-8.19-12.88,13.89,13.89,0,0,0-14.16.82L162.9,196l-44.25-21.06a5.75,5.75,0,0,0-1.89-.79,6,6,0,0,0-4.63.92L102.86,181a6.38,6.38,0,0,0-2,8.79A5.19,5.19,0,0,0,102,191l29.6,24.91-16.35,10.4-9.68-3.69a5.86,5.86,0,0,0-6.12.35l-6.89,4.38a6.4,6.4,0,0,0-2,8.8,5.63,5.63,0,0,0,1,1.17l14,12.35,5.24,17.95a5.45,5.45,0,0,0,.63,1.39,6.25,6.25,0,0,0,8.81,2l6.9-4.39a5.87,5.87,0,0,0,2.89-5.4l.75-10.33,16.35-10.4,10,37.36a5.47,5.47,0,0,0,.67,1.5,6.37,6.37,0,0,0,4,2.86,6,6,0,0,0,1.33.15,6.5,6.5,0,0,0,3.49-1l9.29-5.91a5.84,5.84,0,0,0,2.86-5.73l-.34-49.11,23.16-14.75A13.85,13.85,0,0,0,208.41,193.31Zm-12.57,3.31L170.14,213a5.43,5.43,0,0,0-2.52,4.62l.36,50-1.9,1.21-10.29-38.32a5.46,5.46,0,0,0-3.28-3.66,5.58,5.58,0,0,0-2-.36,5.39,5.39,0,0,0-2.92.85l-24.9,15.85a5.43,5.43,0,0,0-2.51,4.19l-.78,10.79-3.8-13a5.4,5.4,0,0,0-1.63-2.56l-10.19-9,10.1,3.86a5.43,5.43,0,0,0,4.86-.5l24.91-15.85a5.45,5.45,0,0,0,.58-8.75L113.9,186.83l1.9-1.2,45.14,21.48a5.44,5.44,0,0,0,5.26-.33l25.7-16.36a3,3,0,0,1,3.29-.34,4.62,4.62,0,0,1,2.36,3.7A3,3,0,0,1,195.84,196.62Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }