<template>
	<transition
		name="fade"
		>
		<div class="modal is-active new-transation-modal">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
                    <div class="px-3 py-3">
                        <h4 class="mb-4 mt-2 text-center">Edit Transaction</h4>
						<div class="form-group top-radio-btns">
                        <!-- v-bind:class="{ 'income':transaction.type=='income', 'expense':transaction.type=='expense' }"-->
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="options" id="option2" autocomplete="off" :checked="transaction.type=='income'">
                                <label class="custom-control-label price-value two income-text" for="option2" v-on:click="setType('income')">Income</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="options" id="option3" autocomplete="off" :checked="transaction.type=='expense'">
                                <label class="custom-control-label price-value two expense-text" for="option3" v-on:click="setType('expense')">Expense</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 form-group">
                                <label>Amount</label>
                                <currency-input :amount='transaction.amount'
                                 @subtractFromAmountValue='subtractFromAmountValue'
                                 @addToAmountValue='addToAmountValue'
                                 @passValue='passValue'
                                 ></currency-input>
                            </div>
                            <div class="col-sm-4 form-group">
                                <label>Date</label>
                                <date @setDate="setDate" :date='transaction.date_short'></date>
                            </div>
                        </div>
						<div class="row" v-if='transaction.subcategories.length<=1 && !showSplitTransaction'>
                            <div class="col-sm-6 form-group" @click='handleNoType'>
                                <label>Category</label>
                                <div class="clearfix"></div>
								<semantic-select :items="expense_categories" :preSelect='(firstSubcategory) ? firstSubcategory.transaction_category_id : null' @update='function (id) { setCategory(id,0) }' v-if='transaction.type=="expense"'></semantic-select>
								<semantic-select :items="income_categories" :preSelect='(firstSubcategory) ? firstSubcategory.transaction_category_id : null' @update='function (id) { setCategory(id,0) }' v-else-if='transaction.type=="income"'></semantic-select>
	                               <p><a href="#" @click="split($event)">Split category</a></p>
                            </div>
                            <div class="col-sm-6 form-group" @click='handleNoType'>
                                <label>Sub-category</label>
                                <div class="clearfix"></div>
								<semantic-select :items="subcategories" @update='function (id) { setSubcategory(id,0) }' v-if='transaction.type=="expense"' :preSelect='(firstSubcategory) ? firstSubcategory.id : null'></semantic-select>
								<semantic-select :items="subcategories" @update='function (id) { setSubcategory(id,0) }' v-else-if='transaction.type=="income"' :preSelect='(firstSubcategory) ? firstSubcategory.id : null'></semantic-select><!--  :preSelect="" -->
							</div>
                        </div>
						<split-transaction v-else-if='showSplitTransaction || transaction.subcategories.length<1' :transaction='transaction'></split-transaction>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label>Where did you spend the money?</label>
								<entitySelect @setEntity="setEntity" :current='transaction.entity'></entitySelect>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <errors :errors='errors'></errors>
                        <div class="form-row mt-4 justify-content-center">
                            <div class="form-group">
                                <button class="btn btn-outline-primary btn-wide" @click="cancel">Cancel</button>
                                <button class="btn btn-primary btn-wide ml-4" @click="save(index)">Save</button>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</transition>
	<!-- OLD -->
	<!--
	   -<transition
	   -    name="fade"
	   -    >
	   -    <div class="modal is-active">
	   -        <div class="modal-background"></div>
	   -        <div class="modal-content">
	   -            <div class="box">
	   -                <div class="px-3 py-3">
	   -                    <h4 class="mb-4 mt-2">Edit Transaction</h4>
	   -                    <div class="row">
	   -                        <div class="col form-group">
	   -                            <label>Date</label>
	   -                            <date @setDate="setDate" :date='transaction.date_short'></date>
	   -                        </div>
	   -                    </div>
	   -                    <div class="row">
	   -                        <div class="col form-group">
	   -                            <label>Amount</label>
	   -                            <currency-input :amount='transaction.amount'
	   -                             @subtractFromAmountValue='subtractFromAmountValue'
	   -                             @addToAmountValue='addToAmountValue'
	   -                             @passValue='passValue'
	   -                             ></currency-input>
	   -                        </div>
	   -                    </div>
	   -                    <div class="row">
	   -                        <div class="col form-group">
	   -                            <label>Payee</label>
	   -                            <entitySelect @setEntity="setEntity" :current='transaction.entity'></entitySelect>
	   -                        </div>
	   -                    </div>
	   -                    <addNewEntityModal v-if="showAddNewEntityModal"></addNewEntityModal>
	   -                    <div class="row">
	   -                        <div class="col form-group">
	   -                            <label>Type</label>
	   -                            <div class="btn-radio-group">
	   -                                <input type="radio" name="options"  autocomplete="off" v-model="transaction.type" value="income">
	   -                                <label class="btn btn-outline-primary" v-bind:class="{ 'active': transaction.type=='income' }" v-on:click="setType('income')">Income</label>
	   -                                <input type="radio" name="options" value="expense" v-model="transaction.type">
	   -                                <label class="btn btn-outline-primary" v-bind:class="{ 'active': transaction.type=='expense' }" v-on:click="setType('expense')">Expense</label>
	   -                            </div>
	   -                        </div>
	   -                    </div>
	   -                    <div class="row" v-if='transaction.subcategories.length<=1 && !showSplitTransaction'>
	   -                        <div class="col form-group">
	   -                            <label>Category</label>
	   -                            <div class="clearfix"></div>
	   -                            <dropdown @update="function (id) { update(id,0) }" :expense_categories="expense_categories" v-if='(transaction.type=="expense")' :current='(firstSubcategory && firstSubcategory.type=="expense") ? firstSubcategory.id : null'></dropdown>
	   -                            <dropdown @update="function (id) { update(id,0) }" :expense_categories="income_categories" v-else-if='(transaction.type=="income")' :current='(firstSubcategory && firstSubcategory.type=="income") ? firstSubcategory.id : null'></dropdown>
	   -                            <dropdown @update="function (id) { update(id,0) }" :disabled='true' v-else></dropdown>
	   -                            <p><a href="#" @click="split($event)">Split into more than one</a></p>
	   -                        </div>
	   -                    </div>
	   -                    <split-transaction v-else-if='showSplitTransaction || transaction.subcategories.length<1' :transaction='transaction'></split-transaction>
	   -      <errors :errors='errors'></errors>
	   -      <div class="row">
	   -          <div class="col form-group">
	   -              <button class="btn btn-danger mt10" @click="$emit('remove', transaction)">Remove</button>
	   -          </div>
	   -          <div class="col">
	   -              <div class="text-right">
	   -                  <button class="btn btn-link mt10" @click="cancel">Cancel</button>
	   -                  [><button class="btn btn-primary mt10" v-on:click="saveChanges(transaction)">Save Changes</button><]
	   -                  <button class="btn btn-primary mt10" @click="save(index)">Save</button>
	   -              </div>
	   -          </div>
	   -      </div>
	   -                </div>
	   -            </div>
	   -        </div>
	   -    </div>
	   -</transition>
	   -->
	                       <!--<div class="form-row">
	   -      <div class="col form-group">
	   -      <div class="form-check">
	   -      <input type="checkbox" v-model="transaction.set_always"
	   -      v-bind:true-value="1"
	   -      v-bind:false-value="0"
	   -      id="setAlways"
	   -      >
	   -      <label class="form-check-label" for="setAlways">
	   -      Set all future transactions with this entity in the same category.
	   -      </label>
	   -      </div>
	   -      </div>
	   -      </div>-->
</template>
<script>
//				<input type="text" :value="transaction.debit_amount">
//			 	<input type="text" :value="transaction.credit_amount">

export default {
	props:{
		transaction: Object,
		categories: Array,
        index: Number
	},
	data() {
		return {
			original: null,
			original_subcategories: null,
			subcategories: []
		}
	},
	mounted() {

		this.original = Object.assign({}, this.transaction);
		this.original_subcategories = Object.assign({}, this.transaction.subcategories);
        this.$store.commit('clearUpdateTransactionErrors');
	},
	created: function() {

		if(	this.transaction.type==='expense'){
			if(typeof(this.firstSubcategory)!=='undefined') {
				var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(this.firstSubcategory.transaction_category_id));
			}
		} else if (this.transaction.type==='income') {
			if(typeof(this.firstSubcategory)!=='undefined') {
				var m = this.$store.state.income_categories.find(category => Number(category.id) === Number(this.firstSubcategory.transaction_category_id));
			}
		}

		if(m) {
			this.subcategories = m.subcategories;
		}

	},
	computed: {
        showSplitTransaction() {
            return this.$store.state.showSplitTransaction;
        },
        showAddNewEntityModal() {
            return this.$store.state.showAddNewEntityModal;
        },
		income_categories () {
			return this.$store.state.income_categories;
		},
		expense_categories () {
			return this.$store.state.expense_categories;
		},
		transaction_categories () {
			return this.$store.state.transaction_categories;
		},
        errors() {
            return this.$store.state.update_transaction_errors;
        },
        firstSubcategory() {
            if (this.transaction.subcategories.length>0) {
                return this.transaction.subcategories[0];
            }
        },
        firstSubcategoryIndex() {
            return 0;
        }
	},
	methods: {

		handleNoType() {
			if(!this.transaction.type) {
				alert('You must first select a type, income or expense.');
				//alert($(this.$el).find('.dropdown'));
			}
		},

		setCategory(value) {
			if(this.transaction.type==='expense'){
				var m = this.$store.state.expense_categories.find(category => Number(category.id) === Number(value));
			} else if (this.transaction.type==='income') {
				var m = this.$store.state.income_categories.find(category => Number(category.id) === Number(value));
			}
			if(m) {
				this.subcategories = m.subcategories;
			}
		},

		setSubcategory: function(selected) {
			var that = this;
			if(this.transaction.type=='expense') {
				this.$store.state.expense_categories.forEach(function(category) {
					var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
                    console.log('==> transaction_subcategory', JSON.stringify(transaction_subcategory));
					if(transaction_subcategory) {
						if(that.transaction.subcategories.length==1) {
                            console.log(that.transaction.subcategories[0]);
							that.transaction.subcategories[0].id = transaction_subcategory.id;
							//that.transaction.subcategories.splice(subcategory_index, 1, {id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						} else if (that.transaction.subcategories.length==0) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						}
					}
				});

			} else if (this.transaction.type=='income') {
				this.$store.state.income_categories.forEach(function(category) {
					var transaction_subcategory = category.subcategories.find(subcategory => subcategory.id === Number(selected));
					if(transaction_subcategory) {
						if(that.transaction.subcategories.length==1 && transaction_subcategory) {
							that.transaction.subcategories[0].id = transaction_subcategory.id;
							// that.transaction.subcategories.splice(subcategory_index, 1, {id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						} else if (that.transaction.subcategories.length==0) {
							that.transaction.subcategories.push({id: transaction_subcategory.id, name: transaction_subcategory.name, amount: that.transaction.amount });
						}
					}
				});
			}

		},

        subtractFromAmountValue(index) {
            if(accounting.unformat(this.transaction.amount)-10>0) {
                return this.transaction.amount = accounting.unformat(this.transaction.amount) - 10;
            }
        },
        addToAmountValue(index) {
            return this.transaction.amount = accounting.unformat(this.transaction.amount) + 10;
        },
        passValue(value) {
            return this.transaction.amount = (value);
        },
        split(event) {

            event.preventDefault();
            this.$store.commit('setToggleSplitTransaction', true);

        },
        cancel() {

            this.$store.commit('setToggleSplitTransaction', false);
        	this.transaction.subcategories = Object.assign(this.transaction.subcategories, this.original_subcategories);
        	this.transaction = Object.assign(this.transaction, this.original);
            this.transaction.mode = "transaction"
            this.$store.commit('clearUpdateTransactionErrors');

        },
        save(key) {

            this.transaction.amount = accounting.unformat(this.transaction.amount);
			this.transaction.subcategories.forEach(function(val, key, original) {
				if(typeof(original[key]['pivot'])!=='undefined') {
                	original[key]['pivot']['amount'] = accounting.unformat(original[key]['pivot']['amount']);
				}
				if(typeof(original[key]['amount'])!=='undefined') {
                	original[key]['amount'] = accounting.unformat(original[key]['amount']);
				}
            });

			// prevent resetting the amount if user makes no changes
            if(this.transaction.subcategories.length>0 && this.transaction.subcategories[0].amount === null) {
                this.transaction.subcategories[0].amount = this.transaction.amount;
            }

            this.$store.commit('clearUpdateTransactionErrors');
            //this.$store.dispatch('categorize_transaction', {
            this.$store.dispatch('update_transaction', {
                transaction: this.transaction,
                key: key
            });
        },
		setType(type) {
			//this.type = type;
			this.transaction.type = type;
		},
        subtractFromValue(index) {
            if (this.transaction.amount - 10 > 0) {
                this.transaction.amount -= 10;
            }
        },
        addToValue(index) {
            this.transaction.amount += 10;
        },
		setEntity(entity) {
			this.transaction.entity = {id:Number(entity)};
			this.transaction.entity_id = Number(entity);
		},
		setDate(date) {
			this.transaction.date = date;
			this.transaction.date_short = date;
        },
	}
}
</script>
<!--<div class="col-md-1">
	{{ transaction.date_short }}
</div>-->
