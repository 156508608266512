var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("Artboard 1 copy 2")]),
      _c("path", {
        attrs: {
          d:
            "M340.22,41.56a6,6,0,0,0-8.48-.07l-78.9,77.64a6,6,0,0,0,8.42,8.55L340.15,50A6,6,0,0,0,340.22,41.56Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M277.9,149.42a6,6,0,0,0,4.21-1.72L361,70.06a6,6,0,1,0-8.42-8.55l-78.89,77.64a6,6,0,0,0,4.21,10.27Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M45,147.48c34.69,37.76,73,33,83.91,30.68a.27.27,0,0,1,.28.1l30.94,32.28L46.76,328.8A26.57,26.57,0,0,0,85,365.7L197.23,249.28l117.82,123a28.8,28.8,0,0,0,20.9,8.95H336a29,29,0,0,0,20.49-49.59L234.84,210.27l12.21-12.63a34,34,0,0,0,41.29-5.3l94.71-94.72a6,6,0,0,0-8.49-8.49l-94.7,94.72A22,22,0,0,1,253,187.23a11.91,11.91,0,0,0-14.66,2.08L222.18,206.1h0L76.38,357.36a14.65,14.65,0,0,1-20.38.55,14.57,14.57,0,0,1-.59-20.8l145.71-152h0l16.55-17.27a12,12,0,0,0,1-15.41,22.09,22.09,0,0,1,2.19-28.64l94.72-94.72a6,6,0,1,0-8.48-8.49l-94.72,94.72A34.11,34.11,0,0,0,209,159.53l-12.24,12.78L167.33,143a.33.33,0,0,1-.08-.34c3.27-10.63,11.26-48.25-22.9-86a133,133,0,0,0-33.9-26.39c-27.22-14.44-52.79-12.78-73.94,4.8-.16.14-.32.28-.47.43l-2.6,2.69C14,57.62,10,82.92,21.88,111.36A132.76,132.76,0,0,0,45,147.48ZM226.51,218.9,348,340.09a17,17,0,0,1-12,29.09h0a16.87,16.87,0,0,1-12.26-5.25L205.56,240.63ZM42,46.59l2.44-2.51c17.23-14.18,37.46-15.31,60.14-3.36,17.91,9.42,30.66,23.72,30.84,23.92,30,33.08,23.18,65.34,20.38,74.44a12.33,12.33,0,0,0,3.08,12.37L188.47,181l-20,20.9L137.84,170a12.21,12.21,0,0,0-11.47-3.52c-9.34,2-42.17,6-72.6-27.12-.13-.14-13.19-14.16-20.93-32.85C23,82.66,26,62.54,42,46.59Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M105.25,153.85h.62a24.35,24.35,0,0,0,17.78-7.41,6,6,0,1,0-8.48-8.49,12.49,12.49,0,0,1-9.65,3.9C93,141.6,77,127.13,71.89,121.58A6,6,0,1,0,63,129.69C63.93,130.66,85,153.42,105.25,153.85Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }