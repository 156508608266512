var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 400 400"
      }
    },
    [
      _c("title", [_vm._v("1")]),
      _c("path", {
        attrs: {
          d:
            "M94.4,346.9H305.6a5,5,0,0,0,5-5V278.75c0-.2,0-.4,0-.6s0-.26,0-.39a5,5,0,0,0-4.18-4.93L267.54,239a5.07,5.07,0,0,0-3.3-1.23H231.75a5,5,0,0,0,0,10h30.62L291,272.74H106.52l24.91-24.91h37.31a5,5,0,0,0,0-10H129.36a5,5,0,0,0-3.55,1.47l-35,34.95h0a5.71,5.71,0,0,0-.62.76c0,.08-.08.17-.13.25a5.15,5.15,0,0,0-.33.61c0,.11-.07.23-.1.34a6,6,0,0,0-.19.6,5.45,5.45,0,0,0-.1,1v64.12A5,5,0,0,0,94.4,346.9Zm5-10V282.78H300.58v54.08Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M179,166.85V236A20,20,0,0,0,219,236V168A58.94,58.94,0,1,0,179,166.85ZM208.92,236a9.93,9.93,0,0,1-19.86,0V169.82A58.68,58.68,0,0,0,200.61,171a60,60,0,0,0,8.31-.59Zm-8.31-172.9A48.9,48.9,0,1,1,151.72,112,48.94,48.94,0,0,1,200.61,63.14Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M171.34,110a34.86,34.86,0,0,1,18.82-26.11,5,5,0,1,0-4.51-9,44.89,44.89,0,0,0-24.25,33.64,5,5,0,0,0,4.26,5.68,4.36,4.36,0,0,0,.72,0A5,5,0,0,0,171.34,110Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }