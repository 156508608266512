<template>	
	<div class="account-progress">
		<h5 class="title1">Overview</h5>
		<ul class="stats-group">
			<li class="list-group-item">
				Total Saved
				<span>${{ total_goals_saved }}</span>
			</li>
			<li class="list-group-item">
				Total To Go
				<span>${{ total_left_to_save }}</span>
			</li>
			<li class="list-group-item">
				Total Goals
				<span>${{ total_goals_amount }}</span>
			</li>
			<li class="list-group-item">
				Number of Goals
				<span>{{	total_number_of_goals }}</span>
			</li>
			<li class="list-group-item">
				Total Due This Month
				<span>${{ left_to_allocate_this_month }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
    export default {
		mounted: function() {
            this.$store.dispatch('get_current_date');
        },
        methods: {
        
			round (value, decimals) {
  				return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
			},
            monthStringFromDate(date) {
                var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
               if(typeof(date)==='object') {
                   return  monthNames[date.getMonth()];
               }
            },
            number_format(number, decimals=2) {
                var n = !isFinite(+number) ? 0 : +number, 
                    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                    toFixedFix = function (n, prec) {
                        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                        var k = Math.pow(10, prec);
                        return Math.round(n * k) / k;
                    },
                    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
                if (s[0].length > 3) {
                    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
                }
                if ((s[1] || '').length < prec) {
                    s[1] = s[1] || '';
                    s[1] += new Array(prec - s[1].length + 1).join('0');
                }
                return s.join(dec);
            }
        },
		computed: {
            left_to_allocate_this_month() {
    			    return this.number_format(this.$store.getters.total_allocations_due_this_month);
            },
            total_goals_saved() {
                if(this.$store.getters.total_goals_saved) {
    			    return this.number_format(this.$store.getters.total_goals_saved);
                }
                return 0;
            },
            total_left_to_save() {
                if(this.$store.getters.total_goals_saved) {
    			    return this.number_format(this.$store.getters.total_goals - this.$store.getters.total_goals_saved);
                }
                return 0;
            },
			total_number_of_goals() {
				return this.goals.length;
			},
			total_goals_amount () {
                if(this.$store.getters.total_goals) {
    			    return this.number_format(this.$store.getters.total_goals);
                }
                return 0;
			},
			month() {
                if(this.$store.state.current_date){
                    return this.monthStringFromDate(this.$store.state.current_date);
                }
			},
            year() {
                if(this.$store.state.current_date){
                    return this.$store.state.current_date.getFullYear();
                }
            },
			goals() {
				return this.$store.state.goals;
			},
			total_to_be_budgeted () {
				return this.round(this.$store.getters.total_to_be_budgeted,2);
			},
		},
		watch: {

		}
	}
</script>
<!--
<div class="row">
    <div class="col-lg-12">
        <div class="text-center">
            <div id="test-circle3"></div>
        </div>
    </div>
</div>
->
