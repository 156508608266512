<template>
    <div :class="{ mainWrap }" class="main-wrap budgets">
        <router-view name="navbar"></router-view>
        <div aria-live="polite" aria-atomic="true" style="position: relative;">
            <div style="position: absolute; top: 0; right: 0;">
                <toast v-for="(toast, index) in toasts"
                        :toast="toast"
                        :index="index"
                        :key="index"
                        @hide="hideToask(toast, index)"
                        >
                </toast>
            </div>
        </div>
		<router-view></router-view>
    </div>
</template>
<script>
export default {
    beforeCreate() {
		this.$store.commit('initialiseToasts');
        //v-bind:class="{ 'main-wrap landing-page': $route.path == '/', 'h-100': ($route.path == '/login' || $route.path == '/password/reset' || /password/.test($route.path) ), 'main-wrap budgets': $route.path == '/budgets', 'main-wrap goals': $route.path == '/goals', 'main-wrap accounts': $route.path == '/accounts', 'main-wrap institutions': $route.path == '/connected-banks', 'main-wrap account': $route.path == '/account', 'main-wrap plan': $route.path == '/account/plan', 'main-wrap change': $route.path == '/account/plan/change', 'main-wrap boss': $route.path == '/purchase/plan/boss', 'h-100': ( $route.path !== '/' ||  $route.path !== '/budgets' || $route.path !== '/accounts' || $route.path !== '/goals') }"
	},
    computed: {
        mainWrap () {
            const regexpString = '/^budgets/months\/[0-9]+/year\/[0-9]+/';
            const regexp = new RegExp(regexpString);
            return true;
            return this.$route.matched.some(({ name }) => regexp.test(name))
        },
        toasts() {
            return this.$store.state.toasts;
        },
    }
}
</script>
