<template>
	<div class="row align-items-center transations-filter">
		<div class="col-xl-6 col-lg-5">
			<div class="input-group custom-search ml-auto mr-auto ml-lg-3" v-bind:class="{ 'disabled':(accounts.length==0 || (this_month_transactions.length==0 && (!search))) }">
				<div class="input-group-prepend">
					<div class="input-group-text" id="btnGroupAddon2"><i class="fa fa-search"></i></div>
				</div>
				<input class="form-control" type="search" placeholder="Search Transactions" v-model="search" :disabled="accounts.length==0 || (this_month_transactions.length==0 && (!search))">
			</div>
		</div>
		<div class="col-xl-6 col-lg-7 mt-4 mt-lg-0 text-center text-lg-left">
			<button class="btn btn-orange btn-wide btn-box-shadow mr-2" @click="set_current_input('addNewTransaction')" :disabled="accounts.length==0">Add Transaction</button>
			<button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" :disabled="accounts.length==0 || this_month_transactions.length==0">
                <icon-base icon-name="filter" height="20" width="20"><icon-filter slot="icon"></icon-filter></icon-base>
            </button>

            <button href="#" @click="toggleGraph" class="btn btn-secondary" :disabled="accounts.length==0 || this_month_transactions.length==0">
                <icon-base icon-name="graph" height="15" width="20"><icon-graph slot="icon"></icon-graph></icon-base>
            </button>
		</div>
	</div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            category_filter: 'all',
            search: '',
            type_filter: '',
        }
    },
    mounted: function() {

        if(this.$store.state.entities.length==0) {
            this.$store.dispatch('get_entities');
        }

        if(this.$store.state.income_categories.length==0) {
            this.$store.dispatch('get_income_categories');
        }

        if(this.$store.state.expense_categories.length==0) {
            this.$store.dispatch('get_expense_categories');
        }

    },
    methods: {
		toggleGraph() {
			return this.$store.commit('setToggleTransactionsGraphModal', {setting: true});
		},
		setType(type) {
			this.type_filter = type;
		},
        loadCurrentMonth() {
            var current = new Date();
            var current_year = this.$store.state.current_date.setYear(current.getFullYear());
            var current_month = this.$store.state.current_date.setMonth(current.getMonth());
            this.$store.commit('setCurrentDate', new Date(current_month));
            this.$store.dispatch('get_budgets');
            this.$store.dispatch('get_month_transactions', {account: this.account});
        },
        loadPreviousMonth() {
            var previous_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()-1);
            this.$store.commit('setCurrentDate', new Date(previous_month));
            this.$store.dispatch('get_budgets');
            this.$store.dispatch('get_month_transactions', {account: this.account});
        },
        loadNextMonth() {
            var next_month = this.$store.state.current_date.setMonth(this.$store.state.current_date.getMonth()+1);
            this.$store.commit('setCurrentDate', new Date(next_month));
            this.$store.dispatch('get_budgets');
            this.$store.dispatch('get_month_transactions', {account: this.account});
        },
        monthStringFromDate(date) {
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            if(typeof(date)==='object') {
                return  monthNames[date.getMonth()];
            }
        },
		toggle () {
			this.$store.commit('toggleTransactionMode');
		},
        set_current_input(input) {
			this.$store.commit('setCurrentInput', {input: input});
		},
        close () {
            $('.collapse').collapse('hide');
        }
    },
    computed: {

		income_categories () {
			return this.$store.state.income_categories;
		},
		expense_categories () {
			return this.$store.state.expense_categories;
		},
        account() {
            return this.$store.state.account;
        },
        accounts() {
            return this.$store.state.accounts;
        },
        month() {
            if(this.$store.state.current_date){
                return this.monthStringFromDate(this.$store.state.current_date);
            }
        },
        year() {
            if(this.$store.state.current_date){
                return this.$store.state.current_date.getFullYear();
            }
        },
		new_transaction() {
			return this.$store.state.new_transaction;
		},
        this_month_transactions() {
            return this.$store.getters.this_month_transactions;
        }
    },
    watch: {
        search: function(newValue, oldValue) {
            this.$store.commit('searchTransactions', newValue)
        },
        category_filter: function(newValue, oldValue) {
            this.$store.commit('setTransactionCategoryStatusFilter', newValue)
            this.$store.commit('filterTransactions')
        },
        type_filter: function(newValue, oldValue) {
            this.$store.commit('setTransactionTypeFilter', newValue)
            this.$store.commit('filterTransactions')
        }

    }
}
</script>
