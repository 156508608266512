var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 400 400" } },
    [
      _c("title", [_vm._v("i-14")]),
      _c("path", {
        attrs: {
          d:
            "M353.4,314H339.51V285.2a6,6,0,0,0-6-6h-20V176.31h20a6,6,0,0,0,6-6V142H353.4a6,6,0,0,0,3-11.18L203.75,42a6,6,0,0,0-6,0L43.6,130.85a6,6,0,0,0,3,11.19H62v28.27a6,6,0,0,0,6,6H88.27V279.2H68a6,6,0,0,0-6,6V314H46.6a6,6,0,0,0-6,6v32.86a6,6,0,0,0,12,0V326H347.4v26.86a6,6,0,0,0,12,0V320A6,6,0,0,0,353.4,314ZM301.51,279.2H280.17V176.31h21.34Zm-167.9,0V176.31H268.17V279.2ZM200.72,54.07l130.45,76H69ZM74,142H327.51v22.27H74Zm26.32,34.27h21.34V279.2H100.27ZM74,291.2H327.51V314H74Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M224,243.88c0-11.47-10.13-20.87-22.86-21.56a6,6,0,0,0-1.48-.19c-6.81,0-12.35-4.5-12.35-10s5.54-10,12.35-10c5,0,9.47,2.45,11.4,6.23a6,6,0,0,0,10.68-5.47,24,24,0,0,0-16.08-12.06v-5.24a6,6,0,1,0-12,0v5.22c-10.53,2.43-18.35,11.08-18.35,21.36,0,11.69,10.12,21.28,22.85,22a6.17,6.17,0,0,0,1.5.2c6.8,0,12.34,4.3,12.34,9.6s-5.54,9.6-12.34,9.6c-6.14,0-11.52-3.71-12.26-8.46a6,6,0,1,0-11.85,1.85c1.37,8.8,8.73,15.79,18.11,17.92V270a6,6,0,0,0,12,0v-5.17C216.13,262.44,224,254,224,243.88Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }