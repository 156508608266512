<template>
	<transition
		name="fade"
		>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
                    <div class="px-3 py-3">
                        <h4 class="mb-4 mt-2">Spending by Category for Last 7 Days</h4>	
					</div>

					<div class="row">
						<div class="col-md-12">
							<div class="card-box">
								<div id="multi-line-chart" class="ct-chart ct-golden-section"></div>
							</div>
						</div>
					</div>
                    <!--
					   -<div class="row">
					   -    <div class="col-md-6">
					   -        <div class="card-box">
					   -            <div id="pie-chart" class="ct-chart ct-golden-section simple-pie-chart-chartist"></div>
					   -        </div>
					   -    </div>
					   -</div>
                       -->

					<div class="form-row mt-4">
						<div class="form-group col">
							<div class="text-right">
								<button class="btn btn-outline-primary btn-wide" @click="cancel">Done</button>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	mounted() {

			var data = [];
			var amount = [];
			var spent = [];
			var labels = [];

			// @foreach($budgets as $key => $budget)
			//@if($budget->spent>0)
			var subcategories = [];	
			var sevenDays = [];
			var today = new Date();
			var past = subDays(today, 7);
			var lastSevenDaysTransactions = [];

			this.$store.state.this_month_transactions.forEach(function(val, key) {
				if(val){
					var result = isAfter(new Date(val['date']), past)
					if(result) {
						lastSevenDaysTransactions.push(val);
					}
				}
			});
			lastSevenDaysTransactions.forEach(function(val, key) {
				//amount.push(Number(val['debit_amount']));
                if (val['subcategories'] && val['subcategories'].length>0) {
					//labels.push(val['subcategories'][0]['id']);
					subcategories.push({id:val['subcategories'][0]['id'], amount: 0, label: null});
				}
			});

			lastSevenDaysTransactions.forEach(function(val, key) {

				if (val['subcategories'] && val['subcategories'].length>0) {
					var m = subcategories.findIndex(subcategory => Number(val['subcategories'][0]['id']) === Number(subcategory.id));
					// findIndex returns -1 for false
					if(m>-1 && val['subcategories'] && val['subcategories'].length > 0) {
						subcategories[m].amount += Number(val['credit_amount']);
						subcategories[m].label = val['subcategories'][0]['name'];
					}
				}
			});

		subcategories.forEach(function(val, key) {

			labels.push(val.label);
			amount.push(val.amount);
		});
		

/*			amount.push(20);
			amount.push(120);
			amount.push(180);
			amount.push(10);
			spent.push(60);
			spent.push(80);
			spent.push(20);
			spent.push(30);
			labels.push('Test');
			labels.push('Testing');
			labels.push('Testing ABC');
			labels.push('Testing ABC 123');*/
			//@endif
			// @endforeach
			data[0] = amount;
		//	data[1] = spent;

			new Chartist.Bar('#multi-line-chart', {
				labels: labels,
				series: data 
			}, {
				seriesBarDistance: 20,
				reverseData: true,
				horizontalBars: true,
				axisX: {
					labelInterpolationFnc: Chartist.noop
				},
				axisY: {
					offset: 120
				}
			});

			/*var piedata = {
				labels: labels,
				series: spent
			};
			var options = {
				labelInterpolationFnc: function(value) {
					return value[0]
				}
			};

			var responsiveOptions = [
				['screen and (min-width: 640px)', {
					chartPadding: 30,
					labelOffset: 100,
					labelDirection: 'explode',
					labelInterpolationFnc: function(value) {
						return value;
					}
				}],
				['screen and (min-width: 1024px)', {
					labelOffset: 80,
					chartPadding: 20
				}]
			];

			new Chartist.Pie('#pie-chart', piedata, options, responsiveOptions);
            */
	},
	methods: {
		cancel() {

			return this.$store.commit('setToggleTransactionsGraphModal', {setting: false}); 

		},
	}
}
</script>
