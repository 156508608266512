var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "budgets-list" }, [
      _vm.spread_out_budgets && _vm.spread_out_budgets.length
        ? _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c("div", { staticClass: "row table-header" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _vm._v(
                      "\n                        Category\n                        "
                    ),
                    _c(
                      "icon-base",
                      {
                        attrs: {
                          "icon-name": "write",
                          height: "20",
                          width: "20",
                          iconClass: "ml-2 mt-1"
                        }
                      },
                      [
                        _c("icon-sort", {
                          attrs: { slot: "icon" },
                          slot: "icon"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _vm._v("\n                         \n                    ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _vm._v(
                      "\n                        Amount\n                        "
                    ),
                    _c(
                      "icon-base",
                      {
                        attrs: {
                          "icon-name": "write",
                          height: "20",
                          width: "20",
                          iconClass: "ml-2 mt-1"
                        }
                      },
                      [
                        _c("icon-sort", {
                          attrs: { slot: "icon" },
                          slot: "icon"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _vm._v(
                      "\n                        Spent\n                        "
                    ),
                    _c(
                      "icon-base",
                      {
                        attrs: {
                          "icon-name": "write",
                          height: "20",
                          width: "20",
                          iconClass: "ml-2 mt-1"
                        }
                      },
                      [
                        _c("icon-sort", {
                          attrs: { slot: "icon" },
                          slot: "icon"
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(0)
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-1" })
              ]),
              _vm._v(" "),
              _vm.budgets && _vm.budgets.length > 0
                ? _c(
                    "span",
                    _vm._l(_vm.spread_out_budgets, function(budget, index) {
                      return _c(budget.mode, {
                        key: budget.id,
                        tag: "budget",
                        attrs: {
                          budget: budget,
                          index: index,
                          percent: _vm.percent
                        },
                        on: {
                          edit: function($event) {
                            return _vm.edit(budget, index)
                          },
                          remove: function($event) {
                            return _vm.remove(budget, index)
                          },
                          update: function($event) {
                            return _vm.update(budget, index)
                          },
                          subtractFromValue: function($event) {
                            return _vm.subtractFromValue(index)
                          },
                          addToValue: function($event) {
                            return _vm.addToValue(index)
                          },
                          balanceBudget: function($event) {
                            return _vm.balanceBudget(budget.id)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _c("loading-budget")
            ],
            1
          )
        : _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-center" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-svg",
                      attrs: {
                        disabled:
                          _vm.current_input === "add-new-budget" ||
                          !_vm.current_month_is_shown_month
                      },
                      on: {
                        click: function($event) {
                          return _vm.set_current_input("add-new-budget")
                        }
                      }
                    },
                    [
                      _c(
                        "icon-base",
                        {
                          attrs: {
                            "icon-name": "graph",
                            height: "200",
                            width: "200",
                            "view-box": "0 0 200 200",
                            "icon-class": "mt-5"
                          }
                        },
                        [
                          _c("icon-budget", {
                            attrs: { slot: "icon" },
                            slot: "icon"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "onerem text-center" }, [
              _vm._v(
                "You have no expenses for " +
                  _vm._s(_vm.month) +
                  " " +
                  _vm._s(_vm.year) +
                  "."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-center" }, [
              _vm._v("Create a new expense using the button above.")
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "switch-icon", attrs: { href: "" } }, [
      _c("i", { staticClass: "fas fa-exchange-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }