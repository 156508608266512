<template>
    <div class="h-100">
        <full-page-loading-screen v-if='isLoggingIn'></full-page-loading-screen>
        <div class="main-wrap login" v-else>
            <form class="form-signin" method="POST" action="/reset-password">
                <div class="text-center mb-4">
                    <router-link :to="{ name: 'home' }" class="nav-link font-weight-bold small">
                        <img src="/images/logo.png" class="mb-4" alt="Budget It Up Logo">
                    </router-link>
                    </a>
                </div>
                <div class="form-label-group">
                    <input type="email" v-model="user.email" value="" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
                    <label for="inputEmail">Email address</label>
                    <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                    </div>
                </div>
                <div class="form-label-group">
                    <input type="password" class="form-control" id="inputPassword" v-model="user.password" placeholder="Password" required>
                    <label for="inputPassword">Password</label>
                    <div class="input-icon">
                        <i class="fa fa-lock"></i>
                    </div>
                </div>
                <div class="form-label-group">
                    <input type="password" class="form-control" id="inputPasswordConfirmation" v-model="user.password_confirmation" placeholder="Password confirmation" required>
                    <label for="inputPasswordConfirmation">Password confirmation</label>
                    <div class="input-icon">
                        <i class="fa fa-lock"></i>
                    </div>
                </div>
                <errors :errors="errors"></errors>
                <a href="#" class="btn btn-lg btn-outline-primary btn-block mt-5" @click="sendReset($event)">Reset Password</a>
                <p class="mt-5 mb-3 text-muted text-center">
                <a class="underline-link" href="/signup">Don't have an account?</a>
                </p>
                <p class="mt-5 mb-3 text-muted text-center">
                <a class="underline-link" href="/login">Remember your password?</a>
                </p>
            </form>
        </div>
    </div>
</template>

<script>
export default {
	beforeCreate: function() {
		document.body.className = 'pt-0';
	},
	mounted: function() {
		document.getElementById('app').className='h-100';
        document.body.className = 'pt-0';
        this.user.email = this.$router.currentRoute.params.email;

	},
	destroyed: function(){
		document.body.className = '';
		document.getElementById('app').className='';
	},
	data() {
		return {
			user: {
				email: null,
				password: null,
				password_confirmation: null,
				token: this.$route.params.token
			},
		}
	},
	computed: {
		isLoggingIn() {
			return this.$store.state.isLoggingIn;
		},
		errors() {
			return this.$store.state.reset_password_errors;
		},
	},
	methods: {
		sendReset(event) {
			event.preventDefault();
			this.$store.commit('clearResetPasswordErrors');
			if(this.validate()) {
				this.$store.dispatch('reset_password', {userCredentials: this.user})
			}
		},
		validate() {
			if(this.user.email) {
				return true;
			} else if (!this.user.email) {
				this.$store.commit('addResetPasswordErrors', { 'message': 'Please enter your email.'});
			}
		}
	}
}
</script>
