var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M21.25 2.5H18.75V1.25C18.75 0.559644 18.1904 0 17.5 0C16.8096 0 16.25 0.559644 16.25 1.25V2.5H8.75V1.25C8.75 0.559644 8.19036 0 7.5 0C6.80964 0 6.25 0.559644 6.25 1.25V2.5H3.75C1.67893 2.5 0 4.17893 0 6.25V21.25C0 23.3211 1.67893 25 3.75 25H21.25C23.3211 25 25 23.3211 25 21.25V6.25C25 4.17893 23.3211 2.5 21.25 2.5ZM22.5 21.25C22.5 21.9404 21.9404 22.5 21.25 22.5H3.75C3.05964 22.5 2.5 21.9404 2.5 21.25V12.5H22.5V21.25ZM2.5 10H22.5V6.25C22.5 5.55964 21.9404 5 21.25 5H18.75V6.25C18.75 6.94036 18.1904 7.5 17.5 7.5C16.8096 7.5 16.25 6.94036 16.25 6.25V5H8.75V6.25C8.75 6.94036 8.19036 7.5 7.5 7.5C6.80964 7.5 6.25 6.94036 6.25 6.25V5H3.75C3.05964 5 2.5 5.55964 2.5 6.25V10Z",
          fill: "#00B19E"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }